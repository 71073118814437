import { FC, ReactNode, useState } from 'react'

export interface Column {
    className?: string
    label: string
    data?: Record<string, string | number>
}

interface Props {
    columns: Column[]
    children?: ReactNode
    onSelect?: (selected: boolean) => void
    layout?: 'fixed' | 'auto'
}

const Table: FC<Props> = ({ layout = 'auto', columns, children, onSelect }) => {
    const [selected, setSelected] = useState<boolean>(false)

    const className = layout === 'auto' ? 'table-auto' : 'table-fixed'

    return (
        <table className={`${className} w-full`}>
            <thead>
                <tr className="mb-2 w-full border-b border-heather text-center">
                    {onSelect && (
                        <th className="w-10 text-center font-medium">
                            <input
                                type="checkbox"
                                className="text-christine"
                                onChange={() => {
                                    const newValue = !selected
                                    setSelected(newValue)
                                    onSelect(newValue)
                                }}
                            />
                        </th>
                    )}
                    {columns.map((column, index) => (
                        <th key={index} className={`${column.className ?? ''} font-medium`} {...column.data}>
                            <span>{column.label}</span>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    )
}

export default Table
