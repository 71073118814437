import { faEdit, faTrash, faUsers } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from 'assets/backoffice/loader'
import DirectoryIcon from 'components/dataroom/spaceroom/directory-icon'
import ToggleButton from 'components/shared/toggle-button'
import useDebounce from 'core/debounce'
import { del } from 'core/services/http-service'
import toast from 'core/utils/toast'
import { observer } from 'mobx-react-lite'
import { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Partner } from 'stores/partners'
import { useMst } from 'stores/store'
import Admin from '../admin'
import Pagination from '../pagination'

const PartnerLine = ({
    partner,
    showDelete,
    onDelete,
}: {
    partner: Partner
    showDelete: boolean
    onDelete: (partner: Partner) => void
}) => {
    const { t } = useTranslation()
    const [enabled, setEnabled] = useState<boolean>(true)
    const [isLoading, setLoading] = useState<boolean>(false)

    const togglePartner = useCallback(async () => {
        setLoading(true)
        await partner.toggle()
        setEnabled(partner.enabled)
        setLoading(false)
    }, [partner.uuid])

    useEffect(() => {
        setEnabled(partner.enabled)
    }, [partner.uuid])

    return (
        <tr>
            <td className="text-left">
                <div className="flex w-full items-center">
                    <DirectoryIcon partnerIcon={partner.icon} />
                    {partner.name}
                </div>
            </td>
            <td className="text-left">
                <a href={partner.url} target="_blank" rel="noopener noreferrer">
                    {partner.url}
                </a>
            </td>
            <td>
                <div className="flex w-full items-center justify-center">
                    {isLoading ? (
                        <Loader loading={isLoading} className="text-christine" />
                    ) : (
                        <ToggleButton value={enabled} onChange={() => togglePartner()} />
                    )}
                </div>
            </td>
            <td className="flex space-x-1 text-christine">
                <Link to={`/admin/partners/${partner.uuid}`}>
                    <FontAwesomeIcon icon={faEdit} />
                </Link>
                <Link to={`/admin/partners/${partner.uuid}/users`}>
                    <FontAwesomeIcon icon={faUsers} />
                </Link>
                {showDelete && (
                    <button
                        type="button"
                        onClick={async () => {
                            if (confirm(t('web_admin_confirm_delete_partner'))) {
                                onDelete(partner)
                            }
                        }}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                )}
            </td>
        </tr>
    )
}

const limit = 50

const Listing = observer(() => {
    const { admin, user } = useMst()
    const { t } = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [offset, setOffset] = useState<number>(0)
    const [query, setQuery] = useState<string>('')
    const [page, setPage] = useState<number>(0)
    const [pageCount, setPageCount] = useState<number>(0)

    const load = useCallback(async () => {
        setLoading(true)
        await admin.loadPartners(limit, offset, query)

        setPageCount(Math.ceil(admin.totalPartners / limit))

        setLoading(false)
    }, [offset, query])

    const changePage = async page => {
        setPage(page)
        setOffset(Math.ceil(page * limit))
    }

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            load()
        }
    }

    const debouncedQuery = useDebounce<string>(query, 500)
    useEffect(() => {
        load()
    }, [page, debouncedQuery])

    useEffect(() => {
        setPage(0)
    }, [])

    const [showDelete, setShowDelete] = useState<boolean>(false)
    useEffect(() => {
        setShowDelete(user.isAdmin)
    }, [user])

    const deletePartner = async (partner: Partner) => {
        setLoading(true)
        await del(`/v1/web/partners/${partner.uuid}`)
        await load()
        toast('success', 'web_admin_partner_deleted')
    }

    return (
        <Admin
            title={t('web_admin_partners')}
            isLoading={isLoading}
            header={
                <div className="flex items-center space-x-4">
                    <Link to="/admin/partners/create" className="btn">
                        {t('web_admin_partners_new')}
                    </Link>
                    <div className="flex items-center rounded border bg-white px-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2 h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>
                        <input
                            type="text"
                            className="m-0 grow border-none bg-transparent p-0 text-sm focus:border-none focus:outline-none"
                            placeholder={t('web_dashboard_search')}
                            onChange={e => setQuery(e.currentTarget.value)}
                            onKeyDown={e => onKeyDown(e)}
                        />
                    </div>
                </div>
            }>
            <table className="admin w-full table-auto text-center">
                <thead>
                    <tr>
                        <th>{t('web_menu_admin_partners_name')}</th>
                        <th>{t('web_menu_admin_partners_url')}</th>
                        <th>{t('web_menu_admin_partners_status')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {admin.partners.map(partner => (
                        <PartnerLine
                            key={partner.uuid}
                            partner={partner}
                            showDelete={showDelete}
                            onDelete={async partner => await deletePartner(partner)}
                        />
                    ))}
                </tbody>
            </table>
            <div className="mt-8 flex w-full justify-center">
                {pageCount > 1 && <Pagination page={page} totalPages={pageCount} onChangePage={changePage} />}
            </div>
        </Admin>
    )
})

export default Listing
