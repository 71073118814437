import i18next from 'i18next'
import { toJS, values } from 'mobx'
import { Instance, getParent, getRoot, types } from 'mobx-state-tree'
import { RootInstance } from 'stores/store'
import { Files } from '../files'
import { SpaceDirectory } from './directory'
import { SpaceFile } from './file'
import { Space, _Space } from './space'

export enum FileStatus {
    to_sort = 'to_sort',
    pre_sorted = 'pre_sorted',
    sorted = 'sorted',
    dupe = 'dupe',
    quarantine = 'quarantine',
    to_be_validated = 'to_be_validated',
    trash = 'trash',
    loading = 'loading',
}

export const _FilenameSuggestionTag = types.model('FilenameSuggestionTag', {
    key: '',
    label: '',
    value: '',
})
export interface SuggestionTag extends Instance<typeof _FilenameSuggestionTag> { }

export const _FilenameSuggestion = types
    .model('FilenameSuggestion', {
        extension: '',
        filename: '',
        origFilename: '',
        uniqFilename: '',
        imported: types.optional(types.string, '', [null, undefined]),
        prefered: types.optional(types.string, '', [null, undefined]),
        saveTags: types.optional(types.array(_FilenameSuggestionTag), [], [null, undefined]),
        transform: types.optional(types.string, '', [null, undefined]),
        separator: types.optional(types.string, '', [null, undefined]),
        suggestions: types.array(types.string),
        tags: types.array(_FilenameSuggestionTag),
        spaces: types.map(types.late(() => _Space)),
        unknown: types.array(types.string),
        infected: false,
    })
    .volatile<{
        directoryUuid: string
        directoryName: string
        spaceUuid: string
        spaceName: string
    }>(() => ({
        directoryUuid: '',
        directoryName: '',
        spaceUuid: '',
        spaceName: '',
    }))
    .views(self => ({
        get space(): Space | undefined {
            const { files } = getRoot(self) as RootInstance

            if (self.spaceUuid === '') {
                return values<Space>(files.spaces).find(space => space.isTheStack)
            }

            return values<Space>(files.spaces).find(space => space.uuid === self.spaceUuid)
        },

        get directory(): SpaceDirectory | undefined {
            if (self.directoryUuid === '') {
                return this.space.rootDirectory
            }

            const { files } = getRoot(self) as RootInstance

            return files.recursivelyFindDirectory(self.directoryUuid, this.space)
        },

        get processed(): boolean {
            return self.suggestions.length > 1
        },

        get status(): FileStatus {
            if (self.infected) {
                return FileStatus.quarantine
            }

            if (!this.space || this.space.isTheStack) {
                const { files } = getRoot(self) as RootInstance
                const parent = getParent(self, 1) as SpaceFile
                const hashDates: Record<string, number> = files.getFilesByHash(parent.hash)

                if (Object.keys(hashDates).length !== 1) {
                    for (const uuid of Object.keys(hashDates)) {
                        if (uuid === parent.uuid) {
                            continue
                        }
                        const date = hashDates[uuid]
                        if (date < parent.dbCreatedAt) {
                            return FileStatus.dupe
                        }
                    }
                }

                if (self.directoryName !== '' && self.filename !== self.origFilename && this.suggestedSpace) {
                    return FileStatus.pre_sorted
                }

                const space = values<Space>(self.spaces).length === 0 ? undefined : values<Space>(self.spaces)[0]
                if (space) {
                    return FileStatus.pre_sorted
                }

                return FileStatus.to_sort
            }

            return FileStatus.to_be_validated
        },

        get availableSpaces(): Space[] {
            const parent = getParent(self, 2) as Files

            const spaces = [
                ...values<Space>(self.spaces),
                ...values<Space>(parent.spaces).filter(space => !space.isTheStack && !space.isQuarantine),
            ]
                .filter((space, index, self) => self.findIndex(s => space.displayName === s.displayName) === index)
                .sort((a, b) => a.displayName.localeCompare(b.displayName))

            return spaces
        },

        get suggestedSpace(): Space | undefined {
            return values<Space>(self.spaces).length === 0 ? undefined : values<Space>(self.spaces)[0]
        },

        get currentSpace(): string {
            if (this.status === FileStatus.quarantine) {
                return i18next.t('api_space_quarantine')
            }
            if ([FileStatus.trash, FileStatus.dupe].includes(this.status)) {
                return i18next.t('api_space_trash')
            }

            if ([FileStatus.pre_sorted].includes(this.status) && this.suggestedSpace) {
                return this.suggestedSpace.displayName
            }

            if (!this.space || this.space.isTheStack) {
                return i18next.t('api_space_the_stack')
            }

            return this.space.displayName
        },

        get suggestedDirectory() {
            const space = this.suggestedSpace
            if (!space) {
                return undefined
            }

            let directory = toJS(space.rootDirectory)
            // eslint-disable-next-line no-constant-condition
            while (true) {
                if (!directory.directories || directory.directories.length === 0) {
                    break
                }
                const children = Array.from(directory.directories)
                if (!children || children.length === 0) {
                    break
                }
                directory = children[0][1]
            }

            return directory
        },

        get spaceAndPath(): string {
            const spaceName = this.currentSpace

            if ([FileStatus.pre_sorted].includes(this.status)) {
                const suggestedDirectory = this.suggestedDirectory

                return `${spaceName} > ${suggestedDirectory?.name ?? self.directoryName}`
            }
            if ([FileStatus.dupe, FileStatus.quarantine].includes(this.status) && spaceName) {
                return `${spaceName}`
            }

            if (!this.space || this.space.isTheStack) {
                return ''
            }

            return `${spaceName} > ${self.directoryName}`
        },
    }))
    .actions(self => ({
        setSpace(choosenSpace: Space | undefined) {
            self.spaceUuid = choosenSpace?.uuid ?? ''
            self.spaceName = choosenSpace?.name ?? ''
        },

        setDirectory(directory: SpaceDirectory | undefined) {
            self.directoryUuid = directory?.uuid ?? ''
            self.directoryName = directory?.name ?? '/'
        },
    }))

export interface FilenameSuggestion extends Instance<typeof _FilenameSuggestion> { }
