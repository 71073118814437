import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSProperties, FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import Tooltip from './tooltip'

interface Props {
    image?: ReactNode
    className?: string
    children?: ReactNode
    menuStyle?: CSSProperties
    notify?: boolean
    notifyCount?: number
    onClick?: () => void
    tooltip?: ReactNode | string
}

const ButtonImage: FC<Props> = (
    {
        tooltip,
        className = 'relative inline-block mx-2',
        notifyCount = 0,
        notify = false,
        image,
        children,
        menuStyle = {},
        onClick,
    }
) => {
    const [isVisible, setVisible] = useState<boolean>(false)
    const popRef = useRef<HTMLDivElement>(null)

    const handleHideDropdown = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setVisible(false)
        }
    }, [])

    const handleClickOutside = useCallback(
        (event: Event) => {
            if (popRef.current && !popRef.current.contains(event.target as Node)) {
                setVisible(false)
            }
        },
        [popRef]
    )

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true)
        document.addEventListener('click', handleClickOutside, true)

        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true)
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    const origin = 'origin-bottom top-0'
    const style = { transform: 'translateX(-80%)', ...menuStyle }

    return (
        <Tooltip tooltip={isVisible ? undefined : tooltip}>
            <div className={className} onClick={onClick}>
                <div
                    className="relative flex h-12 w-12 cursor-pointer items-center justify-center rounded-50 bg-white shadow-lg"
                    onClick={() => {
                        if (children) {
                            setVisible(!isVisible)
                        }
                    }}>
                    {notify && (
                        <div
                            className="absolute h-3 w-3 animate-ping rounded-full bg-christine"
                            style={{ right: '.7em', top: '.7em' }}></div>
                    )}
                    {notifyCount > 0 && (
                        <div
                            className="absolute h-4 rounded-full bg-christine px-1 text-xs text-white"
                            style={{ left: '2.1em', top: '.6em' }}>
                            {notifyCount}
                        </div>
                    )}
                    {image}
                </div>
                {children && (
                    <>
                        <div
                            className="absolute bottom-0 right-0 hidden h-4 w-4 cursor-pointer items-center justify-center rounded-50 bg-white shadow-md md:flex"
                            onClick={() => setVisible(!isVisible)}>
                            <FontAwesomeIcon
                                icon={faCaretDown}
                                className="fill-current"
                                rotation={isVisible ? 180 : undefined}
                                size="xs"
                            />
                        </div>

                        <div ref={popRef}>
                            {isVisible && (
                                <div
                                    className={`absolute z-50 float-left mt-1 flex flex-col rounded bg-white p-4 text-left text-base shadow-lg ${origin}`}
                                    style={style}>
                                    {children}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </Tooltip>
    )
}

export default ButtonImage
