import { useTranslation } from 'react-i18next'
import { ApiDoneTransferResponse } from '..'

export const EmailTransfer = ({ response }: { response: ApiDoneTransferResponse }) => {
    const { t } = useTranslation()

    return (
        <div className="text-center">
            <h4 className="font-bold text-xl mb-2">{t('web_file_transfer_mail_sent')}</h4>
            <p className="leading-5 font-nunito text-sm italic">{t('web_file_transfer_mail_sent_message')}</p>
        </div>
    )
}
