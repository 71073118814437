import { FC, useState } from 'react'
import { Connector } from '../../stores/telecollecte/connector'

interface Props {
    connector: Connector
    className?: string
    onClick?: () => void
}
const ConnectorImage: FC<Props> = ({ connector, className = '', onClick }) => {
    const urlImage = `/connectors/${connector.uuid}.webp`
    const [logoUnavailabe, isLogo] = useState(false)

    if (logoUnavailabe) {
        return (
            <div
                className={`${className} flex items-center justify-center text-xs text-white text-opacity-50`}
                style={{ backgroundColor: `#${connector.color}` }}
                onClick={onClick}>
                {connector.slug}
            </div>
        )
    }

    return <img src={urlImage} loading="lazy" className={className} onClick={onClick} onError={() => isLogo(true)} />
}

export default ConnectorImage
