import { faCirclePlus, faXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { ReactComponent as CtaArrowTop } from '../../assets/file-transfer/cta-arrow-top.svg'
import ListCheck from 'assets/file-transfer/li-check.svg'
import FileIcon from 'components/dataroom/upload/file-icon'
import { getFileSize } from 'components/dataroom/upload/file-size'
import { getExtension, getFileMimeType, ignoredFile } from 'components/dataroom/upload/mime-types'
import { getFilesAsync } from 'components/dataroom/upload/upload'
import {
    type ApiDoneTransferResponse,
    type ApiInitTransferResponse,
    type ApiUploadFileResponse,
    type FileWithPathAndExtension,
    type TransferFormData,
    buttonClassName,
    transferFormSchema,
    type UploadState,
} from 'components/file-transfer'
import { off, on, trigger } from 'core/events'
import { get, post } from 'core/services/http-service'
import toast from 'core/utils/toast'
import { createRef, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BackgroundCollab from '../../assets/file-transfer/backgrounds/collaboration.jpg'
import UploadCta from '../../assets/file-transfer/backgrounds/upload-cta.jpg'
import { ReactComponent as CtaArrowBottom } from '../../assets/file-transfer/cta-arrow-bottom.svg'
import Logo from '../../assets/file-transfer/logo'
import UploadIcon from '../../assets/file-transfer/upload-icon'
import { ReactComponent as UploadImage } from '../../assets/upload.svg'
import { Header } from './header'
import { EmailTransfer } from './transfer-page/email-transfer'
import { Form } from './transfer-page/form'
import { LinkTransfer } from './transfer-page/link-transfer'
import { Main } from './wrappers/main'
import { Left } from './wrappers/left'
import { Right } from './wrappers/right'
import Footer from 'components/file-transfer/footer'
import Config from 'core/config'
import Treasy from 'assets/treasy'

const byte = 1048576
const defaultFileMaxSize = 50
const defaultTransferMaxSize = 200

const TransferPage = () => {
    const { t, i18n } = useTranslation()

    const inputRef = createRef<HTMLInputElement>()

    const methods = useForm<TransferFormData>({ resolver: zodResolver(transferFormSchema) })
    const { setValue } = methods

    const [filesToUpload, setFilesToUpload] = useState<FileWithPathAndExtension[]>([])
    const [validFilesCount, setValidFilesCount] = useState<number>(0)
    const [transferSizeValid, setTransferSizeValid] = useState<boolean>(true)
    const [transferSize, setTransferSize] = useState<number>(0)
    const [hasFiles, setHasFiles] = useState<boolean>(false)
    const [canSubmit, setCanSubmit] = useState<boolean>(false)
    const [invalidFiles, setInvalidFiles] = useState<string[]>([])
    const [showNoValidFilesError, setShowNoValidFilesError] = useState<boolean>(false)
    const [ctaBackground, setCtaBackground] = useState<string>(BackgroundCollab)
    const [transferModalDisplayed, displayTransferModal] = useState<boolean>(false)
    const [shareType, setShareType] = useState<'email' | 'link'>('email')
    const [uploadState, setUploadState] = useState<UploadState>('pending_user')
    const [uploadDoneResponse, setUploadDoneResponse] = useState<ApiDoneTransferResponse>()

    const [transferMaxSize, setTransferMaxSize] = useState<number>(defaultTransferMaxSize * byte)
    const [fileMaxSize, setFileMaxSize] = useState<number>(defaultFileMaxSize * byte)
    const [uploadPercent, setUploadPercent] = useState<number>(0)

    const [dragging, setDragging] = useState<boolean>(false)
    const dragCounter = useRef<number>(0)

    const tabClassName = 'border-0 border-b border-b-[#B5C0CC] text-lg pt-4 pb-3'
    const activeTabClassName = 'border-b-2 border-b-atomic-tangerine font-bold'

    useEffect(() => {
        const loadSettings = async () => {
            try {
                const { transferMaxSize, fileMaxSize, unit } = await get<
                    never,
                    { transferMaxSize: number; fileMaxSize: number; unit: number }
                >(`/v1/web/file-transfer/settings?locale=${i18n.language}`)

                setTransferMaxSize(transferMaxSize * unit)
                setFileMaxSize(fileMaxSize * unit)
            } catch (error) {
                //
            }
        }
        loadSettings()
    }, [])

    useEffect(() => {
        let totalSize = 0

        for (const file of filesToUpload) {
            if (file.isSizeValid) {
                totalSize += file.file.size
            }
        }

        setTransferSize(totalSize)
        setHasFiles(filesToUpload.length > 0)
        setValidFilesCount(filesToUpload.filter(({ isSizeValid }) => isSizeValid).length)
    }, [filesToUpload])

    useEffect(() => {
        setTransferSizeValid(transferSize <= transferMaxSize)
    }, [transferSize])

    useEffect(() => {
        setCanSubmit(hasFiles && transferSizeValid && validFilesCount > 0)
    }, [hasFiles, transferSizeValid, validFilesCount])

    useEffect(() => {
        on('uploader:trigger', () => onAddFileClicked())

        return () => off('uploader:trigger', () => onAddFileClicked())
    })

    const upload = async (url: string, file: FileWithPathAndExtension, currentFile: number, totalFiles: number) => {
        return new Promise((resolve, reject) => {
            const ajax = new XMLHttpRequest()
            ajax.upload.addEventListener(
                'progress',
                event => {
                    const currentPercent = (event.loaded / event.total) * 100

                    const percent = (currentPercent + 100 * currentFile) / totalFiles

                    setUploadPercent(Math.round(percent))
                },
                false
            )
            ajax.addEventListener('load', event => resolve(event), false)
            ajax.addEventListener('error', event => reject(event), false)
            ajax.addEventListener('abort', () => reject(event), false)
            ajax.open('PUT', url)
            ajax.send(file.file)
        })
    }

    const onSubmit = async (data: TransferFormData) => {
        setUploadState('uploading')

        try {
            const initRes = await post<TransferFormData & { totalSize: number }, ApiInitTransferResponse>(
                `/v1/web/file-transfer/start?locale=${i18n.language}`,
                { ...data, totalSize: transferSize }
            )

            const { cuid } = initRes

            const totalFiles = filesToUpload.length
            let currentFile = 0

            for (const file of filesToUpload) {
                if (file.isSizeValid) {
                    const { url } = await post<{ name: string; size: number; mimeType: string }, ApiUploadFileResponse>(
                        `/v1/web/file-transfer/${cuid}/upload-url?locale=${i18n.language}`,
                        {
                            name: file.file.name,
                            size: file.file.size,
                            mimeType: file.file.type,
                        }
                    )

                    await upload(url, file, currentFile, totalFiles)
                }

                currentFile += 1
            }

            setUploadState('finalizing')

            const doneRes = await get<never, ApiDoneTransferResponse>(
                `/v1/web/file-transfer/${cuid}/done?locale=${i18n.language}`
            )
            setUploadDoneResponse(doneRes)
            setUploadState('ready')
        } catch (error) {
            setUploadState('pending_user')
            toast('error', t('web_file_transfer_error'))
        }
    }

    const onAddFileClicked = () => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }

    const validateFiles = async (uploadFiles: FileWithPathAndExtension[]) => {
        const validFiles: FileWithPathAndExtension[] = []
        const invalidFiles: string[] = []

        for (const file of uploadFiles) {
            if (ignoredFile(file.file)) {
                continue
            }

            const mimeType = getFileMimeType(file.file)
            if (mimeType !== undefined) {
                validFiles.push(file)
            } else if (!invalidFiles.includes(file.file.name)) {
                invalidFiles.push(file.file.name)
            }
        }

        if (invalidFiles.length !== 0) {
            setInvalidFiles(invalidFiles)
        } else if (validFiles.length === 0) {
            setShowNoValidFilesError(true)
        }

        if (validFiles.length > 0) {
            setFilesToUpload([...filesToUpload, ...validFiles])
            displayTransferModal(true)
        }
    }

    const removeFile = (removedIndex: number) =>
        setFilesToUpload(filesToUpload.filter((file, index) => removedIndex !== index))

    const handleInputFile = (files: FileList) =>
        validateFiles(
            Array.from(files).map(file => ({
                file,
                fullPath: file.name,
                extension: getExtension(file.name),
                isSizeValid: file.size <= fileMaxSize,
            }))
        )

    const initNewTransfer = () => {
        setUploadPercent(0)
        setShareType('email')
        setValue('recipients', [''])
        setValue('title', '')
        setValue('object', '')
        setValue('message', '')
        setValue('isSecure', false)
        setFilesToUpload([])
        setUploadState('pending_user')
    }

    const handleDragIn = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()

        for (const item of event.dataTransfer.items) {
            if (item.kind !== 'file') {
                return
            }
        }

        dragCounter.current++
        // safari does not have the length here...
        // if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
        setDragging(true)
        // }
    }
    const handleDragOut = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        dragCounter.current--
        if (dragCounter.current > 0) {
            return
        }
        setDragging(false)
    }

    const handleDrag = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
    }

    const handleDrop = async (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        setDragging(false)

        for (const item of event.dataTransfer.items) {
            if (item.kind !== 'file') {
                return
            }
        }

        dragCounter.current = 0
        const files = await getFilesAsync(event.dataTransfer)
        const valid = files.map(file => ({
            file: file.file,
            fullPath: file.file.name,
            extension: getExtension(file.file.name),
            isSizeValid: file.file.size <= fileMaxSize,
        }))

        validateFiles(valid)

        event.dataTransfer.clearData()
    }

    useEffect(() => {
        window.addEventListener('dragenter', handleDragIn)
        window.addEventListener('dragleave', handleDragOut)
        window.addEventListener('dragover', handleDrag)
        window.addEventListener('drop', handleDrop)

        return () => {
            window.removeEventListener('dragenter', handleDragIn)
            window.removeEventListener('dragleave', handleDragOut)
            window.removeEventListener('dragover', handleDrag)
            window.removeEventListener('drop', handleDrop)
        }
    }, [fileMaxSize, filesToUpload])

    return (
        <div
            className={clsx(
                'font-nunito min-h-screen w-full relative overflow-hidden bg-cover bg-no-repeat bg-center grid grid-cols-1',
                'bg-white'
            )}
            style={{
                backgroundImage: `url(${ctaBackground})`,
            }}
        >
            {!dragging && transferModalDisplayed && <div className="absolute inset-0 z-0 bg-[#2C2A2C]/80" />}

            <input
                type="file"
                ref={inputRef}
                multiple
                onChange={e => {
                    handleInputFile(e.target.files)
                    e.target.value = null
                }}
                className="hidden"
            />

            <Header transferModalDisplayed={transferModalDisplayed} />

            <div className={clsx('flex lg:relative justify-center items-start lg:items-center',
            transferModalDisplayed ? 'items-center' : 'bg-white lg:bg-transparent')}>
                {!transferModalDisplayed ? (
                    <div>
                        <div className="flex-col gap-4 items-center z-50 hidden lg:flex">
                            <button type="button" onClick={() => trigger('uploader:trigger')}>
                                <UploadIcon className="w-[160px] 2xl:w-[270px]" />
                            </button>
                            <div
                                className="bg-white rounded-lg p-5 px-10 text-center cursor-pointer"
                                onClick={() => trigger('uploader:trigger')}
                            >
                                <h4 className="font-bold text-xl">{t('web_file_transfer_drag_drop')}</h4>
                                <p>{t('web_file_transfer_drag_drop_or_click')}</p>
                            </div>
                        </div>
                        <div className="lg:hidden px-8 pt-8 text-sm leading-normal relative">
                            <h2
                                // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                                dangerouslySetInnerHTML={{ __html: t('web_file_transfer_h2') }}
                            />

                            <a
                                href={Config.app.WEBURL}
                                target="_blank"
                                className="relative w-full flex items-center justify-center text-black mt-20"
                                rel="noreferrer"
                            >
                                <div className="absolute -left-24 -bottom-2">
                                    <CtaArrowTop className="" />
                                </div>
                                <Treasy className="w-[100px] h-[46px]" />
                            </a>
                        </div>
                        <div className="flex flex-col text-sm gap-4 p-8 lg:hidden">
                            <div className="flex flex-row gap-4 items-start">
                                <img src={ListCheck} alt="check" className="pt-1" />
                                <div
                                    className=""
                                    dangerouslySetInnerHTML={{ __html: t('web_file_transfer_upload_cta_text') }}
                                />
                            </div>
                            <div className="flex flex-row gap-4 items-start">
                                <img src={ListCheck} alt="check" className="pt-1" />
                                <div
                                    className=""
                                    dangerouslySetInnerHTML={{ __html: t('web_file_transfer_upload_cta_text2') }}
                                />
                            </div>
                            <div className="flex flex-row gap-4 items-start">
                                <img src={ListCheck} alt="check" className="pt-1" />
                                <div
                                    className=""
                                    dangerouslySetInnerHTML={{ __html: t('web_file_transfer_upload_cta_text3') }}
                                />
                            </div>
                        </div>
                        <div className="text-center w-full lg:hidden">
                            <a
                                href={Config.app.WEBURL}
                                target="_blank"
                                className={clsx(buttonClassName, 'relative whitespace-nowrap')}
                                rel="noreferrer"
                            >
                                {t('web_file_transfer_cta_interested')}
                            </a>
                        </div>
                    </div>
                ) : (
                    <Main>
                        <Left>
                            {uploadState !== 'pending_user' ? (
                                <div className="h-full flex flex-col gap-8 items-center justify-center">
                                    <UploadIcon className="w-[170px] shrink-0" />
                                    {['uploading', 'finalizing'].includes(uploadState) && (
                                        <div className="text-center">
                                            <h4 className="font-bold text-xl mb-2 leading-6">
                                                {t(`web_file_transfer_${uploadState}`)}
                                            </h4>
                                            <p className="leading-5 font-nunito text-sm italic">
                                                {t('web_file_transfer_uploading_warning')}
                                            </p>
                                        </div>
                                    )}
                                    {uploadState === 'uploading' && (
                                        <div className="mt-4 flex h-6 w-full overflow-hidden rounded bg-gallery">
                                            <div
                                                className="flex h-6 items-center justify-center overflow-hidden bg-atomic-tangerine text-sm text-white transition-all duration-150"
                                                style={{ width: `${uploadPercent}%` }}
                                            >
                                                {uploadPercent}%
                                            </div>
                                        </div>
                                    )}
                                    {uploadState === 'finalizing' && (
                                        <div className="mt-4 flex h-6 w-full overflow-hidden rounded bg-gallery relative">
                                            <div className="flex h-6 items-center justify-center overflow-hidden bg-atomic-tangerine w-20 transition-all duration-150 absolute animate-[borealisBar_2s_linear_infinite_alternate]" />
                                        </div>
                                    )}
                                    {uploadState === 'ready' && (
                                        <>
                                            {uploadDoneResponse?.shareType === 'link' && (
                                                <LinkTransfer response={uploadDoneResponse} />
                                            )}
                                            {uploadDoneResponse?.shareType === 'email' && (
                                                <EmailTransfer response={uploadDoneResponse} />
                                            )}
                                            <button
                                                onClick={() => initNewTransfer()}
                                                type="button"
                                                className="hover:text-sea-buckthorn font-nunito underline transition-colors"
                                            >
                                                {t('web_file_transfer_init_new')}
                                            </button>
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div className="flex flex-col gap-4">
                                    <div className="flex gap-2 justify-between items-center border-0 border-b border-b-[#B5C0CC] pb-2">
                                        <div className="flex gap-2 items-center">
                                            <span className="font-bold text-lg">
                                                {t('web_file_transfer_files_count', {
                                                    count: validFilesCount,
                                                })}
                                            </span>
                                            {hasFiles && transferSize > 0 && (
                                                <span className="text-another-gray text-sm relative top-[2px]">
                                                    ({getFileSize(t, transferSize)})
                                                </span>
                                            )}
                                        </div>
                                        <button type="button" onClick={() => trigger('uploader:trigger')} className="">
                                            <FontAwesomeIcon
                                                icon={faCirclePlus}
                                                className="text-3xl text-atomic-tangerine"
                                            />
                                        </button>
                                    </div>
                                    {!transferSizeValid && (
                                        <div className="text-atomic-tangerine leading-5 text-sm">
                                            {t('web_file_transfer_max_size_error', {
                                                size: getFileSize(t, transferMaxSize),
                                            })}
                                        </div>
                                    )}
                                    {hasFiles ? (
                                        <ul className="flex flex-col gap-3 max-h-48 xl:max-h-96 overflow-y-auto font-nunito">
                                            {filesToUpload.map((file, index) => {
                                                const { isSizeValid } = file

                                                return (
                                                    <li
                                                        key={index}
                                                        className={'flex gap-4 items-center justify-between pr-1'}
                                                    >
                                                        <FileIcon extension={file.extension} className="w-6 shrink-0" />
                                                        <span className="grow flex flex-col leading-5">
                                                            <span
                                                                className={clsx('break-all', {
                                                                    'text-atomic-tangerine': !isSizeValid,
                                                                })}
                                                            >
                                                                {file.fullPath}
                                                            </span>
                                                            <span
                                                                className={clsx('text-another-gray text-sm', {
                                                                    'text-atomic-tangerine': !isSizeValid,
                                                                })}
                                                            >
                                                                {getFileSize(t, file.file.size)}
                                                            </span>
                                                            {!isSizeValid && (
                                                                <span className="text-another-gray text-xs leading-4">
                                                                    {t('web_file_transfer_file_max_allowed_size', {
                                                                        size: getFileSize(t, fileMaxSize),
                                                                    })}
                                                                </span>
                                                            )}
                                                        </span>
                                                        <button
                                                            type="button"
                                                            onClick={() => removeFile(index)}
                                                            className="flex items-center shrink-0"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faXmark}
                                                                className="text-another-gray text-2xl"
                                                            />
                                                        </button>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    ) : (
                                        <button
                                            type="button"
                                            onClick={() => trigger('uploader:trigger')}
                                            className="text-left leading-5"
                                        >
                                            {t('web_file_transfer_add_files_to_continue')}
                                        </button>
                                    )}
                                </div>
                            )}
                        </Left>
                        <Right uploadState={uploadState}>
                            {uploadState !== 'pending_user' ? (
                                <div className="p-4 flex flex-col gap-4 lg:gap-8">
                                    <div
                                        className="relative w-full bg-cover p-5 lg:h-[425px] xl:p-10 hidden lg:block"
                                        style={{
                                            backgroundImage: `url(${UploadCta})`,
                                        }}
                                    >
                                        <Logo className="w-[160px] lg:w-[320px]" />
                                        <div
                                            className="lg:w-1/2 leading-6 bg-white/75 p-2 mt-1 rounded-md"
                                            dangerouslySetInnerHTML={{ __html: t('web_file_transfer_upload_cta_text') }}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2 items-center relative lg:flex-row lg:justify-between">
                                        <p
                                            dangerouslySetInnerHTML={{ __html: t('web_file_transfer_upload_cta') }}
                                            className="text-center"
                                        />
                                        <div className="absolute hidden xl:block xl:top-[-225px] xl:right-[125px]">
                                            <CtaArrowBottom />
                                        </div>
                                        <a href="/" target="_blank" className={buttonClassName} rel="noreferrer">
                                            {t('web_file_transfer_cta_discover_short')}
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="grid grid-cols-2 items-center">
                                        <button
                                            type="button"
                                            onClick={() => setShareType('email')}
                                            className={clsx(
                                                tabClassName,
                                                shareType === 'email' ? activeTabClassName : ''
                                            )}
                                        >
                                            {t('web_file_transfer_share_type_email')}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setShareType('link')}
                                            className={clsx(
                                                tabClassName,
                                                shareType === 'link' ? activeTabClassName : ''
                                            )}
                                        >
                                            {t('web_file_transfer_share_type_link')}
                                        </button>
                                    </div>
                                    <FormProvider {...methods}>
                                        <Form onSubmit={onSubmit} shareType={shareType} canSubmit={canSubmit} />
                                    </FormProvider>
                                </div>
                            )}
                        </Right>
                    </Main>
                )}
            </div>

            <Footer transferModalDisplayed={transferModalDisplayed} />

            {dragging && (
                <div className="absolute inset-0 bg-[#2C2A2C]/80 z-50 flex items-center justify-center flex-col">
                    <UploadImage />
                    <span className="font-bold text-xl text-white">{t('web_file_transfer_drag_drop')}</span>
                </div>
            )}
        </div>
    )
}

export default TransferPage
