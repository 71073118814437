import { ReactComponent as ArrowRight } from 'assets/arrow/arrow-right.svg'
import { ReactComponent as Folder } from 'assets/icons/icon-folder.svg'
import { Space } from 'stores/files/space'
import { useMst } from 'stores/store'

interface SpaceLineProps {
    space: Space
    onOpenSpace: (space: Space) => void
}
const SpaceLine = ({ space, onOpenSpace }: SpaceLineProps) => {
    const { user } = useMst()

    return (
        <li className="flex cursor-pointer items-center justify-between" onClick={() => onOpenSpace(space)}>
            <div className="my-2 flex items-center">
                <Folder className="h-6 w-6 fill-current" style={{ color: space.color }} />
                <span className="ml-2">
                    {space.userUuid !== user.uuid && <>{space.username} - </>}
                    {space.displayName}
                </span>
            </div>
            <ArrowRight className="ml-2" />
        </li>
    )
}

export default SpaceLine
