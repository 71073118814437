import Admin from 'components/admin/admin'
import { toJS } from 'mobx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useMst } from 'stores/store'
import Form from './form'

const Edit = () => {
    const { admin, brand } = useMst()
    const { t } = useTranslation()
    const { brandId, franchiseId } = useParams()
    const [values, setValues] = useState<Record<string, unknown>>()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(true)

    const onSubmit = async (data: Record<string, unknown>) => {
        const { name, url, logo, icon, partnerId, color, viaColor, brandImage, brandLogo } = data as Record<
            string,
            string
        >
        const { storage } = data as Record<string, number>

        setLoading(true)
        await admin.editingBrand.updateFranchise(
            franchiseId,
            name,
            url,
            logo,
            icon,
            storage,
            partnerId,
            color,
            viaColor,
            brandImage,
            brandLogo,
            (data as { tags: { value: string; label: string }[] }).tags.map(t => t.value) ?? []
        )
        setLoading(false)
        navigate(`/admin/brands/${brandId}/franchises`)
    }

    const load = async (brandId: string, franchiseId: string) => {
        setLoading(true)
        await admin.loadBrand(brandId)
        await admin.editingBrand.loadFranchise(franchiseId)

        const tags = await brand.loadTags(brandId)

        setValues({
            ...toJS(admin.editingBrand.editingFranchise),
            tags: tags
                .map(tag => ({ value: tag.uuid, label: tag.name }))
                .filter(tag =>
                    (admin.editingBrand.editingFranchise.tags as { uuid: string; name: string }[])
                        .map(t => t.uuid)
                        .flat()
                        .includes(tag.value)
                ),
        })
        setLoading(false)
    }
    useEffect(() => {
        if (brandId && franchiseId) {
            load(brandId, franchiseId)
        }
    }, [brandId, franchiseId])

    return (
        <Admin title={t('web_admin_brand_franchise')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} values={values} brandId={brandId} />
        </Admin>
    )
}

export default Edit
