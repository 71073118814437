import { faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Admin from 'components/admin/admin'
import Pagination from 'components/admin/pagination'
import useDebounce from 'core/debounce'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { InformationMessage } from 'stores/informationMessage/information-message'
import { useMst } from 'stores/store'

const MessageLine: FC<{ informationMessage: InformationMessage; partnerId: string; onRefresh: () => void }> = (
    { informationMessage, partnerId, onRefresh }
) => {
    const { informationMessageStore } = useMst()
    const [, setLoading] = useState<boolean>(false)

    const onInformationMessageDelete = async (id: string) => {
        setLoading(true)
        await informationMessageStore.informationMessageDelete(id)
        setLoading(false)
        onRefresh()
    }

    return (
        <tr>
            <td className="text-left">{informationMessage.title}</td>
            <td className="text-left">{informationMessage.bodyMessage}</td>
            <td>
                <span style={{ backgroundColor: informationMessage.color }}>{informationMessage.color}</span>
            </td>
            <td>{informationMessage.dismissible ? '✓' : '✗'}</td>
            <td>
                {informationMessage.startDate !== ''
                    ? DateTime.fromISO(informationMessage.startDate).toLocaleString(DateTime.DATE_SHORT)
                    : ''}
            </td>
            <td>
                {informationMessage.endDate !== ''
                    ? DateTime.fromISO(informationMessage.endDate).toLocaleString(DateTime.DATE_SHORT)
                    : ''}
            </td>
            <td className="flex space-x-1 text-christine">
                <Link to={`/partner/${partnerId}/messages/${informationMessage.uuid}`}>
                    <FontAwesomeIcon icon={faEdit} />
                </Link>
                <FontAwesomeIcon
                    icon={faTrash}
                    className="cursor-pointer"
                    onClick={() => onInformationMessageDelete(informationMessage.uuid)}
                />
            </td>
        </tr>
    )
}

const limit = 50

const Listing: FC = observer(() => {
    const { admin } = useMst()
    const { id } = useParams()
    const { t } = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [offset, setOffset] = useState<number>(0)
    const [query] = useState<string>('')
    const [page, setPage] = useState<number>(0)
    const [pageCount] = useState<number>(0)

    const load = useCallback(async () => {
        setLoading(true)
        await admin.loadInformationMessages(id)

        setLoading(false)
    }, [offset, query])

    const changePage = async page => {
        setPage(page)
        setOffset(Math.ceil(page * limit))
    }

    const debouncedQuery = useDebounce<string>(query, 500)
    useEffect(() => {
        load()
    }, [page, debouncedQuery])

    useEffect(() => {
        setPage(0)
    }, [])

    return (
        <Admin
            title={t('web_admin_information_messages')}
            isLoading={isLoading}
            header={
                <div className="flex items-center space-x-4">
                    <Link to={`/partner/${id}/messages/create`} className="btn">
                        {t('web_admin_information_message_new')}
                    </Link>
                </div>
            }>
            <table className="admin w-full table-auto text-center">
                <thead>
                    <tr>
                        <th>{t('web_menu_admin_information_message_title')}</th>
                        <th>{t('web_menu_admin_information_message_body')}</th>
                        <th>{t('web_menu_admin_information_message_color')}</th>
                        <th>{t('web_menu_admin_information_dismissible_short')}</th>
                        <th>{t('web_menu_admin_information_message_startDate')}</th>
                        <th>{t('web_menu_admin_information_message_endDate')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {admin.informationMessages.map(informationMessage => (
                        <MessageLine
                            key={informationMessage.uuid}
                            informationMessage={informationMessage}
                            partnerId={id}
                            onRefresh={load}
                        />
                    ))}
                </tbody>
            </table>
            <div className="mt-8 flex w-full justify-center">
                {pageCount > 1 && <Pagination page={page} totalPages={pageCount} onChangePage={changePage} />}
            </div>
        </Admin>
    )
})

export default Listing
