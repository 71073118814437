import { ReactComponent as Draw } from 'assets/family.svg'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useMst } from 'stores/store'
import Panel from '../shared/panel'
import SmallLoader from '../shared/small-loader'
import ContentPage from './content-page'
import { AppContext } from 'context'

const Trust = () => {
    const { t } = useTranslation()
    const { isLogged, trustedUserStore, logout } = useMst()
    const { userId } = useParams()
    const navigate = useNavigate()

    const {
        setLogo,
        setIcon,
        setRedirect,
        loginRegister: { setReadOnlyEmail, setEmail },
    } = useContext(AppContext)

    const [loading, setLoading] = useState<boolean>(true)
    const [user, setUser] = useState<string>('')
    const [confirmed, setConfirmed] = useState<boolean>(false)

    const [isCollaborationInvitation, setIsCollaborationInvitation] = useState<boolean>(false)
    const [space, setSpace] = useState<string>()

    const load = async () => {
        setLoading(true)
        const { validSessionUser, user, logo, icon, isCollaborationInvitation, invitedEmail, space } =
            await trustedUserStore.getInvitationInfos(userId)

        if (!validSessionUser) {
            logout(`/trust/${userId}`)

            return
        }

        if (logo) {
            setLogo(logo)
        }

        if (icon) {
            setIcon(icon)
        }

        setIsCollaborationInvitation(isCollaborationInvitation)

        if (isCollaborationInvitation) {
            setSpace(space)
            setReadOnlyEmail(true)
            setEmail(invitedEmail)
        }

        setUser(user.fullname)
        setLoading(false)
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        load()
    }, [userId])

    const confirm = async () => {
        setLoading(true)
        const success = await trustedUserStore.confirm(userId)
        if (success) {
            setConfirmed(true)
        }
        setLoading(false)
    }
    const cancel = async () => {
        setReadOnlyEmail(false)
        setEmail(undefined)
        setLogo(undefined)
        setIcon(undefined)
        navigate('/')
    }
    const gotoDashboard = async () => navigate('/')

    const navigateTo = (path: string) => {
        setRedirect(`/trust/${userId}`)
        const params = new URLSearchParams({ redirect: `/trust/${userId}` })
        navigate(`/${path}?${params.toString()}`)
    }

    return (
        <ContentPage>
            <Panel>
                <div className="flex flex-col items-center space-y-6">
                    <Draw />
                    {loading ? (
                        <SmallLoader className="mt-4" />
                    ) : (
                        <>
                            <h3 className="font-bold lg:w-1/2 text-center">
                                {t(
                                    confirmed
                                        ? isCollaborationInvitation
                                            ? 'web_collaboration_space_invitation_confirmed'
                                            : 'web_trusted_user_confirmed'
                                        : isCollaborationInvitation
                                          ? 'web_collaboration_space_invitation_join'
                                          : 'web_trusted_user_join',
                                    { name: user, space }
                                )}
                            </h3>
                            {confirmed ? (
                                <button type="button" className="btn" onClick={() => gotoDashboard()}>
                                    {t('web_trusted_user_goto_dashboard')}
                                </button>
                            ) : isLogged ? (
                                <div className="flex flex-row space-x-4">
                                    <button type="button" className="btn white" onClick={() => cancel()}>
                                        {t('web_trusted_user_join_cancel')}
                                    </button>
                                    <button type="button" className="btn" onClick={() => confirm()}>
                                        {t('web_trusted_user_join_confirm')}
                                    </button>
                                </div>
                            ) : (
                                <div className="flex flex-col space-y-2">
                                    <div className="flex flex-row space-x-4">
                                        <button type="button" className="btn" onClick={() => navigateTo('login')}>
                                            {t('web_trusted_user_join_login_confirm')}
                                        </button>
                                        <button type="button" className="btn" onClick={() => navigateTo('register')}>
                                            {t('web_trusted_user_join_register_confirm')}
                                        </button>
                                    </div>
                                    <button type="button" className="underline" onClick={() => cancel()}>
                                        {t('web_trusted_user_join_cancel')}
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Panel>
        </ContentPage>
    )
}

export default Trust
