import toast from 'core/utils/toast'
import { flow } from 'mobx'
import { getRoot, Instance, types } from 'mobx-state-tree'
import { get, post } from '../core/services/http-service'
import { _Cgu, CGU } from './cgu/cgu'
import { RootInstance } from './store'

export const _CguStore = types
    .model('Cgu', {
        cgu: types.optional(_Cgu, {}),
    })
    .actions(self => ({
        replace: cgu => {
            self.cgu = cgu
        },
    }))
    .actions(self => ({
        loadCguContent: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: { cgu } }>('/v1/cgu/latest')
                const {
                    data: { cgu },
                } = data
                self.replace(cgu)
            } catch (error) {
                console.error(error)
            }
        }),

        saveNewCguVersion: flow(function* (cgu) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = { cgu: { en: cgu, fr: cgu } }
                const data = yield post<typeof postData, CGU>('/v1/cgu', postData)
                // self.append(data.data)

                toast('success', 'api_new_cgu_version_created')

                return data.data
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))

export interface CguStore extends Instance<typeof _CguStore> {}
