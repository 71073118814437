import Admin from 'components/admin/admin'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { News } from 'stores/news/news'
import { useMst } from '../../../stores/store'
import Form, { Schema } from './form'

const Edit = () => {
    const { newsStore, files } = useMst()
    const { t } = useTranslation()
    const { mid, id } = useParams()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [values, setValues] = useState<Record<string, unknown>>({})

    const onSubmit = async (data: Schema) => {
        const { title, teaserText, bodyMessage, dismissible, startDate, endDate, color, pdf } = data
        await newsStore.newsUpdate(
            id,
            mid,
            title,
            teaserText,
            bodyMessage,
            dismissible,
            startDate,
            endDate,
            pdf as FileList,
            color
        )
        navigate(`/partner/${id}/news`)
    }

    const load = async () => {
        setLoading(true)
        await files.getConfig()
        await newsStore.loadNews(mid)
        // await partners.loadPartnerUsers(id, 500, 0)

        const makeValues = (news: News) => {
            return {
                title: news.title,
                teaserText: news.teaserText,
                bodyMessage: news.bodyMessage,
                dismissible: news.dismissible,
                startDate: news.startDate !== '' ? DateTime.fromISO(news.startDate).toJSDate() : null,
                endDate: news.endDate !== '' ? DateTime.fromISO(news.endDate).toJSDate() : null,
                upload: news.upload,
                color: news.color,
                createdAt: news.createdAt !== '' ? DateTime.fromISO(news.createdAt).toJSDate() : null,
            }
        }
        setValues(makeValues(newsStore.editingNews))
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [mid])

    return (
        <Admin title={t('web_partner_news')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} partnerId={id} values={values} />
        </Admin>
    )
}

export default Edit
