import { cn } from 'core/utils/cn'
import { Fragment, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as TriEasy } from '../../assets/dataroom/tri-easy.svg'
import { ReactComponent as GedEasy } from '../../assets/dataroom/tri-ged-easy.svg'
import { ReactComponent as GedMini } from '../../assets/dataroom/tri-ged-mini.svg'
import { ReactComponent as TriMini } from '../../assets/dataroom/tri-mini.svg'
import toast from '../../core/utils/toast'
import { useMst } from '../../stores/store'
import Modal from '../shared/modal'

const WidgetIcon = ({ widget, className }: { widget: string; className: string }) => {
    return (
        <div
            className={cn(
                'flex cursor-pointer items-center justify-center rounded bg-black-squeeze p-2 shadow-md',
                className
            )}
        >
            {widget === 'mini' && <TriMini />}
            {widget === 'easy' && <TriEasy />}
            {widget === 'ged-mini' && <GedMini />}
            {widget === 'ged-easy' && <GedEasy />}
        </div>
    )
}

interface WidgetProps {
    widget: string
    displayWidget?: string
    onClick: (widget: string) => void
}
const Widget = ({ widget, onClick, displayWidget }: WidgetProps) => {
    const { t } = useTranslation()

    if (displayWidget && displayWidget !== widget) {
        return null
    }

    return (
        <div className="flex flex-col">
            <div className="flex space-x-4" onClick={() => onClick(widget)}>
                <WidgetIcon widget={widget} className="h-16 w-16" />
                <span className="whitespace-pre-line font-roboto font-bold text-regent-gray">
                    {t(`web_widget_widgets_${widget}`)}
                </span>
            </div>
            <div className="widget-desc mt-6 grow">
                <Trans i18nKey={`web_widget_widgets_${widget}_desc`}>{`web_widget_widgets_${widget}_desc`}</Trans>
            </div>
            <div className="flex w-full">
                <button type="button" className="btn m-auto" onClick={() => onClick(widget)}>
                    {t('web_widget_widgets_choose')}
                </button>
            </div>
        </div>
    )
}

const WidgetHelpChooser = ({
    displayWidget,
    changeWidget,
}: { displayWidget?: string; changeWidget: (widget: string) => void }) => {
    const { t } = useTranslation()
    const { files } = useMst()

    const [type, setType] = useState<'part' | 'pro'>('part')

    return (
        <>
            <h4 className="mb-3 w-full text-left font-roboto text-2xl font-bold">{t('web_widget_available_models')}</h4>
            <p className="mb-2 w-full text-left font-bold">{t('web_widget_widgets_desc_title')}</p>
            <p className="w-full text-left">
                <Trans i18nKey="web_widget_widgets_desc_info">web_widget_widgets_desc_info</Trans>
            </p>

            <div className="flex gap-2 items-center justify-center mt-4">
                <button
                    type="button"
                    className={cn('btn m-auto', type === 'pro' && 'white')}
                    onClick={() => setType('part')}
                >
                    {t('web_widget_widgets_part')}
                </button>
                <button
                    type="button"
                    className={cn('btn m-auto', type === 'part' && 'white')}
                    onClick={() => setType('pro')}
                >
                    {t('web_widget_widgets_pro')}
                </button>
            </div>

            <div className="mb-4 mt-8 grid grid-cols-2 w-full gap-12 xl:gap-20 justify-items-center">
                {files.widgets[type].map(widget => (
                    <Widget displayWidget={displayWidget} key={widget} widget={widget} onClick={changeWidget} />
                ))}
            </div>
        </>
    )
}

interface Props {
    onWidgetChoosen: () => void
}
const WidgetChooser = ({ onWidgetChoosen }: Props) => {
    const { t } = useTranslation()
    const { files, user } = useMst()

    const [showInfoModal, setShowInfoModal] = useState<boolean>(false)
    const [displayWidget, setDisplayWidget] = useState<string | undefined>()
    const [showConfirmModal, setShowConfirmModal] = useState<string>()

    const confirmChangeWidget = async (widget: string) => {
        await user.updateWidget(widget)
        await files.getFiles(true)
        onWidgetChoosen()
        setShowInfoModal(false)
        setShowConfirmModal(undefined)
        toast('success', 'web_widget_choosen')
    }

    const changeWidget = async (widget: string) => {
        if (user.widget === 'base') {
            await confirmChangeWidget(widget)
        } else {
            setShowConfirmModal(widget)
        }
    }

    const openModal = (widget: string | undefined = undefined) => {
        setDisplayWidget(widget)
        setShowInfoModal(true)
    }

    return (
        <div className="mb-8 flex flex-col space-y-2">
            <h3 className="text-lg">{t('web_widget_available_models')}</h3>
            <div className=" flex flex-row space-x-8">
                {['part', 'pro'].map(type => (
                    <Fragment key={type}>
                        {files.widgets[type].map(widget => (
                            <div
                                key={widget}
                                className="flex cursor-pointer space-x-4"
                                onClick={() => openModal(widget)}
                            >
                                <WidgetIcon widget={widget} className="h-14 w-14" />
                                <span className="whitespace-pre-line font-roboto font-bold text-regent-gray">
                                    {t(`web_widget_widgets_${widget}`)}
                                </span>
                            </div>
                        ))}
                    </Fragment>
                ))}

                <div className="flex cursor-pointer space-x-4" onClick={() => openModal()}>
                    <div className="flex h-14 w-14 items-center justify-center rounded bg-black-squeeze p-2 shadow-md">
                        <span className="text-xl text-atomic-tangerine">?</span>
                    </div>
                    <span className="whitespace-pre-line font-roboto font-bold text-regent-gray">
                        {t('web_widget_widgets_which_one')}
                    </span>
                </div>
            </div>

            <Modal
                isOpen={showInfoModal}
                size="11/12"
                padding={16}
                onRequestClose={() => setShowInfoModal(false)}
                onCancel={() => setShowInfoModal(false)}
                bottomAlignment="center"
            >
                <WidgetHelpChooser changeWidget={changeWidget} displayWidget={displayWidget} />
            </Modal>

            <Modal
                isOpen={showConfirmModal !== undefined}
                size="1/3"
                onRequestClose={() => setShowConfirmModal(undefined)}
                onCancel={() => setShowConfirmModal(undefined)}
                bottomAlignment="center"
                onConfirm={() => {
                    if (showConfirmModal) {
                        confirmChangeWidget(showConfirmModal)
                    }
                }}
            >
                <h4 className="mb-3 w-full text-left font-roboto text-2xl font-bold">
                    {t('web_widget_change_widget_alert')}
                </h4>
                <p className="mb-2 w-full text-left font-bold">{t('web_widget_change_widget_alert_desc')}</p>
            </Modal>
        </div>
    )
}

export default WidgetChooser
