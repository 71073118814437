import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowRight } from '../../assets/arrow/arrow-right.svg'
import { ReactComponent as Folder } from '../../assets/icons/icon-folder.svg'

const FakeSpaceView: FC<{ onClick: () => void }> = ({ onClick }) => {
    const { t } = useTranslation()

    return (
        <div className="w-full flex-none p-2 md:w-1/2 lg:w-1/3 xl:w-1/4" onClick={onClick}>
            <div className="flex flex-col p-2 shadow-md">
                <div className="relative self-end ">
                    <div className="relative flex w-12 cursor-pointer items-center justify-between rounded bg-white p-2 shadow">
                        <div className="h-3 w-3 rounded-50 text-right shadow-md"></div>
                        <ArrowRight className="h-2 w-2 fill-current text-regent-gray" />
                    </div>
                </div>
                <div className="mt-4 flex flex-row items-center">
                    <Folder className="w-10 flex-none fill-current text-christine" />
                    <div
                        className="-mt-1 ml-4 flex flex-col text-thunder"
                        style={{
                            width: 'calc(100% - 2.5rem - 0.25rem - 2rem)',
                        }}>
                        <span className="truncate text-lg">{t('web_dataroom_your_first_space')}</span>
                        {/* {space.totalFiles > -1 && <span className="font-bold text-sm -mt-2">{t('web_space_number_files', { count: space.totalFiles })}</span>} */}
                    </div>
                </div>
                <div className="mt-4 flex h-6 w-full overflow-hidden rounded-full bg-gallery">
                    {/* <div className="h-6 text-sm text-white flex items-center justify-center overflow-hidden bg-turquoise" style={{ width: `${space.ecoResponsiblePercent}%` }}>{space.ecoResponsiblePercent}%</div>
                <div className="h-6 text-sm text-pale-sky flex items-center justify-center overflow-hidden bg-gallery" style={{ width: `${space.toCompressPercent}%` }}>{space.toCompressPercent}%</div> */}
                </div>

                <div className="mt-4 flex items-center">
                    <div className="h-2 w-2 flex-none rounded-50 bg-turquoise"></div>
                    <span className="ml-2 mr-6 text-sm">{t('web_space_eco_responsible')}</span>
                    <div className="h-2 w-2 flex-none rounded-50 bg-gallery"></div>
                    <span className="ml-2 mr-6 text-sm">{t('web_space_to_compress')}</span>
                </div>
            </div>
        </div>
    )
}

export default FakeSpaceView
