import clsx from 'clsx'
import type { FC } from 'react'

interface Props {
    color: string
    title?: string
    link?: string
    linkLabel?: string
    message: string
    buttonMessage?: string
    onClick?: () => void
    buttons?: { label: string; url: string; className?: string }[]
    className?: string
}

const InformationBar: FC<Props> = ({
    color,
    message,
    buttonMessage,
    onClick,
    title,
    link,
    linkLabel,
    buttons,
    className = 'text-white border-white',
}) => {
    return (
        <div
            className={clsx('flex justify-between space-x-1 border-b p-2', className)}
            style={{ backgroundColor: color }}
        >
            <div className="flex flex-col">
                {title && <h4 className="font-bold">{title}</h4>}
                <div className="flex flex-wrap gap-8 items-center">
                    <span>{message}</span>
                    {link && linkLabel && <a href={link}>{linkLabel}</a>}
                    {buttons && (
                        <div className="flex gap-4">
                            {buttons?.map(button => (
                                <a key={button.url} href={button.url} className={clsx('btn', button.className)}>
                                    {button.label}
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {buttonMessage && (
                <span onClick={onClick} className="cursor-pointer underline">
                    {buttonMessage}
                </span>
            )}
        </div>
    )
}

export default InformationBar
