import ColorPicker from 'components/admin/color-picker'
import StorageChooser from 'components/admin/storage-chooser'
import { getFileSize } from 'components/dataroom/upload/file-size'
import { CropAs } from 'core/images/canvas-utils'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormProps } from '../../forms'
import Label from '../../label'
import PictureUpload from '../../picture-upload'
import 'components/admin/zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { optionValueSchema } from 'components/admin/forms'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { useMst } from 'stores/store'
import { z } from 'zod'

const schema = z.object({
    name: z.string().min(1),
    partnerId: z.string().min(1),
    url: z.string().url(),
    logo: z.string(),
    icon: z.string(),
    color: z.string(),
    brandImage: z.string().optional(),
    brandLogo: z.string(),
    viaColor: z.string(),
    storage: z.number().min(0),
    tags: z.array(optionValueSchema).optional(),
})
type Schema = z.infer<typeof schema>

const Form = observer(({ onSubmit, values, brandId }: FormProps<Schema> & { brandId: string }) => {
    const { t } = useTranslation()
    const { brand } = useMst()

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })

    const watchStorage = watch('storage', 0)

    useEffect(() => {
        if (!values) {
            return
        }

        for (const key of Object.keys(values) as (keyof Schema)[]) {
            setValue(key, values[key])
        }
    }, [values])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Label
                name="name"
                error={errors['name'] && `${t('web_admin_franchise_name')} ${errors['name']?.message}`}
                label={'web_admin_franchise_name'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <input {...register('name')} type="text" className="w-1/3" />
            </Label>
            <Label
                name="tags"
                error={errors['tags'] && `${t('web_partner_invite_tag')} ${errors['tags']?.message}`}
                label={'web_partner_invite_tag'}
                className="my-5 flex w-full flex-wrap items-center">
                <Controller
                    name="tags"
                    control={control}
                    render={({ field }) => (
                        <AsyncCreatableSelect
                            {...field}
                            className="w-1/3"
                            value={field.value}
                            defaultOptions
                            loadOptions={async (inputValue: string) => {
                                const tags = await brand.loadTags(brandId, inputValue)

                                return tags.map(tag => ({
                                    value: tag.uuid,
                                    label: tag.name,
                                }))
                            }}
                            isClearable
                            isMulti
                            onCreateOption={async (inputValue: string) => {
                                const tag = await brand.createTag(brandId, inputValue)
                                field.onChange([...field.value, { value: tag.uuid, label: tag.name }])
                            }}
                        />
                    )}
                />
            </Label>

            <Label
                name="partnerId"
                error={errors['partnerId'] && `${t('web_admin_franchise_partner_id')} ${errors['partnerId']?.message}`}
                label={'web_admin_franchise_partner_id'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <input {...register('partnerId')} type="text" className="w-1/3" />
            </Label>
            <Label
                name="url"
                error={errors['url'] && `${t('web_admin_franchise_url')} ${errors['url']?.message}`}
                label={'web_admin_franchise_url'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <input {...register('url')} type="text" className="w-1/3" defaultValue="https://" autoComplete="off" />
            </Label>
            <Label
                name="storage"
                help="web_admin_pricing_storage_help"
                helpParams={{ storage: getFileSize(t, watchStorage) }}
                error={errors['storage'] && `${t('web_admin_franchise_storage')} ${errors['storage']?.message}`}
                label={'web_admin_franchise_storage'}
                required={true}>
                <Controller control={control} name="storage" render={({ field }) => <StorageChooser {...field} />} />
            </Label>
            <Label
                name="logo"
                error={errors['logo'] && `${t('web_admin_franchise_logo')} ${errors['logo']?.message}`}
                label={'web_admin_franchise_logo'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <Controller
                    control={control}
                    name="logo"
                    render={({ field }) => <PictureUpload {...field} value={field.value} as={CropAs.URL} />}
                />
            </Label>
            <Label
                name="icon"
                error={errors['icon'] && `${t('web_admin_franchise_icon')} ${errors['icon']?.message}`}
                label={'web_admin_franchise_icon'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <Controller
                    control={control}
                    name="icon"
                    render={({ field }) => (
                        <PictureUpload {...field} value={field.value} as={CropAs.URL} aspectRatio={1} />
                    )}
                />
            </Label>
            <Label
                name="color"
                error={errors['color'] && `${t('web_admin_brand_color')} ${errors['color']?.message}`}
                label={'web_admin_brand_color'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <Controller
                    control={control}
                    name="color"
                    render={({ field }) => <ColorPicker color={field.value} onChange={hex => field.onChange(hex)} />}
                />
            </Label>
            <Label
                name="brandImage"
                error={errors['brandImage'] && `${t('web_admin_brand_image')} ${errors['brandImage']?.message}`}
                label={'web_admin_brand_image'}
                className="my-5 flex w-full flex-wrap items-center">
                <Controller
                    control={control}
                    name="brandImage"
                    render={({ field }) => <PictureUpload {...field} value={field.value} as={CropAs.URL} />}
                />
            </Label>
            <Label
                name="brandLogo"
                error={errors['brandLogo'] && `${t('web_admin_brand_download_logo')} ${errors['brandLogo']?.message}`}
                label={'web_admin_brand_download_logo'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <Controller
                    control={control}
                    name="brandLogo"
                    render={({ field }) => <PictureUpload {...field} value={field.value} as={CropAs.URL} />}
                />
            </Label>
            <Label
                name="viaColor"
                error={errors['viaColor'] && `${t('web_admin_brand_via_color')} ${errors['viaColor']?.message}`}
                label={'web_admin_brand_via_color'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <Controller
                    control={control}
                    name="viaColor"
                    render={({ field }) => <ColorPicker color={field.value} onChange={hex => field.onChange(hex)} />}
                />
            </Label>

            <div className="w-full text-right">
                <input type="submit" className="btn cursor-pointer" value={`${t('web_admin_franchise_save')}`} />
            </div>
        </form>
    )
})

export default Form
