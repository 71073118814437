import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowLeft } from '../../assets/arrow/arrow-left.svg'
import { DetachedSpaceOrDirectoryOrFile } from '../../stores/files'
import { SpaceDirectory } from '../../stores/files/directory'
import { Space } from '../../stores/files/space'
import { useMst } from '../../stores/store'
import Modal from '../shared/modal'
import DirectoryModal from './directory-modal'
import CreateSpace from './create-space'
import DirectoryLine from './move/directory-line'
import SpaceLine from './move/space-line'

interface Props {
    ignoreSpace?: Space
    isVisible: boolean
    onClose: () => void
    showDirectories: boolean
    onChoose?: (space: Space | undefined, directory: SpaceDirectory | undefined, action: string) => void
    spaces?: Space[]
    selection?: DetachedSpaceOrDirectoryOrFile[]
    fileExists?: boolean
    isCopy?: boolean
}

type SpaceOrDirectory = Space | SpaceDirectory

const MoveFile = observer(
    ({
        ignoreSpace,
        isVisible,
        onClose,
        onChoose,
        showDirectories,
        fileExists,
        selection = [],
        spaces,
        isCopy = false,
    }: Props) => {
        const { t } = useTranslation()
        const { files, user } = useMst()

        const [parents, setParents] = useState<SpaceOrDirectory[]>([])
        const [currentSpace, setCurrentSpace] = useState<Space | undefined>(undefined)
        const [currentDirectory, setCurrentDirectory] = useState<SpaceDirectory | undefined>(undefined)

        const [spacesDirectories, setSpacesDirectories] = useState<SpaceOrDirectory[]>([])

        const [confirmDisabled, setConfirmDisabled] = useState<boolean>(true)
        const [canCreateSpace, setCanCreateSpace] = useState<boolean>(true)
        const [canCreateDirectory, setCanCreateDirectory] = useState<boolean>(false)

        const [showCreateSpaceModal, setShowCreateSpaceModal] = useState<boolean>(false)
        const [showCreateDirectoryModal, setShowCreateDirectoryModal] = useState<boolean>(false)

        const handleConfirmDisabled = (space: Space | undefined, directory: SpaceDirectory | undefined): boolean => {
            const isOnlySpaces = selection.filter(c => !c.isSpace).length === 0
            if (!space) {
                return !isOnlySpaces
            }

            if (selection.length === 0) {
                return false
            }
            const haveSpace = selection.filter(c => c.isSpace).length !== 0
            if (haveSpace && directory !== undefined) {
                return true
            }

            return false
        }

        const refresh = async (space: Space | undefined, directory: SpaceDirectory | undefined) => {
            if (directory) {
                await directory.refresh()
                setSpacesDirectories([...values<SpaceDirectory>(directory.directories)])
            }
        }

        const refreshCurrentSpace = async () => {
            if (!currentSpace) {
                await files.getFiles()
                setSpacesDirectories([...values<Space>(files.spaces)])

                return
            }
            await currentSpace.refresh()
            const content: SpaceOrDirectory[] = []
            content.push(...values<Space>(currentSpace.spaces))
            if (showDirectories) {
                content.push(...values<SpaceDirectory>(currentSpace.rootDirectory.directories))
            }
            setSpacesDirectories(content)
        }

        const openSpace = async (space: Space) => {
            if (space.uuid && !space.isLoaded) {
                await space.refresh()
            }

            const struct = parents
            struct.push(space)
            setParents(struct)

            setCurrentSpace(space)
            setCurrentDirectory(space.rootDirectory)

            const content: SpaceOrDirectory[] = []
            content.push(...values<Space>(space.spaces))
            if (showDirectories) {
                content.push(...values<SpaceDirectory>(space.rootDirectory.directories))
            }
            setSpacesDirectories(content)

            setConfirmDisabled(handleConfirmDisabled(space, undefined))
        }

        const openDirectory = async (directory: SpaceDirectory) => {
            if (directory.isPersistedDirectory && !directory.isLoaded) {
                await directory.refresh()
            }
            const struct = parents
            struct.push(directory)
            setParents(struct)
            setCurrentDirectory(directory)
            setSpacesDirectories([...values<SpaceDirectory>(directory.directories)])

            setConfirmDisabled(handleConfirmDisabled(currentSpace, directory))
        }

        const currentPath = () => parents.map(s => s.displayName).join(' / ')

        const back = () => {
            const struct = parents
            // exit current directory
            struct.pop()

            // got to previous
            const previous = struct.pop()
            if (!previous) {
                // we are on top
                setSpacesDirectories([...values<Space>(files.spaces)])
                setCurrentSpace(undefined)
                setCurrentDirectory(undefined)
                setConfirmDisabled(handleConfirmDisabled(undefined, undefined))

                return
            }
            setParents(struct)
            if (previous.isSpace) {
                openSpace(previous)
                setCurrentDirectory(undefined)
            } else if (previous.isDirectory) {
                openDirectory(previous)
            }
        }

        const clear = () => {
            setCurrentDirectory(undefined)
            setCurrentSpace(undefined)
            setSpacesDirectories([])
            setParents([])
        }

        const close = () => {
            clear()
            onClose()
        }

        const confirmMove = (action: string) => {
            clear()
            onChoose?.(currentSpace, currentDirectory, action)
        }

        const sort = (a: Space, b: Space) => {
            if (a.userUuid === b.userUuid && a.userUuid === user.uuid) {
                return a.displayName.localeCompare(b.displayName, 'fr', { sensitivity: 'base' })
            }

            if (a.userUuid === user.uuid) {
                return -1
            }
            if (b.userUuid === user.uuid) {
                return 1
            }

            if (a.userUuid === b.userUuid) {
                return a.displayName.localeCompare(b.displayName, 'fr', { sensitivity: 'base' })
            }

            return (a.username ?? '').localeCompare(b.username ?? '', 'fr', { sensitivity: 'base' })
        }

        useEffect(() => {
            if (!isVisible) {
                return
            }
            const load = async () => {
                if (spaces) {
                    setSpacesDirectories(spaces)
                } else if (currentSpace === undefined) {
                    await files.getFiles()
                    setSpacesDirectories([...values<Space>(files.spaces).filter(s => s.parent === '')])
                }
                setConfirmDisabled(handleConfirmDisabled(currentSpace, currentDirectory))
            }

            load()
        }, [isVisible])

        useEffect(() => {
            if (!isVisible) {
                return
            }
            setCanCreateSpace(currentDirectory === undefined || currentDirectory.name === '/')
            setCanCreateDirectory(showDirectories && currentSpace !== undefined)
        })

        if (!isVisible) {
            return null
        }

        return (
            <>
                <Modal
                    size="1/2"
                    isOpen={isVisible}
                    onRequestClose={() => close()}
                    title={t('web_dataroom_choose_destination')}
                    onCancel={() => close()}
                    confirmDisabled={confirmDisabled}
                    okLabel={t(isCopy ? 'web_dataroom_confirm_copy' : 'web_dataroom_confirm_move')}
                    onConfirm={() => confirmMove('')}
                    bottomChildren={
                        <>
                            {canCreateSpace && (
                                <button className="btn" onClick={() => setShowCreateSpaceModal(true)}>
                                    {t('web_dataroom_add_space')}
                                </button>
                            )}
                            {canCreateDirectory && (
                                <button className="btn" onClick={() => setShowCreateDirectoryModal(true)}>
                                    {t('web_dataroom_add_directory')}
                                </button>
                            )}
                        </>
                    }
                >
                    {parents.length > 0 && (
                        <div className="flex w-full cursor-pointer items-center" onClick={() => back()}>
                            <ArrowLeft />
                            <span className="ml-2 font-bold">{currentPath()}</span>
                        </div>
                    )}

                    <ul className="w-full">
                        {spacesDirectories.sort(sort).map(space => {
                            if (space.isSpace && ignoreSpace && ignoreSpace.uuid === space.uuid) {
                                return null
                            }
                            if (space.isSpace && (space.isStack || space.isQuarantine)) {
                                return null
                            }
                            if (space.isSpace) {
                                return (
                                    <SpaceLine key={space.uuid} space={space} onOpenSpace={space => openSpace(space)} />
                                )
                            } else if (space.isDirectory) {
                                return (
                                    <DirectoryLine
                                        key={space.uuid}
                                        directory={space}
                                        onOpenDirectory={directory => openDirectory(directory)}
                                    />
                                )
                            }

                            return null
                        })}
                    </ul>
                    <CreateSpace
                        isOpen={showCreateSpaceModal}
                        parent={currentSpace}
                        onClose={() => setShowCreateSpaceModal(false)}
                        onSpaceCreated={() => refreshCurrentSpace()}
                    />

                    <DirectoryModal
                        isOpen={showCreateDirectoryModal}
                        onClose={() => setShowCreateDirectoryModal(false)}
                        onDirectoryCreated={(space, directory) => refresh(space, directory)}
                        space={currentSpace}
                        parent={currentDirectory ?? currentSpace?.rootDirectory}
                    />
                </Modal>
                <Modal isOpen={fileExists} onRequestClose={() => {}} bottomAlignment="center">
                    <h3 className="mt-12 font-bold">{t('web_uploading_files_duplicated')}</h3>
                    <p className="mt-2">{t('web_uploading_files_rename_or_overwrite')}</p>
                    <div className="inline-flex">
                        <button
                            className="btn float-left mr-4 mt-5 inline-flex cursor-pointer"
                            onClick={() => confirmMove('rename')}
                        >
                            {t('web_uploading_files_rename_button')}
                        </button>
                        <button
                            className="btn ml-4 mt-5 inline-flex cursor-pointer"
                            onClick={() => confirmMove('overwrite')}
                        >
                            {t('web_uploading_files_overwrite_button')}
                        </button>
                    </div>
                </Modal>
            </>
        )
    }
)

export default MoveFile
