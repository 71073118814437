import { faEdit, faTrash, faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from 'assets/backoffice/loader'
import { getFileSize } from 'components/dataroom/upload/file-size'
import ToggleButton from 'components/shared/toggle-button'
import { del } from 'core/services/http-service'
import toast from 'core/utils/toast'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Franchise } from 'stores/brands'
import { useMst } from 'stores/store'
import Admin from '../../admin'

const FranchiseLine = ({
    franchise,
    brandUuid,
    showDelete,
    onDelete,
}: {
    franchise: Franchise
    brandUuid: string
    showDelete: boolean
    onDelete: (franchise: Franchise) => void
}) => {
    const { admin } = useMst()
    const { t } = useTranslation()
    const [enabled, setEnabled] = useState<boolean>(true)
    const [isLoading, setLoading] = useState<boolean>(false)

    const toggleFranchise = useCallback(async () => {
        setLoading(true)
        const refreshed = await admin.toggleBrandFranchise(brandUuid, franchise.uuid)
        if (refreshed) {
            setEnabled(refreshed.enabledAt !== null)
        }
        setLoading(false)
    }, [franchise.uuid])

    useEffect(() => {
        setEnabled(franchise.enabled)
    }, [franchise.uuid])

    return (
        <tr>
            <td className="text-left">
                <div className="flex w-full items-center gap-2">
                    <img src={franchise.icon} className="w-8" />
                    <span>{franchise.name}</span>
                </div>
            </td>
            <td className="text-left">
                <a href={franchise.url} target="_blank" rel="noopener noreferrer">
                    {franchise.url}
                </a>
            </td>
            <td className="text-left">{getFileSize(t, franchise.storage)}</td>
            <td>
                <div className="flex w-full items-center justify-center">
                    {isLoading ? (
                        <Loader loading={isLoading} className="text-christine" />
                    ) : (
                        <ToggleButton value={enabled} onChange={() => toggleFranchise()} />
                    )}
                </div>
            </td>
            <td>
                <div className="mr-2 flex items-center justify-end space-x-1 text-christine">
                    <Link to={`/admin/brands/${brandUuid}/franchises/${franchise.uuid}`}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Link>
                    <Link to={`/franchises/${franchise.uuid}/users`}>
                        <FontAwesomeIcon icon={faUser} />
                    </Link>
                    {showDelete && (
                        <button
                            type="button"
                            onClick={async () => {
                                if (confirm(t('web_admin_confirm_delete_franchise'))) {
                                    onDelete(franchise)
                                }
                            }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    )}
                </div>
            </td>
        </tr>
    )
}

const Listing = observer(() => {
    const { admin, user } = useMst()

    const { brandId } = useParams()

    const { t } = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(true)

    const load = async (brandId: string) => {
        setLoading(true)
        await admin.loadBrand(brandId)
        await admin.editingBrand.loadFranchises()
        setLoading(false)
    }

    const duplicate = async () => {
        setLoading(true)
        await admin.editingBrand.duplicateFranchise()
        await load(brandId)
    }

    useEffect(() => {
        load(brandId)
    }, [brandId])

    const [showDelete, setShowDelete] = useState<boolean>(false)
    useEffect(() => {
        setShowDelete(user.isAdmin)
    }, [user])

    const deleteFranchise = async (franchise: Franchise) => {
        setLoading(true)
        await del(`/v1/bo/brands/${brandId}/franchises/${franchise.uuid}`)
        await load(brandId)
        toast('success', 'web_admin_franchise_deleted')
    }

    return (
        <Admin
            title={admin.editingBrand.name}
            isLoading={isLoading}
            header={
                <div className="flex items-center space-x-4">
                    <button className="btn cursor-pointer" onClick={() => duplicate()}>
                        {t('web_admin_brand_franchise_duplicate')}
                    </button>
                    <Link to={`/admin/brands/${brandId}/franchises/create`} className="btn">
                        {t('web_admin_brand_franchise_new')}
                    </Link>
                </div>
            }>
            <table className="admin w-full table-auto text-center">
                <thead>
                    <tr>
                        <th>{t('web_menu_admin_franchises_name')}</th>
                        <th>{t('web_menu_admin_franchises_url')}</th>
                        <th>{t('web_menu_admin_franchises_storage')}</th>
                        <th>{t('web_menu_admin_franchises_status')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {admin.editingBrand.franchises.map(franchise => (
                        <FranchiseLine
                            key={franchise.uuid}
                            franchise={franchise}
                            brandUuid={brandId}
                            showDelete={showDelete}
                            onDelete={async brand => await deleteFranchise(franchise)}
                        />
                    ))}
                </tbody>
            </table>
        </Admin>
    )
})

export default Listing
