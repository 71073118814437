import { Crop } from 'react-image-crop'

export const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

export enum CropAs {
    URL = 'url',
    BLOB = 'blob',
}

export const readFile = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener(
            'load',
            () => {
                if (reader.result && typeof reader.result === 'string') {
                    resolve(reader.result)
                } else {
                    reject()
                }
            },
            false
        )
        reader.readAsDataURL(file)
    })
}

const createImage = (url: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

export const getCroppedImg = async (
    image: HTMLImageElement,
    crop: Crop,
    as: CropAs,
    mime = 'image/jpeg'
): Promise<Blob | string | undefined> => {
    const canvas = document.createElement('canvas')
    if (canvas === undefined) {
        return undefined
    }
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width ?? 0
    canvas.height = crop.height ?? 0
    const ctx = canvas.getContext('2d')

    if (ctx === null || canvas === null) {
        return undefined
    }

    ctx.drawImage(
        image,
        (crop.x ?? 0) * scaleX,
        (crop.y ?? 0) * scaleY,
        (crop.width ?? 0) * scaleX,
        (crop.height ?? 0) * scaleY,
        0,
        0,
        crop.width ?? 0,
        crop.height ?? 0
    )

    if (as === CropAs.URL) {
        const base64Image = canvas.toDataURL(mime)

        return base64Image
    } else {
        return new Promise(resolve => {
            canvas.toBlob(
                blob => {
                    if (blob) {
                        // blob.name = fileName
                    }
                    resolve(blob || undefined)
                },
                mime,
                1
            )
        })
    }
}

export const getRotatedImage = async (imageSrc: string, rotation = 0, mime = 'image/jpeg'): Promise<string | null> => {
    const image = await createImage(imageSrc)
    const canvas: HTMLCanvasElement = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (ctx === null) {
        return null
    }

    const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270
    if (orientationChanged) {
        canvas.width = image.height
        canvas.height = image.width
    } else {
        canvas.width = image.width
        canvas.height = image.height
    }

    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate((rotation * Math.PI) / 180)
    ctx.drawImage(image, -image.width / 2, -image.height / 2)

    return new Promise<string>(resolve => {
        canvas.toBlob(file => {
            resolve(URL.createObjectURL(file))
        }, mime)
    })
}
