import { detect } from 'detect-browser'
import { FC, ReactNode, useEffect, useState } from 'react'

const browser = detect()

interface Props {
    href: string
    className?: string
    children?: ReactNode
    disabled?: boolean
}

const DownloadLink: FC<Props> = ({ href, className = '', children, disabled = false }) => {
    const [download, setDownload] = useState<boolean>(false)

    useEffect(() => {
        setDownload(browser ? browser.name === 'safari' && browser.os === 'Mac OS' : false)
    })

    if (disabled) {
        return <span className={className}>{children}</span>
    }

    return (
        <a href={`${href}${download ? '?download=1' : ''}`} target="_blank" className={className} rel="noreferrer">
            {children}
        </a>
    )
}

export default DownloadLink
