import { ReactComponent as IconPlaceholder } from 'assets/icons/icon-user-placeholder.svg'
import Tooltip from '../../shared/tooltip'

const SharingPanelLineIcon = (
    { image, tooltip, zIndex = undefined }: { image: string; tooltip: string; zIndex?: number }
) => (
    <Tooltip tooltip={tooltip}>
        <div
            className="relative flex h-6 w-6 items-center justify-center overflow-hidden rounded-full border border-white bg-regent-gray text-white"
            style={{ zIndex }}>
            <div className="absolute inset-0 flex items-center justify-center">
                <IconPlaceholder className="h-3 w-3 fill-current" />
            </div>
            <div
                className={'absolute inset-0 flex items-center justify-center bg-cover bg-center bg-no-repeat'}
                style={{ backgroundImage: `url(${image})` }}
            />
        </div>
    </Tooltip>
)

export default SharingPanelLineIcon
