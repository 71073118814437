import Admin from 'components/admin/admin'
import toast from 'core/utils/toast'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { BrandNews as News } from 'stores/brands/news'
import { useMst } from '../../../stores/store'
import Form, { Schema } from './form'

const Edit = () => {
    const { brand, files } = useMst()
    const { t } = useTranslation()
    const { id: brandId, nid: newsId } = useParams()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [values, setValues] = useState<Record<string, unknown>>({})

    const onSubmit = async (data: Schema) => {
        const { title, teaserText, bodyMessage, dismissible, startDate, endDate, color, pdf } = data
        await brand.updateNews(brandId, newsId, {
            title,
            teaserText,
            bodyMessage,
            dismissible,
            startDate,
            endDate,
            pdf: pdf as FileList,
            color,
        })
        toast('success', t('web_brand_news_updated'))
        navigate(`/brands/${brandId}/news/${newsId}`)
    }

    const load = async (id: string) => {
        setLoading(true)
        await files.getConfig()
        const news = await brand.loadNews(id)

        const makeValues = (news: News) => {
            return {
                title: news.title,
                teaserText: news.teaserText,
                bodyMessage: news.bodyMessage,
                dismissible: news.dismissible,
                startDate: news.startDate !== '' ? DateTime.fromISO(news.startDate).toJSDate() : null,
                endDate: news.endDate !== '' ? DateTime.fromISO(news.endDate).toJSDate() : null,
                upload: news.upload,
                color: news.color,
                createdAt: news.createdAt !== '' ? DateTime.fromISO(news.createdAt).toJSDate() : null,
            }
        }
        setValues(makeValues(news))
        setLoading(false)
    }

    useEffect(() => {
        load(newsId)
    }, [newsId])

    return (
        <Admin title={t('web_partner_news')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} values={values} />
        </Admin>
    )
}

export default Edit
