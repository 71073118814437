import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import Panel from '../shared/panel'
import ProfilePage from './profile-page'

const Sponsorship: FC = observer(() => {
    return (
        <ProfilePage url="/me/sponsorship">
            <Panel className="mt-4" innerClassName="flex justify-center bg-white">
                <div className="flex w-full flex-col md:w-2/3"></div>
            </Panel>
        </ProfilePage>
    )
})

export default Sponsorship
