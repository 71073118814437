import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '../../stores/files/space'
import { useMst } from '../../stores/store'
import Modal from '../shared/modal'

interface Props {
    parent?: Space
    space?: Space
    isOpen: boolean
    onClose: () => void
    onSpaceCreated?: (space: Space) => void
    showName?: boolean
    showColor?: boolean
}

const CreateSpace = observer(
    ({ parent, space, isOpen, onSpaceCreated, onClose, showName = true, showColor = true }: Props) => {
        const { t } = useTranslation()
        const { files } = useMst()

        const [name, setName] = useState<string>(space ? space.name : '')
        const [color, setColor] = useState<string>(space ? space.color : '')
        const [isSaving, setSaving] = useState<boolean>(false)
        const [parentSpace, setParentSpace] = useState<Space>()

        const onValidate = async () => {
            setSaving(true)
            if (space) {
                const s = files.recursivelyFindSpace(space.uuid)
                if (s) {
                    await s.update(name.trim(), color)
                    files.markForceRefresh(s.uuid)
                }
            } else {
                const newSpace = await files.createSpace(name.trim(), color, parentSpace)
                onSpaceCreated?.(newSpace)
            }
            setSaving(false)

            setName('')
            setColor('')
            onClose()
        }

        useEffect(() => {
            setParentSpace(parent)
        }, [parent])

        useEffect(() => {
            setName(space ? space.name : '')
            setColor(space ? space.color : '')
        }, [space])

        return (
            <Modal
                isOpen={isOpen}
                size="1/4"
                title={space ? t('web_edit_space') : t('web_create_new_space')}
                onRequestClose={onClose}
            >
                {showName && (
                    <input
                        type="text"
                        className="mb-4 mt-2 w-3/4 border p-2"
                        placeholder={t('web_create_space_name')}
                        value={name}
                        onChange={e => setName(e.currentTarget.value)}
                    />
                )}

                {showColor && (
                    <>
                        <span className="text-regent-gray mb-2">{t('web_create_space_color_help')}</span>
                        <div className="grid w-2/3 grid-cols-6 gap-4">
                            {files.colors.map(c => (
                                <button
                                    key={c}
                                    className="relative h-6 w-6 rounded-full border-2 outline-none ring-0 transition-all hover:scale-150"
                                    style={{
                                        backgroundColor: c === color ? 'white' : c,
                                        borderColor: c,
                                    }}
                                    onClick={() => setColor(c)}
                                />
                            ))}
                        </div>
                    </>
                )}

                <div className="mb-2 mt-6 flex w-full justify-around">
                    <button className="btn white px-8" onClick={() => onClose()}>
                        {t('web_create_space_cancel')}
                    </button>
                    <button
                        className="btn px-8"
                        disabled={name === '' || color === '' || isSaving}
                        onClick={() => onValidate()}
                    >
                        {t('web_create_space_confirm')}
                    </button>
                </div>
            </Modal>
        )
    }
)

export default CreateSpace
