import { ReactComponent as UploadZen } from 'assets/login/register-zen.svg'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { SharedFile, SharedGroup } from 'stores/files'
import { ShareGroup as ShareGroupName } from 'stores/share-group'
import { useMst } from 'stores/store'
import ContentPage from '../dataroom/content-page'
import Modal from '../shared/modal'
import Panel from '../shared/panel'
import SharingPanelLineContent from './components/sharing-panel-line-content'

interface ShareGroupProps {
    showUserSharedByMe?: boolean
    showUserSharedWithMe?: boolean
}

const ShareGroup: FC<ShareGroupProps> = ({ showUserSharedByMe, showUserSharedWithMe }) => {
    const { files } = useMst()
    const { group } = useParams()
    const { t } = useTranslation()
    const [sharedGroup, setSharedGroup] = useState<SharedGroup>()
    const [downloading, setDownloading] = useState<boolean>(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [shareGroupName, setShareGroup] = useState<ShareGroupName>()

    const [showConfirmStop, setShowConfirmStop] = useState<SharedFile>()
    const [showConfirmDelete, setShowConfirmDelete] = useState<SharedFile>()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [groupName, setGroupName] = useState<string>(shareGroupName ? shareGroupName.groupName : '')

    const stopShare = async (sharedFile: SharedFile) => {
        await files.stopSharing(sharedFile.uuid, false, false)
        setShowConfirmStop(undefined)
    }
    const deleteShare = async (sharedFile: SharedFile) => {
        await files.stopSharing(sharedFile.uuid, true, false)
        setShowConfirmDelete(undefined)
    }
    // const editGroupName = async (shareGroupName: ShareGroupName) => {
    //     await shareGroupName.editGroupName(shareGroupName.uuid, shareGroupName.groupName)
    //     setGroupName(undefined)
    // }

    const load = async () => {
        if (showUserSharedByMe) {
            let sharedGroup = files.sharedGroupByMe.find(sg => sg.uuid === group)
            if (sharedGroup) {
                setSharedGroup(sharedGroup)

                return
            }
            await files.getFilesSharedByMe(true)
            sharedGroup = files.sharedGroupByMe.find(sg => sg.uuid === group)
            if (sharedGroup) {
                setSharedGroup(sharedGroup)

                return
            }
        } else if (showUserSharedWithMe) {
            let sharedGroup = files.sharedGroupWithMe.find(sg => sg.uuid === group)
            if (sharedGroup) {
                setSharedGroup(sharedGroup)

                return
            }
            await files.getFilesSharedWithMe(true)
            sharedGroup = files.sharedGroupWithMe.find(sg => sg.uuid === group)
            if (sharedGroup) {
                setSharedGroup(sharedGroup)

                return
            }
        }
    }

    useEffect(() => {
        load()
    }, [group])

    return (
        <ContentPage title={t('web_link_share')} containerClassnames="flex-col space-y-6">
            <Panel innerClassName="flex flex-col space-y-4">
                <h2 className="text-lg">
                    {sharedGroup
                        ? t('web_share_with_title', {
                              users: sharedGroup.users.map(user => user.user.trustedUser.fullname).join(', '),
                          })
                        : showUserSharedByMe
                        ? t('web_shares_my_share')
                        : showUserSharedWithMe
                        ? t('web_shares_shared_with_me')
                        : t('web_share_loading')}
                </h2>
                <SharingPanelLineContent
                    setDownloading={setDownloading}
                    sharedFiles={sharedGroup?.shares ?? []}
                    showUserSharedByMe={showUserSharedByMe}
                    showUserSharedWithMe={showUserSharedWithMe}
                    setShowConfirmStop={setShowConfirmStop}
                    setShowRestart={() => {}}
                    setShowConfirmDelete={setShowConfirmDelete}
                />
            </Panel>

            <Modal isOpen={downloading} size="1/2" onRequestClose={() => {}} overflowHidden={false}>
                <UploadZen className="-mt-36" />
                <h3 className="mt-12 font-bold">{t('web_share_downloading')}</h3>
            </Modal>
            <Modal
                isOpen={groupName !== undefined}
                title={t('web_edit_group_name')}
                onRequestClose={() => {}}
                // onConfirm={() => editGroupName(setShowEditGroupName)}
            >
                {groupName && (
                    <input
                        type="text"
                        className="mb-4 mt-2 w-3/4 border p-2"
                        placeholder={t('web_edit_group_name')}
                        value={groupName}
                        // onChange={e => editGroupName(shareGroupName)}
                    />
                )}
            </Modal>

            <Modal
                isOpen={showConfirmStop !== undefined}
                title={t('web_confirm_stop_share')}
                onCancel={() => setShowConfirmStop(undefined)}
                onRequestClose={() => setShowConfirmStop(undefined)}
                onConfirm={() => stopShare(showConfirmStop)}
            />

            <Modal
                isOpen={showConfirmDelete !== undefined}
                title={t('web_confirm_delete_share')}
                onCancel={() => setShowConfirmDelete(undefined)}
                onRequestClose={() => setShowConfirmDelete(undefined)}
                onConfirm={() => deleteShare(showConfirmDelete)}
            />
        </ContentPage>
    )
}

export default ShareGroup
