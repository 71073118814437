import Admin from 'components/admin/admin'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useMst } from 'stores/store'
import Form from './form'

const Edit = () => {
    const { admin } = useMst()
    const { t } = useTranslation()
    const { brandId } = useParams()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)

    const onSubmit = async (data: Record<string, unknown>) => {
        const { name, url, logo, icon, partnerId, color, viaColor, brandImage, brandLogo } = data as Record<
            string,
            string
        >
        const { storage } = data as Record<string, number>
        setLoading(true)
        await admin.editingBrand.createFranchise(
            name,
            url,
            logo,
            icon,
            storage,
            partnerId,
            color,
            viaColor,
            brandImage,
            brandLogo,
            (data as { tags: { value: string; label: string }[] }).tags.map(t => t.value) ?? []
        )
        setLoading(false)
        navigate(`/admin/brands/${brandId}/franchises`)
    }

    return (
        <Admin title={t('web_admin_brand_franchise')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} brandId={brandId} />
        </Admin>
    )
}

export default Edit
