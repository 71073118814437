import { getRoot, Instance, types } from 'mobx-state-tree'
import { HttpError } from '../core/services/http-service'
import toast from '../core/utils/toast'
import { RootInstance } from './store'

export const _ErrorManager = types
    .model('ErrorManager', {
        message: '',
    })
    .actions(self => {
        return {
            clean() {
                self.message = ''
            },

            prepare(error: Error) {
                console.error(error)
                if (error instanceof HttpError) {
                    this.setMessage(error.message)
                }
            },

            setMessage(error: string) {
                const root = getRoot(self) as RootInstance

                self.message = error
                if (root.isLogged) {
                    toast('error', self.message)
                }
            },
        }
    })
    .views(self => ({
        get hasError(): boolean {
            return self.message !== ''
        },
    }))

export interface ErrorManager extends Instance<typeof _ErrorManager> {}
