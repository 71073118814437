import { S3 } from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'
import { safariStream } from 'components/dataroom/upload/upload'
import toast from 'core/utils/toast'
import { flow } from 'mobx'
import { getRoot, Instance, types } from 'mobx-state-tree'
import pako from 'pako'
import { del, get, post, put } from '../core/services/http-service'
import { _News, News } from './news/news'
import { RootInstance } from './store'

export const _NewsStore = types
    .model('_NewsStore', {
        news: types.array(_News),
        editingNews: types.optional(_News, {}),
    })
    .actions(self => ({
        replace: ({ news }) => {
            self.news = news.sort((a, b) => b.createdAt - a.createdAt)
        },
        removedNews(id) {
            const removedNews = self.news.filter(n => {
                n.uuid = id
            })[0]
            self.news.remove(removedNews)
        },
        append(n) {
            self.news.push(n)
        },
        assignNews({ news }) {
            self.editingNews = news
        },
    }))
    .actions(self => ({
        load: flow(function*() {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent { }
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const data = yield get<void, JsonReturn>('/v1/web/news')

                const {
                    data: { news },
                } = data
                self.replace({ news })
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        newsCreate: flow(function*(
            partnerUuid: string,
            title: string,
            teaserText: string,
            bodyMessage: string,
            color: string,
            pdf: FileList | null,
            dismissible: boolean,
            startDate: Date,
            endDate: Date
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                let pdfFile: string

                if (pdf) {
                    let file = pdf[0]
                    if (file) {
                        // get config
                        const {
                            data: { config },
                        } = yield get(`/v1/web/partners/${partnerUuid}/config`)

                        const { accessKeyId, secretAccessKey, bucketName, region, dirName } = config

                        const client = new S3({
                            region,
                            credentials: {
                                accessKeyId,
                                secretAccessKey,
                            },
                        })
                        const fileType = file.type
                        const buffer = yield file.arrayBuffer()
                        const filename = `${file.name}.gz`
                        pdfFile = `${dirName}/${filename}`
                        const fileAsArray = new Uint8Array(buffer)
                        file = yield pako.gzip(fileAsArray, { level: 9 })

                        try {
                            const upload = new Upload({
                                client,
                                queueSize: 3,
                                params: {
                                    Bucket: bucketName,
                                    Key: pdfFile,
                                    Body: Blob.prototype.stream ? file : safariStream(file),
                                    ACL: 'public-read',
                                    ContentType: fileType,
                                    ContentEncoding: 'gzip',
                                },
                            })

                            yield upload.done()
                        } catch (error) {
                            console.error({ error, message: error.message })
                            throw new Error('web_upload_error')
                        }
                    }
                }

                const postData = {
                    title,
                    teaserText,
                    bodyMessage,
                    color,
                    file: pdfFile,
                    dismissible,
                    startDate: startDate ? startDate.toISOString() : null,
                    endDate: endDate ? endDate.toISOString() : null,
                    partnerUuid,
                }
                const data = yield post<typeof postData, News>('/v1/web/news', postData)

                self.append(data.data)

                toast('success', 'web_menu_partner_news_created')

                return data.data
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        loadNews: flow(function*(id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: News }>(`/v1/web/news/${id}`)
                const news = data.data
                self.assignNews({ news })
            } catch (error) {
                root.error.prepare(error)
            }
        }),

        newsUpdate: flow(function*(
            partnerUuid: string,
            id: string,
            title: string,
            teaserText: string,
            bodyMessage: string,
            dismissible: boolean,
            startDate: Date,
            endDate: Date,
            pdf: FileList | null,
            color: string
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                let pdfFile: string

                if (pdf) {
                    let file = pdf[0]
                    if (file) {
                        // get config
                        const {
                            data: { config },
                        } = yield get(`/v1/web/partners/${partnerUuid}/config`)

                        const { accessKeyId, secretAccessKey, bucketName, region, dirName } = config

                        const client = new S3({
                            region,
                            credentials: {
                                accessKeyId,
                                secretAccessKey,
                            },
                        })
                        const fileType = file.type
                        const buffer = yield file.arrayBuffer()
                        const filename = `${file.name}.gz`
                        pdfFile = `${dirName}/${filename}`
                        const fileAsArray = new Uint8Array(buffer)
                        file = yield pako.gzip(fileAsArray, { level: 9 })

                        try {
                            const upload = new Upload({
                                client,
                                queueSize: 3,
                                params: {
                                    Bucket: bucketName,
                                    Key: pdfFile,
                                    Body: Blob.prototype.stream ? file : safariStream(file),
                                    ACL: 'public-read',
                                    ContentType: fileType,
                                    ContentEncoding: 'gzip',
                                },
                            })

                            yield upload.done()
                        } catch (error) {
                            console.error({ error, message: error.message })
                            throw new Error('web_upload_error')
                        }
                    }
                }

                const jsonData = {
                    title,
                    teaserText,
                    bodyMessage,
                    dismissible,
                    startDate: startDate ? startDate.toISOString() : null,
                    endDate: endDate ? endDate.toISOString() : null,
                    file: pdfFile,
                    color,
                }

                yield put<typeof jsonData, void>(`/v1/web/news/${id}`, jsonData)

                toast('success', 'web_partner_news_updated')
            } catch (error) {
                root.error.prepare(error)
            }
        }),

        newsDelete: flow(function*(id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()
            try {
                yield del<void>(`/v1/web/news/${id}`)
                self.removedNews(id)
                toast('success', 'web_menu_partner_new_deleted')
            } catch (error) {
                root.error.prepare(error)
            }
        }),

        append(n) {
            self.news.push(n)
        },
    }))

export interface NewsStore extends Instance<typeof _NewsStore> { }
