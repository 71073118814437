import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useMst } from '../../../stores/store'
import Admin from '../admin'
import Form from './form'

const Edit = () => {
    const { userTypeStore } = useMst()
    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [values, setValues] = useState<Record<string, unknown>>({})

    const onSubmit = async data => {
        const { name, parent } = data
        await userTypeStore.userTypeUpdate(userTypeStore.editingUserType.uuid, name, parent?.value)
        navigate('/admin/user-types')
    }

    const load = async () => {
        setLoading(true)
        await userTypeStore.loadUserType(id)
        setValues({
            name: userTypeStore.editingUserType.name,
            parent: {
                value: userTypeStore.editingUserType.parent.parentId,
                label: userTypeStore.editingUserType.parent.name,
            },
        })
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [id])

    return (
        <Admin title={t('web_admin_user_types')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} values={values} />
        </Admin>
    )
}

export default Edit
