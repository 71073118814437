import { faCamera } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getOrientation } from 'get-orientation/browser'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import ReactCrop, { Crop } from 'react-image-crop'
import { CropAs, getCroppedImg, getRotatedImage, ORIENTATION_TO_ANGLE, readFile } from '../../core/images/canvas-utils'

interface Props {
    value?: string
    aspectRatio?: number
    as?: CropAs
    refresh?: boolean
    onChange: (image: string | Blob) => void
}

const PictureUpload = forwardRef<HTMLDivElement, Props>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ value, aspectRatio, as = CropAs.URL, onChange, refresh = false }, ref) => {
        const [imageRef, setImageRef] = useState<HTMLImageElement>()
        const [imageSrc, setImageSrc] = useState<string | null>(null)
        const [isLoaded, setLoaded] = useState<boolean>(false)
        const [mimeType, setMimeType] = useState<string>('image/jpeg')

        const onDrop = useCallback(async acceptedFiles => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0] as File
                let imageDataUrl = await readFile(file)
                setMimeType(file.type)

                // apply rotation if needed
                const orientation = await getOrientation(file)
                const rotation = ORIENTATION_TO_ANGLE[orientation]
                if (rotation) {
                    const rotated = await getRotatedImage(imageDataUrl, rotation, file.type)
                    if (rotated) {
                        imageDataUrl = rotated
                    }
                }

                setImageSrc(imageDataUrl)
                setLoaded(true)
            }
        }, [])

        useEffect(() => {
            if (refresh) {
                setImageSrc(null)
                setLoaded(false)
            }
        }, [refresh])

        const { getRootProps, getInputProps } = useDropzone({
            onDrop,
            accept: ['image/*'],
            multiple: false,
        })

        const [crop, setCrop] = useState<Crop>({ x: 0, y: 0, width: 100, height: 100, unit: '%' })

        const setImageLoaded = img => {
            setImageRef(img)

            let x,
                y,
                width,
                height = 0

            if (!aspectRatio || img.height === img.width) {
                width = img.width
                height = img.height
                x = y = 0
            } else if (img.height < img.width) {
                width = height = img.height
                x = (img.width - img.height) / 2
                y = 0
            } else {
                width = height = img.width
                x = 0
                y = (img.height - img.width) / 2
            }

            const crop: Crop = {
                unit: 'px',
                width,
                height,
                x,
                y,
                aspect: aspectRatio,
            }
            setCrop(crop)

            const updateValue = async (img, crop) => {
                const image = (await getCroppedImg(img, crop, as, mimeType)) as string
                onChange(image)
            }
            updateValue(img, crop)

            return false
        }

        return (
            <div className="flex w-full flex-col items-center justify-center">
                {!isLoaded && (
                    <div className={'relative h-20 w-20 overflow-hidden bg-atomic-tangerine'}>
                        <div className="absolute inset-0 flex items-center justify-center">
                            {!value && <FontAwesomeIcon icon={faCamera} className="h-8 w-8 text-white" />}
                        </div>
                        <div
                            className="absolute inset-x-0 bottom-0 flex h-8 cursor-pointer items-center justify-center text-transparent hover:bg-regent-gray/60 hover:text-regent-gray"
                            {...getRootProps()}>
                            <input {...getInputProps()} />
                            <FontAwesomeIcon icon={faCamera} className="h-4 w-4 text-white" />
                        </div>
                        {value && (
                            <div
                                className={'h-20 w-20 bg-cover bg-no-repeat'}
                                style={{ backgroundImage: `url(${value})` }}></div>
                        )}
                    </div>
                )}
                {imageSrc && isLoaded && (
                    <div className="flex w-full flex-col items-center space-y-4">
                        <ReactCrop
                            src={imageSrc}
                            crop={crop}
                            onChange={async newCrop => {
                                setCrop(newCrop)
                                if (imageRef) {
                                    const image = (await getCroppedImg(imageRef, crop, as)) as string
                                    onChange(image)
                                }
                            }}
                            onImageLoaded={img => setImageLoaded(img)}
                        />
                    </div>
                )}
            </div>
        )
    }
)
PictureUpload.displayName = 'PictureUpload'

export default PictureUpload
