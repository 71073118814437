import { faCaretRight, faPen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as Folder } from 'assets/icons/icon-folder.svg'
import { clsx } from 'clsx'
import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Element, scroller } from 'react-scroll'
import type { SpaceDirectory } from 'stores/files/directory'
import type { SpaceFile } from 'stores/files/file'
import type { Space as SpaceModel } from 'stores/files/space'
import { useMst } from 'stores/store'
import { twMerge } from 'tailwind-merge'
import Editable from '../../shared/editable'
import { Directory } from './directory'
import { File, baseClassNames, checkboxClassNames } from './file'
import { Line } from './line'
import { Loader } from './loader'
import { type FileOrDirectoryProps, padding } from './props'
import { Row } from './row'

export const checkboxColor = (isOver: boolean, checked: boolean, canChangeSelection: boolean) => {
    if (!canChangeSelection) {
        return 'border-0 bg-regent-gray/40'
    }

    return 'border-1 border-christine'
}

interface SpaceProps extends FileOrDirectoryProps {
    space: SpaceModel
    cmp: (a: SpaceFile | SpaceDirectory, b: SpaceFile | SpaceDirectory) => number
    onSelect: (object: SpaceFile | SpaceDirectory | SpaceModel) => void
    onDeselect: (object: SpaceFile | SpaceDirectory | SpaceModel) => void
    selectedFiles: SpaceFile[] | SpaceDirectory[]
    highlightedFile?: string
    highlightedDirectory?: string
    highlightedSpace?: string
    openingIds: string[]
    onRenameSuggestionClicked: (file: SpaceFile) => void
    canOfferSuggestion: boolean
    onOpenChecklist: (directory: SpaceDirectory) => void
}

export const Space = observer(
    ({
        space,
        level,
        cmp,
        selected,
        canChangeSelection,
        onSelect,
        onDeselect,
        selectedFiles,
        highlightedFile,
        highlightedDirectory,
        highlightedSpace,
        openingIds,
        onRenameSuggestionClicked,
        canOfferSuggestion,
        onOpenChecklist,
        isRefreshing,
    }: SpaceProps) => {
        const { t } = useTranslation()
        const { files, user } = useMst()

        const [loading, setLoading] = useState<boolean>(false)

        const [editName, setEditName] = useState<boolean>(false)
        const [name, setName] = useState<string>(space.displayName)

        const nameRef = useRef<HTMLInputElement>(null)
        const [isOver, setOver] = useState<boolean>(false)
        const [checked, setChecked] = useState<boolean>(false)
        const [disabled, setDisabled] = useState<boolean>(false)

        const changeSelection = (newValue: boolean) => {
            newValue ? onSelect(space) : onDeselect(space)
            setChecked(newValue)
        }

        const collator = new Intl.Collator(user.locale, { numeric: true })

        const toggleChecked = () => {
            const newValue = !checked
            changeSelection(newValue)
        }
        useEffect(() => {
            setChecked(selected)
            setDisabled(!canChangeSelection || isRefreshing)
        }, [selected, canChangeSelection, isRefreshing])

        useEffect(() => {
            if (openingIds.includes(space.uuid)) {
                space.open()
            }
        }, [space.uuid, openingIds])

        useEffect(() => {
            editName && nameRef.current && nameRef.current.focus()
        }, [editName, nameRef])

        const onSave = async (name: string) => {
            setName(name)
            await space.rename(name)
            setEditName(false)
        }

        useEffect(() => {
            if (highlightedSpace === space.uuid) {
                scroller.scrollTo(highlightedSpace, {
                    duration: 100,
                    delay: 100,
                    smooth: true,
                    offset: 50,
                })
            }
        }, [highlightedSpace])

        const toggleOpened = async () => {
            if (space.isOpen) {
                space.close()
            } else {
                space.open()
                setLoading(true)
                await space.refresh()
                setLoading(false)
            }
        }

        const load = async (space: SpaceModel) => {
            setLoading(true)
            await space.refresh(files.shouldForceRefresh(space.uuid))
            files.clearForceRefresh(space.uuid)
            setLoading(false)
        }
        useEffect(() => {
            if ((space.isOpen && !space.isLoaded) || files.forceRefresh.includes(space.uuid)) {
                load(space)
            }
        }, [space, files.forceRefresh])

        return (
            <>
                <Line fileOrDirectory={space}>
                    {user.canManagePersonalData && (
                        <Row
                            highlight={highlightedSpace === space.uuid}
                            dropType="space"
                            dropUuid={space.uuid}
                            className={clsx(
                                'group flex items-center justify-center border-l-2 border-christine',
                                checkboxColor(isOver, checked, canChangeSelection),
                                baseClassNames
                            )}
                            onMouseEnter={() => setOver(true)}
                            onMouseLeave={() => setOver(false)}
                        >
                            <input
                                type="checkbox"
                                checked={checked}
                                disabled={disabled}
                                onChange={() => toggleChecked()}
                                className={checkboxClassNames}
                            />
                        </Row>
                    )}
                    <Row
                        highlight={highlightedSpace === space.uuid}
                        dropType="space"
                        dropUuid={space.uuid}
                        style={{
                            paddingLeft: `${padding(level)}rem`,
                            gridColumn: 'span 15 / span 15',
                        }}
                        className={twMerge(baseClassNames.replace('px-2', ''), 'py-1 pr-1')}
                    >
                        <Element name={space.uuid} className="flex items-center space-x-2">
                            <FontAwesomeIcon
                                icon={faCaretRight}
                                className="cursor-pointer fill-current text-inner-space"
                                rotation={space.isOpen ? 90 : undefined}
                                size="xs"
                                onClick={() => toggleOpened()}
                            />
                            <Folder
                                className="h-6 w-6 flex-none cursor-pointer fill-current"
                                style={{ color: space.color }}
                                onClick={() => toggleOpened()}
                            />

                            <Editable
                                isEditing={editName}
                                label={name}
                                onSave={name => onSave(name)}
                                onCancel={() => setEditName(false)}
                            >
                                <div className="flex w-full items-center justify-between space-x-2">
                                    <span
                                        className="grow cursor-pointer truncate font-bold"
                                        onClick={() => toggleOpened()}
                                    >
                                        {name}
                                    </span>
                                    {space.isEditableBy(user) && (
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            fixedWidth
                                            size="sm"
                                            className="shrink-0 grow-0 cursor-pointer fill-current text-pale-sky"
                                            onClick={() => setEditName(true)}
                                        />
                                    )}
                                </div>
                            </Editable>
                        </Element>
                    </Row>
                    <Row
                        highlight={highlightedSpace === space.uuid}
                        dropType="space"
                        dropUuid={space.uuid}
                        className={clsx('col-span-2', baseClassNames)}
                    />
                    <Row
                        highlight={highlightedSpace === space.uuid}
                        dropType="space"
                        dropUuid={space.uuid}
                        className={clsx('col-span-2 text-center', baseClassNames, {
                            'bg-christine/10': highlightedSpace === space.uuid,
                        })}
                    >
                        <span className="hidden xl:block">{t('web_spaceroom_space')}</span>
                        <span className="xl:hidden">{t('web_spaceroom_space_short')}</span>
                    </Row>
                    <Row
                        highlight={highlightedSpace === space.uuid}
                        dropType="space"
                        dropUuid={space.uuid}
                        className={clsx('col-span-2', baseClassNames, {
                            'bg-christine/10': highlightedSpace === space.uuid,
                        })}
                    />
                </Line>
                {space?.isOpen && (
                    <>
                        {loading && <Loader />}
                        {!loading && (
                            <>
                                {[...values<SpaceModel>(space.spaces)]
                                    .sort((a: SpaceModel, b: SpaceModel) => {
                                        return collator.compare(a.displayName, b.displayName)
                                    })
                                    .map(space => (
                                        <Space
                                            isRefreshing={isRefreshing}
                                            key={space.uuid}
                                            space={space}
                                            level={level + 1}
                                            cmp={cmp}
                                            selected={selectedFiles.find(f => f.uuid === space.uuid) !== undefined}
                                            canChangeSelection={!selected && canChangeSelection}
                                            onSelect={onSelect}
                                            onDeselect={onDeselect}
                                            selectedFiles={selectedFiles}
                                            highlightedFile={highlightedFile}
                                            highlightedDirectory={highlightedDirectory}
                                            highlightedSpace={highlightedSpace}
                                            openingIds={openingIds}
                                            onRenameSuggestionClicked={onRenameSuggestionClicked}
                                            canOfferSuggestion={canOfferSuggestion}
                                            onOpenChecklist={onOpenChecklist}
                                        />
                                    ))}
                                {[...values<SpaceDirectory>(space.rootDirectory.directories)]
                                    .sort((a: SpaceDirectory, b: SpaceDirectory) => {
                                        return collator.compare(a.displayName, b.displayName)
                                    })
                                    .map(child => (
                                        <Directory
                                            isRefreshing={isRefreshing}
                                            key={child.uuid}
                                            directory={child}
                                            space={space}
                                            level={level + 1}
                                            openingIds={openingIds}
                                            color={space.color}
                                            canChangeSelection={!selected && canChangeSelection}
                                            cmp={cmp}
                                            selected={selectedFiles.find(f => f.uuid === child.uuid) !== undefined}
                                            onSelect={onSelect}
                                            onDeselect={onDeselect}
                                            selectedFiles={selectedFiles}
                                            highlightedFile={highlightedFile}
                                            highlightedDirectory={highlightedDirectory}
                                            onRenameSuggestionClicked={onRenameSuggestionClicked}
                                            canOfferSuggestion={canOfferSuggestion}
                                            onOpenChecklist={onOpenChecklist}
                                        />
                                    ))}
                                {[...values<SpaceFile>(space.rootDirectory.files)].sort(cmp).map(child => (
                                    <File
                                        isRefreshing={isRefreshing}
                                        key={child.uuid}
                                        file={child}
                                        space={space}
                                        level={level + 1}
                                        selected={selectedFiles.find(f => f.uuid === child.uuid) !== undefined}
                                        canChangeSelection={!selected && canChangeSelection}
                                        onSelect={onSelect}
                                        onDeselect={onDeselect}
                                        highlightedFile={highlightedFile}
                                        onRenameSuggestionClicked={onRenameSuggestionClicked}
                                        canOfferSuggestion={canOfferSuggestion}
                                    />
                                ))}
                            </>
                        )}
                    </>
                )}
            </>
        )
    }
)
