import { post } from 'core/services/http-service'
import toast from 'core/utils/toast'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import Admin from '../../../admin/admin'
import Form, { type FormSchema } from '../../../franchises/users/form'

const Create = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const { brandId } = useParams()

    const onSubmit = async (data: FormSchema) => {
        const postData = {
            ...data,
            role: data.role.value,
            type: data.type.value,
        }
        setLoading(true)

        try {
            await post<typeof postData, never>(`/v1/bo/brands/${brandId}/users`, postData)
            navigate(`/admin/brands/${brandId}/users`)
        } catch (error) {
            setLoading(false)
            toast('error', error.message)
        }
    }

    return (
        <Admin title={t('web_menu_admin_user')} isLoading={isLoading}>
            <Form showMessage onSubmit={onSubmit} showAllRoles={false} brandId={brandId} />
        </Admin>
    )
}

export default Create
