import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Space } from 'stores/files/space'
import { getFromQuery } from '../../core/use-query'
import { useMst } from '../../stores/store'
import ContentPage from './content-page'
import SpacesViewer from './spaces-viewer'
import StackViewer from './stack-viewer'
import StorageStatistics from './storage-statistics'
import WidgetChooser from './widget-chooser'

const Dataroom = observer(() => {
    const { t } = useTranslation()
    const { files, telecollecte, user } = useMst()

    const showWidget = getFromQuery('show-widget')

    const [showWidgets, setShowWidgets] = useState<boolean>(false)

    useEffect(() => {
        const load = async () => {
            await files.getConfig()
            await files.getFiles()
            await telecollecte.syncConnectors()
        }
        load()
    }, [user, telecollecte, files])

    useEffect(() => {
        if (showWidget && showWidget === 'true') {
            setShowWidgets(true)
        }
    }, [showWidget])

    return (
        <ContentPage
            title={t('web_dataroom')}
            showConfigOpened={showWidgets}
            onShowConfigClicked={value => setShowWidgets(value)}
        >
            {showWidgets && <WidgetChooser onWidgetChoosen={() => setShowWidgets(false)} />}

            <StorageStatistics />

            {user.getStacks().map(kind => {
                const space = values<Space>(files.spaces).find(s => s.is(kind))
                if (!space) {
                    return null
                }

                return <StackViewer key={space.uuid} kind={kind} space={space} />
            })}

            <SpacesViewer />
        </ContentPage>
    )
})

export default Dataroom
