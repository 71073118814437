import { Instance, types } from 'mobx-state-tree'
import { _User } from '../users'

export const _CollaborationMessage = types.model('CollaborationMessage', {
    id: types.string,
    message: types.string,
    messageId: types.optional(types.string, '', [null, undefined]),
    collaborationId: types.string,
    replies: types.array(types.late(() => _CollaborationMessage)),
    user: _User,
    createdAt: types.string,
    read: types.boolean,
})

export interface CollaborationMessage extends Instance<typeof _CollaborationMessage> {}
