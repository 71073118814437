import { post } from 'core/services/http-service'
import { DateTime } from 'luxon'
import { flow, getRoot, Instance, types } from 'mobx-state-tree'
import { RootInstance } from '../store'

export const _News = types
    .model('news', {
        uuid: '',
        title: '',
        teaserText: '',
        bodyMessage: '',
        color: '',
        dismissible: false,
        startDate: types.optional(types.string, '', [null, undefined]),
        endDate: types.optional(types.string, '', [null, undefined]),
        upload: types.optional(types.string, '', [null, undefined]),
        partnerUuid: types.optional(types.string, '', [null, undefined]),
        createdAt: '',
        updatedAt: '',
    })
    .views(self => ({
        get date(): DateTime {
            if (self.startDate && self.startDate !== '') {
                return DateTime.fromISO(self.startDate)
            }

            return DateTime.fromISO(self.createdAt)
        },

        get relativeDate(): string {
            const date: string = this.date.toRelative()

            return `${date[0].toLocaleUpperCase()}${date.slice(1)}`
        },

        get smartDate(): string {
            const { days } = DateTime.local().diff(this.date, 'days')
            if (days > 7) {
                return this.date.toLocaleString(DateTime.DATETIME_SHORT)
            }

            return this.relativeDate
        },
    }))
    .actions(self => ({
        dismiss: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = { newsId: self.uuid }
                yield post<typeof data, void>('/v1/web/news/dismiss', data)
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))

export interface News extends Instance<typeof _News> {}
