import { FC, useEffect } from 'react'
import { ReactComponent as LinkedIn } from '../../../assets/sso/linkedin.svg'
import Config from '../../../core/config'
import { getFromQuery } from '../../../core/use-query'
import { useMst } from '../../../stores/store'
import SsoButton from './sso-button'

const LoginLinkedIn: FC = () => {
    const rootStore = useMst()
    const { user } = rootStore

    const sso = getFromQuery('sso')
    const token = getFromQuery('t')

    useEffect(() => {
        const loginFromSso = async () => {
            if (sso && sso === 'linkedin' && token) {
                rootStore.setToken(token)
                await user.linkedinConnect()
                await user.linkAccountIfNeeded()
            }
        }

        loginFromSso()
    })

    const connect = () => {
        window.location.href = `${Config.app.APIURL}/v1/login/linkedin`
    }

    return (
        <SsoButton onClick={() => connect()}>
            <LinkedIn className="h-4 w-4" />
        </SsoButton>
    )
}

export default LoginLinkedIn
