import { DateTime } from 'luxon'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BackupFile } from 'stores/files'

interface BackupProps {
    backup: BackupFile
}

const Backup: FC<BackupProps> = ({ backup }) => {
    const { t } = useTranslation()

    return (
        <div>
            {backup.file !== '' ? (
                <>
                    <a href={backup.file} download>
                        {t('web_download_backup')}
                    </a>
                    {backup.validTo && (
                        <p>
                            {t(`web_backup_download_to`, {
                                date: backup.validTo.toLocaleString(DateTime.DATETIME_SHORT),
                            })}
                        </p>
                    )}
                </>
            ) : (
                <span>{t(`web_backup_status_${backup.status}`)}</span>
            )}
        </div>
    )
}

export default Backup
