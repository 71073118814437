import { Input, selectStyles } from 'components/shared/select-styles'
import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { FirebaseBlacklist, NotificationFrequency } from 'stores/users'
import isBoolean from 'validator/lib/isBoolean'
import { ReactComponent as IconEmail } from '../../assets/notifications/icon-email.svg'
import { ReactComponent as IconPush } from '../../assets/notifications/icon-push.svg'
import { ReactComponent as IconSms } from '../../assets/notifications/icon-sms.svg'
import { useMst } from '../../stores/store'
import ToggleButton from '../shared/toggle-button'

interface Props {
    type: string
    icon: ReactElement
}

interface ToggleProps {
    icon: ReactElement
    isLoading: boolean
    label: string
    value: boolean
    onChange: (value: boolean) => void
}

const Toggle = ({ isLoading, value, onChange, icon, label }: ToggleProps) => {
    return (
        <div className="flex items-center space-x-2 text-inner-space">
            {icon}
            <span className="w-32">{label}</span>
            {isLoading ? (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 h-4 w-4 animate-spin"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                </svg>
            ) : (
                <ToggleButton value={value} onChange={onChange} />
            )}
        </div>
    )
}

interface FormSelectValue {
    value: string
    label: string
}
interface DropdownProps {
    icon: ReactElement
    isLoading: boolean
    label: string
    value: NotificationFrequency
    onChange: (value: FormSelectValue) => void
}

const FrequencyDropdown = ({ isLoading, value, onChange, icon, label }: DropdownProps) => {
    const { t } = useTranslation()

    const options = Object.keys(NotificationFrequency).map(frequency => ({
        value: frequency,
        label: t(`web_notifications_frequency_${frequency}`),
    }))

    return (
        <div className="flex items-center space-x-2 text-inner-space">
            {icon}
            <span className="w-32">{label}</span>
            {isLoading ? (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 h-4 w-4 animate-spin"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                </svg>
            ) : (
                <Select
                    styles={selectStyles}
                    className="w-1/2"
                    value={options.find(opt => opt.value === value)}
                    options={options}
                    onChange={newValue => onChange(newValue)}
                />
            )}
        </div>
    )
}

const canSendSms = false
const canSendPush = true
const canSendInApp = true
const canSendEmail = true

const NotificationType = observer(({ type, icon }: Props) => {
    const { t } = useTranslation()
    const { user } = useMst()

    const [toggle, setToggle] = useState<boolean>(false)
    const [nsActivated, setNsActivated] = useState<string[]>([])

    const [inApp, setInApp] = useState<NotificationFrequency>(NotificationFrequency.live)
    const [inAppLoading, setInAppLoading] = useState<boolean>(false)

    const [email, setEmail] = useState<NotificationFrequency>(NotificationFrequency.live)
    const [emailLoading, setEmailLoading] = useState<boolean>(true)

    const [push, setPush] = useState<NotificationFrequency>(NotificationFrequency.live)
    const [pushLoading, setPushLoading] = useState<boolean>(true)

    const [sms, setSms] = useState<NotificationFrequency>(NotificationFrequency.none)
    const [smsLoading, setSmsLoading] = useState<boolean>(true)

    useEffect(() => {
        const activated: string[] = []

        const firebaseBlacklist: FirebaseBlacklist[] = user.firebaseBlacklist

        if (canSendEmail) {
            const email = firebaseBlacklist.find(fb => fb.namespace === type && fb.type === 'email')
            setEmail(email ? (email.frequency as NotificationFrequency) : NotificationFrequency.none)
            if (email && (email.frequency as NotificationFrequency) !== NotificationFrequency.none) {
                activated.push('email')
            }
        }

        if (canSendInApp) {
            const inapp = firebaseBlacklist.find(fb => fb.namespace === type && fb.type === 'inapp')
            setInApp(inapp ? (inapp.frequency as NotificationFrequency) : NotificationFrequency.none)
            if (inapp && (inapp.frequency as NotificationFrequency) !== NotificationFrequency.none) {
                activated.push('inapp')
            }
        }

        if (canSendPush) {
            const push = firebaseBlacklist.find(fb => fb.namespace === type && fb.type === 'push')
            setPush(push ? (push.frequency as NotificationFrequency) : NotificationFrequency.none)
            if (push && (push.frequency as NotificationFrequency) !== NotificationFrequency.none) {
                activated.push('push')
            }
        }

        if (canSendSms) {
            const sms = firebaseBlacklist.find(fb => fb.namespace === type && fb.type === 'sms')
            setSms(sms ? (sms.frequency as NotificationFrequency) : NotificationFrequency.none)
            if (sms && (sms.frequency as NotificationFrequency) !== NotificationFrequency.none) {
                activated.push('sms')
            }
        }

        setNsActivated(activated)
        setPushLoading(false)
        setEmailLoading(false)
        setInAppLoading(false)
        setSmsLoading(false)
    }, [user])

    const toggleInApp = async (value: boolean) => {
        setInAppLoading(true)
        await user.updateNotification(type, 'inapp', value ? NotificationFrequency.live : NotificationFrequency.none)
        setInApp(value ? NotificationFrequency.live : NotificationFrequency.none)
        const activated = nsActivated
        if (value) {
            activated.push('inapp')
        } else {
            activated.splice(activated.indexOf('inapp'), 1)
        }

        setInAppLoading(false)
    }

    const toggleEmail = async (value: FormSelectValue) => {
        setEmailLoading(true)
        await user.updateNotification(type, 'email', value.value as NotificationFrequency)
        setEmail(value.value as NotificationFrequency)
        const activated = nsActivated
        if ((value.value as NotificationFrequency) !== NotificationFrequency.none) {
            if (activated.indexOf('email') < 0) {
                activated.push('email')
            }
        } else {
            activated.splice(activated.indexOf('email'), 1)
        }

        setEmailLoading(false)
    }

    const togglePush = async (value: boolean) => {
        setPushLoading(true)
        await user.updateNotification(type, 'push', value ? NotificationFrequency.live : NotificationFrequency.none)
        setPush(value ? NotificationFrequency.live : NotificationFrequency.none)
        const activated = nsActivated
        if (value) {
            activated.push('push')
        } else {
            activated.splice(activated.indexOf('push'), 1)
        }

        setPushLoading(false)
    }

    const toggleSms = async (value: boolean) => {
        setSmsLoading(true)
        await user.updateNotification(type, 'sms', value ? NotificationFrequency.live : NotificationFrequency.none)
        setSms(value ? NotificationFrequency.live : NotificationFrequency.none)
        const activated = nsActivated
        if (value) {
            activated.push('sms')
        } else {
            activated.splice(activated.indexOf('sms'), 1)
        }

        setSmsLoading(false)
    }

    return (
        <div className="flex flex-row items-start space-x-8 border-b border-mercury p-4">
            <div className="pt-2 text-regent-gray">{icon}</div>
            <div className="flex flex-col space-y-2">
                <div className="flex cursor-pointer flex-col" onClick={() => setToggle(!toggle)}>
                    <h4 className="font-bold">{t(`web_notifications_type_${type}`)}</h4>
                    {!toggle && <span>{nsActivated.map(ns => t(`web_notifications_ns_${ns}`)).join(', ')}</span>}
                    {toggle && <span>{t(`web_notifications_type_${type}_desc`)}</span>}
                </div>
                <div className={`${toggle ? 'flex flex-col space-y-4' : 'hidden'}`}>
                    {canSendEmail && (
                        <FrequencyDropdown
                            isLoading={emailLoading}
                            value={email}
                            onChange={toggleEmail}
                            label={t('web_notifications_ns_email')}
                            icon={<IconEmail className="h-5 w-5 fill-current" />}
                        />
                    )}
                    {canSendInApp && (
                        <Toggle
                            isLoading={inAppLoading}
                            value={inApp && inApp === NotificationFrequency.live}
                            onChange={toggleInApp}
                            label={t('web_notifications_ns_inapp')}
                            icon={<IconPush className="h-5 w-5 fill-current" />}
                        />
                    )}
                    {canSendPush && (
                        <Toggle
                            isLoading={pushLoading}
                            value={push && push === NotificationFrequency.live}
                            onChange={togglePush}
                            label={t('web_notifications_ns_push')}
                            icon={<IconPush className="h-5 w-5 fill-current" />}
                        />
                    )}
                    {canSendSms && (
                        <Toggle
                            isLoading={smsLoading}
                            value={sms && sms === NotificationFrequency.live}
                            onChange={toggleSms}
                            label={t('web_notifications_ns_sms')}
                            icon={<IconSms className="h-5 w-5 fill-current" />}
                        />
                    )}
                </div>
            </div>
        </div>
    )
})

export default NotificationType
