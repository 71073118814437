import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import SmallUploadIcon from '../../assets/file-transfer/small-upload-icon'
import Logo from '../../assets/file-transfer/logo'
import UploadCta from '../../assets/file-transfer/backgrounds/upload-cta.jpg'
import Config from '../../core/config'
import { trigger } from 'core/events'

export const Header = ({ transferModalDisplayed }: { transferModalDisplayed: boolean }) => {
    const { t } = useTranslation()

    return (
        <div
            className={clsx(
                'w-full z-40 lg:bg-none bg-cover',
                transferModalDisplayed
                    ? 'lg:absolute lg:px-5 lg:pt-5 2xl:top-10 2xl:pt-0 2xl:px-10 bg-none'
                    : 'lg:top-10 lg:px-10 lg:absolute'
            )}
            style={{
                backgroundImage: `url(${UploadCta})`,
            }}
        >
            <div className="h-full lg:h-auto flex flex-col gap-0 lg:gap-2">
                <div className="flex items-center justify-center lg:justify-between w-full bg-atomic-tangerine lg:bg-transparent p-2 lg:p-0">
                    <a href="/" className={clsx(transferModalDisplayed ? 'text-white' : 'text-white lg:text-black')}>
                        <Logo
                            className={clsx(
                                'fill-current',
                                transferModalDisplayed
                                    ? 'w-[120px] xl:w-[180px]'
                                    : 'w-[120px] lg:w-[350px] xl:w-[400px] 2xl:w-[500px]'
                            )}
                        />
                    </a>
                </div>
                <div
                    className={clsx(
                        transferModalDisplayed ? 'hidden' : 'lg:w-[500px] xl:w-[600px]',
                        'flex flex-col gap-8 items-center justify-center lg:justify-start lg:items-start',
                        'bg-black/60 lg:bg-transparent h-full lg:h-auto p-8 lg:p-0 '
                    )}
                >
                    <div
                        className={clsx(
                            'leading-6 flex flex-col gap-4 bg-white lg:bg-transparent ',
                            'rounded-lg lg:rounded-none items-center lg:items-start justify-center lg:justify-start px-10 py-6 lg:px-0 lg:py-0'
                        )}
                    >
                        <SmallUploadIcon className="w-[30px] lg:hidden" />
                        <h1
                            className="text-2xl lg:text-xl text-center lg:text-left"
                            // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                            dangerouslySetInnerHTML={{ __html: t('web_file_transfer_h1') }}
                        />
                        <h2
                            className="hidden lg:block lg:text-lg"
                            // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                            dangerouslySetInnerHTML={{ __html: t('web_file_transfer_h2') }}
                        />
                        <button type="button" className="btn lg:hidden" onClick={() => trigger('uploader:trigger')}>
                            {t('web_file_transfer_transfer')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
