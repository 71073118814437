import localeFr from 'air-datepicker/locale/fr'
import DatePicker from 'components/admin/date-picker'
import { FormProps } from 'components/admin/forms'
import Label from 'components/admin/label'
import ToggleButton from 'components/shared/toggle-button'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMst } from '../../../stores/store'
import MdEditor from '../../shared/md-editor'
import 'components/admin/zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const schema = z.object({
    title: z.string().min(1),
    teaserText: z.string().min(1),
    bodyMessage: z.string().min(1),
    color: z.string().min(1),
    pdf: z.unknown(),
    startDate: z.date(),
    endDate: z.date(),
    dismissible: z.boolean().nullable().default(false),
})
export type Schema = z.infer<typeof schema>

const Form = ({ onSubmit, values }: FormProps<Schema>) => {
    const { files } = useMst()
    const { t } = useTranslation()

    const currentLocale = localeFr

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })

    useEffect(() => {
        if (!values) {
            return
        }

        for (const key of Object.keys(values) as (keyof Schema)[]) {
            setValue(key, values[key])
        }
    }, [values])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Label
                name="title"
                error={errors['title'] && `${t('web_menu_partner_news_title')} ${errors['title']?.message}`}
                label={'web_menu_partner_news_title'}
                required
                className="my-5 flex w-full flex-wrap items-center">
                <input type="text" className="w-1/3 " {...register('title')} />
            </Label>
            <Label
                name="teaserText"
                required
                error={errors['teaserText'] && `${t('web_menu_partner_teaser_test')} ${errors['teaserText']?.message}`}
                label={'web_menu_partner_teaser_test'}
                className="my-5 flex w-full items-center">
                <textarea {...register('teaserText')} className="w-1/3" rows={8}></textarea>
            </Label>
            <Label
                name="color"
                required
                error={errors['color'] && `${t('web_menu_partner_news_color')} ${errors['color']?.message}`}
                label={'web_menu_partner_news_color'}
                className="my-5 flex w-full flex-wrap items-center">
                <Controller
                    control={control}
                    name="color"
                    render={({ field }) => (
                        <div className="grid w-2/3 grid-cols-6 gap-4">
                            {files.colors.map(c => (
                                <button
                                    key={c}
                                    type="button"
                                    className="relative h-6 w-6 rounded-full border-2 outline-none ring-0 transition-all hover:scale-150"
                                    style={{
                                        backgroundColor: c === field.value ? 'white' : c,
                                        borderColor: c,
                                    }}
                                    onClick={() => field.onChange(c)}></button>
                            ))}
                        </div>
                    )}
                />
            </Label>
            <Label
                name="startDate"
                error={
                    errors['startDate'] &&
                    `${t('web_menu_admin_information_message_startDate')} ${errors['startDate']?.message}`
                }
                label={'web_menu_admin_information_message_startDate'}
                className="my-5 flex w-full flex-wrap items-center leading-tight">
                <Controller
                    name="startDate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                        return <DatePicker {...field} locale={currentLocale} timepicker />
                    }}
                />
            </Label>
            <Label
                name="endDate"
                error={
                    errors['endDate'] &&
                    `${t('web_menu_admin_information_message_endDate')} ${errors['endDate']?.message}`
                }
                label={'web_menu_admin_information_message_endDate'}
                className="my-5 flex w-full flex-wrap items-center leading-tight">
                <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => {
                        return <DatePicker {...field} locale={currentLocale} timepicker />
                    }}
                />
            </Label>
            <Label
                name="dismissible"
                error={
                    errors['dismissible'] &&
                    `${t('web_menu_partner_dismissible_short')} ${errors['dismissible']?.message}`
                }
                label={'web_menu_partner_dismissible_short'}
                className="my-5 flex w-full flex-wrap items-center">
                <Controller
                    control={control}
                    name="dismissible"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>

            <Label
                name="pdf"
                error={errors['pdf'] && `${t('web_admin_partner_attach')} ${errors['pdf']?.message}`}
                label={'web_admin_partner_attach'}
                className="my-5 flex w-full flex-wrap items-center">
                <input type="file" accept="application/pdf" {...register('pdf')} />
            </Label>
            <Label
                name="bodyMessage"
                required
                error={errors['name'] && `${t('web_menu_partner_full_text')} ${errors['title']?.message}`}
                label={'web_menu_partner_full_text'}
                className="my-5 flex w-full flex-wrap items-center">
                <Controller
                    control={control}
                    name="bodyMessage"
                    render={({ field }) => (
                        <MdEditor
                            className=" w-2/3"
                            onChange={bodyMessage => field.onChange(bodyMessage)}
                            value={field.value}
                        />
                    )}
                />
            </Label>

            <input type="submit" className="btn float-right cursor-pointer" value={`${t('web_admin_user_save')}`} />
        </form>
    )
}

export default Form
