import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import validator from 'validator'
import hello from '../../assets/login/login-hello.svg'
import validated from '../../assets/login/validated.svg'
import { useMst } from '../../stores/store'
import TwoPart from './two-part'

const ForgottenPassword: FC = () => {
    const { t } = useTranslation()
    const rootStore = useMst()
    const { user, error } = rootStore

    const [email, setEmail] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [displayMessage, setDisplayMessage] = useState<boolean>(false)

    const resetPassword = async () => {
        setIsLoading(true)

        user.setEmail(email)
        await user.recovery()

        setIsLoading(false)
        if (error.hasError) {
            return
        }

        setDisplayMessage(true)
    }

    return (
        <TwoPart
            title={t('web_forgot_password_page_main_title')}
            image={hello}
            subTitle={t('web_forgot_password_page_desc')}>
            <h3 className="my-8 text-2xl font-bold md:text-3xl">{t('web_forgot_password_page_title')}</h3>

            {displayMessage && (
                <>
                    <p className="my-4 text-center">{t('web_forgot_password_page_success')}</p>
                    <div className="pulse-button no-pulse mb-8" style={{ backgroundImage: `url(${validated})` }}></div>
                    <p className="text-center">
                        <Link to="/" className="btn px-8">
                            {t('web_forgot_password_page_continue')}
                        </Link>
                    </p>
                </>
            )}

            {!displayMessage && (
                <>
                    <p className="my-4 text-center px-16">{t('web_forgot_password_page_help')}</p>

                    <div className="flex w-3/4 flex-col">
                        <label className="uppercase">{t('web_forgot_password_page_email_address')}</label>
                        <input
                            type="email"
                            placeholder={t('web_forgot_password_page_email_address')}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />

                        {error.hasError && <p className="my-4 text-center text-christine">{t(error.message)}</p>}

                        <p className="my-4 text-center">
                            <button
                                className="btn px-8"
                                disabled={!validator.isEmail(email) || isLoading}
                                onClick={() => resetPassword()}>
                                {t('web_forgot_password_page_continue')}
                            </button>
                        </p>
                        <p className="text-center">
                            <Link to="/login">{t('web_forgot_password_page_cancel')}</Link>
                        </p>
                    </div>
                </>
            )}
        </TwoPart>
    )
}

export default ForgottenPassword
