import { ReactComponent as Directory } from 'assets/icons/icon-directory.svg'
import { SpaceDirectory } from 'stores/files/directory'

interface DirectoryLineProps {
    directory: SpaceDirectory
    onOpenDirectory: (directory: SpaceDirectory) => void
}

const DirectoryLine = ({ directory, onOpenDirectory }: DirectoryLineProps) => {
    return (
        <li className={'flex cursor-pointer items-center justify-between'} onClick={() => onOpenDirectory(directory)}>
            <div className="my-2 flex items-center">
                <Directory className="h-6 w-6 fill-current text-christine" />
                <span className="ml-2">{directory.name}</span>
            </div>
        </li>
    )
}

export default DirectoryLine
