import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import DownloadPage from 'components/file-transfer/download-page'
import TransferPage from 'components/file-transfer/transfer-page'
import Form from 'components/login/oauth/form'
import Subscription from 'components/me/subscriptions'
import { observer } from 'mobx-react-lite'
import { type ReactNode, Suspense, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIdleTimer } from 'react-idle-timer'
import ReactModal from 'react-modal'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import AdminCreateBrand from './components/admin/brands/create'
import AdminEditBrand from './components/admin/brands/edit'
import AdminCreateBrandFranchise from './components/admin/brands/franchises/create'
import AdminEditBrandFranchise from './components/admin/brands/franchises/edit'
import AdminBrandFranchises from './components/admin/brands/franchises/listing'
import AdminBrands from './components/admin/brands/listing'
import AdminBrandCreateUser from './components/admin/brands/users/create'
import AdminBrandUsers from './components/admin/brands/users/listing'
import Cgu from './components/admin/cgu'
import AdminDashboard from './components/admin/dashboard'
import AdminCreateInformationMessage from './components/admin/informationMessages/create'
import AdminEditInformationMessage from './components/admin/informationMessages/edit'
import ListingMessages from './components/admin/informationMessages/listing'
import AdminMessages from './components/admin/messages'
import AdminCreateNotification from './components/admin/notification/notification'
import AdminCreatePartner from './components/admin/partners/create'
import AdminEditPartner from './components/admin/partners/edit'
import AdminPartners from './components/admin/partners/listing'
import AdminUsersListingPartner from './components/admin/partners/users/listing'
import AdminCreatePricing from './components/admin/pricings/create'
import AdminEditPricing from './components/admin/pricings/edit'
import AdminPricings from './components/admin/pricings/listing'
import AdminSettings from './components/admin/settings'
import AdminCreateUserType from './components/admin/userTypes/create'
import AdminUserType from './components/admin/userTypes/edit'
import Listing from './components/admin/userTypes/listing'
import AdminCreateUser from './components/admin/users/create'
import AdminEditUser from './components/admin/users/edit'
import AdminUsers from './components/admin/users/listing'
import AppDownChecker from './components/app-down-checker'
import BrandListingFranchises from './components/brands/franchises/listing'
import BrandCreateInformationMessage from './components/brands/informationMessages/create'
import BrandEditInformationMessage from './components/brands/informationMessages/edit'
import BrandListingMessages from './components/brands/informationMessages/listing'
import BrandCreateNews from './components/brands/news/create'
import BrandEditNews from './components/brands/news/edit'
import BrandListingNews from './components/brands/news/listing'
import BrandWelcomeMessage from './components/brands/welcome-message'
import CguPage from './components/cms/cgu-page'
import PolicyPage from './components/cms/policy-page'
import Collaboration from './components/collaboration/collaboration'
import Dashboard from './components/dataroom/dashboard'
import Dataroom from './components/dataroom/dataroom'
import NewCgu from './components/dataroom/newCgu/new-cgu'
import AllNews from './components/dataroom/news/all-news'
import NoMatch from './components/dataroom/no-match'
import NoShare from './components/dataroom/no-share'
import Spaceroom from './components/dataroom/spaceroom'
import Trash from './components/dataroom/trash'
import Tribe from './components/dataroom/tribe'
import Trust from './components/dataroom/trust'
import BrandFranchiseCreateUser from './components/franchises/users/create'
import BrandFranchiseEditUser from './components/franchises/users/edit'
import BrandFranchiseListingUsers from './components/franchises/users/index'
import GuestShare from './components/guest/share'
import Loader from './components/loader'
import ForgottenPassword from './components/login/forgotten-password'
import Join from './components/login/join'
import Login from './components/login/login'
import Logout from './components/login/logout'
import Register from './components/login/register'
import ResetPassword from './components/login/reset-password'
import VerifyEmail from './components/login/verify-email'
import Mails from './components/mails/mails'
import Account from './components/me/account'
import Friends from './components/me/friends'
import Me from './components/me/me'
import Options from './components/me/options'
import Pricing from './components/me/pricing'
import Security from './components/me/security'
import Sponsorship from './components/me/sponsorship'
import Sso from './components/me/sso'
import TwoFa from './components/me/two-fa'
import NotificationSettings from './components/notifications/notification-settings'
import Notifications from './components/notifications/notifications'
import PartnerCases from './components/partners/cases'
import PartnerEmail from './components/partners/email'
import PartnerCreateInformationMessage from './components/partners/informationMessages/create'
import PartnerEditInformationMessage from './components/partners/informationMessages/edit'
import PartnerListingMessages from './components/partners/informationMessages/listing'
import PartnerInvite from './components/partners/invite'
import PartnerCreateNews from './components/partners/news/create'
import PartnerEditNews from './components/partners/news/edit'
import PartnerListingNews from './components/partners/news/listing'
import PartnerCreateNotification from './components/partners/notification/notification'
import PartnerEditUsers from './components/partners/users/edit'
import PartnerListingUsers from './components/partners/users/listing'
import PartnerWelcomeMessage from './components/partners/welcome-message'
import Share from './components/share/share'
import ShareGroup from './components/share/share-group'
import Modal from './components/shared/modal'
import Shop from './components/shop/shop'
import Telecollecte from './components/telecollecte/telecollecte'
import Transmission from './components/transmission/transmission'
import Config from './core/config'
import {
    AdminRoute,
    BrandFranchiseRoute,
    BrandRoute,
    GuestRoute,
    PartnerRoute,
    PersonalDataAccessCheck,
    PrivateRoute,
    Redirect,
} from './core/router'
import useInterval from './core/use-interval'
import { Provider, rootStore } from './stores/store'
import { AppContext } from 'context'
import Callback from 'components/oauth/callback'
import PricingList from 'components/pricing/pricing-list'
import PricingCta from 'components/pricing/pricing-cta'

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        axeptioSettings: {
            clientId: string
            cookiesVersion: string
        }
    }
}

const timeoutDuration = Config.app.TIMEOUT_DURATION
const exitTimeout = 15

const Wrapper = () => {
    const { t } = useTranslation()

    const [showDisconnectModal, setShowDisconnectModal] = useState<boolean>(false)
    const [timeRemaining, setTimeRemaining] = useState<number>(exitTimeout)
    const [intervalDelay, setIntervalDelay] = useState<number | null>(null)
    const { informationMessageStore, user } = rootStore

    const timeout = useRef<number | null>(null)

    const handleOnIdle = () => {
        if (!rootStore.isLogged) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            reset()

            return
        }

        if (rootStore.files.isUploading) {
            console.log('Still uploading files')
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            reset()

            return
        }

        const brand = user.currentBrand
        if (brand && !brand.getConfig().disconnect_timeout) {
            reset()

            return
        }

        setShowDisconnectModal(true)
    }

    const { reset } = useIdleTimer({
        timeout: 1000 * 60 * timeoutDuration,
        onIdle: handleOnIdle,
        onActive: () => {},
        onAction: () => {},
        debounce: 500,
    })

    useInterval(() => {
        setTimeRemaining(timeRemaining - 1)
    }, intervalDelay)

    useEffect(() => {
        if (showDisconnectModal) {
            setIntervalDelay(1000)
            timeout.current = window.setTimeout(
                () => {
                    setIntervalDelay(null)
                    rootStore.logout()
                },
                (exitTimeout + 1) * 1000
            )

            return () => {
                setIntervalDelay(null)
                if (timeout.current) {
                    window.clearTimeout(timeout.current)
                }
            }
        }
        // (async () => {
        //     await informationMessageStore.load()
        // })()
    }, [showDisconnectModal, informationMessageStore.informationMessages])

    const stillHere = () => {
        setShowDisconnectModal(false)
        reset()

        if (timeout.current) {
            window.clearTimeout(timeout.current)
        }

        setIntervalDelay(null)
        setTimeRemaining(exitTimeout)
    }

    const [readOnlyEmail, setReadOnlyEmail] = useState<boolean>(false)
    const [email, setEmail] = useState<string>()
    const [redirect, setRedirect] = useState<string>()
    const [logo, setLogo] = useState<string>()
    const [icon, setIcon] = useState<string>()

    return (
        <Provider value={rootStore}>
            <AppContext.Provider
                value={{
                    logo,
                    setLogo,
                    icon,
                    setIcon,
                    redirect,
                    setRedirect,
                    loginRegister: { readOnlyEmail, setReadOnlyEmail, email, setEmail },
                }}
            >
                <AppDownChecker>
                    <BrowserRouter>
                        {process.env.REACT_APP_WE_TREASY_WEB_HOST &&
                        process.env.REACT_APP_WE_TREASY_WEB_HOST !== '' &&
                        window.location.href.includes(process.env.REACT_APP_WE_TREASY_WEB_HOST) ? (
                            <Routes>
                                <Route path="/" element={<TransferPage />} />
                                <Route path="/:transferCuid/:recipientCuid" element={<DownloadPage />} />
                                <Route path="/:transferCuid" element={<DownloadPage />} />
                            </Routes>
                        ) : (
                            <NewCgu>
                                <Routes>
                                    {!process.env.REACT_APP_WE_TREASY_WEB_HOST ||
                                    process.env.REACT_APP_WE_TREASY_WEB_HOST === '' ? (
                                        <>
                                            <Route path="/we-treasy" element={<TransferPage />} />
                                            <Route
                                                path="/we-treasy/:transferCuid/:recipientCuid"
                                                element={<DownloadPage />}
                                            />
                                            <Route path="/we-treasy/:transferCuid" element={<DownloadPage />} />
                                        </>
                                    ) : (
                                        <>
                                            <Route
                                                path="/we-treasy"
                                                element={
                                                    <Redirect to={`${process.env.REACT_APP_WE_TREASY_WEB_HOST}/`} />
                                                }
                                            />
                                            <Route
                                                path="/we-treasy/:transferCuid/:recipientCuid"
                                                element={
                                                    <Redirect
                                                        to={`${process.env.REACT_APP_WE_TREASY_WEB_HOST}/:transferCuid/:recipientCuid`}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/we-treasy/:transferCuid"
                                                element={
                                                    <Redirect
                                                        to={`${process.env.REACT_APP_WE_TREASY_WEB_HOST}/:transferCuid`}
                                                    />
                                                }
                                            />
                                        </>
                                    )}
                                    <Route
                                        path="/oauth/:provider/callback"
                                        element={
                                            <PrivateRoute>
                                                <Callback />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route path="/oauth/authorize" element={<Form />} />
                                    <Route
                                        path="/"
                                        element={
                                            <GuestRoute>
                                                <Navigate to="/login" />
                                            </GuestRoute>
                                        }
                                    />
                                    <Route
                                        path="/register"
                                        element={
                                            <GuestRoute>
                                                <Register />
                                            </GuestRoute>
                                        }
                                    />
                                    <Route
                                        path="/prices"
                                        element={
                                            <PrivateRoute>
                                                <PricingCta />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/login"
                                        element={
                                            <GuestRoute>
                                                <Login />
                                            </GuestRoute>
                                        }
                                    />
                                    <Route
                                        path="/forgotten-password"
                                        element={
                                            <GuestRoute>
                                                <ForgottenPassword />
                                            </GuestRoute>
                                        }
                                    />

                                    <Route path="/logout" element={<Logout />} />
                                    <Route path="/join" element={<Join />} />
                                    <Route path="/change-password" element={<ResetPassword />} />
                                    <Route path="/verify" element={<VerifyEmail />} />
                                    <Route path="/recovery" element={<ResetPassword />} />

                                    <Route path="/s/:shareId" element={<GuestShare />} />

                                    <Route path="/pages/privacy" element={<PolicyPage />} />
                                    <Route path="/pages/cgu" element={<CguPage />} />
                                    <Route path="/shared/:file" element={<NoShare />} />
                                    <Route path="/trust/:userId" element={<Trust />} />
                                    <Route path="/tribe/:invitationId" element={<Tribe />} />

                                    <Route
                                        path="/dashboard"
                                        element={
                                            <PrivateRoute>
                                                <Dashboard />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/news"
                                        element={
                                            <PrivateRoute>
                                                <AllNews />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/dataroom/spaces/*"
                                        element={
                                            <PrivateRoute>
                                                <Spaceroom />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/dataroom/trash"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Trash />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route path="/dataroom/historic" element={<PrivateRoute />} />
                                    <Route path="/dataroom/quarantine" element={<PrivateRoute />} />
                                    <Route
                                        path="/dataroom/telecollecte"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Telecollecte />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/share"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Share />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/share/with/:group"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <ShareGroup showUserSharedWithMe={true} />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/share/me/:group"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <ShareGroup showUserSharedByMe={true} />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/dataroom"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Dataroom />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/collaboration"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Collaboration />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />

                                    <Route
                                        path="/me"
                                        element={
                                            <PrivateRoute>
                                                <Me />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/me/prices"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Pricing />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/me/prices/:id"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Pricing />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/me/logins"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Sso />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/me/security"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Security />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/me/security/twofa"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <TwoFa />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/me/options"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Options />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/me/friends"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Friends />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/me/account"
                                        element={
                                            <PrivateRoute>
                                                <Account />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/me/subscriptions"
                                        element={
                                            <PrivateRoute>
                                                <Subscription />
                                            </PrivateRoute>
                                        }
                                    />

                                    <Route
                                        path="/me/sponsorship"
                                        element={
                                            <PrivateRoute>
                                                <Sponsorship />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/me/notifications"
                                        element={
                                            <PrivateRoute>
                                                <Notifications />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/me/notifications/settings"
                                        element={
                                            <PrivateRoute>
                                                <NotificationSettings />
                                            </PrivateRoute>
                                        }
                                    />

                                    <Route
                                        path="/shop"
                                        element={
                                            <PrivateRoute>
                                                <PersonalDataAccessCheck>
                                                    <Shop />
                                                </PersonalDataAccessCheck>
                                            </PrivateRoute>
                                        }
                                    />
                                    {/* <Route path="/new-cgu" element={<PrivateRoute><NewCgu /></PrivateRoute>} /> */}

                                    <Route
                                        path="/transmission"
                                        element={
                                            <PrivateRoute>
                                                <Transmission />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/mails"
                                        element={
                                            <PrivateRoute>
                                                <Mails />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route path="/historic" element={<PrivateRoute />} />

                                    <Route
                                        path="/admin"
                                        element={
                                            <AdminRoute>
                                                <AdminDashboard />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/settings"
                                        element={
                                            <AdminRoute>
                                                <AdminSettings />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/messages"
                                        element={
                                            <AdminRoute>
                                                <AdminMessages />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/users"
                                        element={
                                            <AdminRoute>
                                                <AdminUsers />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/users/create"
                                        element={
                                            <AdminRoute>
                                                <AdminCreateUser />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/users/:id"
                                        element={
                                            <AdminRoute>
                                                <AdminEditUser />
                                            </AdminRoute>
                                        }
                                    />

                                    <Route
                                        path="/admin/brands"
                                        element={
                                            <AdminRoute>
                                                <AdminBrands />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/brands/create"
                                        element={
                                            <AdminRoute>
                                                <AdminCreateBrand />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/brands/:brandId"
                                        element={
                                            <AdminRoute>
                                                <AdminEditBrand />
                                            </AdminRoute>
                                        }
                                    />

                                    <Route
                                        path="/admin/brands/:brandId/users"
                                        element={
                                            <AdminRoute>
                                                <AdminBrandUsers />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/brands/:brandId/users/create"
                                        element={
                                            <AdminRoute>
                                                <AdminBrandCreateUser />
                                            </AdminRoute>
                                        }
                                    />
                                    {/*<Route
                                path="/admin/brands/:brandId/users/:userId"
                                element={
                                    <AdminRoute>
                                        <AdminEditBrandUser />
                                    </AdminRoute>
                                }
                            /> */}

                                    <Route
                                        path="/admin/brands/:brandId/franchises"
                                        element={
                                            <AdminRoute>
                                                <AdminBrandFranchises />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/brands/:brandId/franchises/create"
                                        element={
                                            <AdminRoute>
                                                <AdminCreateBrandFranchise />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/brands/:brandId/franchises/:franchiseId"
                                        element={
                                            <AdminRoute>
                                                <AdminEditBrandFranchise />
                                            </AdminRoute>
                                        }
                                    />

                                    <Route
                                        path="/admin/user-types"
                                        element={
                                            <AdminRoute>
                                                <Listing />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/user-types/create"
                                        element={
                                            <AdminRoute>
                                                <AdminCreateUserType />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/user-types/:id"
                                        element={
                                            <AdminRoute>
                                                <AdminUserType />
                                            </AdminRoute>
                                        }
                                    />

                                    <Route
                                        path="/admin/notification"
                                        element={
                                            <AdminRoute>
                                                <AdminCreateNotification />
                                            </AdminRoute>
                                        }
                                    />

                                    <Route
                                        path="/admin/information-messages"
                                        element={
                                            <AdminRoute>
                                                <ListingMessages />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/information-messages/create"
                                        element={
                                            <AdminRoute>
                                                <AdminCreateInformationMessage />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/information-messages/:id"
                                        element={
                                            <AdminRoute>
                                                <AdminEditInformationMessage />
                                            </AdminRoute>
                                        }
                                    />

                                    <Route
                                        path="/admin/partners"
                                        element={
                                            <AdminRoute>
                                                <AdminPartners />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/partners/create"
                                        element={
                                            <AdminRoute>
                                                <AdminCreatePartner />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/partners/:id"
                                        element={
                                            <AdminRoute>
                                                <AdminEditPartner />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/partners/:id/users"
                                        element={
                                            <AdminRoute>
                                                <AdminUsersListingPartner />
                                            </AdminRoute>
                                        }
                                    />

                                    <Route
                                        path="/admin/pricings"
                                        element={
                                            <AdminRoute>
                                                <AdminPricings />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/pricings/create"
                                        element={
                                            <AdminRoute>
                                                <AdminCreatePricing />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/pricings/:id"
                                        element={
                                            <AdminRoute>
                                                <AdminEditPricing />
                                            </AdminRoute>
                                        }
                                    />

                                    <Route
                                        path="/partner/:id/email"
                                        element={
                                            <PartnerRoute>
                                                <PartnerEmail />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/partner/:id/welcome-message"
                                        element={
                                            <PartnerRoute>
                                                <PartnerWelcomeMessage />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/partner/:id/cases"
                                        element={
                                            <PartnerRoute>
                                                <PartnerCases />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/partner/:id/invite"
                                        element={
                                            <PartnerRoute>
                                                <PartnerInvite />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/partner/:id/notification"
                                        element={
                                            <PartnerRoute>
                                                <PartnerCreateNotification />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/partner/:id/messages"
                                        element={
                                            <PartnerRoute>
                                                <PartnerListingMessages />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/partner/:id/messages/create"
                                        element={
                                            <PartnerRoute>
                                                <PartnerCreateInformationMessage />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/partner/:id/messages/:mid"
                                        element={
                                            <PartnerRoute>
                                                <PartnerEditInformationMessage />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/partner/:id/users"
                                        element={
                                            <PartnerRoute>
                                                <PartnerListingUsers />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/partner/:id/users/:uid"
                                        element={
                                            <PartnerRoute>
                                                <PartnerEditUsers />
                                            </PartnerRoute>
                                        }
                                    />

                                    <Route
                                        path="/partner/:id/news"
                                        element={
                                            <PartnerRoute>
                                                <PartnerListingNews />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/partner/:id/news/create"
                                        element={
                                            <PartnerRoute>
                                                <PartnerCreateNews />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/partner/:id/news/:mid"
                                        element={
                                            <PartnerRoute>
                                                <PartnerEditNews />
                                            </PartnerRoute>
                                        }
                                    />
                                    <Route
                                        path="/admin/cgu"
                                        element={
                                            <AdminRoute>
                                                <Cgu />
                                            </AdminRoute>
                                        }
                                    />
                                    <Route
                                        path="/brands/:id/news"
                                        element={
                                            <BrandRoute>
                                                <BrandListingNews />
                                            </BrandRoute>
                                        }
                                    />
                                    <Route
                                        path="/brands/:id/news/create"
                                        element={
                                            <BrandRoute>
                                                <BrandCreateNews />
                                            </BrandRoute>
                                        }
                                    />
                                    <Route
                                        path="/brands/:id/news/:nid"
                                        element={
                                            <BrandRoute>
                                                <BrandEditNews />
                                            </BrandRoute>
                                        }
                                    />
                                    <Route
                                        path="/brands/:id/welcome-message"
                                        element={
                                            <BrandRoute>
                                                <BrandWelcomeMessage />
                                            </BrandRoute>
                                        }
                                    />
                                    <Route
                                        path="/brands/:id/franchises"
                                        element={
                                            <BrandRoute>
                                                <BrandListingFranchises />
                                            </BrandRoute>
                                        }
                                    />

                                    <Route
                                        path="/brands/:id/messages"
                                        element={
                                            <BrandRoute>
                                                <BrandListingMessages />
                                            </BrandRoute>
                                        }
                                    />
                                    <Route
                                        path="/brands/:id/messages/create"
                                        element={
                                            <BrandRoute>
                                                <BrandCreateInformationMessage />
                                            </BrandRoute>
                                        }
                                    />
                                    <Route
                                        path="/brands/:id/messages/:mid"
                                        element={
                                            <BrandRoute>
                                                <BrandEditInformationMessage />
                                            </BrandRoute>
                                        }
                                    />
                                    <Route
                                        path="/franchises/:id/users"
                                        element={
                                            <BrandFranchiseRoute>
                                                <BrandFranchiseListingUsers />
                                            </BrandFranchiseRoute>
                                        }
                                    />
                                    <Route
                                        path="/franchises/:id/users/create"
                                        element={
                                            <BrandFranchiseRoute>
                                                <BrandFranchiseCreateUser />
                                            </BrandFranchiseRoute>
                                        }
                                    />
                                    <Route
                                        path="/franchises/:id/users/:userId"
                                        element={
                                            <BrandFranchiseRoute>
                                                <BrandFranchiseEditUser />
                                            </BrandFranchiseRoute>
                                        }
                                    />

                                    <Route path="*" element={<NoMatch />} />
                                </Routes>

                                <Modal
                                    size="1/3"
                                    isOpen={showDisconnectModal}
                                    onRequestClose={() => stillHere()}
                                    onConfirm={() => stillHere()}
                                    title={t('web_idle_title')}
                                    okLabel={t('web_idle_confirm_here')}
                                    bottomAlignment="center"
                                >
                                    <span>
                                        {t('web_idle_content', {
                                            time: t('web_time_minutes', { count: timeoutDuration }),
                                            exit: t('web_time_seconds', { count: timeRemaining }),
                                        })}
                                    </span>
                                </Modal>

                                <ToastContainer />
                            </NewCgu>
                        )}
                    </BrowserRouter>
                </AppDownChecker>
            </AppContext.Provider>
        </Provider>
    )
}

export const i18nCookieName = 'i18next'
export const i18nCookieExpires = 180 * 24 * 60 * 60

const I18n = observer(({ children }: { children: ReactNode }) => {
    const { i18n } = useTranslation()
    const { user } = rootStore

    useEffect(() => {
        const existingLangCookie = document.cookie
            .split('; ')
            .find(cookie => cookie.startsWith(i18nCookieName))
            ?.split('=')[1]

        const lang = user?.locale.length > 0 ? user.locale : (existingLangCookie ?? 'fr')

        i18n.changeLanguage(lang, () => {
            document.cookie = `${i18nCookieName}=${lang};max-age=${i18nCookieExpires}; path=/;`

            const cookiesVersion = {
                fr: 'apptreasy-base',
                en: 'apptreasy-en',
            }

            window.axeptioSettings = {
                clientId: '61670a33b0e5a7586fcc0d57',
                cookiesVersion: cookiesVersion[lang] ?? cookiesVersion.fr,
            }
        })
    }, [user, user?.locale])

    return <>{children}</>
})

interface CacheBusterProps {
    children: ReactNode
}
const CacheBuster = ({ children }: CacheBusterProps) => {
    const { t } = useTranslation()
    const [newVersionAvailable, setNewVersionAvailable] = useState<boolean>(false)

    useEffect(() => {
        fetch('/meta.json', {
            cache: 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(meta => {
                const latestVersion = meta.buildDate
                const currentVersion = Config.app.version.BUILD_DATE
                const shouldForceRefresh = latestVersion > currentVersion

                if (shouldForceRefresh) {
                    console.log('New version availble, refresh')
                    setNewVersionAvailable(true)
                }
            })
    })

    return (
        <>
            {children}

            <Modal
                size="1/3"
                isOpen={newVersionAvailable}
                onRequestClose={() => window.location.reload()}
                onConfirm={() => window.location.reload()}
                title={t('web_idle_new_version_available_title')}
                okLabel={t('web_idle_new_version_available_ok')}
                bottomAlignment="center"
            >
                <p>{t('web_idle_new_version_available')}</p>
            </Modal>
        </>
    )
}

const queryClient = new QueryClient()

const App = () => {
    ReactModal.setAppElement('#root')

    return (
        <Suspense fallback={<Loader />}>
            <QueryClientProvider client={queryClient}>
                <CacheBuster>
                    <I18n>
                        <Wrapper />
                        <ReactQueryDevtools initialIsOpen={false} />
                    </I18n>
                </CacheBuster>
            </QueryClientProvider>
        </Suspense>
    )
}

export default App
