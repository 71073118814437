const on = (eventType: string, listener: (event: CustomEvent) => void): void => {
    document.addEventListener(eventType, listener)
}

const off = (eventType: string, listener: (event: CustomEvent) => void): void => {
    document.removeEventListener(eventType, listener)
}

const once = (eventType: string, listener: (event: CustomEvent) => void): void => {
    const handleEventOnce = (event: CustomEvent) => {
        listener(event)
        off(eventType, handleEventOnce)
    }

    on(eventType, handleEventOnce)
}

const trigger = (eventType: string, data: Record<string, unknown> = {}): void => {
    const event = new CustomEvent(eventType, { detail: data })
    document.dispatchEvent(event)
}

export { on, once, off, trigger }
