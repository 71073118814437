import { ReactComponent as IconClipboard } from 'assets/icons/icon-clipboard.svg'
import toast from 'core/utils/toast'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useMst } from 'stores/store'
import Panel from '../shared/panel'
import { Input, selectStyles } from '../shared/select-styles'
import ProfilePage from './profile-page'
import { get, del, put } from 'core/services/http-service'
import type { Account, MailCollectType, Provider } from 'components/oauth/types'
import { createId } from '@paralleldrive/cuid2'
import { DateTime } from 'luxon'
import { useLocation } from 'react-router'
import { setCookie } from 'components/oauth/cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import SmallLoader from 'components/shared/small-loader'
import { ReactComponent as Google } from '../../assets/sso/google.svg'
import { ReactComponent as Outlook } from '../../assets/outlook.svg'

const Options = observer(() => {
    const { t } = useTranslation()
    const { user } = useMst()
    const location = useLocation()

    const ocrStatuses = [
        { value: 'never', label: t('web_options_ocr_activate_never') },
        { value: 'always', label: t('web_options_ocr_activate_always') },
        { value: 'manually', label: t('web_options_ocr_activate_manually') },
    ] as const

    const accountCollectTypes = [
        { value: 'ALL', label: t('web_me_options_linked_mailbox_collect_type_ALL') },
        { value: 'MAIL_CONTENT', label: t('web_me_options_linked_mailbox_collect_type_MAIL_CONTENT') },
        { value: 'MAIL_ATTACHMENTS', label: t('web_me_options_linked_mailbox_collect_type_MAIL_ATTACHMENTS') },
    ] as const

    const [ocrStatus, setOcrStatus] = useState<(typeof ocrStatuses)[number]>(
        ocrStatuses.find(s => s.value === user.ocrStatus)
    )
    const [validForwardEmails, setValidForwardEmails] = useState<string>(user.validForwardEmails)
    const [saving, setSaving] = useState<boolean>(false)

    const changeOcrStatus = useCallback(
        async (status: (typeof ocrStatuses)[number]) => {
            user.setOcrStatus(status)
            await user.update()
            setOcrStatus(status)
        },
        [ocrStatus]
    )

    const [loading, setLoading] = useState<boolean>(true)
    const [providers, setProviders] = useState<Provider[]>([])
    const [accounts, setAccounts] = useState<Account[]>([])
    const [collectByAccount, setCollectByAccount] = useState<{ accountId: string; collectType: MailCollectType }[]>([])

    useEffect(() => {
        for (const account of accounts) {
            setCollectByAccount(prev => [...prev, { accountId: account.uuid, collectType: account.mailCollectType }])
        }
    }, [accounts])

    async function loadProviders() {
        setLoading(true)
        const {
            data: { providers },
        } = await get<void, { data: { providers: Provider[] } }>('/v1/web/oauth/providers')
        setProviders(providers)
        setLoading(false)
    }

    async function loadAccounts() {
        setLoading(true)
        const {
            data: { accounts },
        } = await get<void, { data: { accounts: Account[] } }>('/v1/web/oauth/accounts')
        setAccounts(accounts)
        setLoading(false)
    }

    useEffect(() => {
        loadProviders()
        loadAccounts()
    }, [])

    async function removeAccount(accountId: string) {
        const remove = confirm(t('web_me_options_unlink_mailbox_confirm'))
        if (remove) {
            const {
                data: { success, email },
            } = await del<{ data: { success: boolean; email: string } }>(`/v1/web/oauth/accounts/${accountId}`)

            if (success) {
                toast('success', t('web_me_options_mail_unlinked', { email }))
            } else {
                toast('error', t('web_me_options_linked_mailbox_error'))
            }

            await loadAccounts()
        }
    }

    async function accountCollectType(accountId: string, collectType: MailCollectType) {
        const putData = {
            collectType,
        }
        const {
            data: { success },
        } = await put<typeof putData, { data: { success: boolean } }>(
            `/v1/web/oauth/accounts/${accountId}/collect-type`,
            putData
        )

        if (success) {
            toast('success', t('web_me_options_linked_mailbox_collect_type_updated'))
            setCollectByAccount(prev =>
                prev.map(c => {
                    if (c.accountId === accountId) {
                        return { ...c, collectType }
                    }

                    return c
                })
            )
        } else {
            toast('error', t('web_me_options_linked_mailbox_error'))
        }
    }

    function oauthLogin(provider: Provider) {
        const state = createId()
        const expires = DateTime.local().plus({ minutes: 5 })

        setCookie(`${provider.reference}_oauth_state`, state, expires)
        setCookie(`${provider.reference}_oauth_redirect`, location.pathname, expires)

        const url = new URL(provider.loginUrl)
        url.searchParams.append('state', state)

        window.location.href = url.toString()
    }

    const saveUser = async () => {
        setSaving(true)
        user.setValidForwardEmails(validForwardEmails)
        await user.update()
        setSaving(false)

        toast('success', 'web_me_profile_saved')
    }

    return (
        <ProfilePage url="/me/options">
            <Panel className="mt-4" innerClassName="flex justify-center bg-white">
                <div className="flex w-full flex-col md:w-2/3">
                    <div className="my-4 hidden">
                        <h2 className="font-bold mb-2 text-lg">{t('web_me_options_linked_mailbox')}</h2>
                        <p className="mb-4">{t('web_me_options_linked_mailbox_help')}</p>
                        {loading ? (
                            <SmallLoader />
                        ) : (
                            accounts.length > 0 && (
                                <ul className="my-2 mb-4 flex flex-col gap-4">
                                    {accounts.map(account => (
                                        <li
                                            key={account.uuid}
                                            className="flex flex-col md:flex-row gap-1 md:gap-8 md:items-center w-full"
                                        >
                                            <span className="shrink-0 flex items-center gap-2">
                                                {account.provider.reference === 'google' && (
                                                    <Google className="h-4 w-4" />
                                                )}
                                                {account.provider.reference === 'microsoft' && (
                                                    <Outlook className="h-6 w-6" />
                                                )}
                                                <span>{account.email}</span>
                                            </span>
                                            <span className="shrink-0">
                                                <span>{t('web_me_options_linked_mailbox_collect_type_label')}</span>
                                                <Select
                                                    styles={selectStyles}
                                                    components={{ Input }}
                                                    placeholder={t('web_placeholder_select')}
                                                    value={accountCollectTypes.find(
                                                        opt =>
                                                            opt.value ===
                                                            collectByAccount.find(c => c.accountId === account.uuid)
                                                                ?.collectType
                                                    )}
                                                    options={accountCollectTypes}
                                                    onChange={async opt =>
                                                        await accountCollectType(
                                                            account.uuid,
                                                            opt.value as MailCollectType
                                                        )
                                                    }
                                                />
                                            </span>
                                            <button
                                                title={t('web_me_options_unlink_mailbox')}
                                                type="button"
                                                className="flex"
                                                onClick={async () => await removeAccount(account.uuid)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} className="text-bittersweet" />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            )
                        )}
                        <div className="flex gap-4">
                            {providers.map(provider => (
                                <button
                                    className="flex items-center gap-2 btn"
                                    type="button"
                                    key={provider.reference}
                                    onClick={() => oauthLogin(provider)}
                                >
                                    {provider.reference === 'google' && <Google className="h-4 w-4" />}
                                    {provider.reference === 'microsoft' && <Outlook className="h-6 w-6" />}
                                    {t('web_me_options_link_mailbox', { provider: provider.name })}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="my-4">
                        <h2 className="font-bold mb-2 text-lg">{t('web_me_options_ocr')}</h2>
                        <div className="prose flex w-full max-w-full flex-col items-center justify-center">
                            <div className="text-left">
                                <Trans i18nKey="web_ocr_new_desc">{t('web_ocr_new_desc')}</Trans>
                            </div>

                            <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                                <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                    {t('web_options_ocr_activate')}
                                </span>
                                <Select
                                    styles={selectStyles}
                                    components={{ Input }}
                                    className="w-full md:w-1/2"
                                    value={ocrStatus}
                                    placeholder={t('web_placeholder_select')}
                                    options={ocrStatuses}
                                    onChange={status => changeOcrStatus(status)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="my-4">
                        <h2 className="font-bold mb-2 text-lg">{t('web_me_options_forward_email')}</h2>
                        <div className="prose flex w-full max-w-full flex-col items-center justify-center gap-4">
                            <div className="text-left">{t('web_me_options_forward_email_desc')}</div>

                            <span className="w-full pr-2">{t('web_me_options_forward_email_address')}</span>
                            <div className="flex w-full flex-col gap-2">
                                <div className="flex flex-row gap-2 not-prose px-12">
                                    <code className="grow rounded bg-geyser px-4 py-2 text-sm shadow-inner ">
                                        {user.forwardEmail}
                                    </code>
                                    <IconClipboard
                                        className="w-4 cursor-pointer fill-current text-regent-gray"
                                        onClick={async () => {
                                            await navigator.clipboard.writeText(user.forwardEmail)
                                            toast('success', 'web_me_options_forward_email_address_copied')
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="text-left text-sm text-regent-gray">
                                <Trans i18nKey="web_me_options_forward_email_help">
                                    {t('web_me_options_forward_email_help')}
                                </Trans>
                            </div>
                            <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                                <span className="w-full pr-2 text-regent-gray md:w-1/2 font-bold">
                                    {t('web_me_options_valid_forward_email')}
                                </span>
                                <textarea
                                    className="w-full md:w-1/2"
                                    value={validForwardEmails}
                                    disabled={saving || user.readOnly}
                                    onChange={e => setValidForwardEmails(e.currentTarget.value)}
                                />
                            </div>
                            <div className="text-left text-sm text-regent-gray">
                                <Trans i18nKey="web_me_options_valid_forward_email_help">
                                    {t('web_me_options_valid_forward_email_help')}
                                </Trans>
                            </div>
                        </div>
                        <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full md:w-1/2" />
                            <button type="button" onClick={() => saveUser()} className="btn w-full md:w-1/2">
                                {t('web_me_profile_save')}
                            </button>
                        </div>
                    </div>
                </div>
            </Panel>
        </ProfilePage>
    )
})

export default Options
