import { availableLocales } from 'core/i18n'
import { getFromQuery } from 'core/use-query'
import french from 'countries/fr.json'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { ReactComponent as BuySuccess } from '../../assets/buy-success.svg'
import toast from '../../core/utils/toast'
import { useMst } from '../../stores/store'
import { Option } from '../admin/forms'
import Modal from '../shared/modal'
import Panel from '../shared/panel'
import { Input, selectStyles } from '../shared/select-styles'
import ProfilePage from './profile-page'

const countries = Object.keys(french).map(key => ({ value: key, label: french[key] }))

const Me = observer(() => {
    const { user } = useMst()
    const { i18n, t } = useTranslation()

    const stripe = getFromQuery('payment')
    const [showStripeSuccess, setshowStripeSuccess] = useState<boolean>(false)

    const [name, setName] = useState<string>(user.fullname)
    const [street, setStreet] = useState<string>(user.street)
    const [email, setEmail] = useState<string>(user.email)
    const [secondaryEmail, setSecondaryEmail] = useState<string>(user.secondaryEmail)
    const [phone, setPhone] = useState<string>(user.phone)
    const [zip, setZip] = useState<string>(user.zip)
    const [number, setNumber] = useState<string>(user.number)
    const [city, setCity] = useState<string>(user.city)
    const [country, setCountry] = useState<Option>(countries.find(c => c.value === user.country))
    const locales = availableLocales.map(language => ({ value: language, label: t(`web_locale_${language}`) }))
    const [locale, setLocale] = useState<Option>(locales.find(l => l.value === user.locale))

    const [saving, setSaving] = useState<boolean>(false)

    useEffect(() => {
        if (stripe) {
            // setshowStripeError(stripe === 'cancel')
            setshowStripeSuccess(stripe === 'success')
        }
    }, [stripe])

    const saveUser = async () => {
        if (name.trim().length === 0) {
            toast('error', t('web_me_name_required'))

            return
        }

        setSaving(true)
        user.setFullname(name)
        user.setEmail(email)
        user.setSecondaryEmail(secondaryEmail)
        user.setPhone(phone)
        user.setStreet(street)
        user.setZip(zip)
        user.setNumber(number)
        user.setCity(city)
        user.setCountry(country?.value)
        user.setLocale(locale.value)
        await user.update()
        setSaving(false)

        toast('success', 'web_me_profile_saved')
    }

    return (
        <ProfilePage url="/me">
            <Panel className="mt-4" innerClassName="flex justify-center bg-white">
                <div className="flex w-full flex-col md:w-2/3">
                    <h2 className="text-lg font-bold">{t('web_me_profile')}</h2>

                    <div className="my-4">
                        <h3 className="mb-2 text-lg">{t('web_me_profile_name')}</h3>
                        <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                {t('web_me_profile_name_desc')}
                            </span>
                            <input
                                className="w-full md:w-1/2"
                                type="text"
                                value={name}
                                disabled={saving || user.readOnly}
                                onChange={e => setName(e.currentTarget.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        saveUser()
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="my-4">
                        <h3 className="mb-2 text-lg">{t('web_me_profile_contact')}</h3>
                        <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                {t('web_me_profile_contact_desc')}
                            </span>
                            <input
                                className="w-full md:w-1/2"
                                type="email"
                                value={email}
                                disabled={saving || user.readOnly}
                                onChange={e => setEmail(e.currentTarget.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        saveUser()
                                    }
                                }}
                            />
                        </div>
                        <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                {t('web_me_profile_secondary_email_desc')}
                            </span>
                            <input
                                className="w-full md:w-1/2"
                                type="email"
                                value={secondaryEmail}
                                disabled={saving}
                                placeholder={t('web_me_profile_secondary_email_placeholder')}
                                onChange={e => setSecondaryEmail(e.currentTarget.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        saveUser()
                                    }
                                }}
                            />
                        </div>
                        <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                {t('web_me_profile_phone_desc')}
                            </span>
                            <input
                                className="w-full md:w-1/2"
                                type="text"
                                value={phone}
                                disabled={saving || user.readOnly}
                                onChange={e => setPhone(e.currentTarget.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        saveUser()
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="my-4">
                        <h3 className="mb-2 text-lg">{t('web_me_profile_address_title')}</h3>
                        <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">{t('web_me_profile_address')}</span>
                            <input
                                className="w-full md:w-1/2"
                                type="text"
                                value={street}
                                disabled={saving || user.readOnly}
                                onChange={s => setStreet(s.currentTarget.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        saveUser()
                                    }
                                }}
                            />
                        </div>
                        <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">
                                {t('web_me_profile_house_number')}
                            </span>
                            <input
                                className="w-full md:w-1/2"
                                type="text"
                                value={number}
                                disabled={saving || user.readOnly}
                                onChange={e => setNumber(e.currentTarget.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        saveUser()
                                    }
                                }}
                            />
                        </div>
                        <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">{t('web_me_profile_zip')}</span>
                            <input
                                className="w-full md:w-1/2"
                                type="text"
                                value={zip}
                                disabled={saving || user.readOnly}
                                onChange={e => setZip(e.currentTarget.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        saveUser()
                                    }
                                }}
                            />
                        </div>
                        <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">{t('web_me_profile_city')}</span>
                            <input
                                className="w-full md:w-1/2"
                                type="text"
                                value={city}
                                disabled={saving || user.readOnly}
                                onChange={e => setCity(e.currentTarget.value)}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        saveUser()
                                    }
                                }}
                            />
                        </div>
                        <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">{t('web_me_profile_country')}</span>
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-full md:w-1/2"
                                isDisabled={saving || user.readOnly}
                                value={country}
                                placeholder={t('web_placeholder_select')}
                                options={countries}
                                onChange={country => setCountry(country)}
                            />
                        </div>
                        <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full pr-2 text-regent-gray md:w-1/2">{t('web_me_profile_locale')}</span>
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-full md:w-1/2"
                                isDisabled={saving || user.readOnly}
                                value={locale}
                                placeholder={t('web_placeholder_select')}
                                options={locales}
                                onChange={locale => setLocale(locale)}
                            />
                        </div>
                        <div className="mt-8 flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <span className="w-full md:w-1/2"></span>
                            <button onClick={() => saveUser()} className="btn w-full md:w-1/2">
                                {t('web_me_profile_save')}
                            </button>
                        </div>
                    </div>
                </div>
            </Panel>

            <Modal
                isOpen={showStripeSuccess}
                bottomAlignment="center"
                onRequestClose={() => setshowStripeSuccess(false)}
                okLabel={t('web_strip_message_ok')}
                onConfirm={() => setshowStripeSuccess(false)}>
                <BuySuccess />

                <h2 className="mb-2 mt-4 text-xl font-bold">{t('web_strip_message_success_title')}</h2>
                <p className="my-6 text-center text-sm text-pale-sky">{t('web_strip_message_success_desc')}</p>
            </Modal>
        </ProfilePage>
    )
})

export default Me
