import { toJS } from 'mobx'

export const mapToObject = <K extends string, V>(m: Map<K, V>) => {
    if (!m) {
        return {}
    }
    const lo: Record<string, unknown> = {}
    if (m instanceof Map) {
        for (const [k, v] of m) {
            if (v instanceof Map) {
                lo[k] = mapToObject(v)
            } else {
                lo[k] = v
            }
        }
    } else if (Object.entries(m).length) {
        for (const [k, v] of Object.entries(m)) {
            if (v instanceof Map) {
                lo[k] = mapToObject(v)
            } else {
                lo[k] = v
            }
        }
    }

    return lo
}

export function deepMerge(target: object, source: object) {
    const result = { ...target, ...source }
    for (const key of Object.keys(result)) {
        result[key] =
            typeof target[key] === 'object' && typeof source[key] === 'object'
                ? deepMerge(target[key], source[key])
                : structuredClone(result[key])
    }

    return result
}

export const mapOrJsonOptions = (opts: string | object): Record<string, unknown> => {
    if (!opts) {
        return {}
    }

    let options = opts
    if (typeof options === 'string') {
        options = JSON.parse(options)
    } else if (typeof options === 'object') {
        options = mapToObject(toJS(options) as Map<string, unknown>)
    }

    return options as Record<string, unknown>
}
