import { ReactComponent as Draw } from 'assets/family.svg'
import { getFromQuery } from 'core/use-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useMst } from 'stores/store'
import Panel from '../shared/panel'
import SmallLoader from '../shared/small-loader'
import ContentPage from './content-page'

const Tribe = () => {
    const { t } = useTranslation()
    const { isLogged, user } = useMst()
    const { invitationId } = useParams()
    const code = getFromQuery('code')
    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(true)
    const [friend, setFriend] = useState<string>('')
    const [confirmed, setConfirmed] = useState<boolean>(false)
    const [message, setMessage] = useState<string>()

    const load = async (invitationId: string, code: string) => {
        setLoading(true)
        const friend = await user.getTribe(invitationId, code)

        if (!friend) {
            navigate('/')
        }
        setFriend(friend.fullname)
        setLoading(false)
    }

    useEffect(() => {
        load(invitationId, code)
    }, [invitationId, code])

    const confirm = async () => {
        setLoading(true)
        const accepted = await user.confirmTribe(invitationId, code)
        if (typeof accepted === 'string') {
            setMessage(t(accepted))
        } else if (typeof accepted === 'boolean') {
            setConfirmed(accepted)
        }
        setLoading(false)
    }
    const cancel = async () => navigate('/')
    const gotoDashboard = async () => navigate('/')

    const navigateTo = (path: string) => {
        const params = new URLSearchParams({
            redirect: `/tribe/${invitationId}?code=${code}`,
        })
        navigate(`/${path}?${params.toString()}`)
    }

    return (
        <ContentPage>
            <Panel>
                <div className="flex flex-col items-center space-y-6">
                    <Draw />
                    {loading ? (
                        <SmallLoader className="mt-4" />
                    ) : (
                        <>
                            <h3 className="font-bold">
                                {t(confirmed ? 'web_tribe_confirmed' : 'web_tribe_join', { name: friend })}
                            </h3>
                            {confirmed ? (
                                <button className="btn" onClick={() => gotoDashboard()}>
                                    {t('web_tribe_goto_dashboard')}
                                </button>
                            ) : isLogged ? (
                                <div className="flex flex-row space-x-4">
                                    <button className="btn white" onClick={() => cancel()}>
                                        {t('web_tribe_join_cancel')}
                                    </button>
                                    <button className="btn" onClick={() => confirm()}>
                                        {t('web_tribe_join_confirm')}
                                    </button>
                                </div>
                            ) : (
                                <div className="flex flex-col space-y-2">
                                    <div className="flex flex-row space-x-4">
                                        <button className="btn" onClick={() => navigateTo('login')}>
                                            {t('web_tribe_join_login_confirm')}
                                        </button>
                                        <button className="btn" onClick={() => navigateTo('register')}>
                                            {t('web_tribe_join_register_confirm')}
                                        </button>
                                    </div>
                                    <button className="underline" onClick={() => cancel()}>
                                        {t('web_tribe_join_cancel')}
                                    </button>
                                </div>
                            )}
                            {message && <div className="text-red-500">{message}</div>}
                        </>
                    )}
                </div>
            </Panel>
        </ContentPage>
    )
}

export default Tribe
