import { Input, selectStyles } from 'components/shared/select-styles'
import ToggleButton from 'components/shared/toggle-button'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { InformationMessage } from 'stores/informationMessage/information-message'
import { useMst } from '../../../stores/store'
import Admin from '../admin'
import DatePicker from '../date-picker'
import Label from '../label'
import 'components/admin/zod'
import { zodResolver } from '@hookform/resolvers/zod'
import localeFr from 'air-datepicker/locale/fr'
import { Schema, schema } from './create'

const Edit = () => {
    const { informationMessageStore, admin, files } = useMst()
    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(true)

    const currentLocale = localeFr
    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })

    const onSubmit = async (data: Schema) => {
        const { title, bodyMessage, color, partnerId, dismissible, startDate, endDate } = data
        await informationMessageStore.informationMessageUpdate(
            informationMessageStore.editingInformationMessage.uuid,
            title,
            dismissible,
            startDate,
            endDate,
            bodyMessage,
            color,
            partnerId.value,
            null // TODO add brand
        )
        navigate('/admin/information-messages')
    }

    const setValues = useCallback((informationMessage: InformationMessage) => {
        setValue('title', informationMessage.title)
        setValue('bodyMessage', informationMessage.bodyMessage)
        setValue('color', informationMessage.color)
        setValue(
            'partnerId',
            admin.selectablePartners.find(p => p.value === informationMessage.partnerUuid)
        )
        setValue('dismissible', informationMessage.dismissible)
        const startDate =
            informationMessage.startDate !== '' ? DateTime.fromISO(informationMessage.startDate).toJSDate() : null
        setValue('startDate', startDate)
        const endDate =
            informationMessage.endDate !== '' ? DateTime.fromISO(informationMessage.endDate).toJSDate() : null
        setValue('endDate', endDate)
    }, [])

    const load = async () => {
        setLoading(true)
        await files.getConfig()
        await informationMessageStore.loadInformationMessage(id)
        await admin.loadPartners(1000, 0)
        setValues(informationMessageStore.editingInformationMessage)
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [id])

    return (
        <Admin title={t('web_admin_information_message')} isLoading={isLoading}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label
                    name="title"
                    error={
                        errors['title'] &&
                        `${t('web_menu_admin_information_message_title')} ${errors['title']?.message}`
                    }
                    label={'web_menu_admin_information_message_title'}
                    className="my-5 flex w-full flex-wrap items-center">
                    <input {...register('title')} type="text" className="w-1/3 " />
                </Label>
                <Label
                    name="color"
                    error={
                        errors['name'] && `${t('web_menu_admin_information_message_color')} ${errors['title']?.message}`
                    }
                    label={'web_menu_admin_information_message_color'}
                    className="my-5 flex w-full flex-wrap items-center">
                    <Controller
                        control={control}
                        name="color"
                        render={({ field }) => (
                            <div className="grid w-2/3 grid-cols-6 gap-4">
                                {files.colors.map(c => (
                                    <button
                                        key={c}
                                        className="relative h-6 w-6 rounded-full border-2 outline-none ring-0 transition-all hover:scale-150"
                                        style={{
                                            backgroundColor: c === field.value ? 'white' : c,
                                            borderColor: c,
                                        }}
                                        onClick={() => field.onChange(c)}></button>
                                ))}
                            </div>
                        )}
                    />
                </Label>
                <Label
                    name="bodyMessage"
                    error={
                        errors['message'] &&
                        `${t('web_menu_admin_information_message_body')} ${errors['message']?.message}`
                    }
                    label={'web_menu_admin_information_message_body'}
                    className="my-5 flex w-full flex-wrap  items-center">
                    <textarea {...register('bodyMessage')} className="w-1/3 " rows={3} />
                </Label>
                <Label
                    name="dismissible"
                    error={
                        errors['dismissible'] &&
                        `${t('web_menu_admin_information_dismissible')} ${errors['dismissible']?.message}`
                    }
                    label={'web_menu_admin_information_dismissible'}
                    className="my-5 flex w-full flex-wrap items-center"
                    required={true}>
                    <Controller
                        control={control}
                        name="dismissible"
                        render={({ field }) => (
                            <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                        )}
                    />
                </Label>
                <Label
                    name="startDate"
                    error={
                        errors['startDate'] &&
                        `${t('web_menu_admin_information_message_startDate')} ${errors['startDate']?.message}`
                    }
                    label={'web_menu_admin_information_message_startDate'}
                    className="my-5 flex w-full flex-wrap items-center leading-tight">
                    <Controller
                        name="startDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                            return <DatePicker {...field} locale={currentLocale} timepicker />
                        }}
                    />
                </Label>
                <Label
                    name="endDate"
                    error={
                        errors['endDate'] &&
                        `${t('web_menu_admin_information_message_endDate')} ${errors['endDate']?.message}`
                    }
                    label={'web_menu_admin_information_message_endDate'}
                    className="my-5 flex w-full flex-wrap items-center leading-tight">
                    <Controller
                        name="endDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                            return <DatePicker {...field} locale={currentLocale} timepicker />
                        }}
                    />
                </Label>
                <Label
                    name="Users"
                    error={
                        errors['users'] &&
                        `${t('web_menu_admin_information_message_user_select')} ${errors['message']?.message}`
                    }
                    label={'web_menu_admin_information_message_user_select'}
                    className="my-5 flex w-full flex-wrap  items-center">
                    <Controller
                        name="partnerId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Select
                                    styles={selectStyles}
                                    components={{ Input }}
                                    className="w-1/3"
                                    value={value}
                                    isClearable={true}
                                    placeholder={t('web_admin_placeholder_select')}
                                    options={admin.selectablePartners}
                                    onChange={partner => onChange(partner)}
                                />
                            )
                        }}
                    />
                </Label>

                <input type="submit" className="btn float-right cursor-pointer" value={`${t('web_admin_user_save')}`} />
            </form>
        </Admin>
    )
}

export default Edit
