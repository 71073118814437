import pako from 'pako'
import { get, post, postFile } from '../../../core/services/http-service'
import type { TreasyFile } from '../../../stores/files/treasy-file'

interface JsonValidity {
    canUpload: boolean
    reason: string
    compress: boolean
}
interface JsonReturnValidity {
    data: JsonValidity
}

interface JsonDefault {
    amazon: boolean
}
interface JsonConfig {
    url: string
    bucket: string
}
interface JsonReturn {
    data: JsonDefault | JsonConfig
}

export const safariStream = (blob): ReadableStream => {
    let position = 0

    return new ReadableStream({
        pull: controller => {
            const chunk = blob.slice(
                position,
                Math.min(blob.size, position + Math.max(controller.desiredSize, 512 * 1024))
            )

            return chunk.arrayBuffer().then(buffer => {
                const uint8array = new Uint8Array(buffer)
                const bytesRead = uint8array.byteLength

                position += bytesRead
                controller.enqueue(uint8array)

                if (position >= blob.size) {
                    controller.close()
                }
            })
        },
    })
}

export const upload = async (
    mimeType: string,
    file: TreasyFile,
    destination: 'space' | 'directory',
    destinationUuid: string,
    onStatus: (status: string, filename: string) => void,
    onUploadProgress: (percent: number) => void,
    action: string
): Promise<{
    uuid: string
    name: string
    origName: string
    action: string
    path: string
    size: number
    origSize: number
    co2Gain: number
    directoryUuid: string
    spaceUuid: string
}> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    let compressedFile: File | any = file.file
    const origSize = file.file.size
    let size = file.file.size
    const filename = file.filename
    let bucket = 'base'

    onStatus('web_uploading_files_check_valid', file.file.name)
    const params = { size, mimeType }
    const {
        data: { canUpload, reason, compress },
    } = await get<typeof params, JsonReturnValidity>('/v1/web/files/valid', params)

    if (!canUpload) {
        throw new Error(reason)
    }

    if (compress) {
        onStatus('web_uploading_files_compressing', file.file.name)
        const buffer = await file.file.arrayBuffer()
        const fileAsArray = new Uint8Array(buffer)
        compressedFile = pako.gzip(fileAsArray, { level: 9 })
        size = compressedFile.length
    }

    onStatus('web_uploading_files_check_destination', file.file.name)
    const destinationConfig = await get<{ filename: string; mimeType: string }, JsonReturn>(
        '/v1/web/files/destination',
        { filename, mimeType: file.file.type }
    )

    onStatus('web_uploading_files_uploading', file.file.name)
    let realFilename: string | undefined = undefined

    const { data } = destinationConfig as JsonReturn

    if ('amazon' in data) {
        throw new Error('web_upload_error')
    }

    const upload = async (url: string, file: TreasyFile) => {
        return new Promise<string>((resolve, reject) => {
            const ajax = new XMLHttpRequest()
            ajax.upload.addEventListener(
                'progress',
                event => {
                    const percent = Math.round((event.loaded / event.total) * 100)
                    onUploadProgress(percent)
                },
                false
            )
            ajax.addEventListener(
                'load',
                () => {
                    const etag = JSON.parse(ajax.getResponseHeader('ETag'))
                    resolve(etag)
                },
                false
            )
            ajax.addEventListener('error', event => reject(event), false)
            ajax.addEventListener('abort', () => reject(event), false)
            ajax.open('PUT', url)
            ajax.send(file.file)
        })
    }

    bucket = data.bucket
    let fileHash: string | undefined
    try {
        fileHash = await upload(data.url, file)
        realFilename = filename
    } catch (error) {
        console.error({ error, message: error.message })
        throw new Error('web_upload_error')
    }

    if (!realFilename) {
        throw new Error('web_upload_error')
    }

    onStatus('web_uploading_files_saving', file.file.name)
    interface FileUploadJsonReturn {
        data: {
            file: {
                uuid: string
                name: string
                origName: string
                path: string
                size: number
                origSize: number
                co2Gain: number
                action: string
                directoryUuid: string
                spaceUuid: string
            }
        }
    }
    const postData = {
        fullPath: file.fullPath,
        displayFilename: file.file.name,
        origFilename: file.file.name,
        realFilename,
        storage: 'amazon',
        fileHash,
        size,
        origSize,
        lastModified: file.file.lastModified,
        compressed: compress,
        destination,
        bucket,
        destinationUuid,
        mimeType,
        action,
        documentTypeUuid: file.documentTypeUuid,
    }
    const {
        data: { file: fileData },
    } = await post<typeof postData, FileUploadJsonReturn>('/v1/web/files', postData)

    return fileData
}

export interface FileWithPath {
    file: File
    fullPath: string
    id?: string
    documentTypeUuid?: string
}

const readEntryContentAsync = (entry): Promise<FileWithPath[]> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise<FileWithPath[]>((resolve, reject) => {
        let reading = 0
        const contents: FileWithPath[] = []

        const readEntry = entry => {
            if (entry.isFile) {
                reading++
                entry.file((file: File) => {
                    reading--

                    contents.push({ file, fullPath: entry.fullPath })

                    if (reading === 0) {
                        resolve(contents)
                    }
                })
            } else if (entry.isDirectory) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                readReaderContent(entry.createReader())
            }
        }

        const readReaderContent = reader => {
            reading++

            reader.readEntries(entries => {
                reading--
                for (const entry of entries) {
                    readEntry(entry)
                }

                if (reading === 0) {
                    resolve(contents)
                }
            })
        }

        readEntry(entry)
    })
}

const readFile = async (item): Promise<FileWithPath[]> => {
    const func =
        typeof item.getAsEntry === 'function'
            ? 'getAsEntry'
            : typeof item.webkitGetAsEntry === 'function'
              ? 'webkitGetAsEntry'
              : undefined
    if (func) {
        const entry = item[func]()
        const entryContent = await readEntryContentAsync(entry)

        return entryContent
    }

    const file = item.getAsFile()

    return [{ file, fullPath: file.name }]
}

export const getFilesAsync = async (dataTransfer: DataTransfer): Promise<FileWithPath[]> => {
    const loadFiles: Promise<FileWithPath[]>[] = []
    for (let i = 0; i < dataTransfer.items.length; i++) {
        const item = dataTransfer.items[i]

        if (item.kind === 'file') {
            loadFiles.push(readFile(item))
        }
    }

    const files: FileWithPath[] = (await Promise.all(loadFiles)).flat()

    return files
}
