import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as Folder } from 'assets/icons/icon-folder.svg'
import { clsx } from 'clsx'
import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { SpaceDirectory } from 'stores/files/directory'
import { SpaceFile } from 'stores/files/file'
import { Space } from 'stores/files/space'
import { cmp } from '../../dataroom/spaceroom'
import { Loader } from '../../dataroom/spaceroom/loader'
import DirectoryLine from './directory-line'
import FileLine from './file-line'
import SharedLinePart, { LineProps } from './shared-line-part'

interface SpaceLineProps extends LineProps {
    space: Space
}

const SpaceLine = observer(
    (
        {
            space,
            users,
            owner,
            partner,
            className,
            level,
            sharedFile,
            showUserSharedByMe,
            showUserSharedWithMe,
            setDownloading,
            setShowConfirmStop,
            setShowRestart,
            setShowConfirmDelete,
        }: SpaceLineProps
    ) => {
        const [loading, setLoading] = useState<boolean>(false)
        const toggleOpened = async () => {
            if (space.isOpen) {
                space.close()
            } else {
                space.open()
                if (!space.isLoaded) {
                    setLoading(true)
                    await space.refresh()
                    setLoading(false)
                }
            }
        }

        useEffect(() => {
            if (space.isOpen && !space.isLoaded) {
                const load = async () => {
                    setLoading(true)
                    await space.refresh()
                    setLoading(false)
                }
                load()
            }
        }, [space])

        return (
            <>
                <SharedLinePart
                    fileOrDirectory={space}
                    className={clsx(className)}
                    level={level}
                    sharedFile={sharedFile}
                    users={users}
                    owner={owner}
                    partner={partner}
                    showUserSharedByMe={showUserSharedByMe}
                    showUserSharedWithMe={showUserSharedWithMe}
                    setDownloading={setDownloading}
                    setShowConfirmStop={setShowConfirmStop}
                    setShowRestart={setShowRestart}
                    setShowConfirmDelete={setShowConfirmDelete}>
                    <FontAwesomeIcon
                        icon={faCaretRight}
                        className="text-inner-space cursor-pointer fill-current"
                        rotation={space.isOpen ? 90 : undefined}
                        size="xs"
                        onClick={() => toggleOpened()}
                    />
                    <Folder
                        className="h-6 w-6 flex-none cursor-pointer fill-current"
                        style={{ color: space.color }}
                        onClick={() => toggleOpened()}
                    />
                    <span>{space.displayName}</span>
                </SharedLinePart>
                {loading && (
                    <tr>
                        <td colSpan={100}>
                            <Loader />
                        </td>
                    </tr>
                )}
                {space.isOpen && (
                    <>
                        {values<Space>(space.spaces).map(space => (
                            <SpaceLine
                                key={space.uuid}
                                className="border-mercury mb-1 h-8 border-b transition-all"
                                space={space}
                                users={users}
                                owner={owner}
                                partner={partner}
                                showUserSharedByMe={showUserSharedByMe}
                                showUserSharedWithMe={showUserSharedWithMe}
                                level={0}
                                sharedFile={sharedFile}
                                setDownloading={setDownloading}
                                setShowConfirmStop={setShowConfirmStop}
                                setShowRestart={setShowRestart}
                                setShowConfirmDelete={setShowConfirmDelete}
                            />
                        ))}
                        {values<SpaceDirectory>(space.rootDirectory.directories).map(child => (
                            <DirectoryLine
                                key={child.uuid}
                                className="border-mercury mb-1 h-8 border-b transition-all"
                                directory={child}
                                users={users}
                                owner={owner}
                                partner={partner}
                                showUserSharedByMe={showUserSharedByMe}
                                showUserSharedWithMe={showUserSharedWithMe}
                                level={level + 1}
                                sharedFile={sharedFile}
                                setDownloading={setDownloading}
                                setShowConfirmStop={setShowConfirmStop}
                                setShowRestart={setShowRestart}
                                setShowConfirmDelete={setShowConfirmDelete}
                            />
                        ))}
                        {values<SpaceFile>(space.rootDirectory.files)
                            .slice()
                            .sort(cmp)
                            .map(child => (
                                <FileLine
                                    key={child.uuid}
                                    className="border-mercury mb-1 h-8 border-b transition-all"
                                    file={child}
                                    users={users}
                                    owner={owner}
                                    partner={partner}
                                    showUserSharedByMe={showUserSharedByMe}
                                    showUserSharedWithMe={showUserSharedWithMe}
                                    level={level + 1}
                                    sharedFile={sharedFile}
                                    setDownloading={setDownloading}
                                    setShowConfirmStop={setShowConfirmStop}
                                    setShowRestart={setShowRestart}
                                    setShowConfirmDelete={setShowConfirmDelete}
                                />
                            ))}
                    </>
                )}
            </>
        )
    }
)
export default SpaceLine
