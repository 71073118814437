import { ReactComponent as IconClipboard } from 'assets/icons/icon-clipboard.svg'
import toast from 'core/utils/toast'
import { toJS } from 'mobx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useMst } from 'stores/store'
import Admin from '../admin'
import Form, { type BrandFormValues, configFiles } from './form'

const Edit = () => {
    const { admin } = useMst()
    const { t } = useTranslation()
    const { brandId } = useParams()
    const [values, setValues] = useState<BrandFormValues>()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(true)
    const [showSecret, setShowSecret] = useState<boolean>(false)

    const onSubmit = async (data: BrandFormValues) => {
        const {
            name,
            url,
            logo,
            icon,
            color,
            viaColor,
            brandImage,
            brandLogo,
            userPricing: { value: userPricing },
            partnerPricing: { value: partnerPricing },
            configFile: { value: configFile },
            role_admin_name,
            role_admin_controller,
            role_admin_franchisee,
            role_admin_agent,

            hrEnabled,
            hrFilePattern,

            ...config
        } = data
        setLoading(true)
        await admin.updateBrand(
            brandId,
            name,
            url,
            logo,
            icon,
            color,
            viaColor,
            brandImage,
            brandLogo,
            configFile,
            {
                roles: {
                    admin: role_admin_name as string,
                    controller: role_admin_controller as string,
                    franchisee: role_admin_franchisee as string,
                    agent: role_admin_agent as string,
                },
                ...config,
            },
            userPricing,
            partnerPricing,
            hrEnabled,
            hrFilePattern
        )
        setLoading(false)
        navigate('/admin/brands')
    }

    const load = async () => {
        setLoading(true)
        await admin.loadBrand(brandId)
        await admin.loadPricings(1000, 0)

        const config = Object.fromEntries(toJS(admin.editingBrand.config))
        const roles = Object.fromEntries(config.roles ?? new Map())

        const pricings = [...admin.pricings].map(pricing => ({
            value: pricing.uuid,
            label: pricing.name,
        }))

        const data = {
            ...toJS(admin.editingBrand),
            configFile: configFiles
                .map(v => ({
                    value: v.value,
                    label: t(`web_admin_brand_config_file_${v.label}`),
                }))
                .find(configFile => configFile.value === admin.editingBrand.configFile),
            ...config,
            role_admin_name: roles.admin ?? t('web_franchise_users_role_admin'),
            role_admin_controller: roles.controller ?? t('web_franchise_users_role_controller'),
            role_admin_franchisee: roles.franchisee ?? t('web_franchise_users_role_franchisee'),
            role_admin_agent: roles.agent ?? t('web_franchise_users_role_agent'),
            userPricing: pricings.find(({ value }) => value === admin.editingBrand.userPricing.uuid),
            partnerPricing: pricings.find(({ value }) => value === admin.editingBrand.partnerPricing.uuid),
        }
        setValues(data)
        setLoading(false)
    }
    useEffect(() => {
        load()
    }, [brandId])

    const onCopy = async e => {
        e.preventDefault()
        try {
            await navigator.clipboard.writeText(admin.editingBrand.apiSecret)
        } catch (e) {
            // ignore
        }
    }

    return (
        <Admin title={t('web_menu_admin_brand')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} values={values} />
            <div className="mt-4">
                <h2 className="text-lg font-bold">{t('web_brand_secrets')}</h2>
                <div className="flex w-full gap-4">
                    <div className="flex w-full flex-col gap-2 lg:w-1/2">
                        <h3 className="font-bold">{t('web_brand_secret_api_token')}</h3>
                        <div className="flex flex-row gap-2">
                            <code className="grow rounded bg-geyser px-4 py-2 text-sm shadow-inner">
                                {admin.editingBrand.apiToken}
                            </code>
                            <IconClipboard
                                className="w-4 cursor-pointer fill-current text-regent-gray"
                                onClick={async () => {
                                    await navigator.clipboard.writeText(admin.editingBrand.apiToken)
                                    toast('success', 'web_brand_secret_api_token_copied')
                                }}
                            />
                        </div>
                    </div>

                    <div className="flex w-full flex-col gap-2 lg:w-1/2">
                        <h3 className="font-bold">{t('web_brand_secret_api_secret')}</h3>
                        <div className="flex flex-row items-center gap-2">
                            <code className="grow rounded bg-geyser px-4 py-2 text-sm shadow-inner" onCopy={onCopy}>
                                {admin.editingBrand.apiSecret
                                    .split('')
                                    .map(letter => (showSecret ? letter : '*'))
                                    .join('')}
                            </code>
                            <span
                                className="cursor-pointer text-regent-gray"
                                onClick={() => setShowSecret(showSecret => !showSecret)}
                            >
                                {showSecret ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                        />
                                    </svg>
                                )}
                            </span>
                            <IconClipboard
                                className="w-4 cursor-pointer fill-current text-regent-gray"
                                onClick={async () => {
                                    await navigator.clipboard.writeText(admin.editingBrand.apiSecret)
                                    toast('success', 'web_brand_secret_api_secret_copied')
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Admin>
    )
}

export default Edit
