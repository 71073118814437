import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Image404You from '../../assets/404-you.jpg'
import Image404 from '../../assets/404.jpg'
import Panel from '../shared/panel'
import ContentPage from './content-page'

const NoMatch: FC = () => {
    const { t } = useTranslation()

    return (
        <ContentPage>
            <Panel>
                <h3 className="mb-4 mt-8 text-center text-2xl font-bold">{t('web_404')}</h3>
                <div className="flex">
                    <div>
                        <img src={Image404} alt="" />
                    </div>
                    <div>
                        <img src={Image404You} alt="" />
                    </div>
                </div>
            </Panel>
        </ContentPage>
    )
}

export default NoMatch
