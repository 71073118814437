import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TrustedUser } from 'stores/trustedUser/trusted-user'
import imagePlaceholder from '../../assets/user-placeholder.png'
import UserCard from './user-card'

const FakeTrustedUser: FC<{ onClick: () => void }> = ({ onClick }) => {
    const { t } = useTranslation()

    return (
        <UserCard
            user={
                {
                    trustedUser: { fullname: t('web_friend_you_first_friend'), picture: imagePlaceholder },
                    accepted: false,
                } as TrustedUser
            }
            onClick={onClick}
            className="cursor-pointer"
        />
    )
}

export default FakeTrustedUser
