import { faPlug } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMst } from 'stores/store'
import SlidingButtonImage from '../shared/sliding-button-image'
import ConnectorImage from './connector-image'

const maxConnectorVisible = 10

const SlidingConnectors: FC = observer(() => {
    const { telecollecte } = useMst()
    const { t } = useTranslation()
    const [opened, setOpened] = useState<boolean>(false)

    useEffect(() => {
        telecollecte.listConnectors()
    }, [telecollecte.synced])

    return (
        <SlidingButtonImage
            tooltip={t('web_tooltips_connectors')}
            isOpen={opened}
            onOpen={value => setOpened(value)}
            image={<FontAwesomeIcon icon={faPlug} size="2xl" className="text-sea-buckthorn" />}>
            {!telecollecte.synced && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-4 h-4 w-4 animate-spin"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                </svg>
            )}
            {telecollecte.connectors.slice(0, maxConnectorVisible).map((connector, index) => (
                <ConnectorImage
                    key={index}
                    className="mr-2 h-6 w-6 cursor-pointer rounded-full"
                    connector={connector}
                    onClick={async () => {
                        await telecollecte.manageConnector(connector)
                        const url = telecollecte.addConnectorUrl
                        window.location.href = url
                    }}
                />
            ))}
        </SlidingButtonImage>
    )
})

export default SlidingConnectors
