import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useMst } from '../../../stores/store'
import Admin from '../admin'
import Form, { BrandFormValues } from './form'

const Create = () => {
    const { admin } = useMst()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)

    const onSubmit = async (data: BrandFormValues) => {
        const {
            name,
            url,
            logo,
            icon,
            color,
            viaColor,
            brandImage,
            brandLogo,
            userPricing: { value: userPricing },
            partnerPricing: { value: partnerPricing },
            configFile: { value: configFile },
            role_admin_name,
            role_admin_controller,
            role_admin_franchisee,
            role_admin_agent,

            hrEnabled,
            hrFilePattern,

            ...config
        } = data
        setLoading(true)
        await admin.createBrand(
            name,
            url,
            logo,
            icon,
            color,
            viaColor,
            brandImage,
            brandLogo,
            configFile,
            {
                roles: {
                    admin: role_admin_name as string,
                    controller: role_admin_controller as string,
                    franchisee: role_admin_franchisee as string,
                    agent: role_admin_agent as string,
                },
                ...config,
            },
            userPricing,
            partnerPricing,

            hrEnabled,
            hrFilePattern
        )
        setLoading(false)
        navigate('/admin/brands')
    }

    const load = async () => {
        setLoading(true)
        await admin.loadPricings(1000, 0)
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    return (
        <Admin title={t('web_menu_admin_brand')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} />
        </Admin>
    )
}

export default Create
