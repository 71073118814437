import { clsx } from 'clsx'
import { getOrientation } from 'get-orientation/browser'
import { observer } from 'mobx-react-lite'
import { type ReactNode, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import ReactCrop, { type Crop } from 'react-image-crop'
import { Link } from 'react-router-dom'
import { ReactComponent as IconCamera } from '../../assets/icons/icon-camera.svg'
import { ReactComponent as IconSeparator } from '../../assets/profile/separator.svg'
import { CropAs, ORIENTATION_TO_ANGLE, getCroppedImg, getRotatedImage, readFile } from '../../core/images/canvas-utils'
import { useMst } from '../../stores/store'
import ContentPage from '../dataroom/content-page'
import Tooltip from '../shared/tooltip'

interface Props {
    url: string
    children?: ReactNode
}

const ProfilePage = observer(({ url, children }: Props) => {
    const { user } = useMst()
    const { t } = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [imageRef, setImageRef] = useState<HTMLImageElement>()
    const [imageName, setImageName] = useState<string>('')
    const [imageSrc, setImageSrc] = useState<string | null>(null)
    const [showUuid, setshowUuid] = useState<boolean>(false)

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0] as File
            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                const rotated = await getRotatedImage(imageDataUrl, rotation)
                if (rotated) {
                    imageDataUrl = rotated
                }
            }

            setImageSrc(imageDataUrl)
            setImageName(file.name)
        }
    }, [])
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: ['image/*'],
        multiple: false,
    })

    const [crop, setCrop] = useState<Crop>({ x: 0, y: 0, width: 100, height: 100, unit: '%' })

    const saveProfilePicture = async () => {
        if (!imageRef) {
            return
        }
        setLoading(true)
        const image = await getCroppedImg(imageRef, crop, CropAs.BLOB)
        if (!image || typeof image !== 'object') {
            setLoading(false)

            return
        }
        await user.uploadProfilePicture(imageName, image)

        setLoading(false)
        setImageRef(undefined)
        setImageSrc(null)
    }

    const links = [
        { key: 'web_me_personnal_infos', link: '/me', show: () => true },
        {
            key: 'web_me_account',
            link: '/me/account',
            show: () => user.franchises.length === 0 && user.canManagePersonalData,
        },
        {
            key: 'web_me_subscriptions',
            link: '/me/subscriptions',
            show: () => user.canManagePersonalData,
        },
        { key: 'web_me_logins', link: '/me/logins', show: () => user.canManagePersonalData },
        { key: 'web_me_security', link: '/me/security', show: () => user.canManagePersonalData },
        { key: 'web_me_friends', link: '/me/friends', show: () => user.canManagePersonalData },
        { key: 'web_me_options', link: '/me/options', show: () => user.canManagePersonalData },
        // {key: 'web_me_connections', link: '/me/connections', show: () => user.canManagePersonalData },
        // {key: 'web_me_sponsorship', link: '/me/sponsorship', show: () => user.canManagePersonalData },
    ]

    return (
        <ContentPage>
            <div className="mb-14 flex w-full flex-col items-center justify-center">
                <Tooltip tooltip={t('web_tooltips_profile_picture')}>
                    <div className="relative h-20 w-20 overflow-hidden rounded-50">
                        <div
                            className="absolute inset-x-0 bottom-0 flex h-8 cursor-pointer items-center justify-center text-transparent hover:bg-regent-gray/60 hover:text-regent-gray"
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <IconCamera className="h-4 w-4 fill-current" />
                        </div>
                        <div
                            className={'h-20 w-20 bg-cover bg-no-repeat'}
                            style={{ backgroundImage: `url(${user.picture})` }}
                        />
                    </div>
                </Tooltip>
                <h1 className="text-lg font-bold" onClick={e => setshowUuid(e.altKey && e.metaKey)}>
                    {user.fullname}
                </h1>
                {showUuid && <h2 className="text-sm font-bold">{user.uuid}</h2>}
                {user.userType?.uuid !== '' && <h2 className="text-sm font-bold">{user.userType.name}</h2>}
                {imageSrc && !isLoading && (
                    <div className="mt-8 flex w-1/3 flex-col items-center space-y-4">
                        <ReactCrop
                            src={imageSrc}
                            crop={crop}
                            onChange={newCrop => setCrop(newCrop)}
                            onImageLoaded={img => {
                                setImageRef(img)

                                const aspect = 1

                                let x: number
                                let y: number
                                let width: number
                                let height: number
                                if (img.height === img.width) {
                                    width = img.width
                                    height = img.height
                                    x = y = 0
                                } else if (img.height < img.width) {
                                    width = height = img.height
                                    x = (img.width - img.height) / 2
                                    y = 0
                                } else {
                                    width = height = img.width
                                    x = 0
                                    y = (img.height - img.width) / 2
                                }

                                setCrop({
                                    unit: 'px',
                                    width,
                                    height,
                                    x,
                                    y,
                                    aspect,
                                })

                                return false
                            }}
                        />
                        <div className="flex w-full items-center justify-center">
                            <button className="btn" onClick={() => saveProfilePicture()} type="button">
                                {t('web_me_profile_save_picture')}
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <nav className="w-full">
                <ul className="flex w-full flex-col justify-center space-y-2 md:flex-row md:space-x-12 md:space-y-0">
                    {links
                        .filter(({ show }) => show())
                        .map(({ key, link }) => (
                            <li key={key}>
                                <Link
                                    className={clsx('flex items-center space-x-2', {
                                        'font-bold text-inner-space': url === link,
                                        'text-regent-gray': url !== link,
                                    })}
                                    to={link}
                                >
                                    {url === link && <IconSeparator className="stroke-current text-atomic-tangerine" />}
                                    <span>{t(key)}</span>
                                </Link>
                            </li>
                        ))}
                </ul>
            </nav>

            {children}
        </ContentPage>
    )
})

export default ProfilePage
