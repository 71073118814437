import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import img01 from '../../assets/todo/mails/01.jpg'
import ContentPage from '../dataroom/content-page'
import Panel from '../shared/panel'
import Slide from '../shared/slide'

const Mails: FC = () => {
    const { t } = useTranslation()

    const pages = [
        {
            content: (
                <>
                    <h1 className="mb-4 text-lg font-bold">
                        Echangez des messages sécurisés avec votre entourage{' '}
                        <span className="italic text-atomic-tangerine">(bientôt disponible)</span>
                    </h1>
                    <h2 className="mb-4 font-bold">
                        Alertez facilement votre expert-comptable de l'envoi d'un justificatif à traiter. Recevez un
                        email de votre conseiller financier qui apporte des explications à un relevé déposé dans votre
                        coffre-fort. Facile et sans risque de phishing !
                    </h2>
                    <p className="mb-4">
                        Vous pouvez utiliser la messagerie mise à disposition par Treasy en toute facilité. Les messages
                        que vous pourriez recevoir proviennent soit de nous (par exemple, une alerte ou une information
                        de mise à jour importante), soit de nos partenaires. Par exemple, vous êtes devenu client de
                        Treasy grâce à votre conseiller financier. Celui-ci souhaite partager avec vous une information
                        importante, en lien avec les documents que vous vous échangez. La messagerie Treasy est un
                        endroit sûr et simple et vous permet de consulter les messages de votre fournisseur lorsque vous
                        effectuez le tri ou le contrôle des documents liés à celui-ci.{' '}
                    </p>
                    <p className="mb-4">
                        Pour vous faciliter la consultation, Treasy surligne les messages non lus et propose des règles
                        de visualisation (par fournisseur partenaire, ou bien par date) et d'archivage.
                    </p>
                    <p className="mb-4">
                        En aucune manière, les emails échangés ne peuvent être piratés ou utilisés comme moyen d'accès à
                        votre coffre-fort Treasy, exactement comme cela se passe avec la messagerie sécurisée de votre
                        banque.
                    </p>
                </>
            ),
            image: img01,
        },
    ]

    return (
        <ContentPage title={t('web_link_mails')}>
            <Panel>
                <Slide pages={pages} title={t('web_link_mails')} page={t('web_todo_mails')} />
            </Panel>
        </ContentPage>
    )
}

export default Mails
