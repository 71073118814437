import { createRef, forwardRef, useState } from 'react'
import { SketchPicker } from 'react-color'

interface ColorPickerProps {
    onChange: (color: string) => void
    color?: string
    className?: string
    disabled?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ColorPicker = forwardRef<HTMLDivElement, ColorPickerProps>(({ onChange, color, className, disabled }, ref) => {
    const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false)

    return (
        <div className={className}>
            <div
                className="inline-block cursor-pointer rounded-sm bg-gray-200 p-1 shadow-sm"
                onClick={() => {
                    if (!disabled) {
                        setDisplayColorPicker(true)
                    }
                }}>
                <div className="h-4 w-9 rounded-sm" style={{ backgroundColor: color ?? '#fff' }} />
            </div>
            {displayColorPicker && (
                <div className="absolute z-10">
                    <div className="fixed inset-0" onClick={() => setDisplayColorPicker(false)} />
                    <SketchPicker color={color} onChange={({ hex }) => onChange(hex)} />
                </div>
            )}
        </div>
    )
})
ColorPicker.displayName = 'ColorPicker'

export default ColorPicker
