import { useMutation } from '@tanstack/react-query'
import { NewCollaboration } from 'components/collaboration/collaboration'
import { post } from 'core/services/http-service'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as WomanTrash } from '../../assets/trash.svg'
import toast from '../../core/utils/toast'
import type { Space } from '../../stores/files/space'
import { useMst } from '../../stores/store'
import Modal from '../shared/modal'
import CreateSpace from './create-space'
import MoveFile from './move-file'

interface Props {
    space?: Space
    isVisible: boolean
    position?: 'top' | 'bottom'
    onClose: () => void
    onRefresh: () => void
    onDeleted: () => void
    isShared?: boolean
    shareLocked?: boolean
    mine?: boolean
    editTitleRequested?: boolean
}

const SpaceroomMenu = ({
    space,
    position = 'bottom',
    isVisible,
    onClose,
    onRefresh,
    onDeleted,
    isShared = false,
    shareLocked = false,
    mine = false,
    editTitleRequested = false,
}: Props) => {
    const popRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation()
    const { files, user } = useMst()

    const [showName, setShowName] = useState<boolean>(false)
    const [showColor, setShowColor] = useState<boolean>(false)

    const [showNewCollaboration, setShowNewCollaboration] = useState<boolean>(false)

    const handleHideDropdown = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            onClose()
        }
    }, [])

    const handleClickOutside = useCallback(
        (event: Event) => {
            if (popRef.current && !popRef.current.contains(event.target as Node)) {
                onClose()
            }
        },
        [popRef]
    )

    const postCancelMutation = useMutation({
        mutationFn: id => post<never, never>(`/v1/web/collaboration/cancel/${id}`),
        onSuccess: () => {
            onDeleted()
            toast(
                'success',
                t('web_collaboration_cancel_success', {
                    date: DateTime.now().plus({ days: 7 }).toLocaleString(DateTime.DATETIME_FULL),
                })
            )
        },
    })
    const postLeaveMutation = useMutation({
        mutationFn: id => post<never, never>(`/v1/web/collaboration/leave/${id}`),
        onSuccess: () => {
            onDeleted()
            toast('success', t('web_collaboration_leave_success'))
        },
    })

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true)
        document.addEventListener('click', handleClickOutside, true)

        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true)
            document.removeEventListener('click', handleClickOutside, true)
        }
    })
    const [showCreateSpaceModal, setShowCreateSpaceModal] = useState<boolean>(false)
    const [showMoveTo, setShowMoveTo] = useState<boolean>(false)

    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
    const [showConfirmLeave, setShowConfirmLeave] = useState<boolean>(false)
    const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false)

    const openCreateSpace = (showName, showColor) => {
        onClose()
        setShowName(showName)
        setShowColor(showColor)
        setShowCreateSpaceModal(true)
    }

    useEffect(() => {
        if (editTitleRequested) {
            openCreateSpace(true, false)
        }
    }, [editTitleRequested])

    const openMoveTo = () => {
        onClose()
        setShowMoveTo(true)
    }

    const deleteSpace = () => {
        onClose()

        if (!space) {
            return
        }

        if (space.hasFile) {
            toast('error', 'web_spaceroom_cant_delete')

            return
        }

        setShowConfirmDelete(true)
    }

    const confirmDelete = async () => {
        if (space) {
            await files.delete([
                {
                    uuid: space.uuid,
                    displayName: space.displayName,
                    isFile: false,
                    isDirectory: false,
                    isSpace: true,
                    isEditable: space.isEditableBy(user),
                    isDeletable: space.isDeletableBy(user),
                    parentUuid: space.parentUuid,
                },
            ])
            onDeleted()
        }
    }

    const origin = position === 'top' ? 'origin-bottom bottom-0' : 'origin-top top-0'
    const style = position === 'top' ? { transform: 'translateX(-12rem)' } : {}

    const setChooseSpace = async (choosenSpace: Space | undefined) => {
        if (space === undefined) {
            return
        }
        setShowMoveTo(false)
        await space.moveTo(choosenSpace)
        await files.getFiles()
        onRefresh()
    }

    if (space === undefined) {
        return null
    }

    return (
        <div ref={popRef}>
            {isVisible && (
                <div
                    className={`absolute z-50 float-left -mt-4 flex w-96 flex-col rounded bg-white p-4 text-left text-base  shadow-lg ${origin}`}
                    style={style}
                >
                    <ul>
                        {mine && space.isEditable && (
                            <>
                                <li className="cursor-pointer" onClick={() => openCreateSpace(true, false)}>
                                    {t('web_spaceroom_rename')}
                                </li>

                                {!isShared && (
                                    <li className="cursor-pointer" onClick={() => openMoveTo()}>
                                        {t('web_spaceroom_move')}
                                    </li>
                                )}
                            </>
                        )}
                        {mine && (
                            <li className="cursor-pointer" onClick={() => openCreateSpace(false, true)}>
                                {t('web_spaceroom_change_color')}
                            </li>
                        )}
                        {mine && space.isDeletable && !isShared && (
                            <li className="cursor-pointer" onClick={() => deleteSpace()}>
                                {t('web_spaceroom_delete')}
                            </li>
                        )}
                        {mine ? (
                            !shareLocked ? (
                                isShared ? (
                                    <li
                                        className="cursor-pointer"
                                        onClick={() => {
                                            onClose()
                                            setShowConfirmCancel(true)
                                        }}
                                    >
                                        {t('web_collaboration_cancel')}
                                    </li>
                                ) : !space.isStack && !space.isQuarantine && !space.isTrashed ? (
                                    <li
                                        className="cursor-pointer"
                                        onClick={() => {
                                            onClose()
                                            setShowNewCollaboration(true)
                                        }}
                                    >
                                        {t('web_collaboration_share')}
                                    </li>
                                ) : null
                            ) : null
                        ) : (
                            <li
                                className="cursor-pointer"
                                onClick={() => {
                                    onClose()
                                    setShowConfirmLeave(true)
                                }}
                            >
                                {t('web_collaboration_leave')}
                            </li>
                        )}
                    </ul>
                </div>
            )}

            <NewCollaboration
                isOpen={showNewCollaboration}
                onClose={() => setShowNewCollaboration(false)}
                onRefresh={onRefresh}
                space={space}
            />

            <CreateSpace
                isOpen={showCreateSpaceModal}
                space={space}
                showName={showName}
                showColor={showColor}
                onClose={() => {
                    setShowCreateSpaceModal(false)
                    onRefresh()
                }}
            />

            <MoveFile
                ignoreSpace={space}
                isVisible={showMoveTo}
                showDirectories={false}
                onClose={() => setShowMoveTo(false)}
                onChoose={space => setChooseSpace(space)}
            />

            <Modal
                isOpen={showConfirmDelete}
                onCancel={() => setShowConfirmDelete(false)}
                onRequestClose={() => setShowConfirmDelete(false)}
                cancelLabel={t('web_dataroom_trash_number_files_cancel')}
                okLabel={t('web_dataroom_trash_number_files_confirm')}
                onConfirm={() => {
                    setShowConfirmDelete(false)
                    confirmDelete()
                }}
            >
                <WomanTrash />
                <h4 className="my-4 text-center text-xl">
                    <Trans i18nKey="web_dataroom_trash_space">web_dataroom_trash_space</Trans>
                </h4>
                <span className="text-regent-gray text-sm">{t('web_dataroom_trash_space_desc')}</span>
            </Modal>

            <Modal
                isOpen={showConfirmCancel}
                onCancel={() => setShowConfirmCancel(false)}
                onRequestClose={() => setShowConfirmCancel(false)}
                cancelLabel={t('web_collaboration_cancel_cancel')}
                okLabel={t('web_collaboration_cancel_confirm')}
                onConfirm={() => {
                    setShowConfirmCancel(false)
                    postCancelMutation.mutate(space.uuid)
                }}
            >
                <h4 className="my-4 text-center text-xl">
                    <Trans i18nKey="web_collaboration_cancel_title">web_dataroom_trash_space</Trans>
                </h4>
                <span className="text-regent-gray text-sm">{t('web_collaboration_cancel_desc')}</span>
            </Modal>

            <Modal
                isOpen={showConfirmLeave}
                onCancel={() => setShowConfirmLeave(false)}
                onRequestClose={() => setShowConfirmLeave(false)}
                cancelLabel={t('web_collaboration_leave_cancel')}
                okLabel={t('web_collaboration_leave_confirm')}
                onConfirm={() => {
                    setShowConfirmLeave(false)
                    postLeaveMutation.mutate(space.uuid)
                }}
            >
                <h4 className="my-4 text-center text-xl">
                    <Trans i18nKey="web_collaboration_leave_title">web_dataroom_trash_space</Trans>
                </h4>
                <span className="text-regent-gray text-sm">{t('web_collaboration_leave_desc')}</span>
            </Modal>
        </div>
    )
}

export default SpaceroomMenu
