import localeFr from 'air-datepicker/locale/fr'
import { ReactComponent as BurgerIcon } from 'assets/icons/icon-burger-line.svg'
import { ReactComponent as GridIcon } from 'assets/icons/icon-grid.svg'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SharedFile } from 'stores/files'
import { useMst } from 'stores/store'
import DatePicker from '../admin/date-picker'
import Modal from '../shared/modal'
import Panel from '../shared/panel'
import Tooltip from '../shared/tooltip'
import SharingPanelGroup from './sharing-panel-group'
import SharingPanelLine from './sharing-panel-line'

export interface SharingPanelProps {
    showUserSharedByMe?: boolean
    showUserSharedWithMe?: boolean
    setDownloading: (value: boolean) => void
}

const SharingPanel = (
    { showUserSharedByMe = false, showUserSharedWithMe = false, setDownloading }: SharingPanelProps
) => {
    const { t } = useTranslation()
    const { files } = useMst()
    const [selectedView, setSelectedView] = useState<'line' | 'grid'>('line')

    const [showConfirmStopAll, setShowConfirmStopAll] = useState<boolean>(false)
    const [showConfirmStop, setShowConfirmStop] = useState<SharedFile>()
    const [showConfirmDelete, setShowConfirmDelete] = useState<SharedFile>()
    const [showRestart, setShowRestart] = useState<SharedFile>()

    const [unlimited, setUnlimited] = useState<boolean>()
    const [fromDate, setFromDate] = useState<DateTime | undefined>()
    const [toDate, setToDate] = useState<DateTime | undefined>()
    const currentLocale = localeFr

    const stopAll = async () => {
        await files.stopAllSharing(selectedView === 'grid')
    }

    const stopShare = async (sharedFile: SharedFile) => {
        await files.stopSharing(sharedFile.uuid, false, selectedView === 'grid')
        setShowConfirmStop(undefined)
    }
    const deleteShare = async (sharedFile: SharedFile) => {
        await files.stopSharing(sharedFile.uuid, true, selectedView === 'grid')
        setShowConfirmDelete(undefined)
    }

    const restart = async (sharedFile: SharedFile) => {
        await files.restartSharing(sharedFile.uuid, fromDate, toDate, unlimited, selectedView === 'grid')
        setShowRestart(undefined)
    }

    return (
        <Panel innerClassName="flex flex-col space-y-4">
            <h2 className="text-lg">
                {showUserSharedByMe && t('web_shares_my_share')}
                {showUserSharedWithMe && t('web_shares_shared_with_me')}
            </h2>
            <div className="flex justify-between">
                <div className="flex">
                    <Tooltip tooltip={t('web_share_show_line')}>
                        <button
                            className={`rounded-l-lg border border-r-0 border-geyser p-2 ${
                                selectedView === 'line' ? 'bg-regent-gray text-white' : 'text-regent-gray'
                            }`}
                            onClick={() => setSelectedView('line')}>
                            <BurgerIcon className="fill-current" />
                        </button>
                    </Tooltip>
                    <Tooltip tooltip={t('web_share_show_grid')}>
                        <button
                            className={`rounded-r-lg border border-geyser p-2 ${
                                selectedView === 'grid' ? 'bg-regent-gray text-white' : 'text-regent-gray'
                            }`}
                            onClick={() => setSelectedView('grid')}>
                            <GridIcon className="fill-current" />
                        </button>
                    </Tooltip>
                </div>
                <div>
                    {showUserSharedByMe && (
                        <button className="btn premium" onClick={() => setShowConfirmStopAll(true)}>
                            {t('web_shares_stop_all_shares')}
                        </button>
                    )}
                </div>
            </div>
            <div>
                {selectedView === 'line' ? (
                    <SharingPanelLine
                        showUserSharedByMe={showUserSharedByMe}
                        showUserSharedWithMe={showUserSharedWithMe}
                        setDownloading={setDownloading}
                        setShowConfirmStop={setShowConfirmStop}
                        setShowRestart={sharedFile => {
                            setUnlimited(sharedFile.unlimited)
                            setFromDate(DateTime.local())
                            setToDate(
                                sharedFile.unlimited ? undefined : (fromDate ?? DateTime.local()).plus({ months: 1 })
                            )
                            setShowRestart(sharedFile)
                        }}
                        setShowConfirmDelete={setShowConfirmDelete}
                    />
                ) : (
                    <SharingPanelGroup
                        showUserSharedByMe={showUserSharedByMe}
                        showUserSharedWithMe={showUserSharedWithMe}
                        setDownloading={setDownloading}
                    />
                )}
            </div>

            <Modal
                isOpen={showConfirmStopAll}
                title={t('web_confirm_stop_all_share')}
                onCancel={() => setShowConfirmStopAll(false)}
                onRequestClose={() => setShowConfirmStopAll(false)}
                onConfirm={() => {
                    setShowConfirmStopAll(false)
                    stopAll()
                }}
            />

            <Modal
                isOpen={showConfirmStop !== undefined}
                title={t('web_confirm_stop_share')}
                onCancel={() => setShowConfirmStop(undefined)}
                onRequestClose={() => setShowConfirmStop(undefined)}
                onConfirm={() => stopShare(showConfirmStop)}
            />

            <Modal
                isOpen={showConfirmDelete !== undefined}
                title={t('web_confirm_delete_share')}
                onCancel={() => setShowConfirmDelete(undefined)}
                onRequestClose={() => setShowConfirmDelete(undefined)}
                onConfirm={() => deleteShare(showConfirmDelete)}
            />

            <Modal
                isOpen={showRestart !== undefined}
                title={t('web_confirm_restart_share')}
                onCancel={() => setShowRestart(undefined)}
                onRequestClose={() => setShowRestart(undefined)}
                onConfirm={() => restart(showRestart)}>
                <div className="flex w-full flex-col space-y-4">
                    <h2>{t('web_confirm_restart_share_choose_date')}</h2>
                    <div className="flex justify-between space-x-4">
                        <div className="col-span-2 flex space-x-4 md:col-span-1">
                            <DatePicker
                                className={'cursor-pointer'}
                                value={fromDate?.toJSDate() ?? new Date()}
                                onChange={date => setFromDate(DateTime.fromJSDate(date as Date))}
                                locale={currentLocale}
                            />
                            <DatePicker
                                className={`${unlimited ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                disabled={unlimited}
                                value={toDate?.toJSDate()}
                                onChange={date => setToDate(DateTime.fromJSDate(date as Date))}
                                locale={currentLocale}
                            />
                        </div>
                        <div className="flex items-center justify-end space-x-8">
                            <label className="flex items-center space-x-4">
                                <span className="">{t('web_share_trusted_people_unlimited_time')}</span>
                                <input
                                    type="checkbox"
                                    checked={unlimited}
                                    onChange={() => {
                                        setUnlimited(current => !current)
                                        setToDate(
                                            !unlimited ? undefined : (fromDate ?? DateTime.local()).plus({ months: 1 })
                                        )
                                    }}
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </Modal>
        </Panel>
    )
}

export default SharingPanel
