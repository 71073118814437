interface Props {
    title?: string
    className?: string
}

const Pricing = ({ title, className = 'h-6 w-6' }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-label={title}
            className={className}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
            />
        </svg>
    )
}

export default Pricing
