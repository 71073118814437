import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { isAlive } from 'mobx-state-tree'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import type { Space } from '../../stores/files/space'
import { useMst } from '../../stores/store'
import Panel from '../shared/panel'
import SmallLoader from '../shared/small-loader'
import CreateSpace from './create-space'
import FakeSpaceView from './fake-space-view'
import SpaceView from './space-view'

const SpacesViewer = observer(() => {
    const { t } = useTranslation()
    const { files, user } = useMst()
    const [loaded, setLoaded] = useState<boolean>(false)
    const [spaces, setSpaces] = useState<Space[]>([])
    const [showCreateSpaceModal, setShowCreateSpaceModal] = useState<boolean>(false)

    const sort = (a: Space, b: Space) => {
        const aName = a.displayNameForUser(user)
        const bName = b.displayNameForUser(user)

        if (a.userUuid === b.userUuid && a.userUuid === user.uuid) {
            return aName.localeCompare(bName, 'fr', { sensitivity: 'base' })
        }

        if (a.userUuid === user.uuid) {
            return -1
        }
        if (b.userUuid === user.uuid) {
            return 1
        }

        if (a.userUuid === b.userUuid) {
            return aName.localeCompare(bName, 'fr', { sensitivity: 'base' })
        }

        return a.username.localeCompare(b.username, 'fr', { sensitivity: 'base' })
    }

    useEffect(() => {
        const spaces = values<Space>(files.spaces)
        if (spaces.length > 0) {
            setSpaces(
                spaces
                    .filter(space => {
                        if (space.isStack || space.isQuarantine) {
                            return false
                        }
                        if (space.isInUserKind(user.getStacks())) {
                            return false
                        }
                        if (space.userUuid === user.uuid && space.jsonOptions?.displayOn?.user === 'dataroom') {
                            return true
                        }

                        return space.parent === '' && !space.isShared
                    })
                    .sort(sort)
            )
            setLoaded(true)
        }
    }, [files.spaces, files.spaces.size])

    return (
        <Panel className="mb-8">
            <div>
                <span className="text-lg">
                    <Trans i18nKey="web_dataroom_other_stack">web_dataroom_other_stack</Trans>
                </span>
                <span
                    className="text-atomic-tangerine float-right cursor-pointer font-bold"
                    onClick={() => setShowCreateSpaceModal(true)}>
                    {t('web_dataroom_add_space')}
                </span>
            </div>
            <div>
                {spaces.length === 0 ? (
                    !loaded ? (
                        <SmallLoader />
                    ) : (
                        <FakeSpaceView onClick={() => setShowCreateSpaceModal(true)} />
                    )
                ) : (
                    <></>
                )}
                <div className="flex flex-row flex-wrap py-4">
                    {spaces.map(space => {
                        if (!isAlive(space)) {
                            return null
                        }

                        return <SpaceView key={space.uuid} space={space} onRefresh={() => setLoaded(false)} />
                    })}
                </div>
            </div>

            <CreateSpace
                isOpen={showCreateSpaceModal}
                onSpaceCreated={() => {
                    files.getFiles()
                    setLoaded(false)
                }}
                onClose={() => setShowCreateSpaceModal(false)}
            />
        </Panel>
    )
})

export default SpacesViewer
