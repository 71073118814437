import { faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Admin from 'components/admin/admin'
import Pagination from 'components/admin/pagination'
import useDebounce from 'core/debounce'
import toast from 'core/utils/toast'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { BrandNews as News } from 'stores/brands/news'
import { useMst } from 'stores/store'

const NewsLine: FC<{ news: News; onRefresh: () => void }> = ({ news, onRefresh }) => {
    const { brand } = useMst()
    const { t } = useTranslation()
    const [, setLoading] = useState<boolean>(false)

    const onNewsDelete = async (id: string) => {
        setLoading(true)
        await brand.deleteNews(id)
        toast('success', t('web_brand_news_deleted'))
        setLoading(false)
        onRefresh()
    }

    return (
        <tr>
            <td className="text-left">{news.title}</td>
            <td className="text-left">{news.teaserText}</td>
            <td className="text-left">{news.bodyMessage}</td>
            <td>
                <span style={{ backgroundColor: news.color }}>{news.color}</span>
            </td>
            <td>{news.startDate !== '' ? DateTime.fromISO(news.startDate).toLocaleString(DateTime.DATE_SHORT) : ''}</td>
            <td>{news.endDate !== '' ? DateTime.fromISO(news.endDate).toLocaleString(DateTime.DATE_SHORT) : ''}</td>
            <td>{news.dismissible ? '✓' : '✗'}</td>
            <td className="flex space-x-1 text-christine">
                <Link to={`/brands/${news.brandUuid}/news/${news.uuid}`}>
                    <FontAwesomeIcon icon={faEdit} />
                </Link>
                <FontAwesomeIcon icon={faTrash} className="cursor-pointer" onClick={() => onNewsDelete(news.uuid)} />
            </td>
        </tr>
    )
}

const limit = 50

const Listing: FC = observer(() => {
    const { brand } = useMst()
    const { id } = useParams()
    const { t } = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [offset, setOffset] = useState<number>(0)
    const [query] = useState<string>('')
    const [page, setPage] = useState<number>(0)
    const [pageCount] = useState<number>(0)
    const [newsList, setNewsList] = useState<News[]>([])

    const load = useCallback(async () => {
        setLoading(true)
        const { news } = await brand.listNews()
        setNewsList(news)
        setLoading(false)
    }, [offset, query])

    const changePage = async page => {
        setPage(page)
        setOffset(Math.ceil(page * limit))
    }

    const debouncedQuery = useDebounce<string>(query, 500)
    useEffect(() => {
        load()
    }, [page, debouncedQuery])

    useEffect(() => {
        setPage(0)
    }, [])

    return (
        <Admin
            title={t('web_partner_news')}
            isLoading={isLoading}
            header={
                <div className="flex items-center space-x-4">
                    <Link to={`/brands/${id}/news/create`} className="btn">
                        {t('web_partner_news_new')}
                    </Link>
                </div>
            }>
            <table className="admin w-full table-auto text-center">
                <thead>
                    <tr>
                        <th>{t('web_menu_partner_news_title')}</th>
                        <th>{t('web_menu_partner_teaser_test')}</th>
                        <th>{t('web_menu_partner_full_text')}</th>
                        <th>{t('web_menu_partner_news_color')}</th>
                        <th>{t('web_menu_admin_information_message_startDate')}</th>
                        <th>{t('web_menu_admin_information_message_endDate')}</th>
                        <th>{t('web_menu_partner_dismissible_short')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {newsList.map(n => (
                        <NewsLine key={n.uuid} news={n} onRefresh={load} />
                    ))}
                </tbody>
            </table>
            <div className="mt-8 flex w-full justify-center">
                {pageCount > 1 && <Pagination page={page} totalPages={pageCount} onChangePage={changePage} />}
            </div>
        </Admin>
    )
})

export default Listing
