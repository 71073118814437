import { z, ZodErrorMap, ZodIssueCode, ZodParsedType } from 'zod'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function joinValues<T extends any[]>(array: T, separator = ' | '): string {
    return array.map(val => (typeof val === 'string' ? `'${val}'` : val)).join(separator)
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function assertNever(_x: unknown): never {
    throw new Error()
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const jsonStringifyReplacer = (_: string, value: any): any => {
    if (typeof value === 'bigint') {
        return value.toString()
    }

    return value
}

export const frErrorMap: ZodErrorMap = (issue, _ctx) => {
    let message: string
    switch (issue.code) {
        case ZodIssueCode.invalid_type:
            if (issue.received === ZodParsedType.undefined) {
                message = 'Requis'
            } else {
                message = `Attendu ${issue.expected}, reçu ${issue.received}`
            }
            break
        case ZodIssueCode.invalid_literal:
            message = `Invalid literal value, expected ${JSON.stringify(issue.expected, jsonStringifyReplacer)}`
            break
        case ZodIssueCode.unrecognized_keys:
            message = `Unrecognized key(s) in object: ${joinValues(issue.keys, ', ')}`
            break
        case ZodIssueCode.invalid_union:
            message = `Chaine invalide`
            break
        case ZodIssueCode.invalid_union_discriminator:
            message = `Invalid discriminator value. Expected ${joinValues(issue.options)}`
            break
        case ZodIssueCode.invalid_enum_value:
            message = `Invalid enum value. Expected ${joinValues(issue.options)}, received '${issue.received}'`
            break
        case ZodIssueCode.invalid_arguments:
            message = `Invalid function arguments`
            break
        case ZodIssueCode.invalid_return_type:
            message = `Invalid function return type`
            break
        case ZodIssueCode.invalid_date:
            message = `Date invalide`
            break
        case ZodIssueCode.invalid_string:
            if (typeof issue.validation === 'object') {
                if ('startsWith' in issue.validation) {
                    message = `Encodage invalide: doit commencer par "${issue.validation.startsWith}"`
                } else if ('endsWith' in issue.validation) {
                    message = `Encodage invalide: doit finir par "${issue.validation.endsWith}"`
                } else {
                    assertNever(issue.validation)
                }
            } else if (issue.validation !== 'regex') {
                message = `Invalide ${issue.validation}`
            } else {
                message = 'Invalide'
            }
            break
        case ZodIssueCode.too_small:
            if (issue.type === 'array')
                message = `La liste doit contenir ${issue.inclusive ? `au moins` : `plus de`} ${
                    issue.minimum
                } élément(s)`
            else if (issue.type === 'string')
                message = `La chaîne de caractères doit contenir ${issue.inclusive ? `au moins` : `plus que`} ${
                    issue.minimum
                } caractère(s)`
            else if (issue.type === 'number')
                message = `Le nombre doit être plus grand ${issue.inclusive ? `ou égal à ` : `que `}${issue.minimum}`
            else if (issue.type === 'date')
                message = `La date doit être plus grande ${issue.inclusive ? `ou égal à ` : `que `}${new Date(
                    issue.minimum as number
                )}`
            else message = 'Encodage invalide'
            break
        case ZodIssueCode.too_big:
            if (issue.type === 'array')
                message = `La liste doit contenir ${issue.inclusive ? `au plus` : `moins de`} ${
                    issue.maximum
                } élément(s)`
            else if (issue.type === 'string')
                message = `La chaîne de caractères doit contenir ${issue.inclusive ? `au plus` : `moins de`} ${
                    issue.maximum
                } caractère(s)`
            else if (issue.type === 'number')
                message = `Le nombre doit être inférieur ${issue.inclusive ? `ou égal ` : ``}à ${issue.maximum}`
            else if (issue.type === 'date')
                message = `La date doit être inférieure ${issue.inclusive ? `ou égal ` : ``}à ${new Date(
                    issue.maximum as number
                )}`
            else message = 'Encodage invalide'
            break
        case ZodIssueCode.custom:
            message = `Encodage invalide`
            break
        case ZodIssueCode.invalid_intersection_types:
            message = `Intersection results could not be merged`
            break
        case ZodIssueCode.not_multiple_of:
            message = `Le nombre doit être un multiple de ${issue.multipleOf}`
            break
        default:
            message = _ctx.defaultError
            assertNever(issue)
    }

    return { message }
}

z.setErrorMap(frErrorMap)
