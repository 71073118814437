import { FC } from 'react'
import SmallLoader from './shared/small-loader'

const Loader: FC = () => {
    return (
        <div className="flex h-full min-h-screen w-full flex-col items-center justify-center bg-gradient-to-b from-white to-athens-gray">
            <SmallLoader />
            <p className="mb-8 text-center text-sm text-regent-gray">Chargement en cours.</p>
        </div>
    )
}

export default Loader
