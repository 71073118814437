import type { ReactNode } from 'react'
import ContentPage from '../dataroom/content-page'
import Panel from '../shared/panel'
import SmallLoader from '../shared/small-loader'

interface Props {
    children?: ReactNode
    header?: ReactNode
    title: string
    isLoading?: boolean
}

const Admin = ({ children, title, isLoading = false, header }: Props) => {
    return (
        <ContentPage>
            <Panel
                size="full"
                className="mb-8 justify-center"
                innerClassName="pt-4 bg-white flex flex-col items-center mt-12"
            >
                <div className="mb-4 flex w-full justify-between">
                    <h1 className="text-2xl font-black">{title}</h1>
                    {header}
                </div>
                <div className="relative w-full">
                    {isLoading && (
                        <div className="absolute inset-0 z-10 flex items-center justify-center bg-black/20 backdrop-blur-sm">
                            <SmallLoader />
                        </div>
                    )}
                    {children}
                </div>
            </Panel>
        </ContentPage>
    )
}

export default Admin
