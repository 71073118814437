import { toJS, values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Folder } from '../../assets/icons/icon-folder.svg'
import type { SpaceDirectory } from '../../stores/files/directory'
import type { ClientCustomField, Space } from '../../stores/files/space'
import { useMst } from '../../stores/store'
import Modal from '../shared/modal'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { trim } from 'validator'
import SmallLoader from 'components/shared/small-loader'
import { get, post } from 'core/services/http-service'

interface Props {
    isOpen: boolean
    space?: Space
    parent?: SpaceDirectory
    userDirectory?: boolean
    onClose: () => void
    onDirectoryCreated?: (space: Space | undefined, parent: SpaceDirectory | undefined) => void
    onDirectoryUpdated?: (space: Space | undefined, parent: SpaceDirectory | undefined) => void
    clientCustomFields?: ClientCustomField[]
    brandConfig?: Record<string, unknown>
    editedDirectory?: SpaceDirectory
    updateContext?: boolean
}

const DirectoryModal = observer(
    ({
        isOpen,
        onClose,
        onDirectoryCreated,
        onDirectoryUpdated,
        space: choosenSpace,
        parent: choosenParent,
        userDirectory = false,
        clientCustomFields,
        brandConfig,
        editedDirectory,
        updateContext = false,
    }: Props) => {
        const { t } = useTranslation()
        const { files } = useMst()

        const [space, setSpace] = useState<string>('')
        const [parent, setParent] = useState<string>('')
        const [isSaving, setSaving] = useState<boolean>(false)
        const [isLoading, setLoading] = useState<boolean>(false)

        let schema: z.AnyZodObject = z.object({
            name: z.string().trim(),
            description: z.string().trim().optional(),
        })

        if (clientCustomFields && userDirectory) {
            for (const field of clientCustomFields) {
                schema = schema.extend({
                    [field.key]:
                        field.type === 'email'
                            ? z.string().email().or(z.literal(''))
                            : field.required
                              ? z.string()
                              : z.string().optional(),
                })
            }
        }

        type Schema = z.infer<typeof schema> & { name: string; description: string }

        const methods = useForm<Schema>({ resolver: zodResolver(schema) })
        const { handleSubmit, register, reset, setValue } = methods

        useEffect(() => {
            if (editedDirectory) {
                setValue('name', editedDirectory.name)
                setValue('description', editedDirectory.description)

                if (editedDirectory.clientCustomValues) {
                    for (const { key, value } of editedDirectory.clientCustomValues) {
                        setValue(key, value)
                    }
                }
            }
        }, [editedDirectory])

        const onSubmit = async (data: Schema) => {
            const { name, description, ...clientCustomValues } = data

            setSaving(true)

            if (updateContext) {
                await files.updateDirectory(
                    editedDirectory.uuid,
                    name,
                    description,
                    space,
                    parent,
                    userDirectory,
                    clientCustomValues,
                    brandConfig
                )
            } else {
                await files.createDirectory(
                    name,
                    description,
                    space,
                    parent,
                    userDirectory,
                    clientCustomValues,
                    brandConfig
                )
            }

            setSaving(false)

            reset()
            onClose()

            if (updateContext) {
                onDirectoryUpdated?.(choosenSpace, choosenParent)
            } else {
                onDirectoryCreated?.(choosenSpace, choosenParent)
            }
        }

        useEffect(() => {
            if (isOpen) {
                setSpace(choosenSpace ? choosenSpace.uuid : '')
                setParent(choosenParent ? choosenParent.uuid : '')
            }
        }, [isOpen, choosenParent, choosenSpace])

        return (
            <Modal
                isOpen={isOpen}
                size="1/3"
                title={t(
                    userDirectory
                        ? brandConfig?.client_label
                            ? `web_${updateContext ? 'update' : 'create'}_user_directory_custom`
                            : 'web_create_user_directory'
                        : updateContext
                          ? 'web_update_directory'
                          : 'web_create_new_directory',
                    brandConfig?.client_label ? { client_label: brandConfig?.client_label } : undefined
                )}
                onRequestClose={() => onClose()}
            >
                {isLoading ? (
                    <SmallLoader />
                ) : (
                    <>
                        {parent === '' && (
                            <>
                                <span className="">{t('web_create_directory_choose_space')}</span>
                                <ul>
                                    {values<Space>(files.spaces).map(s => {
                                        if (s.isStack || s.isQuarantine) {
                                            return null
                                        }

                                        return (
                                            <li key={s.uuid}>
                                                <label className="flex items-center">
                                                    <input
                                                        type="radio"
                                                        name="space"
                                                        className="mr-2 text-christine"
                                                        value={s.uuid}
                                                        checked={s.uuid === space}
                                                        onChange={() => setSpace(s.uuid)}
                                                    />
                                                    <Folder
                                                        className="mr-2 w-6 flex-none fill-current"
                                                        style={{ color: s.color }}
                                                    />
                                                    <span className={`${s.uuid === space ? 'font-bold' : ''}`}>
                                                        {s.displayName}
                                                    </span>
                                                </label>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </>
                        )}

                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-center w-full">
                                <span className="">{t('web_create_directory_informations')}</span>
                                <div className="mb-4 mt-2 w-3/4">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        required
                                        className="border p-2 w-full"
                                        placeholder={`${t(
                                            userDirectory
                                                ? brandConfig?.client_label
                                                    ? 'web_create_user_directory_name_custom'
                                                    : 'web_create_user_directory_name'
                                                : 'web_create_directory_name',
                                            brandConfig?.client_label
                                                ? { client_label: brandConfig.client_label }
                                                : undefined
                                        )} *`}
                                        {...register('name')}
                                    />
                                    <p className="text-base leading-5 mt-1">
                                        {t(
                                            updateContext
                                                ? 'web_update_directory_name_help'
                                                : 'web_create_directory_name_help'
                                        )}
                                    </p>
                                </div>

                                <input
                                    autoComplete="off"
                                    type="text"
                                    className="mb-4 mt-2 w-3/4 p-2"
                                    required={userDirectory}
                                    placeholder={
                                        t(
                                            userDirectory
                                                ? brandConfig?.client_label
                                                    ? 'web_create_user_directory_description_custom'
                                                    : 'web_create_user_directory_description'
                                                : 'web_create_directory_description',
                                            brandConfig?.client_label
                                                ? { client_label: brandConfig.client_label }
                                                : undefined
                                        ) + (userDirectory ? ' *' : '')
                                    }
                                    {...register('description')}
                                />

                                {userDirectory &&
                                    clientCustomFields?.length > 0 &&
                                    clientCustomFields.map(field => (
                                        <div key={field.key} className="mb-4 mt-2 w-3/4">
                                            {field.type === 'textarea' ? (
                                                <textarea
                                                    className="p-2 w-full"
                                                    placeholder={t(field.translationKey) + (field.required ? ' *' : '')}
                                                    required={field.required}
                                                    rows={3}
                                                    {...register(field.key, { value: field.defaultValue })}
                                                />
                                            ) : (
                                                <input
                                                    autoComplete="off"
                                                    type={field.type}
                                                    className="border p-2 w-full"
                                                    placeholder={t(field.translationKey) + (field.required ? ' *' : '')}
                                                    required={field.required}
                                                    {...register(
                                                        field.key,
                                                        field.type === 'number'
                                                            ? { valueAsNumber: true, value: field.defaultValue }
                                                            : { value: field.defaultValue }
                                                    )}
                                                />
                                            )}
                                            {field.help && <p className="text-base leading-5 mt-1">{t(field.help)}</p>}
                                        </div>
                                    ))}

                                <div className="mb-2 mt-6 flex w-full justify-around">
                                    <button type="button" className="btn white px-8" onClick={() => onClose()}>
                                        {t('web_create_space_cancel')}
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn px-8"
                                        disabled={space === null || isSaving}
                                        //onClick={() => onValidate()}
                                    >
                                        {t('web_create_space_confirm')}
                                    </button>
                                </div>
                            </form>
                        </FormProvider>
                    </>
                )}
            </Modal>
        )
    }
)

export default DirectoryModal
