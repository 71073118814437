interface IconFranchiseProps {
    className?: string
}

const IconFranchise = ({ className }: IconFranchiseProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className={className} fill="currentColor">
            <path d="M104 0C117.3 0 128 10.75 128 24V64H176V24C176 10.75 186.7 0 200 0C213.3 0 224 10.75 224 24V112H72C58.75 112 48 122.7 48 136V440C48 453.3 58.75 464 72 464H224C224 482 229.1 498.6 239.1 512H72C32.24 512 0 479.8 0 440V136C0 96.24 32.24 64 72 64H80V24C80 10.75 90.75 0 104 0zM352 304C352 295.2 359.2 288 368 288H400C408.8 288 416 295.2 416 304V336C416 344.8 408.8 352 400 352H368C359.2 352 352 344.8 352 336V304zM400 96C408.8 96 416 103.2 416 112V144C416 152.8 408.8 160 400 160H368C359.2 160 352 152.8 352 144V112C352 103.2 359.2 96 368 96H400zM352 208C352 199.2 359.2 192 368 192H400C408.8 192 416 199.2 416 208V240C416 248.8 408.8 256 400 256H368C359.2 256 352 248.8 352 240V208zM496 416C487.2 416 480 408.8 480 400V368C480 359.2 487.2 352 496 352H528C536.8 352 544 359.2 544 368V400C544 408.8 536.8 416 528 416H496zM480 272C480 263.2 487.2 256 496 256H528C536.8 256 544 263.2 544 272V304C544 312.8 536.8 320 528 320H496C487.2 320 480 312.8 480 304V272zM512 160H576C611.3 160 640 188.7 640 224V448C640 483.3 611.3 512 576 512H320C284.7 512 256 483.3 256 448V64C256 28.65 284.7 0 320 0H448C483.3 0 512 28.65 512 64V160zM464 64C464 55.16 456.8 48 448 48H320C311.2 48 304 55.16 304 64V448C304 456.8 311.2 464 320 464H576C584.8 464 592 456.8 592 448V224C592 215.2 584.8 208 576 208H464V64zM112 320C103.2 320 96 312.8 96 304V272C96 263.2 103.2 256 112 256H144C152.8 256 160 263.2 160 272V304C160 312.8 152.8 320 144 320H112zM96 368C96 359.2 103.2 352 112 352H144C152.8 352 160 359.2 160 368V400C160 408.8 152.8 416 144 416H112C103.2 416 96 408.8 96 400V368zM112 224C103.2 224 96 216.8 96 208V176C96 167.2 103.2 160 112 160H144C152.8 160 160 167.2 160 176V208C160 216.8 152.8 224 144 224H112z" />
        </svg>
    )
}

export default IconFranchise
