import { trigger } from 'core/events'
import { toJS, values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as UploadImage } from '../../assets/upload.svg'
import { SpaceFile } from '../../stores/files/file'
import { Space } from '../../stores/files/space'
import { useMst } from '../../stores/store'
import Panel from '../shared/panel'
import Tooltip from '../shared/tooltip'
import DirectoryIcon from './spaceroom/directory-icon'
import DownloadLink from './spaceroom/download-link'
import FileIcon from './upload/file-icon'

interface ThumbProps {
    file: SpaceFile
}

const Thumb = ({ file }: ThumbProps) => {
    const [hasError, setHasError] = useState<boolean>(false)

    return (
        <DownloadLink href={file.downloadUrl}>
            <div className="flex h-full flex-col items-center justify-around">
                {file.hasImage && !hasError && (
                    <img
                        src={file.imageRepresentation(60)}
                        alt={file.name}
                        className="m-auto h-16 shadow-md"
                        loading="lazy"
                        onError={() => setHasError(true)}
                    />
                )}
                {(!file.hasImage || hasError) && <FileIcon extension={file.extension} className="m-auto h-16 w-auto" />}
                <span className="text-thunder w-full truncate text-center">{file.name}</span>
            </div>
        </DownloadLink>
    )
}

const StackViewer = observer(({ kind, space }: { kind: string; space: Space }) => {
    const { t } = useTranslation()
    const { files } = useMst()
    const navigate = useNavigate()

    useEffect(() => {
        const load = async () => {
            if (!space.isLoaded) {
                await space.refresh()
            }
        }
        load()
    }, [kind, files])

    if (!space) {
        return null
    }

    return (
        <Panel className="mb-8">
            <div className="flex justify-between">
                <span className="text-lg">
                    <Link className="text-black" to={`/dataroom/spaces/${space.uuid}`}>
                        {kind === 'stack' ? (
                            <>
                                <Trans i18nKey="web_dataroom_the_stack"></Trans> /{' '}
                                <strong>
                                    <Trans
                                        i18nKey="web_dataroom_the_stack_files"
                                        values={{ count: space.files.length }}
                                    />
                                </strong>
                            </>
                        ) : (
                            <>{space.name}</>
                        )}
                    </Link>
                </span>
                <div className="flex space-x-2">
                    {files.canUpload && (
                        <span
                            className="text-atomic-tangerine cursor-pointer font-bold"
                            onClick={() => trigger('uploader:trigger')}>
                            {t('web_dataroom_the_stack_add_files')}
                        </span>
                    )}
                    <Tooltip tooltip={t('web_tooltips_dataroom_show_stack')}>
                        <Link
                            to={`/dataroom/spaces/${space.uuid}`}
                            className="text-atomic-tangerine cursor-pointer font-bold">
                            {t('web_dataroom_the_stack_show_all')}
                        </Link>
                    </Tooltip>
                </div>
            </div>
            {space.files.length === 0 && space.directories.length === 0 ? (
                <div
                    className="m-2 flex h-32 w-full flex-none cursor-pointer flex-col items-center p-2"
                    data-drop-type="space"
                    data-drop-uuid={space.uuid}
                    onClick={() => trigger('uploader:trigger')}>
                    <UploadImage />
                    <h2 className="text-xl font-bold">{t('web_dataroom_upload_drag_drop')}</h2>
                    <p className="text-sm">{t('web_dataroom_upload_add_files')}</p>
                </div>
            ) : (
                <div
                    className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-6"
                    data-drop-type="space"
                    data-drop-uuid={space.uuid}>
                    {space.files.slice(0, 6).map(file => (
                        <div key={file.uuid} className="m-2 h-32 w-full flex-none p-2 shadow-md">
                            <Thumb file={file} />
                        </div>
                    ))}
                    {space.directories.slice(0, 6).map(dir => (
                        <div key={dir.uuid} className="m-2 h-32 w-full flex-none p-2 shadow-md">
                            <div className="flex h-full flex-col items-center justify-around">
                                <DirectoryIcon
                                    color={space.color}
                                    onClick={() => navigate(`/dataroom/spaces/${space.uuid}`)}
                                    size="h-20 w-20"
                                    className="ml-2"
                                />
                                {dir.name}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </Panel>
    )
})

export default StackViewer
