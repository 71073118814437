import type { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    label: string
    icon: ReactNode
    onClick: () => void
    tParameters?: Record<string, string>
}

const FloatingBarButton: FC<Props> = ({ label, icon, onClick, tParameters }) => {
    const { t } = useTranslation()

    return (
        <button
            type="button"
            onClick={onClick}
            className="flex cursor-pointer flex-col items-center justify-center text-regent-gray "
        >
            {icon}
            <span className="mt-1 text-sm">{t(label, tParameters)}</span>
        </button>
    )
}

export default FloatingBarButton
