import { zodResolver } from '@hookform/resolvers/zod'
import localeFr from 'air-datepicker/locale/fr'
import DatePicker from 'components/admin/date-picker'
import { FormProps, optionValueSchema } from 'components/admin/forms'
import Label from 'components/admin/label'
import { Input, selectStyles } from 'components/shared/select-styles'
import Select from 'react-select'
import 'components/admin/zod'
import { getTypes } from 'components/admin/users/form'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { useMst } from 'stores/store'
import { z } from 'zod'

const schema = z.object({
    fullname: z.string().min(1),
    email: z.string().email(),
    secondaryEmail: z.union([z.literal(''), z.string().email()]),
    phone: z.string().optional(),
    birthdate: z.coerce.date().optional(),
    type: optionValueSchema,
    tags: z.array(optionValueSchema).optional(),
})
export type Schema = z.infer<typeof schema>

const Form = observer(({ onSubmit, values, partnerId }: FormProps<Schema> & { partnerId: string }) => {
    const { t } = useTranslation()
    const { partners } = useMst()

    const types = getTypes(t)

    const currentLocale = localeFr

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })

    useEffect(() => {
        if (!values) {
            return
        }

        for (const key of Object.keys(values) as (keyof Schema)[]) {
            setValue(key, values[key])
        }
    }, [values])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Label
                name="fullname"
                error={errors['fullname'] && `${t('web_admin_user_fullname')} ${errors['fullname']?.message}`}
                label={'web_admin_user_fullname'}
                className="my-5 flex w-full flex-wrap items-center"
                required>
                <input {...register('fullname')} type="text" className="w-1/3" />
            </Label>

            <Label
                name="email"
                error={errors['email'] && `${t('web_admin_user_email')} ${errors['email']?.message}`}
                label={'web_admin_user_email'}
                className="my-5 flex w-full flex-wrap items-center"
                required>
                <input {...register('email')} type="text" className="w-1/3" required />
            </Label>
            <Label
                name="secondaryEmail"
                error={
                    errors['secondaryEmail'] &&
                    `${t('web_admin_user_secondary_email')} ${errors['secondaryEmail']?.message}`
                }
                label={'web_admin_user_secondary_email'}
                className="my-5 flex w-full flex-wrap items-center">
                <input {...register('secondaryEmail')} type="text" className="w-1/3" />
            </Label>
            <Label
                name="type"
                error={errors['type'] && `${t('web_admin_user_person_type')} ${errors['type']?.message}`}
                label={'web_admin_user_person_type'}
                className="my-5 flex w-full flex-wrap items-center leading-tight"
                required={true}>
                <Controller
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                options={types}
                                onChange={type => onChange(type)}
                            />
                        )
                    }}
                />
            </Label>
            <Label
                name="phone"
                error={errors['phone'] && `${t('web_admin_user_phone')} ${errors['phone']?.message}`}
                label={'web_admin_user_phone'}
                className="my-5 flex w-full flex-wrap items-center">
                <input {...register('phone')} type="text" className="w-1/3" />
            </Label>

            <Label
                name="birthdate"
                error={errors['birthdate'] && `${t('web_admin_user_birthdate')} ${errors['birthdate']?.message}`}
                label={'web_admin_user_birthdate'}
                className="my-5 flex w-full flex-wrap items-center leading-tight">
                <Controller
                    name="birthdate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                        return <DatePicker {...field} locale={currentLocale} />
                    }}
                />
            </Label>
            <Label
                name="tags"
                error={errors['tags'] && `${t('web_partner_invite_tag')} ${errors['tags']?.message}`}
                label={'web_partner_invite_tag'}
                className="my-5 flex w-full flex-wrap items-center">
                <Controller
                    name="tags"
                    control={control}
                    render={({ field }) => (
                        <AsyncCreatableSelect
                            {...field}
                            className="w-1/3"
                            value={field.value}
                            defaultOptions
                            loadOptions={async (inputValue: string) => {
                                const tags = await partners.loadTags(partnerId, inputValue)

                                return tags.map(tag => ({
                                    value: tag.uuid,
                                    label: tag.name,
                                }))
                            }}
                            isClearable
                            isMulti
                            onCreateOption={async (inputValue: string) => {
                                const tag = await partners.createTag(partnerId, inputValue)
                                field.onChange([...field.value, { value: tag.uuid, label: tag.name }])
                            }}
                        />
                    )}
                />
            </Label>

            <input type="submit" className="btn float-right cursor-pointer" value={`${t('web_admin_user_save')}`} />
        </form>
    )
})

export default Form
