import { observable } from 'mobx'
import { flow, getRoot, Instance, types } from 'mobx-state-tree'
import { get } from '../core/services/http-service'
import { RootInstance } from './store'
import { _Connector, Connector } from './telecollecte/connector'

export const _Telecollecte = types
    .model('Telecollecte', {
        connectors: types.array(_Connector),
        hiddens: types.array(_Connector),
        canAddConnectors: false,
        types: types.array(types.string),
    })
    .volatile<{ addConnectorUrl: string; synced: boolean }>(() => ({
        synced: false,
        addConnectorUrl: '',
    }))
    .actions(self => ({
        addConnectorType: flow(function* (type: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const postData = { type }
            try {
                const {
                    data: { url },
                } = yield get<typeof postData, { data: { url: string } }>('/v1/web/add-connector', postData)
                self.addConnectorUrl = url
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        addConnector: flow(function* (type: string, connector: Connector) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = {
                    type,
                    connector: connector.uuid,
                }
                const {
                    data: { url },
                } = yield get<typeof postData, { data: { url: string } }>('/v1/web/add-connector/', postData)
                self.addConnectorUrl = url
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        manageConnector: flow(function* (connector: Connector | null = null) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = connector
                    ? {
                          connector: connector.uuid,
                      }
                    : {}
                const data = yield get<typeof postData, { data: { url: string } }>('/v1/web/manage-connector', postData)
                const {
                    data: { url },
                } = data
                self.addConnectorUrl = url
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        syncConnectors: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {}
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const {
                    data: { connectors, canAddConnectors },
                } = yield get<void, JsonReturn>('/v1/web/sync-connector')
                self.connectors = connectors
                self.canAddConnectors = canAddConnectors
                self.synced = true
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        getTypes: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {}
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const {
                    data: { types },
                } = yield get<void, JsonReturn>('/v1/web/connectors-types')
                self.types = types
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        listConnectors: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {}
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const {
                    data: { connectors },
                } = yield get<void, JsonReturn>('/v1/web/connectors')
                self.connectors = connectors
                self.synced = true
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        getDocuments: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                yield get<void, { data: unknown }>('/v1/web/get-documents')
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))
    .extend(self => {
        const localState = observable.box({})

        return {
            views: {
                get(type: string): Connector[] {
                    const value = localState.get()

                    return value[type] || []
                },
            },
            actions: {
                findHiddenConnectors: flow(function* () {
                    const root = getRoot(self) as RootInstance
                    root.error.clean()

                    interface JsonReturnContent {}
                    interface JsonReturn {
                        data: JsonReturnContent
                    }
                    const getData = { hidden: true }
                    try {
                        const {
                            data: { connectors, canAddConnectors },
                        } = yield get<typeof getData, JsonReturn>('/v1/web/list-connectors', getData)

                        self.hiddens = connectors
                        self.canAddConnectors = canAddConnectors
                    } catch (err) {
                        root.error.prepare(err)
                    }
                }),

                findConnectorsByType: flow(function* (type: string) {
                    const root = getRoot(self) as RootInstance
                    root.error.clean()

                    interface JsonReturnContent {}
                    interface JsonReturn {
                        data: JsonReturnContent
                    }
                    const getData = { type }

                    try {
                        const {
                            data: { connectors, canAddConnectors },
                        } = yield get<typeof getData, JsonReturn>('/v1/web/list-connectors', getData)

                        const value = localState.get()
                        value[type] = connectors.sort((a, b) => {
                            return a.name.localeCompare(b.name)
                        })
                        localState.set(value)

                        self.canAddConnectors = canAddConnectors
                    } catch (err) {
                        root.error.prepare(err)
                    }
                }),
            },
        }
    })

export interface Telecollecte extends Instance<typeof _Telecollecte> {}
