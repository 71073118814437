import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, Navigate } from 'react-router-dom'
import loader from '../../assets/loader.svg'
import zen from '../../assets/login/register-zen.svg'
import validated from '../../assets/login/validated.svg'
import { useQuery } from '../../core/use-query'
import toast from '../../core/utils/toast'
import { useMst } from '../../stores/store'
import TwoPart from './two-part'

const VerifyEmail: FC = observer(() => {
    const [isVerified, setVerified] = useState<boolean>(false)
    const { token, user } = useMst()
    const isLogged = token !== ''
    const emailToken = useQuery().get('token')
    const [valid, setValid] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const { t } = useTranslation()

    const maxPages = 5
    const page = 3

    useEffect(() => {
        const verify = async () => {
            if (emailToken === null || isVerified) {
                return
            }

            await user.fetchVerifyEmailToken(emailToken)

            setValid(user.checked)

            setVerified(true)
            setLoading(false)
        }
        verify()
    }, [emailToken, user, isVerified])

    if (!isLogged) {
        return <Navigate to="/login" />
    }

    if (emailToken === null) {
        toast('error', 'invalid_token')

        return <Navigate to="/dataroom" />
    }

    return (
        <TwoPart image={zen} page={page} maxPages={maxPages}>
            <h3 className="my-8 text-2xl font-bold md:text-3xl">{t('web_verify_email_title')}</h3>
            <p className="mb-8 text-center">
                <Trans i18nKey="web_verify_email_welcome">web_verify_email_welcome</Trans>
            </p>

            {loading && (
                <>
                    <div className="pulse-button mb-8" style={{ backgroundImage: `url(${loader})` }}></div>
                    <p className="mb-8 text-center text-sm text-regent-gray">{t('web_verify_email_verifying')}</p>
                </>
            )}

            {!loading && valid && (
                <>
                    <div className="pulse-button no-pulse mb-8" style={{ backgroundImage: `url(${validated})` }}></div>
                    <p className="mb-8 text-center text-sm text-regent-gray">{t('web_verify_email_verified')}</p>
                    <p className="my-4 text-center">
                        <Link to="/dataroom" className="btn px-8">
                            {t('web_verify_email_continue')}
                        </Link>
                    </p>
                </>
            )}

            {!loading && !valid && (
                <p className="my-4 text-center">
                    <Trans>{t('web_verify_email_verify_error')}</Trans>
                </p>
            )}
        </TwoPart>
    )
})

export default VerifyEmail
