import { clsx } from 'clsx'
import { ReactNode, useEffect, useState } from 'react'
import { SpaceDirectory } from 'stores/files/directory'
import { SpaceFile } from 'stores/files/file'
import { Space } from 'stores/files/space'

interface TrProps {
    fileOrDirectory: SpaceFile | SpaceDirectory | Space
    selected?: boolean
    canChangeSelection?: boolean
    onSelect?: (fileOrDirectory: SpaceFile | SpaceDirectory | Space) => void
    onDeselect?: (fileOrDirectory: SpaceFile | SpaceDirectory | Space) => void
    children?: ReactNode
    className?: string
}

export const Tr = (
    {
        fileOrDirectory,
        selected = false,
        canChangeSelection = true,
        onSelect,
        onDeselect,
        children,
        className = '',
    }: TrProps
) => {
    const [isOver, setOver] = useState<boolean>(false)
    const [checked, setChecked] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)

    const changeSelection = newValue => {
        newValue ? onSelect(fileOrDirectory) : onDeselect(fileOrDirectory)
        setChecked(newValue)
    }

    const toggleChecked = () => {
        const newValue = !checked
        changeSelection(newValue)
    }
    useEffect(() => {
        setChecked(selected)
        setDisabled(!canChangeSelection)
    }, [selected, canChangeSelection])

    return (
        <tr
            className={`mb-1 h-8 border-b border-mercury transition-all duration-300 ease-linear ${className}`}
            onMouseEnter={() => setOver(true)}
            onMouseLeave={() => setOver(false)}>
            {onSelect && onDeselect && (
                <td
                    className={clsx(
                        'w-10 border-l-2 border-christine text-center',
                        `  ${
                            isOver || checked
                                ? canChangeSelection
                                    ? 'border-r-0 bg-christine'
                                    : 'border-0 bg-regent-gray/40'
                                : !canChangeSelection
                                ? 'border-0 bg-regent-gray/40'
                                : ''
                        }`
                    )}>
                    <input
                        type="checkbox"
                        checked={checked}
                        disabled={disabled}
                        onChange={() => toggleChecked()}
                        className={`border-white text-christine ${
                            isOver || checked || !canChangeSelection ? 'visible' : 'invisible'
                        }`}
                    />
                </td>
            )}
            {children}
        </tr>
    )
}
