import { faCopy } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import toast from 'core/utils/toast'
import { useTranslation } from 'react-i18next'
import { ApiDoneTransferResponse } from '..'

export const LinkTransfer = ({ response }: { response: ApiDoneTransferResponse }) => {
    const { t } = useTranslation()

    const onCopy = async () => {
        try {
            await navigator.clipboard.writeText(response.url)
            toast(
                'success',
                t('web_file_transfer_link_copied'),
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                { position: 'top-center' }
            )
        } catch (e) {
            // ignore
        }
    }

    return (
        <>
            <div className="text-center">
                <h4 className="font-bold text-xl mb-2">{t('web_file_transfer_link_ready')}</h4>
                {response.secure && (
                    <p className="leading-5 font-nunito text-sm italic">{t('web_file_transfer_link_ready_secure')}</p>
                )}
            </div>
            <div className="w-full border border-regent-gray font-nunito text-regent-gray rounded-lg flex gap-2 justify-between overflow-hidden items-center px-2 bg-black-squeeze">
                <input
                    type="text"
                    readOnly
                    value={response.url}
                    onClick={e => e.currentTarget.select()}
                    className="cursor-pointer border-0 bg-transparent grow"
                />
                <button type="button" onClick={async () => await onCopy()}>
                    <FontAwesomeIcon icon={faCopy} className="text-lg" />
                </button>
            </div>
        </>
    )
}
