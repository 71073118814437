import { faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useDebounce from 'core/debounce'
import { observer } from 'mobx-react-lite'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useMst } from 'stores/store'
import { UserType } from 'stores/userType/user-type'
import Admin from '../admin'
import Pagination from '../pagination'

const UserLine: FC<{ userType: UserType }> = ({ userType }) => {
    const { userTypeStore } = useMst()
    const navigate = useNavigate()
    // const [enabled, setEnabled] = useState<boolean>(true)
    const [, setLoading] = useState<boolean>(false)

    const onUserTypeDelete = async (id: string) => {
        setLoading(true)
        await userTypeStore.userTypeDelete(id)
        await userTypeStore.load()
        setLoading(false)
        navigate('/admin/user-types')
    }

    useEffect(() => {})

    return (
        <tr>
            <td className="text-left">{userType.name}</td>
            <td className="text-left">{userType.parents ?? ''}</td>
            <td className="flex items-center space-x-1 text-christine">
                <Link to={`/admin/user-types/${userType.uuid}`}>
                    <FontAwesomeIcon icon={faEdit} />
                </Link>
                <FontAwesomeIcon icon={faTrash} onClick={() => onUserTypeDelete(userType.uuid)} />
            </td>
        </tr>
    )
}

const limit = 50

const Listing: FC = observer(() => {
    const { userTypeStore } = useMst()
    const { t } = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [offset, setOffset] = useState<number>(0)
    const [query] = useState<string>('')
    const [page, setPage] = useState<number>(0)
    const [pageCount] = useState<number>(0)

    const load = useCallback(async () => {
        setLoading(true)
        await userTypeStore.load()

        // setPageCount(userTypeStore.userTypes.length)

        setLoading(false)
    }, [offset, query])

    const changePage = async page => {
        setPage(page)
        setOffset(Math.ceil(page * limit))
    }

    // const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    //     if (e.key === 'Enter') {
    //         load()
    //     }
    // }

    const debouncedQuery = useDebounce<string>(query, 500)
    useEffect(() => {
        load()
    }, [page, debouncedQuery])

    useEffect(() => {
        setPage(0)
    }, [])

    return (
        <Admin
            title={t('web_admin_user_types')}
            isLoading={isLoading}
            header={
                <div className="flex items-center space-x-4">
                    <Link to="/admin/user-types/create" className="btn">
                        {t('web_admin_user_type_new')}
                    </Link>
                </div>
            }>
            <table className="admin w-full table-auto text-center">
                <thead>
                    <tr>
                        <th>{t('web_menu_admin_user_type_name')}</th>
                        <th>{t('web_menu_admin_user_type_parent')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {userTypeStore.userTypes.map(userType => (
                        <UserLine key={userType.uuid} userType={userType} />
                    ))}
                </tbody>
            </table>
            <div className="mt-8 flex w-full justify-center">
                {pageCount > 1 && <Pagination page={page} totalPages={pageCount} onChangePage={changePage} />}
            </div>
        </Admin>
    )
})

export default Listing
