import { useLocation } from 'react-router-dom'

export const useQuery = (): URLSearchParams => {
    return new URLSearchParams(useLocation().search)
}

export const getFromQuery = (key: string): string | undefined => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const value = useQuery().get(key)
    if (value) {
        return value
    }

    return undefined
}
