import { DateTime } from 'luxon'
import { Instance, types } from 'mobx-state-tree'

export const _Notification = types
    .model('Notification', {
        id: '',
        type: '',
        title: '',
        body: '',
        content: '',
        read: false,
        createdAt: 0,
    })
    .views(self => ({
        get date(): DateTime {
            return DateTime.fromMillis(self.createdAt)
        },

        get relativeDate(): string {
            return this.date.toRelative() ?? ''
        },

        get smartDate(): string {
            const { days } = DateTime.local().diff(this.date, 'days')
            if (days > 7) {
                return this.date.toLocaleString(DateTime.DATETIME_SHORT)
            }

            return this.relativeDate
        },
    }))

export interface Notification extends Instance<typeof _Notification> {}
