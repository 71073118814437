import { clsx } from 'clsx'

interface OcrProps {
    className?: string
}

const Ocr = ({ className }: OcrProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83.575 83.575" className={clsx('fill-current', className)}>
            <path d="M0 0v17.411h6.965V6.965h10.446V0Zm66.164 0v6.965h10.447v10.446h6.965V0Zm-13.93 20.894a14.355 14.355 0 0 0-12.406 7.182c-2.367 3.958-3.441 8.583-4.462 12.95-.163.694-.381 1.292-.544 1.959-.952-.394-1.524-.653-1.524-.653a3.519 3.519 0 0 0-2.285-.653 4.071 4.071 0 0 0-.435.109 3.484 3.484 0 0 0-1.2 6.42 20.592 20.592 0 0 0 3.482 1.632 15.533 15.533 0 0 1-.979 2.176c-1.442 2.585-2.571 3.7-5.768 3.7-1.156 0-2.068-1.524-2.068-1.524a3.504 3.504 0 1 0-6.312 3.047s2.571 5.441 8.379 5.441a13.535 13.535 0 0 0 11.867-7.18 28.114 28.114 0 0 0 1.741-3.918 56.418 56.418 0 0 0 7.835.653c.014.068-.014.15 0 .218a17.593 17.593 0 0 0 2.285 6.637 7.173 7.173 0 0 0 5.876 3.591 3.484 3.484 0 1 0 .218-6.965 3.6 3.6 0 0 1-.218-.326 12.292 12.292 0 0 1-1.306-4.135 38.834 38.834 0 0 1 1.2-15.562 3.484 3.484 0 1 0-6.747-1.741 48.966 48.966 0 0 0-1.634 11.318 46.181 46.181 0 0 1-5.55-.544c.177-.721.381-1.469.544-2.176 1.02-4.339 2.054-8.42 3.591-10.991s2.9-3.7 6.42-3.7a3.483 3.483 0 1 0 0-6.965ZM0 66.164v17.411h17.411v-6.964H6.965V66.164Zm76.611 0v10.447H66.164v6.965h17.411V66.164Z" />
        </svg>
    )
}

export default Ocr
