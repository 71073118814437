import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ChevronLeft } from '../../assets/arrow/arrow-left.svg'
import { ReactComponent as ChevronRight } from '../../assets/arrow/arrow-right.svg'
import { ReactComponent as Light } from '../../assets/dataroom/light.svg'
import useInterval from '../../core/use-interval'

interface Props {
    intervalDelay: number | null
}

export const TOTAL_TIPS = 21

const Tips: FC<Props> = ({ intervalDelay }) => {
    const { t } = useTranslation()
    const [currentTip, setCurrentTip] = useState<number>(0)
    const [tip, setTip] = useState<string>('')
    const [availableTips, setAvailableTips] = useState<string[]>([])

    const initTips = useCallback(() => {
        const tips: string[] = []
        for (let i = 0; i < TOTAL_TIPS; i++) {
            tips.push(`web_uploading_tip_${i}`)
        }

        return tips.sort(() => Math.random() - 0.5)
    }, [])

    const showTip = () => {
        let tips = availableTips
        if (tips.length === 0) {
            tips = initTips()
            setAvailableTips(tips)
        }

        const tip = tips[currentTip]
        setTip(tip)
    }

    const showPreviousTip = () => {
        let nextTip = currentTip - 1
        if (nextTip < 0) {
            nextTip = TOTAL_TIPS - 1
        }
        setCurrentTip(nextTip)
        showTip()
    }

    const showNextTip = () => {
        let nextTip = currentTip + 1
        if (nextTip >= TOTAL_TIPS) {
            nextTip = 0
        }
        setCurrentTip(nextTip)
        showTip()
    }

    useInterval(() => {
        showNextTip()
    }, intervalDelay)

    useEffect(() => {
        if (intervalDelay) {
            showNextTip()
        }
    }, [intervalDelay])

    return (
        <div className="mt-12 flex w-full items-center justify-center space-x-4 px-16">
            <div
                className={
                    'mr-3 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-white p-2 text-regent-gray shadow-lg'
                }
                onClick={() => showPreviousTip()}>
                <ChevronLeft className="h-2 w-2 fill-current" />
            </div>

            <div className="flex grow items-center justify-center space-x-4">
                <Light className="h-6 w-6 flex-none" />
                <span className="text-sm text-regent-gray">{t(tip)}</span>
            </div>

            <div
                className={
                    'ml-3 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-white p-2 text-regent-gray shadow-lg'
                }
                onClick={() => showNextTip()}>
                <ChevronRight className="h-2 w-2 fill-current" />
            </div>
        </div>
    )
}

export default Tips
