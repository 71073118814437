import { faChevronsUp, faSearch, faSpinner, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import Admin from 'components/admin/admin'
import { getFileSize } from 'components/dataroom/upload/file-size'
import { del, get } from 'core/services/http-service'
import toast from 'core/utils/toast'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import { useParams } from 'react-router'
import { useMst } from 'stores/store'

interface User {
    uuid: string
    email: string
    fullname: string
    firstname: string
    lastname: string
    locale: string
    enabled: boolean
    storage: {
        max: number
        current: number
        percent: number
        totalFiles: number
        theStackFiles: number
        sortedFilesCount: number
        sorted: number
        toSort: number
        preSortedCount: number
        preSorted: number
        quarantineCount: number
        quarantine: number
        sharedCount: number
        shared: number
        compressedCount: number
        compressed: number
    }
}

interface Data {
    users: User[]
    nextCursor?: number
}

const Users = () => {
    const { user } = useMst()
    const { t } = useTranslation()
    const { id } = useParams()

    const [search, setSearch] = useState<string>()
    const [gotoTopButton, showGotoTopButton] = useState<boolean>(false)

    useEffect(() => {
        const handleScroll = () => {
            showGotoTopButton(window.scrollY > 0)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const fetchUsers = async ({ pageParam = 0 }) => {
        const { data } = await get<{ cursor: number; q?: string }, { data: Data }>(`/v1/web/partners/${id}/users`, {
            cursor: pageParam,
            q: search,
        })

        return data
    }

    const queryClient = useQueryClient()

    const { data, fetchNextPage, hasNextPage, isFetching, refetch } = useInfiniteQuery({
        queryKey: ['partners.users'],
        queryFn: fetchUsers,
        initialPageParam: null,
        getNextPageParam: lastPage => lastPage.nextCursor,
    })

    useEffect(() => {
        queryClient.clear()
        refetch()
    }, [id])

    const [showDelete, setShowDelete] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        setShowDelete(user.isAdmin)
    }, [user])

    const deleteUser = async (user: User) => {
        setLoading(true)
        await del(`/v1/web/partners/${id}/users/${user.uuid}/destroy`)
        setLoading(false)
        refetch()
        toast('success', 'web_admin_partner_user_deleted')
    }

    return (
        <Admin
            title={t('web_admin_users')}
            isLoading={isFetching || loading}
            header={
                <div className="flex items-center space-x-4">
                    <div className="flex items-center gap-1 rounded border bg-white px-4 py-1">
                        <FontAwesomeIcon icon={faSearch} size="sm" className="text-gray-500" />
                        <input
                            type="text"
                            className="m-0 grow border-none bg-transparent p-0 text-sm focus:border-none focus:outline-none"
                            placeholder={t('web_dashboard_search')}
                            onChange={e => setSearch(e.currentTarget.value)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    refetch()
                                }
                            }}
                        />
                    </div>
                </div>
            }
        >
            {(data?.pages ?? []).flatMap(p => p.users).length > 0 ? (
                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => fetchNextPage()}
                    hasMore={hasNextPage}
                    loader={<FontAwesomeIcon icon={faSpinner} spin key={0} />}
                >
                    <table className="admin w-full table-auto">
                        <thead>
                            <tr>
                                <th className="text-left">{t('web_franchise_users_name')}</th>
                                <th className="text-left">{t('web_franchise_users_email')}</th>
                                <th className="text-left">{t('web_franchise_users_storage')}</th>
                                <th className="text-left">{t('web_franchise_users_enabled')}</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {(data?.pages ?? [])
                                .flatMap(p => p.users)
                                .filter((item, index, self) => self.findIndex(i => i.uuid === item.uuid) === index)
                                .map(item => {
                                    return (
                                        <tr key={item.uuid}>
                                            <td>{item.fullname}</td>
                                            <td>{item.email}</td>
                                            <td>{getFileSize(t, item.storage.current)}</td>
                                            <td>
                                                {item.enabled ? '✔︎' : '✘'}
                                                {showDelete && (
                                                    <button
                                                        type="button"
                                                        onClick={async () => {
                                                            if (confirm(t('web_admin_confirm_delete_partner_user'))) {
                                                                deleteUser(item)
                                                            }
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </InfiniteScroll>
            ) : (
                <p className="text-sm">
                    <Trans
                        i18nKey={search ? 'web_franchise_users_no_items_search' : 'web_franchise_users_no_items'}
                        components={[<strong key={0} />]}
                        values={{ search }}
                    />
                </p>
            )}
            {gotoTopButton && (
                <div
                    className="fixed bottom-5 right-5 cursor-pointer rounded bg-chathams-blue p-2 text-white"
                    onClick={() =>
                        window.scrollTo({
                            top: 0,
                        })
                    }
                >
                    <FontAwesomeIcon icon={faChevronsUp} fixedWidth />
                </div>
            )}
        </Admin>
    )
}

export default Users
