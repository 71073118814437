import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useMst } from '../../../stores/store'
import Admin from '../admin'
import Form, { Schema } from './form'

const Create = () => {
    const { admin, userTypeStore } = useMst()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)

    const onSubmit = async (data: Schema) => {
        const {
            fullname,
            email,
            secondaryEmail,
            phone,
            birthdate,
            role: { value: role },
            type: { value: type },
            partner,
            pricing,
        } = data
        setLoading(true)
        await admin.createUser(
            fullname,
            email,
            secondaryEmail,
            phone,
            birthdate,
            role,
            partner?.value ?? null,
            pricing.value,
            type
        )
        setLoading(false)
        navigate('/admin/users')
    }

    const load = async () => {
        setLoading(true)
        await admin.loadPartners(1000, 0)
        await admin.loadPricings(1000, 0)
        await userTypeStore.load(undefined, true)
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    return (
        <Admin title={t('web_menu_admin_user')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} />
        </Admin>
    )
}

export default Create
