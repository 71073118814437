import clsx from 'clsx'
import Tooltip from 'components/shared/tooltip'
import { IconSize } from './line-icon'
import LineUser, { KindOfUser } from './line-user'

const LineUsersImpl = ({ users, size }: { users: KindOfUser[]; size?: IconSize }) => {
    switch (users.length) {
        case 0:
            return null
        case 1:
            return <LineUser user={users[0]} zIndex={1} size={size} />
        case 2:
            return (
                <>
                    {users.map((user, index) => (
                        <LineUser key={user.uuid} user={user} zIndex={users.length - index} size={size} />
                    ))}
                </>
            )
        default: {
            const allUsers = [...users]
            const shownUsers = allUsers.splice(0, 2)

            return (
                <>
                    {shownUsers.map((user, index) => (
                        <LineUser key={user.uuid} user={user} zIndex={users.length - index} size={size} />
                    ))}
                    <div className="relative font-nunito text-xs font-bold text-thunder" style={{ zIndex: 1 }}>
                        <Tooltip
                            tooltip={
                                <ul>
                                    {allUsers.map((user, index) => (
                                        <li key={index}>{user.fullname}</li>
                                    ))}
                                </ul>
                            }>
                            <span className="ml-2">+{allUsers.length}</span>
                        </Tooltip>
                    </div>
                </>
            )
        }
    }
}

export default function LineUsers({ users, size = 'md' }: { users: KindOfUser[]; size?: IconSize }) {
    return (
        <div className={clsx('flex items-center justify-center', size === 'sm' ? '-space-x-2' : '-space-x-3')}>
            <LineUsersImpl users={users} size={size} />
        </div>
    )
}
