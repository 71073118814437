import SmallLoader from 'components/shared/small-loader'
import { getFromQuery } from 'core/use-query'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { Navigate } from 'react-router-dom'
import { CGU } from 'stores/cgu/cgu'
import { useMst } from 'stores/store'
import logo from '../../../assets/large-logo.svg'
import toast from '../../../core/utils/toast'
import Panel from '../../shared/panel'

interface Props {
    children: ReactNode
}

const NewCgu: FC<Props> = observer(({ children }) => {
    const { t } = useTranslation()
    const { cguStore, user } = useMst()
    const redirect = getFromQuery('redirect')

    const [cgu, setCgu] = useState<CGU>()
    const [loading, setLoading] = useState<boolean>(true)

    const load = async () => {
        setLoading(true)
        await cguStore.loadCguContent()
        setCgu(cguStore.cgu)
        setLoading(false)
    }

    useEffect(() => {
        if (user.hasNewCgu && !cgu) {
            load()
        }
    }, [user.hasNewCgu])

    const save = async () => {
        setLoading(true)
        user.agreeCgu(cgu.id)
            .then(() => {
                toast('success', 'api_new_cgu_version_agreed')
            })
            .catch(err => {
                toast('error', err)
            })
        setLoading(false)
    }

    if (!user.hasNewCgu) {
        if (user.id.length > 0 && redirect) {
            return <Navigate to={redirect} />
        }

        return <>{children}</>
    }

    return (
        <>
            <div className="flex h-full flex-col items-center justify-center bg-white">
                <div className="w-full bg-white lg:bg-gradient-to-b lg:from-white lg:to-athens-gray">
                    <div className="my-8 flex items-center justify-center">
                        <img src={logo} alt="Treasy" className="w-96" />
                    </div>
                </div>
                <Panel className="mb-8 p-8" size="1/2">
                    <div className="prose w-full max-w-full">
                        {loading ? (
                            <SmallLoader />
                        ) : (
                            <>
                                <ReactMarkdown>{cgu.value}</ReactMarkdown>
                                <div className="flex w-full justify-end">
                                    <button className="btn mt-2 cursor-pointer" onClick={() => save()}>
                                        {t('web_admin_cgu_agree')}
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </Panel>
            </div>
        </>
    )
})

export default NewCgu
