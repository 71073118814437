import { FC } from 'react'
import { components, InputProps } from 'react-select'
import { Option } from '../admin/forms'

export const selectStyles = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    control: (provided: Record<string, string | number>, state: unknown): Record<string, string | number> => ({
        ...provided,
        borderColor: '#D8E0E6',
        borderRadius: 0,
        padding: '0.20rem 0',
    }),
}

export const Input: FC = (props: InputProps<Option, false>) => {
    return <components.Input {...props} inputClassName="p-0" />
}
export const MultiInput: FC = (props: InputProps<Option[]>) => {
    return <components.Input {...props} inputClassName="p-0" />
}
