import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import img01 from '../../assets/todo/transmission/01.jpg'
import img02 from '../../assets/todo/transmission/02.jpg'
import img03 from '../../assets/todo/transmission/03.jpg'
import ContentPage from '../dataroom/content-page'
import Panel from '../shared/panel'
import Slide from '../shared/slide'

const Transmission: FC = () => {
    const { t } = useTranslation()

    const pages = [
        {
            content: (
                <>
                    <span className="italic text-atomic-tangerine">(bientôt disponible)</span>
                    <p className="mb-4">
                        Il y a, dans la vie, des situations où il est matériellement impossible de rester connecté pour
                        un certain temps à ses affaires, et à son patrimoine numérique.
                        <br />
                        Pour le meilleur : de longues vacances, un tour du monde ? D'excellentes raisons, préparées
                        d'avance, de ne pas rester connecté au monde administratif.
                        <br />
                        Comme pour le pire : une hospitalisation, un accident de la vie, un événement non planifiable
                        qui rend indisponible pour une durée inconnue...
                    </p>
                    <p className="mb-4">
                        Parce que vous êtes prévoyant, vous utilisez Treasy. Tous les documents et toutes les
                        informations nécessaires à la bonne tenue de votre vie y sont rassemblés, classés, protégés.
                        Quoi de plus naturel que de vous aider à organiser leur devenir en votre absence.
                    </p>
                    <p className="mb-4">Rien de plus simple, par la mise en place de directives avec Treasy.</p>
                    <p className="mb-4">
                        Créer des directives c'est décider. C'est décider du devenir de vos fichiers numériques, de leur
                        destruction, ou de leur transmission à ceux que vous souhaitez désigner comme vos bénéficiaires
                        de contenus.
                        <br />
                        C'est donc décider d'aider ses proches à gérer les conséquences de votre indisponibilité, en
                        ayant toutes les informations et toute la documentation pour le faire.
                        <br />
                        C'est décider de la personne de confiance qui veillera au respect de vos volontés concernant
                        votre patrimoine numérique.
                        <br />
                        Créer des directives c'est donc protéger, vos proches et votre vie numérique.
                    </p>
                    <p className="mb-4">
                        Pour concevoir une directive, Treasy vous guide pas à pas. A chaque étape, vous êtes invité à
                        faire des choix et/ou à compléter les informations nécessaires.
                    </p>
                </>
            ),
            image: img01,
        },
        {
            content: (
                <>
                    <p className="mb-4">
                        Puis, vous serez invité à laisser un ou plusieurs messages à vos bénéficiaires de contenus ou
                        personne de confiance
                    </p>
                </>
            ),
            image: img02,
        },
        {
            content: (
                <>
                    <p className="mb-4">
                        A l'issue de ces étapes, vous verrez apparaître une page intitulée "résumé pour validation".
                        (ajouter écran proto) Cet écran vous permettra de visualiser l'ensemble de vos préférences et
                        informations. Ainsi, vérifiez que tout est correct : ce que vous avez confié à Treasy doit être
                        le reflet de ce que vous voulez. Corrigez si besoin.
                    </p>
                    <p className="mb-4">
                        L'étape suivante est la validation de vos choix. Seule cette validation rend votre directive
                        valable (juridiquement), et opposable aux personnes qu'elle concerne. Treasy s'assure que vous
                        allez jusqu'au bout du processus et que votre directive aura une valeur. Il serait trop dommage
                        de faire tout cela pour rien...
                        <br />
                        Bien sûr, une directive validée chez Treasy reste modifiable dans le temps, aussi souvent que
                        vous le souhaiterez.{' '}
                    </p>
                </>
            ),
            image: img03,
        },
    ]

    return (
        <ContentPage title={t('web_link_transfer')}>
            <Panel>
                <Slide pages={pages} title={t('web_link_transfer')} page={t('web_todo_transfer')} />
            </Panel>
        </ContentPage>
    )
}

export default Transmission
