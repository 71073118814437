import { useTranslation } from 'react-i18next'
import { ReactComponent as IconMenu } from '../../assets/dataroom/icon-tri.svg'
import SlidingButtonImage from '../shared/sliding-button-image'

interface Props {
    opened: boolean
    onClicked: (value: boolean) => void
}

const ModelConfig = ({ opened, onClicked }: Props) => {
    const { t } = useTranslation()

    return (
        <SlidingButtonImage
            image={<IconMenu className="h-6 w-6 fill-current text-atomic-tangerine" />}
            showSeparator={false}
            isOpen={opened}
            onOpen={onClicked}
            tooltip={t('web_tooltips_choose_model')}
        >
            <span className="mr-4 cursor-pointer font-roboto font-bold text-regent-gray">
                {t('web_models_button_validate')}
            </span>
        </SlidingButtonImage>
    )
}

export default ModelConfig
