import { faEnvelope, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from 'components/shared/tooltip'
import { del } from 'core/services/http-service'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMst } from 'stores/store'
import { User } from 'stores/users'
import Admin from '../../admin'

const UserLine = ({
    user,
    showDelete,
    onDelete,
}: {
    user: User
    showDelete: boolean
    onDelete: (user: User) => void
}) => {
    const { t } = useTranslation()
    const { user: userStore } = useMst()

    return (
        <tr>
            <td className="text-left">{user.email}</td>
            <td className="text-left">{user.fullname}</td>
            <td className="flex items-center space-x-1 text-christine">
                <Tooltip tooltip={t('web_admin_user_send_recovery_tooltip')}>
                    <button
                        type="button"
                        onClick={async () => {
                            if (confirm(t('web_admin_user_send_recovery'))) {
                                try {
                                    await userStore.sendRecoveryEmail(user.id)
                                    toast.success(t('web_admin_user_recovery_sent'))
                                } catch (error) {
                                    toast.error(error.message ?? error)
                                }
                            }
                        }}>
                        <FontAwesomeIcon icon={faEnvelope} />
                    </button>
                </Tooltip>

                {showDelete && (
                    <button
                        type="button"
                        onClick={async () => {
                            if (confirm(t('web_admin_confirm_delete_brand_user'))) {
                                onDelete(user)
                            }
                        }}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                )}
            </td>
        </tr>
    )
}

const Listing = observer(() => {
    const { admin, user } = useMst()

    const { brandId } = useParams()

    const { t } = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(true)

    const load = async (brandId: string) => {
        setLoading(true)
        await admin.loadBrand(brandId)
        await admin.editingBrand.loadUsers()
        setLoading(false)
    }

    useEffect(() => {
        load(brandId)
    }, [brandId])

    const [showDelete, setShowDelete] = useState<boolean>(false)
    useEffect(() => {
        setShowDelete(user.isAdmin)
    }, [user])

    const deleteUser = async (user: User) => {
        setLoading(true)
        await del(`/v1/web/brands/${brandId}/users/${user.uuid}`)
        await load(brandId)
        toast.success(t('web_admin_brand_user_deleted'))
    }

    return (
        <Admin
            title={admin.editingBrand.name}
            isLoading={isLoading}
            header={
                <div className="flex items-center space-x-4">
                    <Link to={`/admin/brands/${brandId}/users/create`} className="btn">
                        {t('web_admin_users_new')}
                    </Link>
                </div>
            }>
            {admin.editingBrand.users.length === 0 ? (
                <p>{t('web_no_result')}</p>
            ) : (
                <table className="admin w-full table-auto text-center">
                    <thead>
                        <tr>
                            <th>{t('web_menu_admin_brands_name')}</th>
                            <th>{t('web_menu_admin_brands_email')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {admin.editingBrand.users.map(user => (
                            <UserLine
                                key={user.id}
                                user={user}
                                showDelete={showDelete}
                                onDelete={async user => await deleteUser(user)}
                            />
                        ))}
                    </tbody>
                </table>
            )}
        </Admin>
    )
})

export default Listing
