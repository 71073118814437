import { Fragment, ReactNode } from 'react'
import { SpaceDirectory } from 'stores/files/directory'
import { SpaceFile } from 'stores/files/file'
import { Space } from 'stores/files/space'

interface LineProps {
    fileOrDirectory?: SpaceFile | SpaceDirectory | Space
    children?: ReactNode
}

export const Line = ({ children }: LineProps) => {
    return <Fragment>{children}</Fragment>
}
