import LineIcon, { IconSize } from './line-icon'

export interface KindOfUser {
    uuid: string
    fullname: string
    picture: string
}

export default function LineUser({ user, zIndex, size }: { user: KindOfUser; zIndex: number; size?: IconSize }) {
    return <LineIcon tooltip={user.fullname} zIndex={zIndex} image={user.picture} size={size} />
}
