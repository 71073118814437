import { CSSProperties, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface RowProps {
    children?: ReactNode
    className?: string
    style?: CSSProperties
    highlight?: boolean

    dropType?: 'space' | 'directory'
    dropUuid?: string

    onMouseEnter?: () => void
    onMouseLeave?: () => void
}

export const Row = (
    { children, className, highlight, style, onMouseEnter, onMouseLeave, dropType, dropUuid }: RowProps
) => {
    return (
        <div
            className={twMerge(className, highlight && 'bg-christine/10')}
            style={style}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-drop-type={dropType}
            data-drop-uuid={dropUuid}>
            {children}
        </div>
    )
}
