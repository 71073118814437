import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMst } from 'stores/store'
import Admin from '../../admin/admin'
import MdEditor from '../../shared/md-editor'

const Cgu: FC = observer(() => {
    const { t } = useTranslation()
    const { cguStore } = useMst()

    const [cgu, setCgu] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)

    const load = async () => {
        setLoading(true)
        await cguStore.loadCguContent()
        setCgu(cguStore.cgu.value)
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    const save = async () => {
        setLoading(true)
        await cguStore.saveNewCguVersion(cgu)
        setLoading(false)
    }

    return (
        <Admin isLoading={loading} title={t('web_admin_cgu')}>
            <MdEditor onChange={value => setCgu(value)} value={cgu} />
            <button className="btn float-right mt-4 cursor-pointer" onClick={() => save()}>
                {t('web_admin_cgu_save')}
            </button>
        </Admin>
    )
})

export default Cgu
