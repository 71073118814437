import { zodResolver } from '@hookform/resolvers/zod'
import Loader from 'components/loader'
import { post } from 'core/services/http-service'
import { getFromQuery } from 'core/use-query'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { z } from 'zod'

const Form = observer(() => {
    const { t } = useTranslation()

    const client_id = getFromQuery('client_id') // treasy_zapier
    const redirect_uri = getFromQuery('redirect_uri') // ?
    const grant_type = getFromQuery('grant_type') // authorization_code
    const response_type = getFromQuery('response_type') // code
    const state = getFromQuery('state') // random string

    const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const querySchema = z.object({
        client_id: z.string(),
        redirect_uri: z.string().url(),
        grant_type: z.string(),
        response_type: z.string(),
        state: z.string(),
    })

    const bodySchema = z.object({
        email: z.string().email(),
        password: z.string(),
        client_id: z.string(),
        redirect_uri: z.string().url(),
        grant_type: z.string(),
        response_type: z.string(),
        state: z.string(),
    })
    type FormData = z.infer<typeof bodySchema>

    useEffect(() => {
        setIsLoading(true)

        const queryResult = querySchema.safeParse({
            client_id,
            redirect_uri,
            grant_type,
            response_type,
            state,
        })

        if (!queryResult.success) {
            setIsLoading(false)

            return
        }

        setValue('client_id', client_id)
        setValue('redirect_uri', redirect_uri)
        setValue('grant_type', grant_type)
        setValue('response_type', response_type)
        setValue('state', state)

        setIsFormDisplayed(true)
        setIsLoading(false)
    }, [client_id, redirect_uri, grant_type, response_type, state, errorDisplayed])

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: zodResolver(bodySchema),
    })

    useEffect(() => {
        const allErrors = []

        for (const [field, { message }] of Object.entries(errors)) {
            allErrors.push(message)
        }

        if (allErrors.length > 0) {
            setErrorMessage(allErrors.join(', '))
        }
    }, [errors])

    const onSubmit = async (data: FormData) => {
        if (isLoading) {
            return false
        }

        try {
            setIsLoading(true)
            const { redirect_to } = await post<FormData, { redirect_to: string }>('/oauth/authorize', data)
            window.location.assign(redirect_to)
        } catch (error) {
            toast.error(error.message ?? error)
            setIsLoading(false)
        }
    }

    return isLoading ? (
        <Loader />
    ) : (
        isFormDisplayed && (
            <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                <div className="flex w-full flex-col">
                    <h3 className="text-center text-2xl font-bold md:text-3xl">{t('web_login_page_title')}</h3>
                    <input type="hidden" {...register('client_id')} />
                    <input type="hidden" {...register('redirect_uri')} />
                    <input type="hidden" {...register('grant_type')} />
                    <input type="hidden" {...register('response_type')} />
                    <input type="hidden" {...register('state')} />
                    <label className="uppercase">{t('web_login_page_email_address')}</label>
                    <input
                        type="email"
                        placeholder={t('web_login_page_email_address')}
                        {...register('email')}
                        required
                    />
                    <label className="mt-4 uppercase">{t('web_login_page_password')}</label>
                    <input
                        type="password"
                        placeholder={t('web_login_page_email_address')}
                        {...register('password')}
                        required
                    />
                    {errorMessage && <p className="mt-4 text-center text-christine">{t(errorMessage)}</p>}
                    <button className="btn px-8 mt-4" type="submit">
                        {t('web_login_page_continue')}
                    </button>
                </div>
            </form>
        )
    )
})

export default Form
