import { mapOrJsonOptions } from 'core/utils/map-to-object'
import { DateTime } from 'luxon'
import { values } from 'mobx'
import { applySnapshot, flow, getRoot, Instance, types } from 'mobx-state-tree'
import { _AnyJsonValue } from 'stores/any'
import { _Checklist, _Client } from 'stores/brands'
import { User } from 'stores/users'
import validator from 'validator'
import { get, post, put } from '../../core/services/http-service'
import { RootInstance } from '../store'
import { _SpaceFile, SpaceFile } from './file'
import { _ClientCustomValue, Space } from './space'

export const _SpaceDirectory = types
    .model('SpaceDirectory', {
        uuid: '',
        name: '',
        description: '',
        userUuid: types.optional(types.string, '', [null, undefined]),
        files: types.map(_SpaceFile),
        directories: types.map(types.late(() => _SpaceDirectory)),
        parent: types.optional(types.string, '', [null, undefined]),
        dateAdded: 0,
        lastModified: 0,
        spaceUuid: '',
        isLoaded: false,
        options: types.optional(_AnyJsonValue, {}, [null, undefined]),
        connector: types.optional(types.string, '', [null, undefined]),
        connectorUuid: types.optional(types.string, '', [null, undefined]),
        subscriber: types.optional(types.string, '', [null, undefined]),
        label: types.optional(types.string, '', [null, undefined]),
        expireAt: types.optional(types.string, '', [null, undefined]),
        subConnector: false,
        isDeletable: true,
        isEditable: true,
        clientUuid: types.optional(types.string, '', [null, undefined]),
        client: types.optional(_Client, {}, [null, undefined]),
        checklist: types.optional(_Checklist, {}, [null, undefined]),
        checklistPercentage: types.optional(types.number, 0, [null, undefined]),
        canRefresh: types.optional(types.boolean, false, [null, undefined]),
        clientCustomValues: types.array(_ClientCustomValue),
    })
    .views(self => ({
        get expirationDate(): DateTime | undefined {
            if (!self.expireAt) {
                return undefined
            }

            return DateTime.fromISO(self.expireAt)
        },
        get expirationDays(): number | undefined {
            if (!self.expireAt) {
                return undefined
            }
            const date = DateTime.fromISO(self.expireAt)

            return date.diff(DateTime.now(), 'days').toObject().days
        },
        get jsonOptions(): Record<string, unknown> {
            return mapOrJsonOptions(self.options)
        },
        get isPersistedDirectory() {
            return validator.isUUID(self.uuid)
        },
        get isDirectory() {
            return true
        },
        get isFile() {
            return false
        },
        get isSpace() {
            return false
        },
        get isConnector() {
            return false
        },
        get displayName() {
            return self.name
        },
        get storeStatus() {
            return null
        },
        get topSpace(): Space | undefined {
            const root = getRoot(self) as RootInstance

            return root.files.recursivelyGetSpaceParent(self.spaceUuid)
        },

        get parentUuid() {
            const root = getRoot(self) as RootInstance
            const directory = root.files.recursivelyFindDirectory(self.parent)

            if (directory && directory.name !== '/') {
                return directory.uuid
            }

            return self.spaceUuid
        },

        get parentDirectoryName(): string {
            const root = getRoot(self) as RootInstance
            const directory = root.files.recursivelyFindDirectory(self.parent)

            return directory?.displayName ?? ''
        },

        get allParents(): string[] {
            const root = getRoot(self) as RootInstance

            const uuids: string[] = [self.uuid]

            if (self.parent) {
                const directory = root.files.recursivelyFindDirectory(self.parent)
                if (directory) {
                    uuids.push(...directory.allParents)
                }
            } else {
                const space = root.files.recursivelyFindSpace(self.spaceUuid)
                if (space) {
                    uuids.push(...space.allParents)
                }
            }

            return uuids
        },

        findConnectorParent() {
            const root = getRoot(self) as RootInstance

            const findParentRecursively = (parentUuid: string) => {
                const directory = root.files.recursivelyFindDirectory(parentUuid)
                if (!directory) {
                    return undefined
                }

                if (!directory.subConnector) {
                    return directory
                }

                return findParentRecursively(directory.parent)
            }

            const directory = findParentRecursively(self.parent)

            return directory ?? self
        },

        get createdAt(): DateTime {
            return DateTime.fromMillis(self.dateAdded)
        },
        get updatedAt(): DateTime {
            return DateTime.fromMillis(self.lastModified)
        },

        get isOpen(): boolean {
            const root = getRoot(self) as RootInstance

            return root.config.isOpened(self.uuid)
        },

        get isRootDirectory() {
            return self.name === '/'
        },
    }))
    .views(self => ({
        get spaceName(): string {
            return this.topSpace?.displayName ?? ''
        },

        isEditAllowed(): boolean {
            return !self.jsonOptions.clientDirectory && !self.jsonOptions.checklistDirectory
        },

        isEditableBy(user: User): boolean {
            if (!self.isEditable) {
                return false
            }

            const topSpace = this.topSpace
            if (!topSpace) {
                return false
            }

            if (topSpace.jsonOptions && topSpace.jsonOptions.internalBrandDirectory) {
                return user.uuid === self.userUuid
            }

            return true
        },

        isDeletableBy(user: User): boolean {
            if (!self.isDeletable) {
                return false
            }

            const topSpace = this.topSpace
            if (!topSpace) {
                return false
            }

            if (topSpace.jsonOptions && topSpace.jsonOptions.internalBrandDirectory) {
                return user.uuid === self.userUuid
            }

            return true
        },
    }))
    .actions(self => ({
        _reset: () => {
            applySnapshot(self, {})
        },

        _refresh(directory: SpaceDirectory) {
            applySnapshot(self, directory)
        },
    }))
    .actions(self => ({
        setChecklistPercentage(percentage: number) {
            self.checklistPercentage = percentage
        },

        setDirectories(directories: SpaceDirectory[]) {
            self.directories.replace(directories)
        },

        open() {
            const root = getRoot(self) as RootInstance
            root.config.changeOpened(self, true)
        },

        close() {
            const root = getRoot(self) as RootInstance
            root.config.changeOpened(self, false)
        },

        shouldOpen(highlightedDirectory: string | undefined, highlightedFile: string | undefined) {
            if (!highlightedDirectory && !highlightedFile) {
                return
            }

            const recursiveFind = (directory: SpaceDirectory): boolean => {
                if (highlightedDirectory === directory.uuid) {
                    return true
                }

                for (const file of values<SpaceFile>(directory.files)) {
                    if (file.uuid === highlightedFile) {
                        return true
                    }
                }
                for (const child of values<SpaceDirectory>(directory.directories)) {
                    if (recursiveFind(child)) {
                        child.open()

                        return true
                    }
                }

                return false
            }

            if (recursiveFind(self)) {
                this.open()
            }
        },

        rename: flow(function* (name: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const postData = {
                name,
            }
            interface JsonDirectory {}
            interface JsonData {
                directory: JsonDirectory
            }
            interface JsonReturn {
                data: JsonData
            }

            try {
                const data = yield put<typeof postData, JsonReturn>(`/v1/web/directories/${self.uuid}`, postData)

                const {
                    data: { directory, collaborationData },
                } = data
                const space = root.files.spaces.get(directory.spaceUuid)
                if (space) {
                    yield space.refresh()
                }
                if (collaborationData) {
                    const notifyData = {
                        type: 'renamed_objects',
                        data: {
                            movedObjects: [
                                {
                                    object: {
                                        uuid: directory.uuid,
                                        name: directory.displayName,
                                    },
                                    collaborationData,
                                },
                            ],
                        },
                    }
                    yield post<typeof notifyData, void>('/v1/web/notifications/event', notifyData)
                }
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        refresh: async () => {
            const root = getRoot(self) as RootInstance
            if (root) {
                root.error.clean()
            }

            if (self.name === '/') {
                const space = root.files.recursivelyFindSpace(self.spaceUuid)
                if (space) {
                    await space.refresh()

                    return
                }
            }

            try {
                const data = await get<void, { data: { directory: SpaceDirectory } }>(
                    `/v1/web/directories/${self.uuid}`
                )

                const {
                    data: { directory },
                } = data
                directory.isLoaded = true
                self._refresh(directory)
            } catch (err) {
                root.error.prepare(err)
            }
        },
    }))
export interface SpaceDirectory extends Instance<typeof _SpaceDirectory> {}
