import { clsx } from 'clsx'
import { FC } from 'react'

interface Props {
    color?: string
    partnerIcon?: string
    connectorUuid?: string
    userDirectory?: boolean
    onClick?: () => void
    className?: string
    size?: string
}

const DirectoryIcon: FC<Props> = (
    { color = '#F15F12', connectorUuid, partnerIcon, userDirectory = false, onClick, className, size = 'w-6 h-6' }
) => {
    return (
        <div
            className={clsx('relative', size, className, { 'cursor-pointer': onClick !== undefined })}
            onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className={clsx('absolute inset-0', size)}
                viewBox="0 0 30.253 24.92">
                <path
                    d="M24.751 5.345a1.312 1.312 0 00-1.357-1.373H11.833a.44.44 0 01-.363-.143l-1.339-1.335-.012-.012a1.293 1.293 0 00-1.029-.41H3.372a1.416 1.416 0 00-1.47 1.369v4.344h22.849z"
                    fill={color}
                />
                {connectorUuid && (
                    <path
                        d="M26.585 4.127V.917a.917.917 0 111.834 0v3.21zm3.21.459H19.707a.459.459 0 00-.459.458v.917a.459.459 0 00.459.459h.458v.917a4.587 4.587 0 003.668 4.493v2.843h1.834V11.83a4.587 4.587 0 003.668-4.494v-.917h.459a.459.459 0 00.454-.458v-.917a.459.459 0 00-.454-.459zm-6.879-.459V.917a.917.917 0 10-1.834 0v3.21z"
                        fill="#ffa300"
                    />
                )}
                <path
                    d="M1.902 9.45h-.494c-.762 0-1.505.3-1.4 1.446s1.4 12.535 1.4 12.535c.161 1.059.7 1.488 1.488 1.488H23.87c.756 0 1.25-.464 1.369-1.488 0 0 1.321-11 1.4-12.228s-.53-1.755-1.4-1.755H1.902z"
                    fill={color}
                />

                {userDirectory && (
                    <path
                        d="M13.601 17.557a2 2 0 10-2-2 2 2 0 002 2zm1.78.445h-.767a2.42 2.42 0 01-2.027 0h-.766a1.78 1.78 0 00-1.78 1.784v.222a.668.668 0 00.667.667h5.785a.668.668 0 00.667-.667v-.222a1.78 1.78 0 00-1.78-1.78z"
                        fill="#fff"
                    />
                )}
            </svg>
            {connectorUuid && (
                <>
                    <div className="absolute inset-0 flex items-center justify-center">
                        <img src={`/connectors/${connectorUuid}.webp`} loading="lazy" className="mt-1.5 h-3 w-3" />
                    </div>
                </>
            )}
            {partnerIcon && (
                <>
                    <div className="absolute inset-0 flex items-center justify-center">
                        <img src={partnerIcon} loading="lazy" className="mt-1.5 h-3 w-3" />
                    </div>
                </>
            )}
        </div>
    )
}

export default DirectoryIcon
