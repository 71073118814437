import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ChevronLeft } from '../../assets/arrow/arrow-left.svg'
import { ReactComponent as ChevronRight } from '../../assets/arrow/arrow-right.svg'
import { useMst } from '../../stores/store'
import Todo from '../todo/todo'
import WizardProgress from './wizard-progress'

interface SlidePage {
    content: ReactNode
    image: string
}

interface Props {
    title: string
    page: string
    pages: SlidePage[]
}

const Slide: FC<Props> = observer(({ title, pages, page }) => {
    const { menuOpen } = useMst()
    const [currentPage, setCurrentPage] = useState<number>(0)
    const { t } = useTranslation()

    const partRef = useRef<HTMLDivElement>(null)

    // force refresh when the menu change
    useEffect(() => {}, [menuOpen])

    return (
        <div className="flex flex-row items-center justify-center bg-white">
            <div className="relative w-full">
                <div ref={partRef} className="flex flex-row overflow-hidden">
                    {pages.map((page, index) => (
                        <div
                            key={index}
                            className="flex w-full shrink-0 flex-col items-center justify-center space-x-14 px-6 transition-transform duration-700 ease-in-out md:flex-row"
                            style={{
                                transform: partRef.current
                                    ? `translate(${partRef.current.clientWidth * -currentPage}px, 0)`
                                    : '',
                            }}>
                            <div
                                className="h-60 w-full bg-contain bg-center bg-no-repeat md:h-1/2 md:w-1/2"
                                style={{ backgroundImage: `url(${page.image})` }}></div>
                            <div className="w-full md:w-1/2">{page.content}</div>
                        </div>
                    ))}

                    <div
                        className="flex w-full shrink-0 flex-col items-center justify-center space-x-14 px-6 transition-transform duration-700 ease-in-out md:flex-row"
                        style={{
                            transform: partRef.current
                                ? `translate(${partRef.current.clientWidth * -currentPage}px, 0)`
                                : '',
                        }}>
                        <Todo className="w-full md:w-2/3" title={title} page={page} />
                    </div>
                </div>
                <div
                    className={`absolute inset-y-1/2 -left-1 z-50 mr-3 flex h-10 w-10 items-center justify-center rounded-100 bg-white p-2 shadow-lg ${
                        currentPage > 0 ? 'cursor-pointer text-thunder' : 'text-regent-gray'
                    }`}
                    onClick={() => {
                        if (currentPage > 0) {
                            setCurrentPage(currentPage - 1)
                        }
                    }}>
                    <ChevronLeft className="h-4 w-4 fill-current" />
                </div>
                <div
                    className={`absolute inset-y-1/2 -right-1 z-50 ml-3 flex h-10 w-10 items-center justify-center rounded-100 bg-white p-2 shadow-lg ${
                        currentPage < pages.length - 1 ? 'cursor-pointer text-thunder' : 'text-regent-gray'
                    }`}
                    onClick={() => {
                        if (currentPage < pages.length) {
                            setCurrentPage(currentPage + 1)
                        }
                    }}>
                    <ChevronRight className="h-4 w-4 fill-current" />
                </div>

                <WizardProgress
                    max={pages.length + 1}
                    current={currentPage}
                    onChangePage={page => setCurrentPage(page)}
                />

                {currentPage !== pages.length && (
                    <div className="flex justify-center">
                        <button className="btn" onClick={() => setCurrentPage(pages.length)}>
                            {t('web_todo_note_now')}
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
})

export default Slide
