import clsx from 'clsx'
import { ReactNode } from 'react'

interface Props {
    visible: boolean
    children?: ReactNode
}

const FloatingBar = ({ visible, children }: Props) => {
    return (
        <div
            className={clsx(
                `fixed bottom-0 w-max rounded-lg bg-white shadow-xl transition-all duration-500 ease-in-out`,
                visible ? 'mb-0' : '-mb-48'
            )}
            style={{
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
            {children}
        </div>
    )
}

export default FloatingBar
