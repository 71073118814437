import { Instance, types } from 'mobx-state-tree'
import { SpaceDirectory } from './files/directory'
import { Space } from './files/space'

const _OpenedDirectoryOrSpace = types.model('OpenedDirectoryOrSpace', {
    uuid: types.identifier,
    opened: types.boolean,
})

export const _Config = types
    .model('Config', {
        opened: types.map(_OpenedDirectoryOrSpace),
    })
    .views(self => ({
        isOpened(uuid: string): boolean {
            if (!self.opened.has(uuid)) {
                return false
            }

            const value = self.opened.get(uuid)

            return value ? value.opened : false
        },
    }))
    .actions(self => ({
        changeOpened(directoryOrSpace: SpaceDirectory | Space, opened: boolean) {
            if (!self.opened.has(directoryOrSpace.uuid)) {
                self.opened.put({ uuid: directoryOrSpace.uuid, opened })

                return
            }

            self.opened.set(directoryOrSpace.uuid, { uuid: directoryOrSpace.uuid, opened })
        },
    }))

export interface Config extends Instance<typeof _Config> {}
