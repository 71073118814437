import { DateTime } from 'luxon'
import { Instance, types } from 'mobx-state-tree'
import { get, post } from '../../core/services/http-service'
import { _CollaborationMessage, CollaborationMessage } from './message'

export const _CollaborationMessageStore = types
    .model('CollaborationMessageStore', {
        messages: types.array(_CollaborationMessage),
        unreadCount: 0,
    })
    .volatile<{ currentCollaborationId: string; panelOpened: boolean; collaborationId: string }>(() => ({
        currentCollaborationId: '',
        panelOpened: false,
        collaborationId: '',
    }))
    .actions(self => ({
        replaceMessages: messages => {
            const currents = self.messages.map(message => message.id)

            for (const message of messages) {
                if (!currents.includes(message.id)) {
                    self.messages.push(message)
                    continue
                }
                const same = self.messages.find(m => m.id === message.id)
                if (same.replies.length !== message.replies.length) {
                    same.replies = message.replies
                }
            }
            self.messages.sort(
                (a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis()
            )
        },
        updateUnreadCount: count => (self.unreadCount = count),
    }))
    .actions(self => ({
        setCurrentCollaborationId: id => (self.currentCollaborationId = id),
        setPanelOpened: opened => (self.panelOpened = opened),
        flagMessagesRead: async (collaborationId: string) => {
            if (collaborationId.length === 0) {
                self.replaceMessages([])
                self.updateUnreadCount(0)

                return
            }

            try {
                const {
                    data: { readCount },
                } = await get<void, { data: { readCount: number } }>(
                    `/v1/web/collaborations/${collaborationId}/messages/flag-read`
                )
                self.updateUnreadCount(self.unreadCount - readCount)
            } catch (error) {
                console.error(error)
            }
        },
    }))
    .actions(self => ({
        loadCollaborationMessages: async (collaborationId: string) => {
            if (collaborationId.length === 0) {
                self.messages.clear()
                self.updateUnreadCount(0)

                return
            }

            if (collaborationId !== self.collaborationId) {
                self.messages.clear()
                self.updateUnreadCount(0)
                self.collaborationId = collaborationId
            }

            try {
                const {
                    data: { messages, unreadCount },
                } = await get<void, { data: { messages: CollaborationMessage[]; unreadCount: number } }>(
                    `/v1/web/collaborations/${collaborationId}/messages`
                )

                self.replaceMessages(messages)

                if (self.panelOpened) {
                    await self.flagMessagesRead(collaborationId)
                } else {
                    self.updateUnreadCount(unreadCount)
                }
            } catch (error) {
                console.error(error)
            }
        },
        postCollaborationMessage: async (
            collaborationId: string,
            message: string,
            inReplyTo?: CollaborationMessage
        ): Promise<CollaborationMessage | undefined> => {
            try {
                const postData = { message, messageId: inReplyTo?.id }

                const {
                    data: { message: savedMessage },
                } = await post<typeof postData, { data: { message: CollaborationMessage } }>(
                    `/v1/web/collaborations/${collaborationId}/message`,
                    postData
                )

                return savedMessage
            } catch (error) {
                console.error(error)
            }
        },
    }))

export interface CollaborationMessageStore extends Instance<typeof _CollaborationMessageStore> {}
