import type { SpaceFile } from 'stores/files/file'

export interface FileOrDirectoryProps {
    level: number
    selected: boolean
    canChangeSelection: boolean
    className?: string
    isRefreshing: boolean
    onSetFileDocumentTypeClicked?: (file: SpaceFile) => void
}

export const padding = (level: number): number => (level === 0 ? 0.25 : level * 1.35)
