export default function SmallUploadIcon({ className }: { className: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.771 41.346" className={className}>
            <path
                fill="#fed2b2"
                d="M30.968 27.109a3.474 3.474 0 0 0-.906-3.7c-1.282-1.612-3.54-4-5.292-6.21s-.024-4.363 1.666-2.016l1.076 1.471c.222.295 1.349 1.61 1.7 1.956.79.786 3.117.606 3.117.606.52-1.592 1-14.308 1-17.741L30.615.582a5.337 5.337 0 0 0-2.867-.07s-8.915 1.443-9.335 1.561a1.929 1.929 0 0 0-.71 3.28l1.682 1.656c1.183 1.38.081 3.454-1.723 1.768l-5.431-7.126L9.246.34C7.477-.686 6.546.707 4.933 3.2a3.615 3.615 0 0 0-.194.381A29.2 29.2 0 0 0 .445 14.67c-1.684 9.94 1.582 19.166 7.585 23.212a32.305 32.305 0 0 0 7.89 3.293s11.054-5.24 14.795-13.435c.007-.01.022-.022.028-.032.025-.05.031-.094.054-.143.055-.124.117-.241.171-.366.006-.021-.004-.063 0-.09Z"
            />
            <path
                fill="#fca869"
                d="M33.954 28.42a3.474 3.474 0 0 0-.906-3.7c-1.282-1.612-3.54-4-5.292-6.21s.44-4.149 2.131-1.8l1.523 1.881c.581.983 1.7 1.3 2.217-2.6.478-3.624 1.069-8.663 1.143-11.441.1-3.65-2.621-3.248-2.621-3.248s-7.466 1.079-10.927 1.854c-1.6.359-2.12 1.8-.754 3.023l1.625 1.707c2.106 2.105.358 3.888-1.446 2.2L12.23 1.649c-1.769-1.026-2.7.367-4.313 2.86a3.617 3.617 0 0 0-.194.381 29.2 29.2 0 0 0-4.294 11.089c-2.1 12.39 3.492 23.67 12.489 25.194 7.025 1.19 14.04-3.928 17.781-12.124.007-.01.022-.022.028-.032.026-.05.031-.094.055-.143.055-.124.117-.241.171-.366.007-.021-.003-.06.001-.088Z"
            />
        </svg>
    )
}
