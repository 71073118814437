import { zodResolver } from '@hookform/resolvers/zod'
import localeFr from 'air-datepicker/locale/fr'
import { Input, selectStyles } from 'components/shared/select-styles'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useMst } from 'stores/store'
import { z } from 'zod'
import DatePicker from '../date-picker'
import { FormProps, optionValueSchema } from '../forms'
import Label from '../label'
import 'components/admin/zod'
import { TFunction } from 'i18next'

const schema = z.object({
    fullname: z.string().min(1),
    email: z.string().email().min(1),
    secondaryEmail: z.union([z.literal(''), z.string().email()]),
    role: optionValueSchema,
    phone: z.string().optional(),
    birthdate: z.coerce.date().optional(),
    userType: optionValueSchema.optional().nullable(),
    partner: optionValueSchema.optional().nullable(),
    pricing: optionValueSchema,
    type: optionValueSchema,
})
export type Schema = z.infer<typeof schema>

export const getRoles = (t: TFunction) => {
    return [
        { value: 'ADMIN', label: t('web_menu_admin_users_role_admin') },
        { value: 'USER', label: t('web_menu_admin_users_role_user') },
        { value: 'GUEST_PARTNER', label: t('web_menu_admin_users_role_guest_partner') },
    ]
}

export const getTypes = (t: TFunction) => {
    return [
        { value: 'NATURAL', label: t('web_menu_admin_users_type_natural') },
        { value: 'LEGAL', label: t('web_menu_admin_users_type_legal') },
    ]
}

const Form = observer(({ onSubmit, values }: FormProps<Schema>) => {
    const { t } = useTranslation()
    const { admin, userTypeStore } = useMst()

    const currentLocale = localeFr

    const roles = getRoles(t)
    const types = getTypes(t)

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })

    useEffect(() => {
        if (!values) {
            return
        }

        for (const key of Object.keys(values) as (keyof Schema)[]) {
            setValue(key, values[key])
        }
    }, [values])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Label
                name="fullname"
                error={errors['fullname'] && `${t('web_admin_user_fullname')} ${errors['fullname']?.message}`}
                label={'web_admin_user_fullname'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <input {...register('fullname')} type="text" className="w-1/3" />
            </Label>

            <Label
                name="email"
                error={errors['email'] && `${t('web_admin_user_email')} ${errors['email']?.message}`}
                label={'web_admin_user_email'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <input {...register('email')} type="text" className="w-1/3" />
            </Label>
            <Label
                name="secondaryEmail"
                error={
                    errors['secondaryEmail'] &&
                    `${t('web_admin_user_secondary_email')} ${errors['secondaryEmail']?.message}`
                }
                label={'web_admin_user_secondary_email'}
                className="my-5 flex w-full flex-wrap items-center">
                <input {...register('secondaryEmail')} type="text" className="w-1/3" />
            </Label>
            <Label
                name="phone"
                error={errors['phone'] && `${t('web_admin_user_phone')} ${errors['phone']?.message}`}
                label={'web_admin_user_phone'}
                className="my-5 flex w-full flex-wrap items-center">
                <input {...register('phone')} type="text" className="w-1/3" />
            </Label>
            {/*
        <Label
            name="password"
            error={errors['password'] && `${t('web_admin_user_password')} ${errors['password']?.message}`}
            label={'web_admin_user_password'}
            className="flex w-full flex-wrap my-5 items-center"
        >
        </Label> */}

            <Label
                name="birthdate"
                error={errors['birthdate'] && `${t('web_admin_user_birthdate')} ${errors['birthdate']?.message}`}
                label={'web_admin_user_birthdate'}
                className="my-5 flex w-full flex-wrap items-center leading-tight">
                <Controller
                    name="birthdate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                        return <DatePicker {...field} locale={currentLocale} />
                    }}
                />
            </Label>

            <Label
                name="role"
                error={errors['role'] && `${t('web_admin_user_role')} ${errors['role']?.message}`}
                label={'web_admin_user_role'}
                className="my-5 flex w-full flex-wrap items-center leading-tight"
                required={true}>
                <Controller
                    name="role"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                options={roles}
                                onChange={role => onChange(role)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="type"
                error={errors['type'] && `${t('web_admin_user_person_type')} ${errors['type']?.message}`}
                label={'web_admin_user_person_type'}
                className="my-5 flex w-full flex-wrap items-center leading-tight"
                required={true}>
                <Controller
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                options={types}
                                onChange={type => onChange(type)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="userType"
                error={errors['userType'] && `${t('web_admin_user_type')} ${errors['userType']?.message}`}
                label={'web_admin_user_type'}
                className="my-5 flex w-full flex-wrap items-center leading-tight">
                <Controller
                    name="userType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                isClearable={true}
                                options={[...userTypeStore.userTypes]
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(userType => ({ value: userType.uuid, label: userType.name }))}
                                onChange={userType => onChange(userType)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="partner"
                error={errors['partner'] && `${t('web_admin_user_partner')} ${errors['partner']?.message}`}
                label={'web_admin_user_partner'}
                className="my-5 flex w-full flex-wrap items-center leading-tight">
                <Controller
                    name="partner"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                isClearable={true}
                                options={[...admin.partners].map(partner => ({
                                    value: partner.uuid,
                                    label: partner.name,
                                }))}
                                onChange={partner => onChange(partner)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="pricing"
                error={errors['pricing'] && `${t('web_admin_user_pricing')} ${errors['pricing']?.message}`}
                label={'web_admin_user_pricing'}
                className="my-5 flex w-full flex-wrap items-center leading-tight"
                required={true}>
                <Controller
                    name="pricing"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                isClearable={true}
                                options={[...admin.pricings].map(pricing => ({
                                    value: pricing.uuid,
                                    label: pricing.name,
                                }))}
                                onChange={pricing => onChange(pricing)}
                            />
                        )
                    }}
                />
            </Label>

            <input type="submit" className="btn float-right cursor-pointer" value={`${t('web_admin_user_save')}`} />
        </form>
    )
})

export default Form
