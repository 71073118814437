import { observer } from 'mobx-react-lite'
import { type ReactNode, useEffect, useState } from 'react'
import { ReactComponent as Hello } from '../assets/login/login-hello.svg'
import { useMst } from '../stores/store'

const AppDownChecker = observer(({ children }: { children: ReactNode }) => {
    const rootStore = useMst()
    const { notificationCenter, isLogged, files, collaborationMessageStore } = rootStore

    const [isDown, setDown] = useState<boolean>(false)

    useEffect(() => {
        let eventSource: EventSource | null = null
        let timer: ReturnType<typeof setTimeout> | null = null

        const connectEventSource = () => {
            if (eventSource) {
                eventSource.close()
            }
            const url = new URL(`${process.env.REACT_APP_API_HOST}/v1/ws/listen`)
            url.searchParams.append('identification', rootStore.identification)
            if (rootStore.token && rootStore.token !== '') {
                url.searchParams.append('user', rootStore.token)
            }

            eventSource = new EventSource(url.toString())

            eventSource.onmessage = event => {
                setDown(false)

                const { notification, error, ocrFileMovedTo, ocrFileRenamed, collaborationMessage } = JSON.parse(
                    event.data
                )

                if (error) {
                    const [message] = error
                    if (message && message === 'api_ws_no_user_error') {
                        rootStore.logout()

                        return
                    }
                }

                if (collaborationMessage) {
                    collaborationMessageStore.setCurrentCollaborationId(collaborationMessage.collaborationId)
                }

                if (notification) {
                    notificationCenter.append(notification)
                }

                if (ocrFileMovedTo) {
                    files.setOcrFileMovedTo(ocrFileMovedTo)
                }
                if (ocrFileRenamed) {
                    files.setOcrFileRenamed(ocrFileRenamed)
                }
            }
            eventSource.onerror = err => {
                console.log('onerror', err)
                setDown(true)
                timer = setTimeout(() => {
                    connectEventSource()
                }, 1000)
            }
        }
        connectEventSource()

        return () => {
            if (eventSource) {
                eventSource.close()
            }
        }
    }, [isLogged, rootStore.token])

    if (!isDown) {
        return <>{children}</>
    }

    return (
        <div className="flex h-full min-h-screen w-full flex-col items-center justify-center bg-gradient-to-b from-white to-athens-gray">
            <Hello />
            <h3 className="mb-4 mt-8 text-center text-2xl font-bold">Votre connexion internet semble instable</h3>
            <p className="">Veuillez vérifier votre connexion internet avant de vous connecter à Treasy.</p>
        </div>
    )
})

export default AppDownChecker
