import Admin from 'components/admin/admin'
import { getTypes } from 'components/admin/users/form'
import { toJS } from 'mobx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useMst } from '../../../stores/store'
import Form, { Schema } from './form'

const Edit = () => {
    const { partners } = useMst()
    const { t } = useTranslation()
    const { id, uid } = useParams()
    const [values, setValues] = useState<Record<string, unknown>>()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(true)

    const onSubmit = async (data: Schema) => {
        const { fullname, email, phone, birthdate, tags, secondaryEmail, type } = data
        setLoading(true)
        await partners.updateUser(
            id,
            uid,
            fullname,
            email,
            secondaryEmail,
            phone,
            birthdate,
            tags?.map(t => t.value) ?? [],
            type.value
        )
        setLoading(false)
        navigate(`/partner/${id}/users`)
    }

    const types = getTypes(t)

    const load = async () => {
        setLoading(true)
        await partners.loadUser(id, uid)
        const tags = await partners.loadTags(id)
        const user = partners.editingUser

        setValues({
            ...toJS(user),
            birthdate: user.birthDate?.toJSDate(),
            type: types.find(type => type.value === user.type),
            tags: [...tags]
                .map(tag => ({ value: tag.uuid, label: tag.name }))
                .filter(tag =>
                    user.tags
                        .map(t => t.uuid)
                        .flat()
                        .includes(tag.value)
                ),
        })
        setLoading(false)
    }
    useEffect(() => {
        load()
    }, [id])

    return (
        <Admin title={t('web_menu_admin_user')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} values={values} partnerId={id} />
        </Admin>
    )
}

export default Edit
