import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import ContentPage from '../dataroom/content-page'

const Dataroom = observer(() => {
    const { t } = useTranslation()

    return <ContentPage title={t('web_admin')}></ContentPage>
})

export default Dataroom
