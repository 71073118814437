import { clsx } from 'clsx'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface LabelProps {
    name: string
    className?: string
    children?: ReactNode
    label: string
    required?: boolean
    help?: string
    helpParams?: Record<string, string | number>
    error?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Label: FC<LabelProps> = ({ name, className, children, label, help, helpParams, error, required = false }) => {
    const { t } = useTranslation()

    return (
        <div className={clsx('mb-3 flex w-full flex-col space-y-2', className)}>
            <div className="flex w-full flex-row">
                <label htmlFor={name} className="w-1/5 pt-2 text-gray-700">
                    {t(label)}
                    {required && <span className="text-bittersweet">*</span>}
                </label>
                <div className="flex w-4/5 flex-col space-y-2">
                    <div className="flex w-full items-center space-x-2">{children}</div>
                    {help && <p className="text-sm">{t(help, helpParams)}</p>}
                    {error && <p className="text-sm text-red-600">{t(error)}</p>}
                </div>
            </div>
        </div>
    )
}
export default Label
