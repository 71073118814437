import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useMst } from '../../../stores/store'
import Admin from '../admin'
import Form, { Schema } from './form'

const Create = () => {
    const { admin } = useMst()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(false)

    const onSubmit = async (data: Schema) => {
        const {
            name,
            url,
            companyNumber,
            healthPartner,
            logo,
            icon,
            pricing,
            otherPricing,
            managers,
            caseManagers,
            contactUrl,
        } = data
        setLoading(true)
        await admin.createPartner(
            name,
            url,
            contactUrl,
            companyNumber,
            healthPartner,
            logo,
            icon,
            pricing.value,
            otherPricing ? otherPricing.map(p => p.value) : [],
            managers ? managers.map(u => u.value) : [],
            caseManagers ? caseManagers.map(u => u.value) : []
        )
        setLoading(false)
        navigate('/admin/partners')
    }

    useEffect(() => {
        admin.loadPricings(1000, 0)
    }, [])

    return (
        <Admin title={t('web_menu_admin_partner')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} />
        </Admin>
    )
}

export default Create
