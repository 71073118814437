import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BrandNews } from 'stores/brands/news'
import { News as Model } from 'stores/news/news'
import { useMst } from 'stores/store'
import News from './news'

interface NewsBoardProps {}

const NewsBoard: FC<NewsBoardProps> = observer(() => {
    const { newsStore, brand, user } = useMst()
    const { t } = useTranslation()

    const [isOpen, setOpen] = useState<boolean>(true)
    const [brandNews, setBrandNews] = useState<BrandNews[]>([])

    const load = async () => {
        if (user.currentBrand) {
            const { news } = await brand.dashboardListNews()
            setBrandNews(news)
        } else {
            await newsStore.load()
        }
    }

    useEffect(() => {
        load()
    }, [])

    if ((!user.currentBrand && newsStore.news.length === 0) || (user.currentBrand && brandNews.length === 0)) {
        return null
    }

    return (
        <div className="flex flex-col space-y-4">
            <h3
                className="flex cursor-pointer items-center space-x-4 text-3xl font-bold"
                onClick={() => setOpen(open => !open)}>
                <span>{t('web_news_title')}</span>
                {isOpen ? (
                    <FontAwesomeIcon icon={faChevronDown} className="h-4 text-regent-gray" />
                ) : (
                    <FontAwesomeIcon icon={faChevronUp} className="h-4 text-regent-gray" />
                )}
            </h3>

            {isOpen && (
                <>
                    {user.currentBrand ? (
                        <>
                            {brandNews.slice(0, 3).map(news => (
                                <News key={news.uuid} news={news} onRefresh={() => load()} />
                            ))}
                        </>
                    ) : (
                        <>
                            {values<Model>(newsStore.news)
                                .slice(0, 3)
                                .map(news => (
                                    <News key={news.uuid} news={news} onRefresh={() => load()} />
                                ))}
                        </>
                    )}
                    {(newsStore.news.length > 3 || brandNews.length > 3) && (
                        <p>
                            <Link
                                to={'/news'}
                                className="block w-full cursor-pointer text-right text-sm text-regent-gray">
                                {t('web_news_show_more_news')}
                            </Link>
                        </p>
                    )}
                </>
            )}
        </div>
    )
})

export default NewsBoard
