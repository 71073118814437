import { ReactComponent as Apple } from 'assets/sso/apple.svg'
import Config from 'core/config'
import { getFromQuery } from 'core/use-query'
import { FC, useEffect } from 'react'
import { useMst } from 'stores/store'
import { v4 as uuidv4 } from 'uuid'
import SsoButton from './sso-button'

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        AppleID: any
    }
}

const LoginApple: FC = () => {
    const rootStore = useMst()
    const { user } = rootStore

    const sso = getFromQuery('sso')
    const token = getFromQuery('t')

    useEffect(() => {
        const loginFromSso = async () => {
            if (sso && sso === 'apple' && token) {
                rootStore.setToken(token)
                await user.appleConnect()
                await user.linkAccountIfNeeded()
            }
        }

        loginFromSso()
    })

    useEffect(() => {
        if (window.AppleID && window.AppleID.auth) {
            const nonce = uuidv4()

            const params = {
                clientId: Config.sso.apple.CLIENT_ID,
                redirectURI: Config.sso.apple.REDIRECT_URI,
                scope: 'name email',
                state: 'initial',
                usePopup: false,
                nonce,
            }
            window.AppleID.auth.init(params)
        }
    })

    const connect = async () => {
        try {
            await window.AppleID.auth.signIn()
        } catch (err) {
            console.error('apple signin', err)
        }
    }

    return (
        <SsoButton onClick={() => connect()}>
            <Apple className="h-4 w-4" />
        </SsoButton>
    )
}

export default LoginApple
