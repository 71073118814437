import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clsx } from 'clsx'
import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { SpaceDirectory } from 'stores/files/directory'
import { SpaceFile } from 'stores/files/file'
import { cmp } from '../../dataroom/spaceroom'
import DirectoryIcon from '../../dataroom/spaceroom/directory-icon'
import { Loader } from '../../dataroom/spaceroom/loader'
import FileLine from './file-line'
import SharedLinePart, { LineProps } from './shared-line-part'

interface DirectoryLineProps extends LineProps {
    directory: SpaceDirectory
    color?: string
}

const DirectoryLine = observer(
    (
        {
            directory,
            users,
            owner,
            partner,
            className,
            color = '#F15F12',
            level,
            sharedFile,
            showUserSharedByMe,
            showUserSharedWithMe,
            setDownloading,
            setShowConfirmStop,
            setShowRestart,
            setShowConfirmDelete,
        }: DirectoryLineProps
    ) => {
        const [loading, setLoading] = useState<boolean>(false)
        const toggleOpen = async () => {
            if (directory.isOpen) {
                directory.close()
            } else {
                directory.open()
                if (!directory.isLoaded) {
                    setLoading(true)
                    await directory.refresh()
                    setLoading(false)
                }
            }
        }

        useEffect(() => {
            if (sharedFile.canDownload && directory.isOpen && !directory.isLoaded) {
                const load = async () => {
                    setLoading(true)
                    await directory.refresh()
                    setLoading(false)
                }
                load()
            }
        }, [directory])

        return (
            <>
                <SharedLinePart
                    fileOrDirectory={directory}
                    className={clsx(className)}
                    level={level}
                    sharedFile={sharedFile}
                    users={users}
                    owner={owner}
                    partner={partner}
                    showUserSharedByMe={showUserSharedByMe}
                    showUserSharedWithMe={showUserSharedWithMe}
                    setDownloading={setDownloading}
                    setShowConfirmStop={setShowConfirmStop}
                    setShowRestart={setShowRestart}
                    setShowConfirmDelete={setShowConfirmDelete}>
                    {sharedFile.canDownload && (
                        <FontAwesomeIcon
                            icon={faCaretRight}
                            className="cursor-pointer fill-current text-inner-space"
                            rotation={directory.isOpen ? 90 : undefined}
                            size="xs"
                            onClick={() => toggleOpen()}
                        />
                    )}
                    <DirectoryIcon
                        color={color}
                        onClick={sharedFile.canDownload ? () => toggleOpen() : undefined}
                        connectorUuid={directory.connectorUuid}
                        className={sharedFile.canDownload ? '' : 'opacity-70'}
                    />
                    <span className={`${sharedFile.canDownload ? '' : 'text-regent-gray opacity-70'}`}>
                        {directory.displayName}
                    </span>
                </SharedLinePart>
                {loading && (
                    <tr>
                        <td colSpan={100}>
                            <Loader />
                        </td>
                    </tr>
                )}
                {sharedFile.canDownload && directory.isOpen && (
                    <>
                        {values<SpaceDirectory>(directory.directories).map(child => (
                            <DirectoryLine
                                key={child.uuid}
                                className="mb-1 h-8 border-b border-mercury transition-all"
                                directory={child}
                                users={users}
                                owner={owner}
                                partner={partner}
                                showUserSharedByMe={showUserSharedByMe}
                                showUserSharedWithMe={showUserSharedWithMe}
                                level={level + 1}
                                sharedFile={sharedFile}
                                setDownloading={setDownloading}
                                setShowConfirmStop={setShowConfirmStop}
                                setShowRestart={setShowRestart}
                                setShowConfirmDelete={setShowConfirmDelete}
                            />
                        ))}
                        {values<SpaceFile>(directory.files)
                            .slice()
                            .sort(cmp)
                            .map(child => (
                                <FileLine
                                    key={child.uuid}
                                    className="mb-1 h-8 border-b border-mercury transition-all"
                                    file={child}
                                    users={users}
                                    owner={owner}
                                    partner={partner}
                                    showUserSharedByMe={showUserSharedByMe}
                                    showUserSharedWithMe={showUserSharedWithMe}
                                    level={level + 1}
                                    sharedFile={sharedFile}
                                    setDownloading={setDownloading}
                                    setShowConfirmStop={setShowConfirmStop}
                                    setShowRestart={setShowRestart}
                                    setShowConfirmDelete={setShowConfirmDelete}
                                />
                            ))}
                    </>
                )}
            </>
        )
    }
)
export default DirectoryLine
