import { Input, selectStyles } from 'components/shared/select-styles'
import i18next, { availableLocales } from 'core/i18n'
import toast from 'core/utils/toast'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useMst } from 'stores/store'
import MdEditor from '../shared/md-editor'
import Admin from './admin'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import Label from './label'
import { get, put } from 'core/services/http-service'

type SettingValue =
    | {
          type: 'string'
          value: string
      }
    | {
          type: 'boolean'
          value: boolean
      }
    | {
          type: 'number'
          value: number
      }
    | {
          type: 'json'
          value: Record<string, unknown>
      }

interface Setting {
    key: string
    value: number
}

const Settings = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(true)

    const settingsSchema = z.object({
        fileTransferTransferMaxSize: z.number(),
        fileTransferFileMaxSize: z.number(),
    })
    type SettingsData = z.infer<typeof settingsSchema>

    const methods = useForm<SettingsData>({ resolver: zodResolver(settingsSchema) })
    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
    } = methods

    const onSubmit = async (data: SettingsData) => {
        const settings: Setting[] = []
        for (const [key, value] of Object.entries(data)) {
            settings.push({ key, value })
        }
        setLoading(true)
        await put<{ settings: Setting[] }, never>('/v1/bo/settings', { settings })
        setLoading(false)
        toast('success', t('web_admin_settings_saved'))
    }

    useEffect(() => {
        const load = async () => {
            const { settings } = await get<
                never,
                { settings: ({ key: 'fileTransferTransferMaxSize' | 'fileTransferFileMaxSize' } & SettingValue)[] }
            >('/v1/bo/settings')

            for (const setting of settings) {
                setValue(setting.key, setting.value as number)
            }

            setLoading(false)
        }

        load()
    }, [])

    return (
        <Admin title={t('web_admin_settings')} isLoading={loading}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h2 className="text-xl font-black">{t('web_admin_settings_file_transfer_title')}</h2>
                    <Label
                        name="fileTransferTransferMaxSize"
                        error={
                            errors['fileTransferTransferMaxSize'] &&
                            `${t('web_admin_settings_file_transfer_transfer_max_size')} ${
                                errors['fileTransferTransferMaxSize']?.message
                            }`
                        }
                        label={'web_admin_settings_file_transfer_transfer_max_size'}
                        className="my-5 flex w-full flex-wrap items-center"
                        required={true}
                    >
                        <input
                            {...register('fileTransferTransferMaxSize', { valueAsNumber: true })}
                            type="number"
                            className="w-1/3"
                            required
                            min={0}
                        />
                    </Label>
                    <Label
                        name="fileTransferFileMaxSize"
                        error={
                            errors['fileTransferFileMaxSize'] &&
                            `${t('web_admin_settings_file_transfer_file_max_size')} ${
                                errors['fileTransferFileMaxSize']?.message
                            }`
                        }
                        label={'web_admin_settings_file_transfer_file_max_size'}
                        className="my-5 flex w-full flex-wrap items-center"
                        required={true}
                    >
                        <input
                            {...register('fileTransferFileMaxSize', { valueAsNumber: true })}
                            type="number"
                            className="w-1/3"
                            required
                            min={0}
                        />
                    </Label>
                    <div className="w-full text-right">
                        <input type="submit" className="btn cursor-pointer" value={`${t('web_admin_settings_save')}`} />
                    </div>
                </form>
            </FormProvider>
        </Admin>
    )
}

export default Settings
