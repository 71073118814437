import Tooltip from 'components/shared/tooltip'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useMst } from 'stores/store'
import { ReactComponent as ArrowRight } from '../../assets/arrow/arrow-right.svg'
import { ReactComponent as Folder } from '../../assets/icons/icon-folder.svg'
import type { Space } from '../../stores/files/space'
import SpaceroomMenu from './spaceroom-menu'

interface Props {
    space: Space
    onRefresh: () => void
}

const SpaceView = observer(({ space, onRefresh }: Props) => {
    const { t } = useTranslation()
    const { user, files } = useMst()

    const [showSpaceroomMenu, setShowSpaceroomMenu] = useState<boolean>(false)

    useEffect(() => {
        if (space.totalFiles === -1) {
            space.getFileCount()
        }
    }, [space.totalFiles])

    return (
        <div key={space.uuid} className="w-full flex-none p-2 md:w-1/2 lg:w-1/3 xl:w-1/4">
            <div className="flex flex-col p-2 shadow-md">
                <div className="relative self-end ">
                    <div
                        onClick={() => setShowSpaceroomMenu(true)}
                        className="relative flex w-12 cursor-pointer items-center justify-between rounded bg-white p-2 shadow"
                    >
                        <div
                            className="h-3 w-3 rounded-50 text-right shadow-md"
                            style={{ backgroundColor: space.color }}
                        />
                        <ArrowRight className="h-2 w-2 fill-current text-regent-gray" />
                    </div>
                    <SpaceroomMenu
                        space={space}
                        isVisible={showSpaceroomMenu}
                        onClose={() => setShowSpaceroomMenu(false)}
                        onRefresh={onRefresh}
                        onDeleted={() => {
                            files.markForceRefresh(space.uuid)
                            onRefresh()
                        }}
                        isShared={space.isShared}
                        shareLocked={space.shareLocked}
                        mine={space.userUuid === user.uuid}
                    />
                </div>
                <Link to={`/dataroom/spaces/${space.uuid}`} className="mt-4 flex flex-row items-center">
                    <Folder className="w-10 flex-none fill-current" style={{ color: space.color }} />
                    <div
                        className="-mt-1 ml-4 flex flex-col text-thunder"
                        style={{
                            width: 'calc(100% - 2.5rem - 0.25rem - 2rem)',
                        }}
                    >
                        {space.displayNameForUser(user).length > 28 ? (
                            <Tooltip tooltip={space.displayNameForUser(user)} as="span" className="truncate text-lg">
                                {space.displayNameForUser(user)}
                            </Tooltip>
                        ) : (
                            <span className="truncate text-lg">{space.displayNameForUser(user)}</span>
                        )}
                        {space.totalFiles > -1 && (
                            <span className="-mt-2 text-sm font-bold">
                                {t('web_space_number_files', { count: space.totalFiles })}
                            </span>
                        )}
                    </div>
                </Link>
                <div className="mt-4 flex h-6 w-full overflow-hidden rounded-full bg-gallery">
                    <div
                        className="flex h-6 items-center justify-center overflow-hidden bg-turquoise text-sm text-white"
                        style={{ width: `${space.ecoResponsiblePercent}%` }}
                    >
                        {space.ecoResponsiblePercent}%
                    </div>
                    <div
                        className="flex h-6 items-center justify-center overflow-hidden bg-gallery text-sm text-pale-sky"
                        style={{ width: `${space.toCompressPercent}%` }}
                    >
                        {space.toCompressPercent}%
                    </div>
                </div>

                <div className="mt-4 flex items-center">
                    <div className="h-2 w-2 flex-none rounded-50 bg-turquoise" />
                    <span className="ml-2 mr-6 text-sm">{t('web_space_eco_responsible')}</span>
                    <div className="h-2 w-2 flex-none rounded-50 bg-gallery" />
                    <span className="ml-2 mr-6 text-sm">{t('web_space_to_compress')}</span>
                </div>
            </div>
        </div>
    )
})

export default SpaceView
