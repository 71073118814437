import { getTypes } from 'components/admin/users/form'
import { get, put } from 'core/services/http-service'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { Brand } from 'stores/brands'
import { useMst } from 'stores/store'
import Admin from '../../admin/admin'
import Form, { type FormSchema } from './form'

interface Data {
    user?: FormSchema
}

export default function Edit() {
    const { brand } = useMst()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const { id, userId } = useParams()
    const [formValue, setFormValue] = useState<FormSchema>()

    const [brandId, setBrandId] = useState<string>()
    const types = getTypes(t)

    useEffect(() => {
        const load = async (id: string, userId: string) => {
            const { data } = await get<void, { data: Data }>(`/v1/web/franchises/${id}/users/${userId}`)
            const { user } = data

            const {
                data: { brand: franchiseBrand },
            } = await get<void, { data: { brand: Brand } }>(`/v1/web/franchises/${id}/brand`)

            setBrandId(franchiseBrand.uuid)

            const tags = await brand.loadTags(franchiseBrand.uuid)

            setFormValue({
                ...user,
                type: types.find(type => type.value === user.type),
                tags: tags
                    .map(tag => ({ value: tag.uuid, label: tag.name }))
                    .filter(tag =>
                        (user.tags as { uuid: string; name: string }[])
                            .map(t => t.uuid)
                            .flat()
                            .includes(tag.value)
                    ),
            })
        }
        load(id, userId)
    }, [id, userId])

    const onSubmit = async (data: FormSchema) => {
        const postData = {
            ...data,
            role: data.role.value,
            type: data.type.value,
            tags: data.tags?.map(t => t.value) ?? [],
        }
        setLoading(true)

        try {
            await put<typeof postData, never>(`/v1/web/franchises/${id}/users/${userId}`, postData)
            navigate(`/franchises/${id}/users`)
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <Admin title={t('web_menu_admin_user')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} values={formValue} brandId={brandId} />
        </Admin>
    )
}
