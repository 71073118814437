import { TFunction } from 'i18next'

const sufixes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'] as const
type FileSizeSuffix = (typeof sufixes)[number]

export const getFileSizeSuffix = (bytes: number): FileSizeSuffix => {
    if (bytes === 0) {
        return 'bytes'
    }

    const i = Math.round(Math.log(bytes) / Math.log(1024))
    if (Math.abs(i) === Infinity) {
        return 'bytes'
    }

    return sufixes[i]
}

export const getFileSize = (t: TFunction, bytes: number): string => {
    if (bytes === 0) {
        return t('web_file_size_bytes', { value: 0 })
    }

    const i = Math.round(Math.log(bytes) / Math.log(1024))
    if (Math.abs(i) === Infinity) {
        return t('web_file_size_bytes', { value: 0 })
    }
    const value = Math.round((bytes / Math.pow(1024, i)) * 100) / 100

    return t(`web_file_size_${sufixes[i]}`, { value })
}
