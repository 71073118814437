import { toJS } from 'mobx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useMst } from '../../../stores/store'
import Admin from '../admin'
import Form, { getRoles, getTypes, Schema } from './form'

const Edit = () => {
    const { admin, userTypeStore } = useMst()
    const { t } = useTranslation()
    const { id } = useParams()
    const [values, setValues] = useState<Record<string, unknown>>()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(true)

    const roles = getRoles(t)
    const types = getTypes(t)

    const onSubmit = async (data: Schema) => {
        const {
            fullname,
            email,
            secondaryEmail,
            phone,
            birthdate,
            role: { value: role },
            type: { value: type },
            partner,
            pricing,
            userType,
        } = data
        setLoading(true)
        await admin.updateUser(
            id,
            fullname,
            email,
            secondaryEmail,
            phone,
            birthdate,
            role,
            partner?.value ?? null,
            pricing.value,
            userType?.value ?? null,
            type
        )
        setLoading(false)
        navigate('/admin/users')
    }

    const load = async () => {
        setLoading(true)
        await admin.loadPartners(1000, 0)
        await admin.loadPricings(1000, 0)
        await userTypeStore.load(undefined, true)
        await admin.loadUser(id)
        setValues({
            ...toJS(admin.editingUser),
            birthdate: admin.editingUser.birthDate?.toJSDate(),
            role: roles.find(role => role.value === admin.editingUser.role),
            type: types.find(type => type.value === admin.editingUser.type),
            partner: [...admin.partners]
                .map(partner => ({ value: partner.uuid, label: partner.name }))
                .find(partner => partner.value === admin.editingUser.partner?.uuid),
            pricing: [...admin.pricings]
                .map(pricing => ({ value: pricing.uuid, label: pricing.name }))
                .find(pricing => pricing.value === admin.editingUser.pricing?.uuid),
            userType: [...userTypeStore.userTypes]
                .map(userType => ({ value: userType.uuid, label: userType.name }))
                .find(userType => userType.value === admin.editingUser.userType?.uuid),
        })
        setLoading(false)
    }
    useEffect(() => {
        load()
    }, [id])

    return (
        <Admin title={t('web_menu_admin_user')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} values={values} />
        </Admin>
    )
}

export default Edit
