import { observer } from 'mobx-react-lite'
import { createRef, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Email } from '../../assets/enveloppe.svg'
import { ReactComponent as Apple } from '../../assets/sso/apple.svg'
import { ReactComponent as Google } from '../../assets/sso/google.svg'
import { ReactComponent as LinkedIn } from '../../assets/sso/linkedin.svg'
import Config from '../../core/config'
import { useMst } from '../../stores/store'
import Panel from '../shared/panel'
import ProfilePage from './profile-page'

interface SsoMethodInfos {
    id?: string
    method: string
    name: string
    uuid: string
    email: string
    isConnected: boolean
    connect: () => void
}

interface Props {
    sso: SsoMethodInfos
    editable: boolean
    // onConnect?: (sso: string) => void
    onDisconnect?: (uuid: string) => void
}

const SsoMethod = observer(({ sso, editable, onDisconnect }: Props) => {
    const { t } = useTranslation()

    return (
        <div className="flex w-full items-center justify-between space-x-8">
            <div>
                {sso.method === '' && <Email className="h-4 w-4" />}
                {sso.method === 'apple' && <Apple className="h-4 w-4" />}
                {sso.method === 'google' && <Google className="h-4 w-4" />}
                {sso.method === 'linkedin' && <LinkedIn className="h-4 w-4" />}
            </div>

            {sso.isConnected ? (
                <span className="grow">
                    {sso.name} - (<span className="font-bold">{sso.email}</span>)
                </span>
            ) : (
                <span className="grow">{t('web_me_sso_not_connected')}</span>
            )}
            {editable && (
                <div className="w-48">
                    {sso.isConnected ? (
                        <button className="btn w-full" onClick={() => onDisconnect?.(sso.uuid)}>
                            {t('web_me_sso_logout')}
                        </button>
                    ) : (
                        <button
                            className="btn w-full"
                            id={sso.id}
                            onClick={async () => {
                                void sso.connect()
                            }}>
                            {t('web_me_sso_login')}
                        </button>
                    )}
                </div>
            )}
        </div>
    )
})

const Sso = observer(() => {
    const { user } = useMst()
    const { t } = useTranslation()

    const buttonRef = createRef<HTMLDivElement>()
    const [ssos, setSsos] = useState<SsoMethodInfos[]>([])

    const load = async () => {
        await user.getSsos()
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        buildMethods()
    }

    const buildMethods = () => {
        const ssos: SsoMethodInfos[] = []

        const methods = {
            google: {
                action: async () => {
                    window.google.accounts.id.prompt()
                },
            },
            apple: {
                action: async () => {
                    const params = {
                        clientId: Config.sso.apple.CLIENT_ID,
                        redirectURI: Config.sso.apple.REDIRECT_URI,
                        scope: 'name email',
                        state: 'connect',
                        usePopup: false,
                        nonce: user.id,
                    }
                    window.AppleID.auth.init(params)
                    try {
                        await window.AppleID.auth.signIn()
                    } catch (err) {
                        console.error('apple signin', err)
                    }
                },
            },
            linkedin: {
                action: async () => {
                    window.location.href = `${Config.app.APIURL}/v1/login/linkedin?uuid=${user.id}&from=web`
                },
            },
        }

        for (const method of Object.keys(methods)) {
            if (method === user.ssoMethod) {
                continue
            }
            const account = user.ssos.find(ac => ac.ssoMethod === method)
            ssos.push({
                method,
                name: account?.fullname ?? '',
                uuid: account?.id ?? '',
                email: account?.email ?? '',
                isConnected: account !== undefined,
                connect: methods[method].action,
                id: methods[method].id,
            })
        }
        setSsos(ssos)

        const handleCredentialResponse = async response => {
            await user.googleConnect(response.credential, user.id)
            load()
        }

        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID,
                callback: handleCredentialResponse,
            })
        }
    }

    useEffect(() => {
        load()
    }, [])

    const disconnect = async (uuid: string) => {
        await user.deleteSso(uuid)
        buildMethods()
    }

    return (
        <ProfilePage url="/me/logins">
            <Panel className="mt-4" innerClassName="flex justify-center bg-white">
                <div className="flex w-full flex-col md:w-2/3">
                    <h2 className="text-lg font-bold">{t('web_me_sso_methods')}</h2>
                    <span className="w-full pr-2 text-regent-gray">{t('web_me_sso_methods_desc')}</span>

                    <div className="my-4">
                        <h3 className="mb-2 text-lg">{t('web_me_sso_methods_default')}</h3>
                        <div className="flex w-full flex-col items-start space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                            <SsoMethod
                                sso={{
                                    method: user.ssoMethod,
                                    name: user.fullname,
                                    uuid: user.id,
                                    email: user.email,
                                    isConnected: true,
                                    connect: () => {},
                                }}
                                editable={false}
                            />
                        </div>
                    </div>
                    <div className="my-4">
                        <h3 className="mb-2 text-lg">{t('web_me_sso_methods_other')}</h3>
                        <div className="flex w-full flex-col items-start space-y-2">
                            {ssos.map(sso => {
                                return (
                                    <SsoMethod
                                        key={sso.method}
                                        sso={sso}
                                        editable={true}
                                        onDisconnect={uuid => disconnect(uuid)}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Panel>
        </ProfilePage>
    )
})

export default Sso
