import { Instance, types } from 'mobx-state-tree'

export const _AnyJsonValue = types.union(
    types.string,
    types.number,
    types.integer,
    types.Date,
    types.boolean,
    types.map(types.late(() => _AnyJsonValue)),
    types.array(types.late(() => _AnyJsonValue)),
    types.undefined,
    types.null
)
export interface AnyJsonValue extends Instance<typeof _AnyJsonValue> {}
