import { CSSProperties, FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as Logo } from '../../assets/todo.svg'
import { useMst } from '../../stores/store'
import SmallButton from './small-button'

interface TodoPage {
    title: string
    page: string
    className?: string
    style?: CSSProperties
}

const Todo: FC<TodoPage> = ({ title, page, className = '', style = {} }) => {
    const { t } = useTranslation()
    const { todo } = useMst()

    const [note, setNote] = useState<number | null>(null)
    const [message, setMessage] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(false)

    const pageTitle = `${page.charAt(0).toUpperCase()}${page.slice(1)}`

    const submit = async () => {
        if (note === null) {
            return
        }

        setLoading(true)
        await todo.submit(title, note, message)
    }

    return (
        <div className={`flex flex-col items-center ${className}`} style={style}>
            <Logo className="my-8" />
            <h2 className="text-2xl font-bold">{t('web_page_todo_title', { pageTitle })}</h2>
            <p className="my-6">
                <Trans i18nKey="web_page_todo_desc">{{ page }}</Trans>
            </p>
            <div className="flex flex-wrap items-center justify-center">
                <SmallButton name="0" size={6} active={note === 0} onClick={() => setNote(0)} />
                <SmallButton name="1" size={8} active={note === 1} onClick={() => setNote(1)} />
                <SmallButton name="2" size={10} active={note === 2} onClick={() => setNote(2)} />
                <SmallButton name="3" size={12} active={note === 3} onClick={() => setNote(3)} />
                <SmallButton name="4" size={14} active={note === 4} onClick={() => setNote(4)} />
                <SmallButton name="5" size={16} active={note === 5} onClick={() => setNote(5)} />
            </div>
            <h3 className="text-lg font-bold">{t('web_page_todo_tell_us_more')}</h3>
            <textarea
                className="my-4 w-full p-4"
                cols={80}
                rows={8}
                placeholder={t('web_page_todo_message_placeholder')}
                value={message}
                onChange={e => setMessage(e.currentTarget.value)}></textarea>
            <button
                className="btn px-12"
                onClick={() => submit()}
                disabled={isLoading || message === '' || note === null}>
                {t('web_page_todo_send')}
            </button>
        </div>
    )
}
export default Todo
