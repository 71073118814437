import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { scroller } from 'react-scroll'
import { ReactComponent as ArrowDown } from '../../assets/arrow/arrow-down.svg'
import { ReactComponent as ArrowRight } from '../../assets/arrow/arrow-right.svg'
import { ReactComponent as Like } from '../../assets/telecollecte/like.svg'
import { ReactComponent as Soon } from '../../assets/telecollecte/soon.svg'
import toast from '../../core/utils/toast'
import { useMst } from '../../stores/store'
import { Connector } from '../../stores/telecollecte/connector'
import SmallLoader from '../shared/small-loader'
import ConnectorImage from './connector-image'

interface SlidingPanelContent {
    title: string
    type?: string
    search?: string
    capabilities?: string
    isOpen: boolean
    children?: ReactNode
    isAvailable?: boolean
}

const showMoreConnectorsVisible = 10
const defaultMaxConnectorsVisible = 10

const SlidingPanel: FC<SlidingPanelContent> = observer(
    ({ children, title, type, capabilities, isOpen, search, isAvailable }) => {
        const { telecollecte } = useMst()
        const { t } = useTranslation()
        const [isOpened, open] = useState<boolean>(isOpen)
        const [connectors, setConnectors] = useState<Connector[]>([])
        const [loaded, setLoaded] = useState<boolean>(false)

        const [maxConnectorsVisible, setMaxConnectorsVisible] = useState<number>(defaultMaxConnectorsVisible)

        const addConnectors = (connectors: Connector[]): void => {
            const filteredConnectors = connectors.filter(connector => search === undefined || connector.uuid === search)
            setConnectors(filteredConnectors)
            setLoaded(true)
        }

        useEffect(() => {
            if (type) {
                const load = async (type: string) => {
                    let connectors = telecollecte.get(type)
                    if (connectors.length > 0) {
                        addConnectors(connectors)

                        return
                    }

                    await telecollecte.findConnectorsByType(type)
                    connectors = telecollecte.get(type)
                    addConnectors(connectors)
                }

                load(type)
            } else if (!isAvailable) {
                const load = async () => {
                    let connectors = telecollecte.hiddens
                    if (connectors.length > 0) {
                        addConnectors(connectors)

                        return
                    }

                    await telecollecte.findHiddenConnectors()
                    connectors = telecollecte.hiddens
                    addConnectors(connectors)
                }

                load()
            } else {
                const load = async () => {
                    await telecollecte.listConnectors()
                    addConnectors(telecollecte.connectors)
                }
                load()
            }
        }, [type, search, isAvailable])

        if (search && connectors.length === 0) {
            return null
        }

        return (
            <>
                <h1 className="flex items-center justify-between">
                    <div className="flex items-center" onClick={() => open(!isOpened)}>
                        <span className="text-lg">
                            {!type && <Trans i18nKey={title}>{title}</Trans>}
                            {type && (
                                <Trans i18nKey={title} values={{ type: t(`api_connectors_category_${type}`) }}>
                                    {title}
                                </Trans>
                            )}
                        </span>
                        {!isOpened && <ArrowRight className="ml-2 h-3 w-3" />}
                        {isOpened && <ArrowDown className="ml-2 h-3 w-3" />}
                    </div>
                    {children}
                </h1>
                {isOpened && (
                    <div className="mb-8 mt-4 flex flex-wrap">
                        {!isAvailable && <span className="w-full italic">{t('web_telecollecte_soon_desc')}</span>}
                        {!loaded && <SmallLoader />}
                        {(type ? connectors.slice(0, maxConnectorsVisible) : connectors).map((connector, index) => {
                            return (
                                <div key={index} className="w-1/2 p-2 md:w-40">
                                    <div
                                        className="relative flex h-36 w-full cursor-pointer flex-col items-center justify-center space-y-4 overflow-hidden bg-white shadow-lg"
                                        onClick={async () => {
                                            if (isAvailable) {
                                                if (capabilities) {
                                                    if (!telecollecte.canAddConnectors) {
                                                        toast('error', 'web_no_valid_subscription')

                                                        return
                                                    }
                                                    await telecollecte.addConnector(capabilities, connector)
                                                    const url = telecollecte.addConnectorUrl
                                                    window.location.href = url
                                                } else {
                                                    await telecollecte.manageConnector(connector)
                                                    const url = telecollecte.addConnectorUrl
                                                    window.location.href = url
                                                }
                                            } else {
                                                await connector.toggleLike()
                                            }
                                        }}>
                                        {!isAvailable && (
                                            <Like
                                                className={`absolute right-3 top-3 fill-current ${
                                                    connector.liked ? 'text-tan-hide' : 'text-geyser'
                                                }`}
                                            />
                                        )}

                                        <ConnectorImage className="h-14 w-14" connector={connector} />
                                        <span
                                            className={`mb-1 w-full break-words px-2 text-center ${
                                                isAvailable ? 'text-thunder' : 'text-heather'
                                            }`}>
                                            {connector.name}
                                        </span>
                                    </div>
                                </div>
                            )
                        })}

                        {!search && (type || !isAvailable) && connectors.length > maxConnectorsVisible && (
                            <div className="w-1/2 p-2 md:w-40">
                                <div
                                    className={`flex h-36 w-full flex-col items-center justify-center space-y-4 bg-white shadow-lg ${
                                        type ? 'cursor-pointer' : ''
                                    }`}
                                    onClick={async () => {
                                        setMaxConnectorsVisible(maxConnectorsVisible + showMoreConnectorsVisible)
                                    }}>
                                    <div
                                        className={
                                            'flex h-14 w-14 items-center justify-center rounded-full bg-geyser text-xs text-white'
                                        }>
                                        <span className="text-6xl font-bold">+</span>
                                    </div>
                                    <span className="mb-1 text-center">{t('web_telecollecte_show_more')}</span>
                                </div>
                            </div>
                        )}

                        {!search && type && isAvailable && (
                            <div className="w-1/2 p-2 md:w-40">
                                <div
                                    className={
                                        'flex h-36 w-full cursor-pointer flex-col items-center justify-center space-y-4 bg-white shadow-lg'
                                    }
                                    onClick={() => {
                                        scroller.scrollTo('soon', {
                                            duration: 100,
                                            delay: 100,
                                            smooth: true,
                                            offset: 50,
                                        })
                                    }}>
                                    <div
                                        className={
                                            'flex h-14 w-14 items-center justify-center rounded-full bg-geyser text-xs text-white'
                                        }>
                                        <Soon className="" />
                                    </div>
                                    <span className="mb-1 text-center">{t('web_telecollecte_soon')}</span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </>
        )
    }
)

export default SlidingPanel
