import { DateTime } from 'luxon'
import { Instance, types } from 'mobx-state-tree'

export const _UserType = types
    .model('user_types', {
        uuid: '',
        parentId: types.optional(types.string, '', [null, undefined]),
        // parent: types.optional(types.map(types.late(() => _UserType)),  { name: '', parentId: '' }, [null]),
        parent: types.optional(
            types.model({ name: types.string, parentId: types.optional(types.string, '', [null, undefined]) }),
            { name: '', parentId: '' },
            [null, undefined]
        ),
        parents: types.optional(types.string, '', [null, undefined]),
        name: '',
        createdAt: '',
        updatedAt: '',
    })
    .views(self => ({
        get date(): DateTime {
            return DateTime.fromISO(self.createdAt)
        },

        get relativeDate(): string {
            return this.date.toRelative() ?? ''
        },

        get smartDate(): string {
            const { days } = DateTime.local().diff(this.date, 'days')
            if (days > 7) {
                return this.date.toLocaleString(DateTime.DATETIME_SHORT)
            }

            return this.relativeDate
        },
    }))

export interface UserType extends Instance<typeof _UserType> {}
