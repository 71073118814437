import { SharedUser } from 'stores/files'
import SharingPanelGroupIcon from './sharing-panel-group-icon'

interface SharingPanelGroupUserProps {
    user: SharedUser
    size: 'big' | 'small'
    zIndex: number
    userType?: string
    accepted?: boolean
}
const SharingPanelGroupUser = ({ user, zIndex, size, userType, accepted }: SharingPanelGroupUserProps) => {
    return (
        <SharingPanelGroupIcon
            zIndex={zIndex}
            image={user.user.trustedUser.picture}
            size={size}
            userType={userType}
            accepted={accepted}
        />
    )
}
export default SharingPanelGroupUser
