import { FC, ReactNode } from 'react'

interface Props {
    id?: string
    children: ReactNode
    onClick?: () => void
}

const SsoButton: FC<Props> = ({ id, children, onClick }) => {
    return (
        <button
            id={id}
            className="ml-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-50 bg-white shadow-lg hover:shadow-sm focus:outline-none"
            onClick={onClick}>
            {children}
        </button>
    )
}

export default SsoButton
