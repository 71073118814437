import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { buttonClassName } from '.'
import Config from '../../core/config'
import { ReactComponent as CtaArrowTop } from '../../assets/file-transfer/cta-arrow-top.svg'

export default function Footer({ transferModalDisplayed }: { transferModalDisplayed: boolean }) {
    const { t } = useTranslation()

    return (
        <div
            className={clsx(
                transferModalDisplayed ? 'absolute bottom-0 inset-x-0' : 'lg:absolute lg:bottom-0 lg:inset-x-0 ',
                'bg-black/60 hidden lg:flex flex-col lg:flex-row gap-4',
                'items-center justify-around py-6 text-sm lg:text-base px-4'
            )}
        >
            <span
                className="text-white"
                // biome-ignore lint/security/noDangerouslySetInnerHtml: it's safe baby
                dangerouslySetInnerHTML={{ __html: t('web_file_transfer_upload_cta_text') }}
            />
            <a
                href={Config.app.WEBURL}
                target="_blank"
                className={clsx(buttonClassName, 'relative whitespace-nowrap')}
                rel="noreferrer"
            >
                <div className="absolute right-8 bottom-4 hidden lg:block">
                    <CtaArrowTop className="rotate-180" />
                </div>
                {t('web_file_transfer_cta_interested')}
            </a>
        </div>
    )
}
