import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import { isMacOs } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../assets/arrow/small-arrow-right.svg'
import { ReactComponent as Folder } from '../../assets/icons/icon-folder.svg'
import { ReactComponent as IconGlass } from '../../assets/icons/icon-glass.svg'
import { ReactComponent as SmallFolder } from '../../assets/icons/icon-small-folder.svg'
import useDebounce from '../../core/debounce'
import { SpaceDirectory } from '../../stores/files/directory'
import { SpaceFile } from '../../stores/files/file'
import { Space } from '../../stores/files/space'
import { SearchResult } from '../../stores/search'
import { SearchHistory } from '../../stores/search/history'
import { useMst } from '../../stores/store'
import { Connector } from '../../stores/telecollecte/connector'
import ConnectorImage from '../telecollecte/connector-image'
import FileIcon from './upload/file-icon'

const ShowHistory = ({ history, onClick }: { history: SearchHistory; onClick: (term: string) => void }) => {
    return (
        <li
            className="text-inner-space flex cursor-pointer items-center space-x-2 px-2 py-1 text-base"
            onClick={() => onClick(history.term)}>
            <IconGlass className="fill-current" />
            <span>{history.term}</span>
        </li>
    )
}
const ShowFile = ({ file }: { file: SpaceFile }) => {
    return (
        <>
            <div className="flex items-center space-x-2">
                {file.hasImage && <img src={file.imageRepresentation(25)} alt={file.name} className="w-5" />}
                {!file.hasImage && <FileIcon extension={file.extension} className="w-5" />}
                <span>{file.displayName}</span>
            </div>
            <div className="text-regent-gray flex items-center space-x-2 pl-4">
                <Arrow className="fill-current" />
                <span className="text-xs">{file.spaceName}</span>
                {!['', '/'].includes(file.directoryName) && (
                    <>
                        <Arrow className="fill-current" />
                        <span className="text-xs">{file.directoryName}</span>
                    </>
                )}
            </div>
        </>
    )
}
const ShowDirectory = ({ directory }: { directory: SpaceDirectory }) => {
    return (
        <>
            <div className="flex items-center space-x-2">
                <SmallFolder className="text-regent-gray w-5 fill-current" />
                <span>{directory.displayName}</span>
            </div>
            <div className="text-regent-gray flex items-center space-x-2 pl-4">
                <Arrow className="fill-current" />
                <span className="text-xs">{directory.spaceName}</span>
                {!['', '/'].includes(directory.parentDirectoryName) && (
                    <>
                        <Arrow className="fill-current" />
                        <span className="text-xs">{directory.parentDirectoryName}</span>
                    </>
                )}
            </div>
        </>
    )
}
const ShowSpace = ({ space }: { space: Space }) => {
    const { t } = useTranslation()

    return (
        <>
            <div className="flex items-center space-x-2">
                <Folder className="w-5 flex-none fill-current" style={{ color: space.color }} />
                <span>{space.displayName}</span>
            </div>
            <div className="text-regent-gray flex items-center space-x-2 pl-4">
                <Arrow className="fill-current" />
                <span className="text-xs">{space.parent ? space.parentName : t('web_breadcrumb_dataroom')}</span>
            </div>
        </>
    )
}
const ShowConnector = ({ connector }: { connector: Connector }) => {
    const { t } = useTranslation()

    return (
        <>
            <div className="flex items-center space-x-2">
                <ConnectorImage className="h-5 w-5" connector={connector} />
                <span>{connector.displayName}</span>
            </div>
            <div className="text-regent-gray flex items-center space-x-2 pl-4">
                <Arrow className="fill-current" />
                <span className="text-xs">{t('web_search_in_telecollecte')}</span>
            </div>
        </>
    )
}

const Result = ({ fileOrDirectory, onClick }: { fileOrDirectory: SearchResult; onClick: (url: string) => void }) => {
    const isDirectory = fileOrDirectory.isDirectory
    const isSpace = fileOrDirectory.isSpace
    const isFile = fileOrDirectory.isFile
    const isConnector = fileOrDirectory.isConnector

    return (
        <li
            className="border-geyser text-inner-space flex cursor-pointer flex-col border-b px-2 py-1 text-base"
            onClick={() => onClick(fileOrDirectory.url)}>
            {isConnector && <ShowConnector connector={fileOrDirectory as Connector} />}
            {isSpace && <ShowSpace space={fileOrDirectory as Space} />}
            {isDirectory && <ShowDirectory directory={fileOrDirectory as SpaceDirectory} />}
            {isFile && <ShowFile file={fileOrDirectory as SpaceFile} />}
        </li>
    )
}

const Search = observer(() => {
    const { t } = useTranslation()
    const { search, files } = useMst()
    const navigate = useNavigate()

    const [searchTerm, setSearchTerm] = useState<string>('')

    const [isVisible, setVisible] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)
    const popRef = useRef<HTMLDivElement>(null)

    const searchRef = useRef<HTMLInputElement>(null)

    const debouncedSearchTerm = useDebounce<string>(searchTerm, 500)

    const handleHideDropdown = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setVisible(false)
        }
    }, [])

    const handleClickOutside = useCallback(
        (event: Event) => {
            if (popRef.current && !popRef.current.contains(event.target as Node)) {
                setVisible(false)
            }
        },
        [popRef]
    )

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true)
        document.addEventListener('click', handleClickOutside, true)

        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true)
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    const handleSearch = useCallback(
        (event: KeyboardEvent) => {
            if (searchRef.current && event.key === '/' && event.shiftKey) {
                event.preventDefault()
                searchRef.current.focus()
            }
        },
        [searchRef]
    )

    useEffect(() => {
        document.addEventListener('keydown', handleSearch, true)

        return () => {
            document.removeEventListener('keydown', handleSearch, true)
        }
    })

    const load = async () => {
        if (debouncedSearchTerm) {
            setVisible(true)
            setLoading(true)
            await search.search(debouncedSearchTerm)
            setLoading(false)
        }
    }
    useEffect(() => {
        load()
    }, [debouncedSearchTerm])

    const goto = async (url: string) => {
        await search.save(searchTerm)
        setVisible(false)
        navigate(url, { relative: 'path' })
    }

    const loadFiles = async () => {
        // when we take the focus on the search field,
        // force the load of the spaces in order to find the parents
        await files.getFiles()
    }

    useEffect(() => {
        const onKeydown = (e: KeyboardEvent) => {
            if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault()
                searchRef.current?.focus()
            }
        }
        window.addEventListener('keydown', onKeydown)

        return () => window.removeEventListener('keydown', onKeydown)
    }, [])

    return (
        <div className="relative w-1/2 bg-white shadow-md">
            <div className="text-regent-gray focus:text-inner-space flex items-center px-4 py-2">
                <IconGlass className="fill-current" />
                <input
                    type="search"
                    id="search"
                    name="search"
                    ref={searchRef}
                    autoComplete="__away"
                    list="autocompleteOff"
                    onKeyDown={async e => {
                        if (debouncedSearchTerm && e.key === 'Enter') {
                            await search.save(debouncedSearchTerm)
                        }
                    }}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.currentTarget.value)}
                    onFocus={() => loadFiles()}
                    className="focus:text-inner-space m-0 grow border-none bg-transparent px-2 py-0 text-sm focus:border-none focus:outline-none"
                    placeholder={t('web_dataroom_search')}
                />
                <span className="pointer-events-none flex gap-1 p-1">
                    <kbd className="border-secondary text-secondary rounded border px-1 py-0.5 text-sm shadow-[inset_0_-1px_0_rgb(var(--secondary-color))]">
                        {isMacOs ? '⌘' : 'Ctrl'}
                    </kbd>
                    <kbd className="border-secondary text-secondary rounded border px-1 py-0.5 text-sm shadow-[inset_0_-1px_0_rgb(var(--secondary-color))]">
                        K
                    </kbd>
                </span>
            </div>
            <div ref={popRef}>
                {isVisible && (
                    <div
                        className="absolute z-50 float-left flex w-full origin-bottom-right flex-col overflow-auto rounded-b-lg border-t bg-white p-2 text-left text-base shadow-lg"
                        style={{ maxHeight: '20em' }}>
                        {isLoading ? (
                            <p className="flex items-center gap-2 px-2 py-1">
                                <span>{t('web_dataroom_search_loading')}</span>
                                <FontAwesomeIcon icon={faSpinner} spin />
                            </p>
                        ) : (
                            search.results.length === 0 && (
                                <p className="px-2 py-1">
                                    {t('web_dataroom_search_no_result', { searchTerm: debouncedSearchTerm })}
                                </p>
                            )
                        )}
                        {!isLoading && search.history.length > 0 && (
                            <div className="">
                                {search.history.map(history => (
                                    <ShowHistory
                                        key={history.term}
                                        history={history}
                                        onClick={term => {
                                            setSearchTerm(term)
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                        {!isLoading && search.results.length > 0 && (
                            <ul>
                                {search.results.map(fileOrDirectory => (
                                    <Result
                                        key={fileOrDirectory.uuid}
                                        fileOrDirectory={fileOrDirectory}
                                        onClick={url => goto(url)}
                                    />
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
})

export default Search
