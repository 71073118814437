import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SharedFile } from 'stores/files'
import Table, { Column } from '../../shared/table'
import { SharingPanelProps } from '../sharing-panel'
import DirectoryLine from './directory-line'
import FileLine from './file-line'
import SpaceLine from './space-line'

interface SharingPanelLineContentProps extends SharingPanelProps {
    sharedFiles: SharedFile[]
    setShowConfirmStop: (sharedFile: SharedFile) => void
    setShowRestart: (sharedFile: SharedFile) => void
    setShowConfirmDelete: (sharedFile: SharedFile) => void
}

const SharingPanelLineContent = observer(
    (
        {
            sharedFiles,
            showUserSharedByMe = false,
            showUserSharedWithMe = false,
            setDownloading,
            setShowConfirmStop,
            setShowRestart,
            setShowConfirmDelete,
        }: SharingPanelLineContentProps
    ) => {
        const { t } = useTranslation()
        const [columns, setColumns] = useState<Column[]>([])

        useEffect(() => {
            const cols = [
                { label: t('web_shares_file_name'), className: 'text-left text-sm' },
                {
                    label: showUserSharedByMe ? t('web_shares_shared_with') : t('web_shares_shared_by'),
                    className: 'text-sm',
                },
                { label: t('web_shares_shared_to'), className: 'text-sm' },
                { label: t('web_shares_status'), className: 'text-sm' },
                { label: t('web_shares_action'), className: 'text-sm' },
                { label: '', className: '' },
            ]

            setColumns(cols)
        }, [])

        return (
            <Table columns={columns}>
                {sharedFiles.length === 0 ? (
                    <tr>
                        <td colSpan={columns.length}>
                            {t(showUserSharedByMe ? 'web_share_no_share_by_me' : 'web_share_no_share_with_me')}
                        </td>
                    </tr>
                ) : (
                    sharedFiles.map(sharedFile => {
                        if (sharedFile.isFile) {
                            return (
                                <FileLine
                                    key={sharedFile.uuid}
                                    className="mb-1 h-8 border-b border-mercury transition-all"
                                    file={sharedFile.file}
                                    users={showUserSharedByMe ? sharedFile.users : undefined}
                                    owner={showUserSharedWithMe ? sharedFile.owner : undefined}
                                    partner={
                                        showUserSharedWithMe && sharedFile.partner.uuid ? sharedFile.partner : undefined
                                    }
                                    showUserSharedByMe={showUserSharedByMe}
                                    showUserSharedWithMe={showUserSharedWithMe}
                                    level={0}
                                    sharedFile={sharedFile}
                                    setDownloading={setDownloading}
                                    setShowConfirmStop={setShowConfirmStop}
                                    setShowRestart={setShowRestart}
                                    setShowConfirmDelete={setShowConfirmDelete}
                                />
                            )
                        } else if (sharedFile.isDirectory) {
                            return (
                                <DirectoryLine
                                    key={sharedFile.uuid}
                                    className="mb-1 h-8 border-b border-mercury transition-all"
                                    directory={sharedFile.directory}
                                    users={showUserSharedByMe ? sharedFile.users : undefined}
                                    owner={showUserSharedWithMe ? sharedFile.owner : undefined}
                                    partner={
                                        showUserSharedWithMe && sharedFile.partner.uuid ? sharedFile.partner : undefined
                                    }
                                    showUserSharedByMe={showUserSharedByMe}
                                    showUserSharedWithMe={showUserSharedWithMe}
                                    level={0}
                                    sharedFile={sharedFile}
                                    setDownloading={setDownloading}
                                    setShowConfirmStop={setShowConfirmStop}
                                    setShowRestart={setShowRestart}
                                    setShowConfirmDelete={setShowConfirmDelete}
                                />
                            )
                        } else if (sharedFile.isSpace) {
                            return (
                                <SpaceLine
                                    key={sharedFile.uuid}
                                    className="mb-1 h-8 border-b border-mercury transition-all"
                                    space={sharedFile.space}
                                    users={showUserSharedByMe ? sharedFile.users : undefined}
                                    owner={showUserSharedWithMe ? sharedFile.owner : undefined}
                                    partner={
                                        showUserSharedWithMe && sharedFile.partner.uuid ? sharedFile.partner : undefined
                                    }
                                    showUserSharedByMe={showUserSharedByMe}
                                    showUserSharedWithMe={showUserSharedWithMe}
                                    level={0}
                                    sharedFile={sharedFile}
                                    setDownloading={setDownloading}
                                    setShowConfirmStop={setShowConfirmStop}
                                    setShowRestart={setShowRestart}
                                    setShowConfirmDelete={setShowConfirmDelete}
                                />
                            )
                        }

                        return null
                    })
                )}
            </Table>
        )
    }
)

export default SharingPanelLineContent
