import { faEdit, faTrash, faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from 'assets/backoffice/loader'
import FranchiseIcon from 'assets/icons/backoffice/franchise'
import ToggleButton from 'components/shared/toggle-button'
import useDebounce from 'core/debounce'
import { del } from 'core/services/http-service'
import toast from 'core/utils/toast'
import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Brand } from 'stores/brands'
import { useMst } from 'stores/store'
import Admin from '../admin'
import Pagination from '../pagination'

const BrandLine = ({
    brand,
    showDelete,
    onDelete,
}: {
    brand: Brand
    showDelete: boolean
    onDelete: (brand: Brand) => void
}) => {
    const { t } = useTranslation()

    const { admin } = useMst()
    const [enabled, setEnabled] = useState<boolean>(brand.enabledAt !== null && brand.enabledAt !== '')
    const [isLoading, setLoading] = useState<boolean>(false)

    const toggleBrand = useCallback(async () => {
        setLoading(true)
        const refreshed = await admin.toggleBrand(brand.uuid)
        if (refreshed) {
            setEnabled(refreshed.enabledAt !== null)
        }
        setLoading(false)
    }, [brand.uuid])

    return (
        <tr>
            <td className="text-left">
                <div className="flex w-full items-center gap-2">
                    <img src={brand.icon} className="w-8" />
                    <span>{brand.name}</span>
                </div>
            </td>
            <td className="text-left">
                <a href={brand.url} target="_blank" rel="noopener noreferrer">
                    {brand.url}
                </a>
            </td>
            <td>
                <div className="flex w-full items-center justify-center">
                    {isLoading ? (
                        <Loader loading={isLoading} className="text-christine" />
                    ) : (
                        <ToggleButton value={enabled} onChange={() => toggleBrand()} />
                    )}
                </div>
            </td>
            <td>
                <div className="mr-2 flex items-center justify-end space-x-1 text-christine">
                    <Link to={`/admin/brands/${brand.uuid}`}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Link>
                    <Link to={`/admin/brands/${brand.uuid}/franchises`}>
                        <FranchiseIcon className="h-5 w-5" />
                    </Link>
                    <Link to={`/admin/brands/${brand.uuid}/users`}>
                        <FontAwesomeIcon icon={faUser} />
                    </Link>
                    {showDelete && (
                        <button
                            type="button"
                            onClick={async () => {
                                if (confirm(t('web_admin_confirm_delete_brand'))) {
                                    onDelete(brand)
                                }
                            }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    )}
                </div>
            </td>
        </tr>
    )
}

const limit = 50

const Listing = observer(() => {
    const { admin, user } = useMst()
    const { t } = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [offset, setOffset] = useState<number>(0)
    const [query, setQuery] = useState<string>('')
    const [page, setPage] = useState<number>(0)
    const [pageCount, setPageCount] = useState<number>(0)

    const load = useCallback(async () => {
        setLoading(true)
        await admin.loadBrands(limit, offset, query)

        setPageCount(Math.ceil(admin.totalBrands / limit))
        setLoading(false)
    }, [offset, query])

    const changePage = async page => {
        setPage(page)
        setOffset(Math.ceil(page * limit))
    }

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            load()
        }
    }

    const debouncedQuery = useDebounce<string>(query, 500)
    useEffect(() => {
        load()
    }, [page, debouncedQuery])

    useEffect(() => {
        setPage(0)
    }, [])

    const [showDelete, setShowDelete] = useState<boolean>(false)
    useEffect(() => {
        setShowDelete(user.isAdmin)
    }, [user])

    const deleteBrand = async (brand: Brand) => {
        setLoading(true)
        await del(`/v1/bo/brands/${brand.uuid}`)
        await load()
        toast('success', 'web_admin_brand_deleted')
    }

    return (
        <Admin
            title={t('web_admin_brands')}
            isLoading={isLoading}
            header={
                <div className="flex items-center space-x-4">
                    <Link to="/admin/brands/create" className="btn">
                        {t('web_admin_brands_new')}
                    </Link>
                    <div className="flex items-center rounded border bg-white px-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2 h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>
                        <input
                            type="text"
                            className="m-0 grow border-none bg-transparent p-0 text-sm focus:border-none focus:outline-none"
                            placeholder={t('web_dashboard_search')}
                            onChange={e => setQuery(e.currentTarget.value)}
                            onKeyDown={e => onKeyDown(e)}
                        />
                    </div>
                </div>
            }>
            <table className="admin w-full table-auto text-center">
                <thead>
                    <tr>
                        <th>{t('web_menu_admin_brands_name')}</th>
                        <th>{t('web_menu_admin_brands_status')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {values<Brand>(admin.brands).map(brand => (
                        <BrandLine
                            key={brand.uuid}
                            brand={brand}
                            showDelete={showDelete}
                            onDelete={async brand => await deleteBrand(brand)}
                        />
                    ))}
                </tbody>
            </table>
            <div className="mt-8 flex w-full justify-center">
                {pageCount > 1 && <Pagination page={page} totalPages={pageCount} onChangePage={changePage} />}
            </div>
        </Admin>
    )
})

export default Listing
