import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Space } from 'stores/files/space'
import { useMst } from 'stores/store'
import Panel from '../shared/panel'
import ToggleButton from '../shared/toggle-button'
import { getFileSize } from './upload/file-size'

const StorageStatistics = observer(() => {
    const { files, user } = useMst()
    const { t } = useTranslation()
    const [stockageView, setStockageView] = useState<boolean>(false)

    const {
        meta: { percent, current: currentValue, max: maxValue, sorted, toSort, preSorted, quarantine },
    } = files
    const current = getFileSize(t, currentValue)
    const max = getFileSize(t, maxValue)

    return (
        <Panel className="mb-8">
            <div>
                <div className="flex items-center justify-between">
                    <span className="text-lg">
                        <Trans i18nKey="web_dataroom_current_storage_space">
                            {{ percent }}, {{ current }}, {{ max }}
                        </Trans>
                    </span>
                    <ToggleButton
                        value={stockageView}
                        onChange={v => setStockageView(v)}
                        tooltip={t('web_tooltips_dataroom_toggle_display')}
                    />
                </div>
                <div className="mt-4 flex h-6 w-full overflow-hidden rounded bg-gallery">
                    {!stockageView && (
                        <>
                            <div
                                className="flex h-6 items-center justify-center overflow-hidden bg-melrose text-sm text-white"
                                style={{ width: `${sorted}%` }}>
                                {sorted}%
                            </div>
                            <div
                                className="flex h-6 items-center justify-center overflow-hidden bg-seagull text-sm text-white"
                                style={{ width: `${toSort}%` }}>
                                {toSort}%
                            </div>
                            <div
                                className="flex h-6 items-center justify-center overflow-hidden bg-yellow-orange text-sm text-white"
                                style={{ width: `${preSorted}%` }}>
                                {preSorted}%
                            </div>
                            <div
                                className="flex h-6 items-center justify-center overflow-hidden bg-japonica text-sm text-white"
                                style={{ width: `${quarantine}%` }}>
                                {quarantine}%
                            </div>
                        </>
                    )}
                    {stockageView &&
                        values<Space>(files.spaces).map(stack => (
                            <div
                                key={stack.uuid}
                                className="flex h-6 items-center justify-center overflow-hidden text-sm text-white"
                                style={{ width: `${stack.sizeInPercent(maxValue)}%`, backgroundColor: stack.color }}>
                                {getFileSize(t, stack.size)}
                            </div>
                        ))}
                </div>

                <div className="mt-4 flex items-center">
                    {!stockageView && (
                        <>
                            <div className="h-2 w-2 rounded-50 bg-melrose"></div>
                            <span className="ml-2 mr-6">{t('web_dataroom_legend_sorted')}</span>
                            <div className="h-2 w-2 rounded-50 bg-seagull"></div>
                            <span className="ml-2 mr-6">{t('web_dataroom_legend_to_sort')}</span>
                            <div className="h-2 w-2 rounded-50 bg-yellow-orange"></div>
                            <span className="ml-2 mr-6">{t('web_dataroom_legend_pre_sorted')}</span>
                            <div className="h-2 w-2 rounded-50 bg-japonica"></div>
                            <span className="ml-2 mr-6">{t('web_dataroom_legend_quarantine')}</span>
                        </>
                    )}
                    {stockageView && (
                        <div className="grid md:grid-cols-2 lg:grid-cols-4">
                            {values<Space>(files.spaces).map(stack => (
                                <div key={stack.uuid} className="flex items-center">
                                    <div className="h-2 w-2 rounded-50" style={{ backgroundColor: stack.color }}></div>
                                    <span className="ml-2 mr-6">{stack.displayNameForUser(user)}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </Panel>
    )
})

export default StorageStatistics
