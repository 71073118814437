import Tooltip from 'components/shared/tooltip'
import { SharedUser } from 'stores/files'
import SharingPanelLineUser from './sharing-panel-line-user'

const SharingPanelLineUsers = ({ users }: { users: SharedUser[] }) => {
    switch (users.length) {
        case 0:
            return null
        case 1:
            return <SharingPanelLineUser user={users[0]} zIndex={1} />
        case 2:
            return (
                <>
                    {users.map((user, index) => (
                        <SharingPanelLineUser key={user.uuid} user={user} zIndex={users.length - index} />
                    ))}
                </>
            )
        default: {
            const allUsers = [...users]
            const shownUsers = allUsers.splice(0, 2)

            return (
                <>
                    {shownUsers.map((user, index) => (
                        <SharingPanelLineUser key={user.uuid} user={user} zIndex={users.length - index} />
                    ))}
                    <div className="relative font-nunito text-xs font-bold text-thunder" style={{ zIndex: 1 }}>
                        <Tooltip
                            tooltip={
                                <ul>
                                    {allUsers.map((user, index) => (
                                        <li key={index}>{user.user.trustedUser.fullname}</li>
                                    ))}
                                </ul>
                            }>
                            <span className="ml-2">+{allUsers.length}</span>
                        </Tooltip>
                    </div>
                </>
            )
        }
    }
}
export default SharingPanelLineUsers
