import { i18nCookieExpires, i18nCookieName } from 'app'
import clsx from 'clsx'
import { availableLocales } from 'core/i18n'
import { ReactElement, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/large-logo.svg'
import WizardProgress from '../shared/wizard-progress'
import { AppContext } from 'context'
import ViaTreasy from 'assets/via-treasy'

interface Props {
    title?: string | ReactElement
    subTitle?: string | ReactElement
    image: string
    lodoDiv?: ReactNode
    children: ReactNode
    bottomChildren?: ReactNode
    page?: number
    maxPages?: number
    textPos?: string
    part?: string
}

const TwoPart = ({ subTitle, image, page, maxPages, children, bottomChildren, part, lodoDiv }: Props) => {
    const { t, i18n } = useTranslation()
    const { logo: contextLogo } = useContext(AppContext)

    return (
        <div className="flex h-full min-h-screen flex-col items-center justify-center bg-white lg:flex-row">
            <div className="w-full bg-white md:w-1/2 lg:h-full lg:min-h-screen lg:bg-gradient-to-b lg:from-white lg:to-athens-gray xl:w-2/3">
                {lodoDiv ? (
                    lodoDiv
                ) : (
                    <div className="flex">
                        <div className="flex flex-col items-end md:m-8">
                            <img
                                src={contextLogo ?? logo}
                                className={clsx(contextLogo ? 'max-h-20' : '', 'm-auto')}
                                alt="Treasy"
                            />
                            {contextLogo && <ViaTreasy className="text-[#2c2a2c]" />}
                        </div>
                    </div>
                )}
                <div className="m-auto hidden max-w-4xl flex-col justify-center text-left md:flex">
                    <img src={image} alt="" className="m-auto max-w-md object-contain" />
                    {subTitle && part === 'register' && <p className="m-8 hidden text-left lg:block">{subTitle}</p>}
                    {subTitle && (part === 'login' || !part) && (
                        <p className="m-8 hidden text-center lg:block">{subTitle}</p>
                    )}
                    {page && maxPages && <WizardProgress max={maxPages} current={page} />}
                </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center p-4 md:w-1/2 lg:h-full xl:w-1/3">
                <div className={clsx('mt-8 flex w-full grow flex-col items-center justify-center')}>{children}</div>
                {bottomChildren && (
                    <div className="flex grow p-8">
                        <div className="flex items-center self-end">{bottomChildren}</div>
                    </div>
                )}
                <div className="mt-8 flex w-full items-center justify-center divide-x divide-inner-space text-sm text-inner-space">
                    {availableLocales.map(locale => (
                        <button
                            key={locale}
                            className="px-2"
                            onClick={() =>
                                i18n.changeLanguage(locale, () => {
                                    document.cookie = `${i18nCookieName}=${locale};max-age=${i18nCookieExpires}; path=/;`
                                })
                            }
                        >
                            {t(`web_locale_${locale}`)}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TwoPart
