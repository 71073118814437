import { SharedUser } from 'stores/files'
import SharingPanelLineIcon from './sharing-panel-line-icon'

const SharingPanelLineUser = ({ user, zIndex }: { user: SharedUser; zIndex: number }) => {
    return (
        <SharingPanelLineIcon
            tooltip={user.user.trustedUser.fullname}
            zIndex={zIndex}
            image={user.user.trustedUser.picture}
        />
    )
}

export default SharingPanelLineUser
