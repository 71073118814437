import path from 'path'
import { faCircleChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Ocr from 'assets/dataroom/ocr'
import { clsx } from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { SuggestionTag } from 'stores/files/suggestion'
import { SpaceFile } from '../../../stores/files/file'
import Modal from '../../shared/modal'

interface Props {
    isOpen: boolean
    file?: SpaceFile
    onValidate: (
        filename: string,
        tags: SuggestionTag[] | null,
        separator: string | null,
        transform: string | null
    ) => void
    onManualRename?: () => void
    onRequestClose: () => void
}

interface TagProps {
    tag: SuggestionTag
    onRemove?: (tag: SuggestionTag) => void
    disabled: boolean
}

const Tag = ({ tag, onRemove, disabled }: TagProps) => {
    const label = (tag: SuggestionTag) => {
        if (tag.value === tag.label) {
            return tag.value
        }

        return `${tag.value} (${tag.label})`
    }

    return (
        <span
            draggable={true}
            className={clsx(
                'flex grow-0 gap-2 rounded px-3 py-0.5 text-xs',
                disabled ? 'bg-gallery text-thunder' : 'bg-atomic-tangerine text-white'
            )}
            onDragStart={event =>
                event.dataTransfer.setData(
                    'text',
                    JSON.stringify({ ...tag, shouldRemoveFirst: typeof onRemove !== 'undefined' })
                )
            }>
            <span>{label(tag)}</span>
            {onRemove && <span onClick={() => onRemove(tag)}>x</span>}
        </span>
    )
}

type Case = 'lowercase' | 'uppercase' | 'capitalize'
const changeCase = (str: string | null, caseType: Case) => {
    switch (caseType) {
        case 'lowercase':
            return str.toLocaleLowerCase()
        case 'uppercase':
            return str.toLocaleUpperCase()
        case 'capitalize':
            return str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase()
        default:
            return str
    }
}

const selectStyles = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    control: (provided: Record<string, string | number>, state: unknown): Record<string, string | number> => ({
        ...provided,
        borderColor: '#D8E0E6',
        borderRadius: 0,
        padding: '0.1rem 0',
    }),
}

const RenameModal = ({ isOpen, file, onValidate, onManualRename, onRequestClose }: Props) => {
    const { t } = useTranslation()

    const [hasOcr, setHasOcr] = useState<boolean>(false)
    const [ocrStatus, setOcrStatus] = useState<string>()

    const [allTags, setAllTags] = useState<SuggestionTag[]>([])
    const [tags, setTags] = useState<SuggestionTag[]>([])

    const [saveModel, setSaveModel] = useState<boolean>(false)

    const [selectedSuggestion, setSelectedSuggestion] = useState<string>('')

    const separatorOptions = [
        { value: '-', label: t('web_rename_modal_separator_dash') },
        { value: '_', label: t('web_rename_modal_separator_undescore') },
        { value: ' ', label: t('web_rename_modal_separator_space') },
    ]
    const [separator, setSeparator] = useState<{ value: string; label: string }>(separatorOptions[0])
    const transformOptions = [
        { value: null, label: t('web_rename_modal_transform_none') },
        { value: 'lowercase', label: t('web_rename_modal_transform_lowercase') },
        { value: 'uppercase', label: t('web_rename_modal_transform_uppercase') },
        { value: 'capitalize', label: t('web_rename_modal_transform_capitalize') },
    ]
    const [transform, setTransform] = useState<{ value: string | null; label: string }>(transformOptions[0])

    const makeFilename = useCallback(
        (filename: string) => {
            return filename
                .split(/[_-\s]+/)
                .map(part => changeCase(part, transform.value as Case))
                .join(separator.value)
        },
        [separator, transform]
    )

    useEffect(() => {
        setSelectedSuggestion(suggestion => makeFilename(suggestion))
    }, [separator, transform])

    const clear = () => {
        setHasOcr(false)
        setOcrStatus(undefined)
        setSeparator(separatorOptions[0])
        setTransform(transformOptions[0])
        setAllTags([])
        setTags([])
        setSelectedSuggestion('')
    }

    useEffect(() => {
        if (!file) {
            clear()

            return
        }

        setHasOcr(file.ocr && file.ocr.jobId !== '')
        setOcrStatus(file.ocr?.status)

        const allTags = file.suggestion.tags.map(({ key, value, label }) => ({ key, value, label }))
        setAllTags(allTags)

        if (file.suggestion.separator !== '') {
            const separator = separatorOptions.find(option => option.value === file.suggestion.separator)
            setSeparator(separator ?? separatorOptions[0])
        }
        if (file.suggestion.transform !== '') {
            const transform = transformOptions.find(option => option.value === file.suggestion.transform)
            setTransform(transform ?? transformOptions[0])
        }

        setTags(file.suggestion.saveTags.map(({ key, value, label }) => ({ key, value, label })))

        setSelectedSuggestion(
            file.suggestion.saveTags.length > 0
                ? makeFilename(`${file.suggestion.saveTags.map(tag => tag.value).join('-')}.${file.extension}`)
                : makeFilename(file.filename)
        )
    }, [file])

    const onDrop = event => {
        event.preventDefault()
        const data = JSON.parse(event.dataTransfer.getData('text'))
        let newTags = tags
        if (data.shouldRemoveFirst) {
            newTags = newTags.filter(t => t.key !== data.key)
        }

        newTags.push(data)
        setTags(newTags)
        setSelectedSuggestion(
            newTags.length > 0
                ? makeFilename(`${newTags.map(tag => tag.value).join('-')}.${file.extension}`)
                : undefined
        )
    }

    const onRemove = useCallback(
        (tag: SuggestionTag) => {
            const newTags = tags.filter(t => t.key !== tag.key)
            setTags(newTags)
            setSelectedSuggestion(
                newTags.length > 0
                    ? makeFilename(`${newTags.map(tag => tag.value).join('-')}.${file.extension}`)
                    : undefined
            )
        },
        [tags, file]
    )

    if (!file) {
        return null
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} size="2/3" overflowHidden={false} maxHeight="100%">
            <div className="grid w-full grid-cols-1 items-center justify-center gap-4 lg:w-3/4">
                {hasOcr && (
                    <div className="flex justify-center">
                        <Ocr className="w-12 text-christine" />
                    </div>
                )}
                <h4 className="text-center font-nunito text-xl font-bold">{t('web_rename_modal_rename_title')}</h4>
                <p className="text-center">
                    <span className="font-bold text-atomic-tangerine">{t('web_rename_modal_rename_orig')}</span>{' '}
                    <span>{file.origFilename}</span>
                </p>
                <p className="text-center font-bold">{t('web_rename_modal_rename_suggest')}</p>
                <ul className="px-12">
                    {file.suggestion.suggestions.map((s, index) => {
                        const filename = makeFilename(s)

                        return (
                            <li key={index}>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="text-atomic-tangerine"
                                        value={selectedSuggestion}
                                        checked={selectedSuggestion === filename && tags.length === 0}
                                        onChange={() => {
                                            setSelectedSuggestion(filename)
                                            setTags([])
                                        }}
                                    />
                                    <span
                                        className={clsx(
                                            'ml-2',
                                            selectedSuggestion === filename && tags.length === 0 ? 'font-bold' : ''
                                        )}>
                                        {path.basename(filename)}
                                    </span>
                                </label>
                            </li>
                        )
                    })}
                </ul>

                {typeof ocrStatus !== 'undefined' && ocrStatus !== '' && ocrStatus !== 'finished' ? (
                    <span className="text-center">{t(`web_tooltips_dataroom_ocr_${ocrStatus}`)}</span>
                ) : (
                    allTags.length > 0 && (
                        <>
                            <p className="text-center">
                                <span className="font-bold text-atomic-tangerine underline">
                                    {t('web_rename_modal_rename_or')}
                                </span>
                            </p>
                            <p className="text-center">
                                <span className="font-bold">{t('web_rename_modal_rename_make_yours')}</span>
                                <br />
                                <span className="text-sm">{t('web_rename_modal_rename_make_yours_help')}</span>
                            </p>
                            <div className="flex w-full flex-col gap-4 px-12">
                                <div className="flex w-full flex-wrap gap-2">
                                    {allTags
                                        .filter(tag => tags.find(t => t.key === tag.key) === undefined)
                                        .map(tag => (
                                            <Tag key={tag.key} tag={tag} disabled={true} />
                                        ))}
                                </div>
                                <div
                                    className="flex min-h-[2rem] w-full flex-wrap items-center gap-2 rounded bg-gallery px-2"
                                    onDragOver={e => e.preventDefault()}
                                    onDrop={onDrop}>
                                    {tags.map(tag => (
                                        <Tag key={tag.key} tag={tag} onRemove={onRemove} disabled={false} />
                                    ))}
                                </div>
                            </div>
                            {tags.length > 0 && (
                                <div className="flex items-center justify-center space-x-2">
                                    <FontAwesomeIcon
                                        icon={faCircleChevronRight}
                                        className="w-4 text-atomic-tangerine"
                                    />
                                    <div>
                                        <span className="font-bold text-atomic-tangerine">
                                            {t('web_rename_modal_rename_choosen')}
                                        </span>{' '}
                                        <span>{selectedSuggestion}</span>
                                    </div>
                                </div>
                            )}
                        </>
                    )
                )}
                <div className="flex items-center justify-center space-x-4 px-12 text-sm">
                    <span>{t('web_rename_modal_separator')}</span>
                    <Select
                        styles={selectStyles}
                        className="w-1/3"
                        value={separator}
                        options={separatorOptions}
                        onChange={value => setSeparator(value)}
                    />
                    <span>{t('web_rename_modal_transform')}</span>
                    <Select
                        styles={selectStyles}
                        className="w-1/3"
                        value={transform}
                        options={transformOptions}
                        onChange={value => setTransform(value)}
                    />
                </div>
                {['ocr', 'bi'].includes(file.suggestion.imported) && tags.length > 0 && (
                    <div className="w-full px-12">
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="text-atomic-tangerine"
                                checked={saveModel}
                                onChange={() => setSaveModel(s => !s)}
                            />
                            <span className={clsx('ml-2')}>{t('web_rename_modal_save_model')}</span>
                        </label>
                    </div>
                )}
                <div className="mb-2 mt-6 flex w-full justify-around">
                    <button
                        className="btn white px-8"
                        onClick={() => {
                            onRequestClose()
                            clear()
                        }}>
                        {t('web_rename_modal_rename_cancel')}
                    </button>
                    {onManualRename && (
                        <button
                            className="btn white px-8"
                            onClick={() => {
                                onManualRename()
                                clear()
                            }}>
                            {t('web_rename_modal_rename_modify')}
                        </button>
                    )}
                    <button
                        className="btn px-8"
                        disabled={!selectedSuggestion || selectedSuggestion === ''}
                        onClick={() => {
                            let saveTags = null
                            let saveSeparator = null
                            let saveTransform = null
                            if (saveModel && tags.length > 0) {
                                saveTags = tags
                                saveSeparator = separator.value
                                saveTransform = transform.value
                            }
                            onValidate(selectedSuggestion, saveTags, saveSeparator, saveTransform)
                            clear()
                        }}>
                        {t('web_rename_modal_rename_validate')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default RenameModal
