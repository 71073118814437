import { faChevronsUp, faSearch, faSpinner, faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'
import Admin from 'components/admin/admin'
import { get } from 'core/services/http-service'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useMst } from 'stores/store'

interface Data {
    franchises: {
        uuid: string
        name: string
    }[]
    nextCursor?: number
}

const Users = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const { user } = useMst()

    const [search, setSearch] = useState<string>()
    const [gotoTopButton, showGotoTopButton] = useState<boolean>(false)

    useEffect(() => {
        const handleScroll = () => {
            showGotoTopButton(window.scrollY > 0)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const fetch = useCallback(
        async ({ pageParam = 0 }) => {
            const { data } = await get<{ cursor: number; search?: string }, { data: Data }>(
                `/v1/web/brands/${id}/franchises`,
                {
                    cursor: pageParam,
                    search: search && search.length > 0 ? search : undefined,
                }
            )

            return data
        },
        [search]
    )

    const { data, fetchNextPage, hasNextPage, refetch, isLoading } = useInfiniteQuery({
        queryKey: ['franchises'],
        queryFn: fetch,
        initialPageParam: null,
        getNextPageParam: lastPage => lastPage.nextCursor,
    })

    return (
        <Admin
            title={user.brands.find(b => b.uuid === id)?.getConfig()?.franchise_label ?? t('web_admin_franchises')}
            isLoading={isLoading}
            header={
                <div className="flex items-center space-x-4">
                    <div className="flex items-center gap-1 rounded border bg-white px-4 py-1">
                        <FontAwesomeIcon icon={faSearch} size="sm" className="text-gray-500" />
                        <input
                            type="search"
                            className="m-0 grow border-none bg-transparent p-0 text-sm focus:border-none focus:outline-none"
                            placeholder={t('web_dashboard_search')}
                            onChange={e => {
                                let value = e.currentTarget.value.trim()
                                if (value === '') {
                                    value = undefined
                                }
                                setSearch(value)
                                if (!value || value.length >= 3) {
                                    refetch()
                                }
                            }}
                        />
                        {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                    </div>
                </div>
            }
        >
            {(data?.pages ?? []).flatMap(p => p.franchises).length > 0 ? (
                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => fetchNextPage()}
                    hasMore={hasNextPage}
                    loader={<FontAwesomeIcon icon={faSpinner} spin key={0} />}
                >
                    <table className="admin w-full table-auto">
                        <thead>
                            <tr>
                                <th className="text-left">{t('web_franchise_name_listing')}</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {(data?.pages ?? [])
                                .flatMap(p => p.franchises)
                                .filter((item, index, self) => self.findIndex(i => i.uuid === item.uuid) === index)
                                .map(item => (
                                    <tr key={item.uuid}>
                                        <td>{item.name}</td>
                                        <td>
                                            <Link to={`/franchises/${item.uuid}/users`}>
                                                <FontAwesomeIcon icon={faUser} />
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </InfiniteScroll>
            ) : (
                <p className="text-sm">
                    <Trans
                        i18nKey={search ? 'web_franchise_users_no_items_search' : 'web_franchise_users_no_items'}
                        components={[<strong key={0} />]}
                        values={{ search }}
                    />
                </p>
            )}
            {gotoTopButton && (
                <div
                    className="bg-chathams-blue fixed bottom-5 right-5 cursor-pointer rounded p-2 text-white"
                    onClick={() =>
                        window.scrollTo({
                            top: 0,
                        })
                    }
                >
                    <FontAwesomeIcon icon={faChevronsUp} fixedWidth />
                </div>
            )}
        </Admin>
    )
}

export default Users
