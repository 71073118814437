import { Input, selectStyles } from 'components/shared/select-styles'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'
import { useMst } from 'stores/store'
import { FormProps, optionValueSchema } from '../forms'
import Label from '../label'
import 'components/admin/zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const schema = z.object({
    name: z.string().min(1),
    parent: optionValueSchema.optional(),
})
export type Schema = z.infer<typeof schema>

const Form = ({ onSubmit, values }: FormProps<Schema>) => {
    const { t } = useTranslation()
    const { userTypeStore } = useMst()

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })

    useEffect(() => {
        if (!values) {
            return
        }

        for (const key of Object.keys(values) as (keyof Schema)[]) {
            setValue(key, values[key])
        }
    }, [values])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Label
                name="name"
                error={errors['name'] && `${t('web_admin_user_type_name')} ${errors['name']?.message}`}
                label={'web_admin_user_type_name'}
                className="my-5 flex w-full flex-wrap items-center">
                <input {...register('name')} type="text" className="w-1/3 " />
            </Label>
            <Label
                name="parent"
                error={errors['parent'] && `${t('web_admin_user_type_parent')} ${errors['parent']?.message}`}
                label={'web_admin_user_type_parent'}
                className="my-5 flex w-full flex-wrap  items-center">
                <Controller
                    name="parent"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <AsyncSelect
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                isClearable={true}
                                defaultOptions
                                placeholder={t('web_admin_placeholder_select')}
                                loadOptions={async (inputValue: string) => {
                                    return (
                                        inputValue.trim().length === 0
                                            ? userTypeStore.userTypes
                                            : userTypeStore.userTypes.filter(userType =>
                                                  userType.name.toLowerCase().includes(inputValue.trim().toLowerCase())
                                              )
                                    ).map(userType => ({
                                        value: userType.uuid,
                                        label: userType.name,
                                    }))
                                }}
                                onChange={user => onChange(user)}
                            />
                        )
                    }}
                />
            </Label>

            <input type="submit" className="btn float-right cursor-pointer" value={`${t('web_admin_user_save')}`} />
        </form>
    )
}

export default Form
