import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconPrint } from '../../assets/icons/icon-print.svg'
import { useMst } from '../../stores/store'
import ContentPage from '../dataroom/content-page'
import Panel from '../shared/panel'

interface Props {
    title: string
    name: string
    children?: ReactNode
}

const CmsPage: FC<Props> = observer(({ title, name, children }) => {
    const store = useMst()
    const { pages } = store
    const { t } = useTranslation()

    const [content, setContent] = useState<string>('')

    useEffect(() => {
        if (!pages.has(name))
            (async () => {
                await store.getCmsPages()
                const page = pages.get(name)
                if (page) {
                    setContent(page.text)
                }
            })()
        if (pages.has(name)) {
            const page = pages.get(name)
            if (page) {
                setContent(page.text)
            }
        }
    }, [pages])

    return (
        <ContentPage title={t(title)}>
            <Panel className="cms-page relative mt-4">
                <div className="absolute right-3 top-3">
                    <div
                        onClick={() => window.print()}
                        className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-white shadow-lg">
                        <IconPrint className="fill-current print:hidden" />
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </Panel>
            {children}
        </ContentPage>
    )
})

export default CmsPage
