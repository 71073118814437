import toast from 'core/utils/toast'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useMst } from 'stores/store'
import Admin from '../admin/admin'
import MdEditor from '../shared/md-editor'
import Modal from '../shared/modal'

const Email: FC = observer(() => {
    const { t } = useTranslation()
    const { admin } = useMst()
    const { id } = useParams()

    const [emailContent, setEmailContent] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)
    const [showInvitationError, setShowInvitationError] = useState<boolean>(false)

    const load = async () => {
        setEmailContent('')
        setLoading(true)
        await admin.loadPartnerEmailTemplate(id)
        setEmailContent(admin.emailTemplate)
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    const save = async () => {
        if (!emailContent.includes('[INVITATION_LINK]')) {
            setShowInvitationError(true)

            return
        }
        setLoading(true)
        await admin.savePartnerEmailTemplate(id, emailContent)
        setEmailContent(admin.emailTemplate)
        setLoading(false)
        toast('success', 'web_admin_email_saved')
    }

    return (
        <Admin isLoading={loading} title={t('web_partner_email')}>
            <MdEditor onChange={value => setEmailContent(value)} value={emailContent} />
            <button className="btn float-right mt-4 cursor-pointer" onClick={() => save()}>
                {t('web_admin_partner_save')}
            </button>

            <Modal
                isOpen={showInvitationError}
                bottomAlignment="center"
                onRequestClose={() => setShowInvitationError(false)}
                okLabel={t('web_confirm_delete_ok')}
                onConfirm={() => setShowInvitationError(false)}>
                <h2 className="mb-2 mt-4 text-xl font-bold">{t('web_partner_no_invitation_link')}</h2>
                <p className="my-6 text-center text-sm text-pale-sky">{t('web_partner_no_invitation_link_desc')}</p>
            </Modal>
        </Admin>
    )
})

export default Email
