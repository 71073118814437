import { Input, selectStyles } from 'components/shared/select-styles'
import i18next, { availableLocales } from 'core/i18n'
import toast from 'core/utils/toast'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useMst } from 'stores/store'
import MdEditor from '../shared/md-editor'
import Admin from './admin'

const WelcomeMessage: FC = observer(() => {
    const { t } = useTranslation()
    const store = useMst()
    const { pages } = store

    const [loading, setLoading] = useState<boolean>(true)
    const [welcome, setWelcome] = useState<string>()
    const [dashboard, setDashboard] = useState<string>()
    const [history, setHistory] = useState<string>()
    const [locale, setLocale] = useState<string>(i18next.language)

    const load = async (locale: string) => {
        setLoading(true)
        await store.getCmsPages(locale)
        const welcomePage = pages.get('welcome-message')
        if (welcomePage) {
            setWelcome(welcomePage.text)
        }
        const dashboardPage = pages.get('dashboard-message')
        if (dashboardPage) {
            setDashboard(dashboardPage.text)
        }
        const historyPage = pages.get('history')
        if (historyPage) {
            setHistory(historyPage.text)
        }
        setLoading(false)
    }

    useEffect(() => {
        load(locale)
    }, [pages, locale])

    const save = async () => {
        setLoading(true)
        await store.saveCmsPage('welcome-message', welcome, locale)
        await store.saveCmsPage('dashboard-message', dashboard, locale)
        await store.saveCmsPage('history', history, locale)

        setLoading(false)
        toast('success', 'web_admin_welcome_message_saved')
    }

    return (
        <Admin isLoading={loading} title={t('web_admin_messages')}>
            <Select
                styles={selectStyles}
                components={{ Input }}
                className="w-1/3"
                value={availableLocales
                    .map(locale => ({ value: locale, label: locale }))
                    .find(({ value }) => value === locale)}
                options={availableLocales.map(locale => ({ value: locale, label: locale }))}
                onChange={locale => {
                    setLocale(locale.value)
                }}
            />

            <h2 className="mb-1 mt-4 text-lg font-bold">{t('web_admin_welcome_message')}</h2>
            <MdEditor onChange={setWelcome} value={welcome} />

            <h2 className="mb-1 mt-4 text-lg font-bold">{t('web_admin_dashboard_message')}</h2>
            <MdEditor onChange={setDashboard} value={dashboard} />

            <h2 className="mb-1 mt-4 text-lg font-bold">{t('web_admin_history')}</h2>
            <MdEditor onChange={setHistory} value={history} />

            <button className="btn float-right mt-4 cursor-pointer" onClick={() => save()}>
                {t('web_admin_partner_save')}
            </button>
        </Admin>
    )
})

export default WelcomeMessage
