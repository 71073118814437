import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { OTPInput, type SlotProps } from 'input-otp'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { buttonClassName, type CodeFormData, codeFormSchema, inputClassName } from '..'

export const CodeForm = ({
    onSubmit,
    onResendCode,
    onCancel,
    submitting,
}: {
    onSubmit: (data: CodeFormData) => void
    onResendCode: () => void
    onCancel: () => void
    submitting: boolean
}) => {
    const { t } = useTranslation()

    const methods = useForm<CodeFormData>({
        resolver: zodResolver(codeFormSchema),
    })
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = methods

    const FakeCaret = () => {
        return (
            <div className="pointer-events-none flex items-center justify-center animate-caret-blink">
                <div className="w-px h-8 bg-thunder" />
            </div>
        )
    }

    const Slot = (props: SlotProps) => {
        return (
            <div
                className={clsx(
                    'text-center rounded-md border border-another-gray h-12 w-10 shrink-0 font-bold font-nunito text-thunder relative text-lg flex items-center justify-center',
                    props.isActive ? 'border-thunder' : ''
                )}
            >
                {props.char ?? ''}
                {props.hasFakeCaret && <FakeCaret />}
            </div>
        )
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8 items-center w-full">
                <Controller
                    control={control}
                    name="accessCode"
                    render={({ field }) => {
                        return (
                            <div className="w-full">
                                <OTPInput
                                    maxLength={6}
                                    onChange={(value: string) => {
                                        field.onChange(value)
                                    }}
                                    containerClassName="flex justify-center"
                                    render={({ slots }) => (
                                        <div className="grid grid-cols-6 gap-1">
                                            {slots.map((slot, index) => (
                                                <Slot key={index} {...slot} />
                                            ))}
                                        </div>
                                    )}
                                />
                                {errors.accessCode?.message && (
                                    <p className="text-christine text-sm leading-4 pt-2 text-center px-10">
                                        {errors.accessCode?.message as string}
                                    </p>
                                )}
                            </div>
                        )
                    }}
                />

                <div className="font-nunito text-center">
                    <div className="flex gap-2">
                        <span>{t('web_file_transfer_download_code_not_received')}</span>
                        <button
                            type="button"
                            onClick={onResendCode}
                            className="hover:text-sea-buckthorn font-nunito underline transition-colors"
                        >
                            {t('web_file_transfer_download_resend_code')}
                        </button>
                    </div>
                </div>
                <div className="flex flex-col">
                    <button type="submit" className={buttonClassName} disabled={submitting}>
                        {t('web_file_transfer_download_code_form_submit')}
                    </button>
                    <button
                        type="button"
                        onClick={onCancel}
                        className="hover:text-sea-buckthorn font-nunito underline transition-colors"
                    >
                        {t('web_file_transfer_download_email_typo')}
                    </button>
                </div>
            </form>
        </FormProvider>
    )
}
