import localeFr from 'air-datepicker/locale/fr'
import { Input, selectStyles } from 'components/shared/select-styles'
import ToggleButton from 'components/shared/toggle-button'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { useMst } from '../../../stores/store'
import Admin from '../admin'
import DatePicker from '../date-picker'
import Label from '../label'
import 'components/admin/zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { optionValueSchema } from '../forms'

export const schema = z.object({
    title: z.string().min(1),
    bodyMessage: z.string().min(1),
    color: z.string(),
    dismissible: z.boolean().nullable().default(false),
    startDate: z.date(),
    endDate: z.date(),
    partnerId: optionValueSchema.nullish(),
})
export type Schema = z.infer<typeof schema>

const Create = () => {
    const { informationMessageStore, admin, files } = useMst()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(false)

    const currentLocale = localeFr
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })

    const onSubmit = async (data: Schema) => {
        const { title, bodyMessage, color, dismissible, startDate, endDate, partnerId } = data
        setLoading(true)
        await informationMessageStore.informationMessageCreate(
            title,
            bodyMessage,
            dismissible,
            startDate,
            endDate,
            color,
            partnerId?.value ?? null,
            null // TODO add brand\
        )

        setLoading(false)
        navigate('/admin/information-messages')
    }

    const load = async () => {
        setLoading(true)
        await admin.loadPartners(1000, 0)
        await files.getConfig()
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    return (
        <Admin
            title={t('web_admin_information_messages')}
            isLoading={isLoading}
            header={
                <div className="flex items-center  space-x-4">
                    <Link to="/admin/information-messages" className="btn">
                        {t('web_admin_user_types_back')}
                    </Link>
                </div>
            }>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label
                    name="title"
                    error={
                        errors['title'] &&
                        `${t('web_menu_admin_information_message_title')} ${errors['title']?.message}`
                    }
                    label={'web_menu_admin_information_message_title'}
                    className="my-5 flex w-full flex-wrap items-center">
                    <input {...register('title')} type="text" className="w-1/3 " />
                </Label>
                <Label
                    name="color"
                    error={
                        errors['color'] &&
                        `${t('web_menu_admin_information_message_color')} ${errors['color']?.message}`
                    }
                    label={'web_menu_admin_information_message_color'}
                    className="my-5 flex w-full flex-wrap items-center">
                    <Controller
                        control={control}
                        name="color"
                        render={({ field }) => (
                            <div className="grid w-2/3 grid-cols-6 gap-4">
                                {files.colors.map(c => (
                                    <button
                                        key={c}
                                        className="relative h-6 w-6 rounded-full border-2 outline-none ring-0 transition-all hover:scale-150"
                                        style={{
                                            backgroundColor: c === field.value ? 'white' : c,
                                            borderColor: c,
                                        }}
                                        onClick={() => field.onChange(c)}></button>
                                ))}
                            </div>
                        )}
                    />
                </Label>
                <Label
                    name="bodyMessage"
                    error={
                        errors['bodyMessage'] &&
                        `${t('web_menu_admin_information_message_body')} ${errors['bodyMessage']?.message}`
                    }
                    label={'web_menu_admin_information_message_body'}
                    className="my-5 flex w-full flex-wrap  items-center">
                    <textarea {...register('bodyMessage')} className="w-1/3 " rows={3} />
                </Label>

                <Label
                    name="dismissible"
                    error={
                        errors['dismissible'] &&
                        `${t('web_menu_admin_information_dismissible')} ${errors['dismissible']?.message}`
                    }
                    label={'web_menu_admin_information_dismissible'}
                    className="my-5 flex w-full flex-wrap items-center"
                    required={true}>
                    <Controller
                        control={control}
                        name="dismissible"
                        render={({ field }) => (
                            <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                        )}
                    />
                </Label>
                <Label
                    name="startDate"
                    error={
                        errors['startDate'] &&
                        `${t('web_menu_admin_information_message_startDate')} ${errors['startDate']?.message}`
                    }
                    label={'web_menu_admin_information_message_startDate'}
                    className="my-5 flex w-full flex-wrap items-center leading-tight">
                    <Controller
                        name="startDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                            return <DatePicker {...field} locale={currentLocale} timepicker />
                        }}
                    />
                </Label>
                <Label
                    name="endDate"
                    error={
                        errors['endDate'] &&
                        `${t('web_menu_admin_information_message_endDate')} ${errors['endDate']?.message}`
                    }
                    label={'web_menu_admin_information_message_endDate'}
                    className="my-5 flex w-full flex-wrap items-center leading-tight">
                    <Controller
                        name="endDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                            return <DatePicker {...field} locale={currentLocale} timepicker />
                        }}
                    />
                </Label>
                <Label
                    name="Users"
                    error={
                        errors['partnerId'] &&
                        `${t('web_menu_admin_information_message_user_select')} ${errors['partnerId']?.message}`
                    }
                    label={'web_menu_admin_information_message_user_select'}
                    className="my-5 flex w-full flex-wrap  items-center">
                    <Controller
                        name="partnerId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Select
                                    styles={selectStyles}
                                    components={{ Input }}
                                    className="w-1/3"
                                    value={value}
                                    isClearable={true}
                                    placeholder={t('web_admin_placeholder_select')}
                                    options={[...admin.partners].map(partner => ({
                                        value: partner.uuid,
                                        label: partner.name,
                                    }))}
                                    onChange={partner => onChange(partner)}
                                />
                            )
                        }}
                    />
                </Label>
                <input type="submit" className="btn float-right cursor-pointer" value={`${t('web_admin_user_save')}`} />
            </form>
        </Admin>
    )
}

export default Create
