import type { ReactNode } from 'react'
import Tooltip from './tooltip'

interface Props {
    label?: string
    value: boolean
    disabled?: boolean
    onChange: (value: boolean) => void
    tooltip?: ReactNode | string
}

const ToggleButton = ({ tooltip, label, value, onChange, disabled = false }: Props) => {
    return (
        <Tooltip tooltip={tooltip}>
            <div
                className={`${disabled ? '' : 'cursor-pointer'}`}
                onClick={() => {
                    if (disabled) {
                        return
                    }
                    onChange(!value)
                }}
            >
                <div className="relative">
                    <div
                        className={`h-6 w-12 ${value && !disabled ? 'bg-christine' : 'bg-gray-chateau'
                            } rounded-full shadow-inner`}
                    >
                        <div
                            className={'absolute rounded-full bg-white shadow transition-all duration-300 ease-in-out'}
                            style={{
                                width: '1.2rem',
                                height: '1.2rem',
                                top: '.15rem',
                                left: value ? '1.6em' : '.2rem',
                            }}
                        />
                    </div>
                </div>
                {label && <div className="ml-3 font-medium text-gray-700">{label}</div>}
            </div>
        </Tooltip>
    )
}

export default ToggleButton
