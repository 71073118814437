import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { ReactComponent as NotFound } from '../../assets/not-found.svg'
import Panel from '../shared/panel'
import ContentPage from './content-page'

const NoShare: FC = () => {
    const { t } = useTranslation()
    const { file } = useParams()

    return (
        <ContentPage>
            <Panel>
                <div className="flex flex-col items-center">
                    <h3 className="mb-4 mt-8 text-center text-2xl font-bold">{t('web_share_file_not_found_title')}</h3>
                    <NotFound />
                    <p className="">
                        <Trans i18nKey="web_share_file_not_found_desc" values={{ file }}>
                            {file}
                        </Trans>
                    </p>
                </div>
            </Panel>
        </ContentPage>
    )
}

export default NoShare
