import Tippy from '@tippyjs/react'
import type { ElementType, ReactElement, ReactNode } from 'react'

const Tooltip = ({
    tooltip,
    children,
    as = 'div',
    className,
}: { tooltip?: ReactNode | string; children?: ReactElement; as?: ElementType; className?: string }) => {
    if (!tooltip) {
        return children
    }

    const As = as

    return (
        <Tippy content={tooltip} theme="light">
            <As className={className}>{children}</As>
        </Tippy>
    )
}

export default Tooltip
