import Admin from 'components/admin/admin'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { InformationMessage } from 'stores/informationMessage/information-message'
import { useMst } from '../../../stores/store'
import Form, { Schema } from './form'

const Edit = () => {
    const { informationMessageStore, files, partners } = useMst()
    const { t } = useTranslation()
    const { mid, id } = useParams()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [values, setValues] = useState<Record<string, unknown>>({})

    const onSubmit = async (data: Schema) => {
        const { title, bodyMessage, color, dismissible, startDate, endDate, users, tags } = data
        await informationMessageStore.informationMessageUpdate(
            informationMessageStore.editingInformationMessage.uuid,
            title,
            dismissible,
            startDate,
            endDate,
            bodyMessage,
            color,
            id,
            null,
            users ? users.map(u => u.value) : [],
            tags ? tags.map(u => u.value) : []
        )
        navigate(`/partner/${id}/messages`)
    }

    const load = async () => {
        setLoading(true)
        await files.getConfig()
        await informationMessageStore.loadInformationMessage(mid)
        await partners.loadUsers(500, 0, undefined, id)

        const makeValues = (informationMessage: InformationMessage) => {
            return {
                title: informationMessage.title,
                bodyMessage: informationMessage.bodyMessage,
                color: informationMessage.color,
                dismissible: informationMessage.dismissible,
                startDate:
                    informationMessage.startDate !== ''
                        ? DateTime.fromISO(informationMessage.startDate).toJSDate()
                        : null,
                endDate:
                    informationMessage.endDate !== '' ? DateTime.fromISO(informationMessage.endDate).toJSDate() : null,
                users: informationMessage.sendUsers.map(u => {
                    const user = partners.users.find(us => u === us.uuid)

                    return {
                        value: u,
                        label: `${user.fullname} (${user.email})`,
                    }
                }),
            }
        }
        setValues(makeValues(informationMessageStore.editingInformationMessage))
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [mid])

    return (
        <Admin title={t('web_admin_information_message')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} partnerId={id} values={values} />
        </Admin>
    )
}

export default Edit
