import { FC, useEffect } from 'react'
import { useMst } from '../../stores/store'

const Logout: FC = () => {
    const rootStore = useMst()
    const { user } = rootStore

    useEffect(() => {
        if (user.isGoogleUser && window.gapi && window.google) {
            window.google.accounts.id.disableAutoSelect()
        }
        rootStore.logout()
    }, [])

    return (
        <div className="invisible hidden">
            <a id="g-signin2" href="/logout">
                Google
            </a>
        </div>
    )
}

export default Logout
