import toast from 'core/utils/toast'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useMst } from 'stores/store'
import Admin from '../admin/admin'
import MdEditor from '../shared/md-editor'
import Modal from '../shared/modal'

const WelcomeMessage: FC = observer(() => {
    const { t } = useTranslation()
    const { admin } = useMst()
    const { id } = useParams()

    const [welcomeMessageContent, setWelcomeMessageContent] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)
    const [showInvitationError, setShowInvitationError] = useState<boolean>(false)

    const load = async () => {
        setWelcomeMessageContent('')
        setLoading(true)
        await admin.loadPartnerWelcomeMessage(id)
        setWelcomeMessageContent(admin.welcomeMessage)
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    const save = async () => {
        setLoading(true)
        await admin.saveWelcomeMessage(id, welcomeMessageContent)
        setWelcomeMessageContent(admin.welcomeMessage)
        setLoading(false)
        toast('success', 'web_admin_welcome_message_saved')
    }

    return (
        <Admin isLoading={loading} title={t('web_partner_welcome_message')}>
            <MdEditor onChange={value => setWelcomeMessageContent(value)} value={welcomeMessageContent} />
            <button className="btn float-right mt-4 cursor-pointer" onClick={() => save()}>
                {t('web_admin_partner_save')}
            </button>

            <Modal
                isOpen={showInvitationError}
                bottomAlignment="center"
                onRequestClose={() => setShowInvitationError(false)}
                okLabel={t('web_confirm_delete_ok')}
                onConfirm={() => setShowInvitationError(false)}>
                <h2 className="mb-2 mt-4 text-xl font-bold">Partner name required</h2>
            </Modal>
        </Admin>
    )
})

export default WelcomeMessage
