import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link, useParams } from 'react-router-dom'
import { useMst } from '../../../stores/store'
import Admin from '../admin'
import Form from './form'

const Create = () => {
    const { userTypeStore } = useMst()
    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(true)

    const onSubmit = async data => {
        const { name, parent } = data
        await userTypeStore.userTypeCreate(name, parent?.value)
        navigate('/admin/user-types')
    }

    const load = async () => {
        setLoading(true)
        await userTypeStore.load()
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [id])

    return (
        <Admin
            title={t('web_admin_user_type')}
            isLoading={isLoading}
            header={
                <div className="flex items-center  space-x-4">
                    <Link to="/admin/user-types" className="btn">
                        {t('web_admin_user_types_back')}
                    </Link>
                </div>
            }>
            <Form onSubmit={onSubmit} />
        </Admin>
    )
}

export default Create
