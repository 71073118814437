import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { SharedFile } from 'stores/files'
import { useMst } from 'stores/store'
import SharingPanelLineContent from './components/sharing-panel-line-content'
import { SharingPanelProps } from './sharing-panel'

interface SharingPanelLineProps extends SharingPanelProps {
    setShowConfirmStop: (sharedFile: SharedFile) => void
    setShowRestart: (sharedFile: SharedFile) => void
    setShowConfirmDelete: (sharedFile: SharedFile) => void
}

const SharingPanelLine = observer(
    (
        {
            showUserSharedByMe = false,
            showUserSharedWithMe = false,
            setDownloading,
            setShowConfirmStop,
            setShowRestart,
            setShowConfirmDelete,
        }: SharingPanelLineProps
    ) => {
        const { files } = useMst()
        const [sharedFiles, setSharedFiles] = useState<SharedFile[]>([])

        const load = async () => {
            if (showUserSharedByMe) {
                await files.getFilesSharedByMe()
                setSharedFiles(files.sharedByMe)
            } else if (showUserSharedWithMe) {
                await files.getFilesSharedWithMe()
                setSharedFiles(files.sharedWithMe)
            }
        }

        useEffect(() => {
            load()
        }, [])

        return (
            <SharingPanelLineContent
                setDownloading={setDownloading}
                sharedFiles={sharedFiles}
                showUserSharedByMe={showUserSharedByMe}
                showUserSharedWithMe={showUserSharedWithMe}
                setShowConfirmStop={setShowConfirmStop}
                setShowRestart={setShowRestart}
                setShowConfirmDelete={setShowConfirmDelete}
            />
        )
    }
)

export default SharingPanelLine
