import toast from 'core/utils/toast'
import { flow } from 'mobx'
import { getRoot, Instance, types } from 'mobx-state-tree'
import { del, get, post, put } from '../core/services/http-service'
import { RootInstance } from './store'
import { _UserType, UserType } from './userType/user-type'

export const _UserTypeStore = types
    .model('UserTypeStore', {
        userTypes: types.array(_UserType),
        editingUserType: types.optional(_UserType, {}),
        totalUserTypes: 0,
    })
    .volatile<{ loaded: boolean }>(() => ({
        loaded: false,
    }))
    .actions(self => ({
        replace: ({ userTypes }) => {
            self.userTypes = userTypes.sort((a, b) => b.createdAt - a.createdAt)
        },
        setLoaded: loaded => {
            self.loaded = loaded
        },
        assignUserType({ userType }) {
            self.editingUserType = userType
        },
        assignUserTypes({ userTypes, totalUserTypes }) {
            self.userTypes = userTypes
            self.totalUserTypes = totalUserTypes
        },
        append(userType) {
            self.userTypes.push(userType)
        },
        removeUserType(id) {
            const removedUserType = self.userTypes.filter(userType => {
                userType.uuid = id
            })[0]
            self.userTypes.remove(removedUserType)
        },
    }))
    .actions(self => ({
        load: flow(function* (q?: string, withParent?: boolean) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {}
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const getData = { q, withParent }
                const data = yield get<typeof getData, JsonReturn>('/v1/web/user-types', getData)

                const {
                    data: { userTypes },
                } = data
                self.setLoaded(true)
                self.replace({ userTypes })
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        append(userType) {
            self.userTypes.push(userType)
        },

        loadUserType: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: UserType }>(`/v1/bo/userTypes/${id}`)
                const userType = data.data

                self.assignUserType({ userType })
            } catch (error) {
                root.error.prepare(error)
            }
        }),

        userTypeUpdate: flow(function* (id: string, name: string, parentId: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                name,
                parentId,
            }

            try {
                const postData = yield put<typeof jsonData, void>(`/v1/bo/userTypes/${id}`, jsonData)
                const {
                    data: { userType },
                } = postData
                self.assignUserType({ userType })

                toast('success', 'web_user_type_updated')
            } catch (error) {
                root.error.prepare(error)
            }
        }),

        userTypeDelete: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()
            try {
                yield del<void>(`/v1/bo/userTypes/${id}`)
                self.removeUserType(id)
                toast('success', 'web_user_type_deleted')
            } catch (error) {
                root.error.prepare(error)
            }
        }),

        userTypeCreate: flow(function* (name: string, parentId: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = { name, parentId }
                const data = yield post<typeof postData, UserType>('/v1/bo/userTypes', postData)

                self.append(data.data)

                toast('success', 'api_user_type_created')

                return data.data
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        findUsers: flow(function* (userTypeId: string, city: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const getData = { city }
                const {
                    data: { users },
                } = yield get<typeof getData, { data: unknown }>(`/v1/web/user-types/${userTypeId}/users`, getData)

                return users
            } catch (error) {
                root.error.prepare(error)
            }
        }),
    }))

export interface UserTypeStore extends Instance<typeof _UserTypeStore> {}
