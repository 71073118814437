import { observer } from 'mobx-react-lite'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ArrowDown } from '../../assets/arrow/arrow-down.svg'
import { ReactComponent as ArrowRight } from '../../assets/arrow/arrow-right.svg'
import { useMst } from '../../stores/store'
import { Connector } from '../../stores/telecollecte/connector'
import SmallLoader from '../shared/small-loader'

interface SlidingPanelGeneralContent {
    title: string
    type?: string
    search?: string
    capabilities?: string
    isOpen: boolean
    children?: ReactNode
    isAvailable?: boolean
    innerContent?: ReactNode
}

// const showMoreConnectorsVisible = 10
// const defaultMaxConnectorsVisible = 10

const SlidingPanelGeneral: FC<SlidingPanelGeneralContent> = observer(
    ({ title, type, isOpen, search, isAvailable, innerContent }) => {
        const { telecollecte } = useMst()
        const { t } = useTranslation()
        const [isOpened, open] = useState<boolean>(isOpen)
        const [connectors, setConnectors] = useState<Connector[]>([])
        const [loaded, setLoaded] = useState<boolean>(false)

        // const [maxConnectorsVisible, setMaxConnectorsVisible] = useState<number>(defaultMaxConnectorsVisible)

        const addConnectors = (connectors: Connector[]): void => {
            const filteredConnectors = connectors.filter(connector => search === undefined || connector.uuid === search)
            setConnectors(filteredConnectors)
            setLoaded(true)
        }

        useEffect(() => {
            if (type) {
                const load = async (type: string) => {
                    let connectors = telecollecte.get(type)
                    if (connectors.length > 0) {
                        addConnectors(connectors)

                        return
                    }

                    await telecollecte.findConnectorsByType(type)
                    connectors = telecollecte.get(type)
                    addConnectors(connectors)
                }

                load(type)
            } else if (!isAvailable) {
                const load = async () => {
                    let connectors = telecollecte.hiddens
                    if (connectors.length > 0) {
                        addConnectors(connectors)

                        return
                    }

                    await telecollecte.findHiddenConnectors()
                    connectors = telecollecte.hiddens
                    addConnectors(connectors)
                }

                load()
            } else {
                const load = async () => {
                    await telecollecte.listConnectors()
                    addConnectors(telecollecte.connectors)
                }
                load()
            }
        }, [type, search, isAvailable])

        if (search && connectors.length === 0) {
            return null
        }

        return (
            <>
                <h1 className="flex items-center justify-between">
                    <div className="flex items-center" onClick={() => open(!isOpened)}>
                        <span className="text-lg">{title}</span>
                        {!isOpened && <ArrowRight className="ml-2 h-3 w-3" />}
                        {isOpened && <ArrowDown className="ml-2 h-3 w-3" />}
                    </div>
                </h1>
                {isOpened && (
                    <div className="mb-8 mt-4 flex flex-wrap">
                        {!isAvailable && <span className="w-full italic">{t('web_telecollecte_soon_desc')}</span>}
                        {!loaded && <SmallLoader />}
                        {innerContent}
                    </div>
                )}
            </>
        )
    }
)

export default SlidingPanelGeneral
