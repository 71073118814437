import { clsx } from 'clsx'
import { CSSProperties, ReactNode, useState } from 'react'

export interface Column {
    className?: string
    style?: CSSProperties
    label: string
    data?: Record<string, string | number>
}

interface Props {
    columns: Column[]
    children?: ReactNode
    onSelect?: (selected: boolean) => void
}

const Grid = ({ columns, children, onSelect }: Props) => {
    const [selected, setSelected] = useState<boolean>(false)

    return (
        <div className="grid w-full grid-cols-[repeat(22,_minmax(0,_1fr))]">
            {onSelect && (
                <div className="col-span-1 border-b border-heather text-center font-medium">
                    <input
                        type="checkbox"
                        className="text-christine"
                        onChange={() => {
                            const newValue = !selected
                            setSelected(newValue)
                            onSelect(newValue)
                        }}
                    />
                </div>
            )}
            {columns.map((column, index) => (
                <div
                    key={index}
                    className={clsx(column.className, 'border-b border-heather font-medium')}
                    style={column.style}
                    {...column.data}
                >
                    <span>{column.label}</span>
                </div>
            ))}
            {children}
        </div>
    )
}

export default Grid
