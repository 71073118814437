import { zodResolver } from '@hookform/resolvers/zod'
import { optionValueSchema } from 'components/admin/forms'
import { getTypes } from 'components/admin/users/form'
import { Input, selectStyles } from 'components/shared/select-styles'
import ToggleButton from 'components/shared/toggle-button'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import Select from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { useMst } from 'stores/store'
import { z } from 'zod'
import Admin from '../admin/admin'
import Label from '../admin/label'

const schema = z.object({
    email: z.string(),
    subject: z.string(),
    tags: z.array(optionValueSchema),
    type: optionValueSchema,
    confidential: z.boolean().optional(),
})
type Schema = z.infer<typeof schema>

const Invite = () => {
    const { t } = useTranslation()
    const { admin, partners } = useMst()
    const { id } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })

    const types = getTypes(t)

    const onSubmit = async (data: Schema) => {
        const { email, subject, confidential, tags, type } = data
        setLoading(true)
        await admin.inviteUser(id, email, subject, confidential, tags?.map(t => t.value) ?? [], type.value)
        setLoading(false)
        navigate(`/partner/${id}/cases`)
    }

    return (
        <Admin isLoading={loading} title={t('web_partner_invite')}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label
                    name="email"
                    error={errors['email'] && `${t('web_partner_invite_email')} ${errors['email']?.message}`}
                    label={'web_partner_invite_email'}
                    className="my-5 flex w-full flex-wrap items-center"
                    help={'web_partner_invite_email_help'}
                    required={true}>
                    <input {...register('email')} type="text" className="w-1/3" autoComplete="false" />
                </Label>
                <Label
                    name="type"
                    error={errors['type'] && `${t('web_admin_user_person_type')} ${errors['type']?.message}`}
                    label={'web_admin_user_person_type'}
                    className="my-5 flex w-full flex-wrap items-center leading-tight"
                    required={true}>
                    <Controller
                        name="type"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Select
                                    styles={selectStyles}
                                    components={{ Input }}
                                    className="w-1/3"
                                    value={value}
                                    options={types}
                                    onChange={type => onChange(type)}
                                />
                            )
                        }}
                    />
                </Label>

                <Label
                    name="tags"
                    error={errors['tags'] && `${t('web_partner_invite_tag')} ${errors['tags']?.message}`}
                    label={'web_partner_invite_tag'}
                    className="my-5 flex w-full flex-wrap items-center"
                    required={true}>
                    <Controller
                        name="tags"
                        control={control}
                        render={({ field }) => (
                            <AsyncCreatableSelect
                                {...field}
                                className="w-1/3"
                                value={field.value}
                                defaultOptions
                                loadOptions={async (inputValue: string) => {
                                    const tags = await partners.loadTags(id, inputValue)

                                    return tags.map(tag => ({
                                        value: tag.uuid,
                                        label: tag.name,
                                    }))
                                }}
                                isClearable
                                isMulti
                                onCreateOption={async (inputValue: string) => {
                                    const tag = await partners.createTag(id, inputValue)
                                    field.onChange([...field.value, { value: tag.uuid, label: tag.name }])
                                }}
                            />
                        )}
                    />
                </Label>
                <Label
                    name="subject"
                    error={errors['subject'] && `${t('web_partner_invite_subject')} ${errors['subject']?.message}`}
                    label={'web_partner_invite_subject'}
                    className="my-5 flex w-full flex-wrap items-center"
                    required={true}>
                    <input
                        {...register('subject')}
                        type="text"
                        className="w-1/3"
                        defaultValue={t('web_partner_invitation_subject') as string}
                    />
                </Label>

                <Label
                    name="confidential"
                    error={
                        errors['confidential'] &&
                        `${t('web_partner_invite_confidential')} ${errors['confidential']?.message}`
                    }
                    label={'web_partner_invite_confidential'}
                    className="my-5 flex w-full flex-wrap items-center"
                    required={true}>
                    <Controller
                        control={control}
                        name="confidential"
                        render={({ field }) => (
                            <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                        )}
                    />
                </Label>

                <input type="submit" className="btn float-right cursor-pointer" value={`${t('web_partner_invite')}`} />
            </form>
        </Admin>
    )
}

export default Invite
