import clsx from 'clsx'
import type { ReactNode } from 'react'
import type { UploadState } from '..'

export const Right = ({ children, uploadState }: { children: ReactNode; uploadState?: UploadState }) => (
    <div
        className={clsx(
            'bg-white rounded-2xl',
            uploadState ? (uploadState !== 'pending_user' ? 'lg:w-[800px]' : 'lg:w-[490px]') : 'lg:w-[800px]'
        )}
    >
        {children}
    </div>
)
