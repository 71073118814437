import { FC, ReactNode } from 'react'
import Tooltip from './tooltip'

interface Props {
    image?: ReactNode | string
    tooltip?: ReactNode | string
    imageFullsize?: boolean
    showSeparator?: boolean
    children?: ReactNode
    isOpen: boolean
    onOpen: (value: boolean) => void
}

const SlidingButtonImage: FC<Props> = (
    { tooltip, image, imageFullsize = false, children, isOpen, showSeparator = true, onOpen }
) => {
    const toggleDropdown = () => {
        const newValue = !isOpen
        onOpen(newValue)
    }

    return (
        <Tooltip tooltip={tooltip}>
            <div className="relative mx-2 inline-block rounded-full bg-white shadow-lg">
                <div
                    className="flex h-12 items-center overflow-hidden transition-all duration-500 ease-in-out"
                    onClick={() => toggleDropdown()}>
                    {image && typeof image === 'string' && (
                        <div
                            className={`h-12 w-12 cursor-pointer ${
                                imageFullsize ? 'bg-contain' : 'bg-center bg-no-repeat'
                            }`}
                            style={{ backgroundImage: `url(${image})` }}></div>
                    )}
                    {image && typeof image !== 'string' && (
                        <div
                            className={'flex h-12 w-12 cursor-pointer items-center justify-center'}
                            style={{ backgroundImage: `url(${image})` }}>
                            {image}
                        </div>
                    )}

                    {children && (
                        <>
                            {showSeparator && (
                                <span className={`${isOpen ? 'block' : 'hidden'} mr-4 text-regent-gray`}>/</span>
                            )}
                            <div className={`flex ${isOpen ? 'block' : 'hidden'}`}>{children}</div>
                        </>
                    )}
                </div>
            </div>
        </Tooltip>
    )
}

export default SlidingButtonImage
