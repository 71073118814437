import AirDatepicker, { type AirDatepickerOptions } from 'air-datepicker'
import { clsx } from 'clsx'
import { forwardRef, useEffect, useRef } from 'react'

interface DatePickerProps extends Partial<AirDatepickerOptions> {
    onChange: (date: Date | Date[]) => void
    value: Date | Date[]
    className?: string
    disabled?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>((props, ref) => {
    const $input = useRef<HTMLInputElement>()
    const dp = useRef<AirDatepicker>()

    useEffect(() => {
        const { onChange, value, ...rest } = props

        dp.current = new AirDatepicker($input.current, {
            ...rest,
            onSelect: ({ date }) => onChange(date),
        })
        if (value) {
            dp.current.selectDate(value)
        }
    }, [])

    useEffect(() => {
        const { value, ...rest } = props
        dp.current.update(rest)
        if (value) {
            dp.current.selectDate(value, { updateTime: true, silent: true })
        }
    }, [props])

    return (
        <div className={clsx(props.className ?? 'w-1/3')}>
            <input ref={$input} type="text" className="w-full" disabled={props.disabled} />
        </div>
    )
})
DatePicker.displayName = 'DatePicker'

export default DatePicker
