import clsx from 'clsx'
import { buttonClassName, inputClassName, type ShareType, type TransferFormData } from 'components/file-transfer'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const Form = ({
    onSubmit,
    shareType,
    canSubmit,
}: {
    onSubmit: (data: TransferFormData) => void
    shareType: ShareType
    canSubmit: boolean
}) => {
    const { t } = useTranslation()
    const methods = useFormContext()

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
        control,
    } = methods

    const isSecure = useWatch({ control, name: 'isSecure' })

    useEffect(() => {
        setValue('shareType', shareType)

        if (shareType === 'link' && !isSecure) {
            setValue('recipients', '')
            setValue('downloadNotify', false)
        }
    }, [shareType, isSecure])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register('shareType')} />
            <div className="p-4">
                {shareType === 'email' && (
                    <div className="flex flex-col gap-4">
                        <div>
                            <input
                                autoComplete="off"
                                required={shareType === 'email'}
                                className={inputClassName}
                                type="email"
                                placeholder={
                                    t('web_file_transfer_field_your_email') + (shareType === 'email' ? '*' : '')
                                }
                                {...register('senderEmail')}
                            />
                            {errors.senderEmail?.message && (
                                <p className="text-christine text-sm leading-4 pt-1">
                                    {errors.senderEmail?.message as string}
                                </p>
                            )}
                        </div>
                        <div>
                            <input
                                autoComplete="off"
                                required={shareType === 'email'}
                                className={inputClassName}
                                type="text"
                                placeholder={
                                    t('web_file_transfer_field_recipients') + (shareType === 'email' ? '*' : '')
                                }
                                {...register('recipients')}
                            />
                            {errors.recipients?.message && (
                                <p className="text-christine text-sm leading-4 pt-1">
                                    {errors.recipients?.message as string}
                                </p>
                            )}
                        </div>
                        <div>
                            <input
                                autoComplete="off"
                                className={inputClassName}
                                type="text"
                                placeholder={t('web_file_transfer_field_object')}
                                {...register('object')}
                            />
                            {errors.object?.message && (
                                <p className="text-christine text-sm leading-4 pt-1">
                                    {errors.object?.message as string}
                                </p>
                            )}
                        </div>
                        <div>
                            <textarea
                                rows={3}
                                className={clsx(inputClassName, '')}
                                {...register('message')}
                                placeholder={t('web_file_transfer_field_message')}
                            />
                            {errors.message?.message && (
                                <p className="text-christine text-sm leading-4 pt-1">
                                    {errors.message?.message as string}
                                </p>
                            )}
                        </div>
                        <div className="flex gap-2">
                            <div>
                                <input type="checkbox" {...register('downloadNotify')} id="downloadNotify" />
                            </div>
                            <div>
                                <label htmlFor="downloadNotify" className="font-nunito">
                                    {t('web_file_transfer_field_download_notify')}
                                </label>
                                <p className="text-xs font-italic font-nunito">
                                    {t('web_file_transfer_field_download_notify_help')}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {shareType === 'link' && (
                    <div className="flex flex-col gap-4">
                        <div>
                            <input
                                autoComplete="off"
                                className={inputClassName}
                                type="text"
                                placeholder={t('web_file_transfer_field_title')}
                                {...register('title')}
                            />
                            {errors.title?.message && (
                                <p className="text-christine text-sm leading-4 pt-1">
                                    {errors.title?.message as string}
                                </p>
                            )}
                        </div>
                        <div>
                            <input
                                autoComplete="off"
                                required={shareType === 'link'}
                                className={inputClassName}
                                type="email"
                                placeholder={
                                    t('web_file_transfer_field_your_email') + (shareType === 'link' ? '*' : '')
                                }
                                {...register('senderEmail')}
                            />
                            {errors.senderEmail?.message && (
                                <p className="text-christine text-sm leading-4 pt-1">
                                    {errors.senderEmail?.message as string}
                                </p>
                            )}
                        </div>
                        <div>
                            <input
                                autoComplete="off"
                                required={shareType === 'link' && isSecure}
                                className={clsx(inputClassName, shareType === 'link' && !isSecure ? 'hidden' : '')}
                                type="text"
                                placeholder={
                                    t('web_file_transfer_field_recipients') +
                                    (shareType === 'link' && isSecure ? '*' : '')
                                }
                                {...register('recipients')}
                            />
                            {errors.recipients?.message && (
                                <p
                                    className={clsx(
                                        'text-christine text-sm leading-4 pt-1',
                                        shareType === 'link' && !isSecure ? 'hidden' : ''
                                    )}
                                >
                                    {errors.recipients?.message as string}
                                </p>
                            )}
                        </div>
                        <div className="flex gap-2">
                            <div>
                                <input type="checkbox" {...register('isSecure')} id="isSecure" />
                            </div>
                            <div>
                                <label htmlFor="isSecure" className="font-nunito">
                                    {t('web_file_transfer_field_secure')}
                                </label>
                                <p className="text-xs font-italic font-nunito">
                                    {t('web_file_transfer_field_secure_help')}
                                </p>
                            </div>
                        </div>
                        {isSecure ? (
                            <div className="flex gap-2">
                                <div>
                                    <input type="checkbox" {...register('downloadNotify')} id="downloadNotify" />
                                </div>
                                <div>
                                    <label htmlFor="downloadNotify" className="font-nunito">
                                        {t('web_file_transfer_field_download_notify')}
                                    </label>
                                    <p className="text-xs font-italic font-nunito">
                                        {t('web_file_transfer_field_download_notify_help')}
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <input type="hidden" {...register('downloadNotify')} id="downloadNotify" />
                        )}
                    </div>
                )}
                <div className="flex justify-end pt-8">
                    <button disabled={!canSubmit} type="submit" className={buttonClassName}>
                        {t(shareType === 'email' ? 'web_file_transfer_email_submit' : 'web_file_transfer_link_submit')}
                    </button>
                </div>
            </div>
        </form>
    )
}
