interface WaitProps {
    className?: string
    onClick?: () => void
}

const Wait = ({ className = 'h-6 w-6', onClick }: WaitProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            className={className}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
            />
        </svg>
    )
}

export default Wait
