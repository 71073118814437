import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import img01 from '../../assets/todo/shop/01.jpg'
import img02 from '../../assets/todo/shop/02.jpg'
import ContentPage from '../dataroom/content-page'
import Panel from '../shared/panel'
import Slide from '../shared/slide'

const Shop: FC = () => {
    const { t } = useTranslation()

    const pages = [
        {
            content: (
                <>
                    <h1 className="mb-4 text-lg font-bold">
                        Une boutique de services pratiques en complément de votre abonnement Treasy !{' '}
                        <span className="italic text-atomic-tangerine">(bientôt disponible)</span>
                    </h1>
                    <h2 className="mb-4 font-bold">
                        Pour que votre expérience de Treasy soit encore plus fluide et plus efficace, notre boutique
                        vous proposera des services additionnels de gestion de vos fichiers.
                    </h2>
                    <p className="mb-4">
                        Avec les abonnements Easy-Plus, Easy-Best et Easy-Pro, vous pouvez accéder aux services
                        additionnels qui simplifient encore davantage la vie numérique.
                    </p>
                    <ul className="mb-4 list-inside list-disc">
                        <li>Une possibilité d'acheter de la capacité de stockage complémentaire.</li>
                        <li>
                            Un service de numérisation de vos documents papier, pour (enfin) préserver ce qui compte
                            dans toute la paperasse accumulée et y accéder en cas de besoin.
                        </li>
                        <li>
                            Un service d'impression d'albums de vos plus belles photos numériques, pour le plaisir de
                            les feuilleter et de les offrir à vos proches.
                        </li>
                        <li>
                            Un service de gestion sécurisée de vos connexions, qui inclut une authentification renforcée
                            des accès à Treasy et le moyen de simplifier la gestion de tous vos mots de passe, qu'on
                            appelle un trousseau de mots de passe.
                        </li>
                    </ul>
                </>
            ),
            image: img01,
        },
        {
            content: (
                <>
                    <h1 className="mb-4 text-lg font-bold">
                        Une boutique de services pratiques en complément de votre abonnement Treasy !
                    </h1>
                    <h2 className="mb-4 font-bold">
                        Pour que votre expérience de Treasy soit encore plus fluide et plus efficace, notre boutique
                        vous proposera des services additionnels de gestion de vos fichiers.
                    </h2>
                    <p className="mb-4">
                        Avec les abonnements Easy-Plus, Easy-Best et Easy-Pro, vous pouvez accéder aux services
                        additionnels qui simplifient encore davantage la vie numérique.
                    </p>
                    <ul className="mb-4 list-inside list-disc">
                        <li>Une possibilité d'acheter de la capacité de stockage complémentaire.</li>
                        <li>
                            Un service de numérisation de vos documents papier, pour (enfin) préserver ce qui compte
                            dans toute la paperasse accumulée et y accéder en cas de besoin.
                        </li>
                        <li>
                            Un service d'impression d'albums de vos plus belles photos numériques, pour le plaisir de
                            les feuilleter et de les offrir à vos proches.
                        </li>
                        <li>
                            Un service de gestion sécurisée de vos connexions, qui inclut une authentification renforcée
                            des accès à Treasy et le moyen de simplifier la gestion de tous vos mots de passe, qu'on
                            appelle un trousseau de mots de passe.
                        </li>
                    </ul>
                </>
            ),
            image: img02,
        },
    ]

    return (
        <ContentPage title={t('web_link_shop')}>
            <Panel>
                <Slide pages={pages} title={t('web_link_shop')} page={t('web_todo_shop')} />
            </Panel>
        </ContentPage>
    )
}

export default Shop
