import { Input, selectStyles } from 'components/shared/select-styles'
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import type { FormatOptionLabelMeta, GroupBase, MultiValue, Options, OptionsOrGroups } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import type { Accessors } from 'react-select/dist/declarations/src/useCreatable'
import type { Space } from 'stores/files/space'
import { useMst } from 'stores/store'
import _, { set } from 'lodash'
import { get } from 'core/services/http-service'

type Option = { value?: string; label: string; options?: Option[] }

export function UsersDropdown({
    selectedPeople,
    setSelectedPeople,
    space,
}: {
    selectedPeople: MultiValue<Option>
    setSelectedPeople: Dispatch<SetStateAction<MultiValue<Option>>>
    space?: Space
}) {
    const { t } = useTranslation()
    const { user } = useMst()

    const [canInvite, setCanInvite] = useState<boolean>(true)
    const [maxInvitations, setMaxInvitations] = useState<number>(0)
    const [usedInvitations, setUsedInvitations] = useState<number>(0)
    const [freeUsers, setFreeUsers] = useState<string[]>([])
    const [removedUsers, setRemovedUsers] = useState<string[]>([])

    async function getInviteInfos(space: Space) {
        const {
            data: {
                collaborationFreeUsers,
                collaborationRemovedUsers,
                trustedFreeUsers,
                pendingInvitations,
                canInvite,
                maxInvitations,
            },
        } = await get<
            never,
            {
                data: {
                    collaborationFreeUsers: string[]
                    collaborationRemovedUsers: string[]
                    trustedFreeUsers: string[]
                    pendingInvitations: string[]
                    canInvite: boolean
                    maxInvitations: number
                }
            }
        >(`/v1/web/collaborations/${space.collaborationId}/infos`)

        setCanInvite(canInvite)

        setFreeUsers([...collaborationFreeUsers, ...trustedFreeUsers])
        setRemovedUsers(collaborationRemovedUsers)

        setMaxInvitations(maxInvitations)
        setUsedInvitations(collaborationFreeUsers.length + pendingInvitations.length)
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setSelectedPeople([
            ...selectedPeople,
            ...(space?.invitations ?? []).map(email => ({ value: email, label: email })),
        ])

        if (space?.collaborationId) {
            getInviteInfos(space)
        }
    }, [space])

    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    async function loadOptions(
        inputValue: string,
        callback: (options: OptionsOrGroups<Option, GroupBase<Option>>) => void
    ) {
        const { trustedUsers, trustedUsersPerFranchise, trustedUsersPerBrand } = await user.findTrustedUser(
            inputValue,
            { excludeFreeUsers: !canInvite }
        )

        const users = [...trustedUsers]

        for (const franchise of trustedUsersPerFranchise) {
            users.push(...Object.values(franchise))
        }

        for (const brand of trustedUsersPerBrand) {
            users.push(...Object.values(brand))
        }

        const options: Option[] = []

        if (users.length > 0) {
            options.push({
                label: t('web_collaboration_trusted_users'),
                options: users
                    .flat()
                    .filter(({ trustedUser }) => !selectedPeople.find(({ value }) => value === trustedUser.uuid))
                    .map(({ accepted, trustedUser, userType }) => {
                        return {
                            value: trustedUser.uuid,
                            label: `${trustedUser.fullname} (${trustedUser.email})`,
                        }
                    }),
            })
        }

        callback(options)
    }

    const _loadOptions = _.debounce(
        (inputValue: string, callback: (options: OptionsOrGroups<Option, GroupBase<Option>>) => void) => {
            loadOptions(inputValue, callback)
        },
        800
    )

    return (
        <div>
            <AsyncCreatableSelect
                isValidNewOption={(
                    inputValue: string,
                    value: Options<Option>,
                    options: OptionsOrGroups<Option, GroupBase<Option>>,
                    accessors: Accessors<Option>
                ) =>
                    usedInvitations < maxInvitations &&
                    options.length === 0 &&
                    emailRegex.test(inputValue) &&
                    !selectedPeople.map(({ value }) => value.toLowerCase()).includes(inputValue.toLowerCase())
                }
                formatCreateLabel={(inputValue: string) => {
                    return t('web_collaboration_invite_email', { email: inputValue })
                }}
                formatOptionLabel={(data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => {
                    return formatOptionLabelMeta.context === 'menu' || !emailRegex.test(data.value)
                        ? data.label
                        : t('web_collaboration_invite_email_selected', { email: data.label })
                }}
                isOptionDisabled={(option: Option, selectValue: Options<Option>) => {
                    if (!freeUsers.includes(option.value)) {
                        return false
                    }

                    return usedInvitations === maxInvitations
                }}
                styles={selectStyles}
                components={{ Input }}
                className="w-full"
                value={selectedPeople}
                isClearable={true}
                isMulti
                defaultOptions
                noOptionsMessage={({ inputValue }) =>
                    t(
                        emailRegex.test(inputValue)
                            ? usedInvitations < maxInvitations
                                ? 'web_collaboration_no_options'
                                : 'web_collaboration_no_more_invitations'
                            : 'web_collaboration_no_options'
                    )
                }
                placeholder={t('web_collaboration_create_add_people')}
                loadOptions={_loadOptions}
                onChange={selectedUsers => {
                    setSelectedPeople(selectedUsers)

                    const invitationsCount = selectedUsers
                        .map(({ value }) => value)
                        .filter(value => emailRegex.test(value)).length
                    const freeCount = selectedUsers
                        .map(({ value }) => value)
                        .filter(value => freeUsers.includes(value) && !removedUsers.includes(value)).length

                    setUsedInvitations(freeCount + invitationsCount)
                }}
            />
            <p className="text-regent-gray mt-1">
                {t('web_collaboration_remaining_invitations', { count: maxInvitations - usedInvitations })}
            </p>
        </div>
    )
}
