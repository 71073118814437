import { ReactComponent as ArrowDown } from 'assets/arrow/arrow-down.svg'
import { ReactComponent as ArrowUp } from 'assets/arrow/arrow-up.svg'
import { clsx } from 'clsx'
import { observer } from 'mobx-react-lite'
import { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useMst } from 'stores/store'
import Tooltip from '../tooltip'

const baseMenuCss = 'py-4'
const activeMenuCss = 'border-christine border-l-3 bg-atomic-tangerine/25'
const inactiveMenuCss = ''
const baseMenuLinkCss = 'truncate'
const activeMenuLinkCss = 'text-christine'
const inactiveMenuLinkCss = 'text-pale-sky'
const disabledMenuLinkCss = 'text-heather'

export const Separator = () => {
    return <li className="mx-2 border-b-2 border-solid border-gray-300"></li>
}

interface SubMenuProps {
    link?: string
    name: string
    children: ReactNode
    label: string
    icon: ReactElement
}
export const SubMenu = observer(({ link, name, children, label, icon }: SubMenuProps) => {
    const { t } = useTranslation()
    const { menuOpen, openedMenu, setOpenedMenu } = useMst()

    return (
        <>
            <li
                className={clsx(baseMenuCss, 'cursor-pointer', {
                    'flex items-center justify-between pl-12': menuOpen,
                    'pl-2 text-center': !menuOpen,
                })}>
                {link ? (
                    <Link
                        to={link}
                        className={clsx(
                            baseMenuLinkCss,
                            openedMenu === name ? activeMenuLinkCss : inactiveMenuLinkCss
                        )}>
                        {icon}
                        {menuOpen && (
                            <span className="grow">
                                <Tooltip tooltip={t(label)}>
                                    <>{t(label)}</>
                                </Tooltip>
                            </span>
                        )}
                    </Link>
                ) : (
                    <span
                        className={clsx(
                            baseMenuLinkCss,
                            'flex items-center',
                            openedMenu === name ? activeMenuLinkCss : inactiveMenuLinkCss
                        )}>
                        {icon}
                        {menuOpen && (
                            <span className="grow">
                                <Tooltip tooltip={t(label)}>
                                    <>{t(label)}</>
                                </Tooltip>
                            </span>
                        )}
                    </span>
                )}

                {menuOpen && (
                    <div className="cursor-pointer" onClick={() => setOpenedMenu(openedMenu === name ? '' : name)}>
                        {openedMenu === name ? (
                            <ArrowUp className={'float-right mr-2 mt-1 inline h-3 w-3 fill-current'} />
                        ) : (
                            <ArrowDown className={'float-right mr-2 mt-1 inline h-3 w-3 fill-current'} />
                        )}
                    </div>
                )}
            </li>
            {menuOpen && openedMenu === name && children}
        </>
    )
})

interface MenuLinkLiProps {
    url: string
    link?: string
    inSubMenu: boolean
    menuOpen: boolean
    children: ReactNode
}
const MenuLinkLi = ({ url, link, inSubMenu, menuOpen, children }: MenuLinkLiProps) => {
    return (
        <li
            className={clsx(
                baseMenuCss,
                url === link ? activeMenuCss : inactiveMenuCss,
                inSubMenu ? 'pl-20' : menuOpen ? 'pl-12' : 'pl-2 text-center'
            )}>
            {children}
        </li>
    )
}

interface MenuLinkProps {
    href?: string
    link?: string
    label: string
    icon: ReactElement
    inSubMenu?: boolean
    tooltip?: string
    disabled?: boolean
}
export const MenuLink = observer(
    ({ link, href, label, icon, inSubMenu = false, tooltip, disabled = false }: MenuLinkProps) => {
        const { t } = useTranslation()
        const location = useLocation()
        const url = location.pathname
        const { menuOpen } = useMst()

        const span = (label: string) => <span className="whitespace-pre-line">{t(label)}</span>

        if (href) {
            return (
                <MenuLinkLi url={url} link={link} inSubMenu={inSubMenu} menuOpen={menuOpen}>
                    <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={clsx(baseMenuLinkCss, inactiveMenuLinkCss, {
                            [disabledMenuLinkCss]: disabled,
                        })}>
                        {icon}
                        {menuOpen && (
                            <>{tooltip ? <Tooltip tooltip={t(tooltip)}>{span(label)}</Tooltip> : span(label)}</>
                        )}
                    </a>
                </MenuLinkLi>
            )
        }

        if (link) {
            return (
                <MenuLinkLi url={url} link={link} inSubMenu={inSubMenu} menuOpen={menuOpen}>
                    <Link
                        to={link}
                        className={clsx(baseMenuLinkCss, url === link ? activeMenuLinkCss : inactiveMenuLinkCss, {
                            'flex items-center': menuOpen,
                            [disabledMenuLinkCss]: disabled,
                        })}>
                        {icon}
                        {menuOpen && (
                            <>{tooltip ? <Tooltip tooltip={t(tooltip)}>{span(label)}</Tooltip> : span(label)}</>
                        )}
                    </Link>
                </MenuLinkLi>
            )
        }

        return (
            <MenuLinkLi url={url} link={link} inSubMenu={inSubMenu} menuOpen={menuOpen}>
                <span className={clsx(baseMenuLinkCss, disabledMenuLinkCss, 'cursor-pointer')}>
                    {icon}
                    {menuOpen && <>{tooltip ? <Tooltip tooltip={t(tooltip)}>{span(label)}</Tooltip> : span(label)}</>}
                </span>
            </MenuLinkLi>
        )
    }
)
