import { ReactComponent as IconPlaceholder } from 'assets/icons/icon-user-placeholder.svg'
import clsx from 'clsx'
import Tooltip from '../shared/tooltip'

export type IconSize = 'sm' | 'md'

export default function LineIcon(
    {
        image,
        tooltip,
        zIndex = undefined,
        size = 'md',
    }: {
        image: string
        tooltip: string
        zIndex?: number
        size?: IconSize
    }
) {
    return (
        <Tooltip tooltip={tooltip}>
            <div
                className={clsx(
                    size === 'sm' ? 'h-5 w-5' : 'h-8 w-8',
                    'relative flex items-center justify-center overflow-hidden rounded-full border border-white bg-regent-gray text-white'
                )}
                style={{ zIndex }}>
                <div className="absolute inset-0 flex items-center justify-center">
                    <IconPlaceholder className="h-3 w-3 fill-current" />
                </div>
                <div
                    className={'absolute inset-0 flex items-center justify-center bg-cover bg-center bg-no-repeat'}
                    style={{ backgroundImage: `url(${image})` }}
                />
            </div>
        </Tooltip>
    )
}
