import { FC } from 'react'

interface Props {
    title?: string
    className?: string
}

const Case: FC<Props> = ({ title, className = 'h-6 w-6' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-label={title}
            className={className}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
            />
        </svg>
    )
}

export default Case
