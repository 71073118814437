import { type Instance, flow, getRoot, types } from 'mobx-state-tree'
import { del, get, post, put } from '../core/services/http-service'
import toast from '../core/utils/toast'
import { type Brand, type Franchise, _Brand } from './brands'
import { _InformationMessage } from './informationMessage/information-message'
import { _News } from './news/news'
import { type Partner, _Partner } from './partners'
import { _Pricing, _StripePrice, _StripeProduct } from './pricing'
import type { RootInstance } from './store'
import { type User, _User } from './users'

export const _Admin = types
    .model('Admin', {
        users: types.array(_User),
        totalUsers: 0,
        editingUser: types.optional(_User, {}),

        stripePrices: types.array(_StripePrice),
        stripeProducts: types.array(_StripeProduct),

        pricings: types.array(_Pricing),
        totalPricings: 0,
        editingPricing: types.optional(_Pricing, {}),

        partners: types.array(_Partner),
        totalPartners: 0,
        editingPartner: types.optional(_Partner, {}),
        emailTemplate: '',
        welcomeMessage: '',

        informationMessages: types.array(_InformationMessage),
        news: types.array(_News),
        totalInformationMessages: 0,
        totalNews: 0,

        brands: types.array(_Brand),
        totalBrands: 0,
        editingBrand: types.optional(_Brand, {}),
    })
    .views(self => ({
        get selectablePrices() {
            return [...self.stripePrices].map(price => ({
                value: price.id,
                label: price.friendlyAdminName,
            }))
        },
        get selectableProducts() {
            return [...self.stripeProducts].map(product => ({
                value: product.id,
                label: product.friendlyAdminName,
            }))
        },
        get selectablePartners() {
            return [...self.partners].map(partner => ({
                value: partner.uuid,
                label: partner.name,
            }))
        },
    }))
    .actions(self => ({
        assignUser({ user }) {
            self.editingUser = user
        },
        assignUsers({ users, totalUsers }) {
            self.users = users
            self.totalUsers = totalUsers
        },
        assignPartner({ partner }) {
            self.editingPartner = partner
        },
        assignPartners({ partners, totalPartners }) {
            self.partners = partners
            self.totalPartners = totalPartners
        },
        assignBrand({ brand }) {
            self.editingBrand = brand
        },
        assignBrands({ brands, totalBrands }) {
            self.brands = brands
            self.totalUsers = totalBrands
        },
        assignPricing({ pricing }) {
            self.editingPricing = {
                ...pricing,
                _duration: JSON.stringify(pricing.duration),
            }
        },
        assignPricings({ pricings, totalPricings }) {
            self.pricings = pricings.map(pricing => ({
                ...pricing,
                _duration: JSON.stringify(pricing.duration),
            }))
            self.totalPricings = totalPricings
        },
        assignStripePrices({ stripePrices }) {
            self.stripePrices = stripePrices
        },
        assignStripeProducts({ stripeProducts }) {
            self.stripeProducts = stripeProducts
        },
        assignInformationMessages({ informationMessages, totalInformationMessages }) {
            self.informationMessages = informationMessages
            self.totalInformationMessages = totalInformationMessages
        },
        assignNews({ news, totalNews }) {
            self.news = news
            self.totalNews = totalNews
        },
    }))
    .actions(self => ({
        loadUsers: flow(function* (limit: number, offset: number, q?: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            self.users.clear()

            const getData = {
                limit,
                offset,
                q: q === '' ? undefined : q,
            }

            try {
                const {
                    data: { users, count },
                } = yield get<typeof getData, { data: typeof self }>('/v1/bo/users', getData)
                self.assignUsers({ users, totalUsers: count })
            } catch (error) {
                console.error(error)
            }
        }),

        loadUser: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: User }>(`/v1/bo/users/${id}`)
                const {
                    data: { user },
                } = data

                self.assignUser({ user })
            } catch (error) {
                console.error(error)
            }
        }),

        createUser: flow(function* (
            firstname: string,
            email: string,
            secondaryEmail: string,
            phone: string,
            birthdate: Date,
            role: string,
            partner: string | undefined,
            price: string,
            type: string
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                firstname,
                email,
                secondaryEmail,
                phone,
                birthdate,
                role,
                partner,
                price,
                type,
            }

            try {
                const postData = yield put<typeof jsonData, void>('/v1/bo/users', jsonData)
                const {
                    data: { user },
                } = postData
                self.assignUser({ user })

                toast('success', 'web_user_profile_saved')
            } catch (error) {
                console.error(error)
            }
        }),

        updateUser: flow(function* (
            id: string,
            firstname: string,
            email: string,
            secondaryEmail: string,
            phone: string,
            birthdate: Date,
            role: string,
            partner: string | undefined,
            price: string,
            userType: string | undefined,
            type: string
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                firstname,
                email,
                secondaryEmail,
                phone,
                birthdate: birthdate ? birthdate.toISOString() : null,
                role,
                partner,
                price,
                userType,
                type,
            }

            try {
                const postData = yield put<typeof jsonData, void>(`/v1/bo/users/${id}`, jsonData)
                const {
                    data: { user },
                } = postData
                self.assignUser({ user })

                toast('success', 'web_user_profile_saved')
            } catch (error) {
                console.error(error)
            }
        }),

        loadPartners: flow(function* (limit: number, offset: number, q?: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            self.partners.clear()

            const getData = {
                limit,
                offset,
                q: q === '' ? undefined : q,
            }

            try {
                const {
                    data: { partners, count },
                } = yield get<typeof getData, { data: typeof self }>('/v1/bo/partners', getData)
                self.assignPartners({ partners, totalPartners: count })
            } catch (error) {
                console.error(error)
            }
        }),

        loadPartnerEmailTemplate: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: { template: string } }>(`/v1/web/partners/${id}/email-template`)
                const {
                    data: { template },
                } = data

                self.emailTemplate = template
            } catch (error) {
                console.error(error)
            }
        }),
        loadPartnerWelcomeMessage: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: { welcomeMessage: string } }>(
                    `/v1/web/partners/${id}/welcome-message`
                )
                const {
                    data: { welcomeMessage },
                } = data

                self.welcomeMessage = welcomeMessage
            } catch (error) {
                console.error(error)
            }
        }),
        loadWelcomeMessage: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: { welcomeMessage: string } }>('/v1/bo/welcome-message')
                const {
                    data: { welcomeMessage },
                } = data

                self.welcomeMessage = welcomeMessage
            } catch (error) {
                console.error(error)
            }
        }),

        savePartnerEmailTemplate: flow(function* (id, emailContent) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const putData = {
                    template: {
                        fr: emailContent,
                    },
                }
                const data = yield put<typeof putData, { data: { template: string } }>(
                    `/v1/web/partners/${id}/email-template`,
                    putData
                )
                const {
                    data: { template },
                } = data

                self.emailTemplate = template
            } catch (error) {
                console.error(error)
            }
        }),

        saveWelcomeMessage: flow(function* (id, welcomeMessageContent) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const putData = {
                    welcomeMessage: {
                        fr: welcomeMessageContent,
                    },
                }
                const data = yield put<typeof putData, { data: { welcomeMessage: string } }>(
                    `/v1/web/partners/${id}/welcome-message`,
                    putData
                )
                const {
                    data: { welcomeMessage },
                } = data

                self.welcomeMessage = welcomeMessage
            } catch (error) {
                console.error(error)
            }
        }),

        loadPartner: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: Partner }>(`/v1/bo/partners/${id}`)
                const {
                    data: { partner },
                } = data

                self.assignPartner({ partner })
            } catch (error) {
                console.error(error)
            }
        }),

        createPartner: flow(function* (
            name: string,
            url: string,
            contactUrl: string,
            companyNumber: string,
            healthPartner: boolean,
            logo: string,
            icon: string,
            pricing: string,
            otherPricing: string[],
            managers: string[],
            caseManagers: string[]
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                name,
                url,
                contactUrl,
                companyNumber,
                healthPartner,
                logo,
                icon,
                pricing,
                otherPricing,
                managers,
                caseManagers,
            }

            try {
                const postData = yield post<typeof jsonData, void>('/v1/bo/partners', jsonData)
                const {
                    data: { partner },
                } = postData
                self.assignPartner({ partner })

                toast('success', 'web_partner_profile_saved')
            } catch (error) {
                console.error(error)
            }
        }),

        updatePartner: flow(function* (
            id: string,
            name: string,
            url: string,
            contactUrl: string,
            companyNumber: string,
            healthPartner: boolean,
            logo: string,
            icon: string,
            pricing: string,
            otherPricing: string[],
            managers: string[],
            caseManagers: string[]
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                name,
                url,
                contactUrl,
                companyNumber,
                healthPartner,
                logo,
                icon,
                pricing,
                otherPricing,
                managers,
                caseManagers,
            }

            try {
                const postData = yield put<typeof jsonData, void>(`/v1/bo/partners/${id}`, jsonData)
                const {
                    data: { partner },
                } = postData
                self.assignPartner({ partner })

                toast('success', 'web_partner_profile_saved')
            } catch (error) {
                console.error(error)
            }
        }),

        loadBrands: async (limit: number, offset: number, q?: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            self.brands.clear()

            const getData = {
                limit,
                offset,
                q: q === '' ? undefined : q,
            }

            try {
                const {
                    data: { brands, count },
                } = await get<typeof getData, { data: { brands: Brand[]; count: number } }>('/v1/bo/brands', getData)
                self.assignBrands({ brands, totalBrands: count })
            } catch (error) {
                console.error(error)
            }
        },

        toggleBrand: async (uuid: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = await put<void, { data: { brand: Brand } }>(`/v1/bo/brands/${uuid}/toggle`)
                const {
                    data: { brand },
                } = data

                return brand
            } catch (error) {
                root.error.prepare(error)
            }
        },

        toggleBrandFranchise: async (brandId: string, uuid: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = await put<void, { data: { franchise: Franchise } }>(
                    `/v1/bo/brands/${brandId}/franchises/${uuid}/toggle`
                )
                const {
                    data: { franchise },
                } = data

                return franchise
            } catch (error) {
                root.error.prepare(error)
            }
        },

        loadBrand: async (id: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = await get<void, { data: { brand: Brand } }>(`/v1/bo/brands/${id}`)
                const {
                    data: { brand },
                } = data

                self.assignBrand({ brand })

                return brand
            } catch (error) {
                console.error(error)
            }
        },

        createBrand: async (
            name: string,
            url: string,
            logo: string,
            icon: string,
            color: string,
            viaColor: string,
            brandImage: string,
            brandLogo: string,
            configFile: string,
            config: Record<string, string | number | boolean | Record<string, string>>,
            userPricing: string,
            partnerPricing: string,

            hrEnabled: boolean,
            hrFilePattern: string
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                name,
                url,
                logo,
                icon,
                color,
                viaColor,
                brandImage,
                brandLogo,
                configFile,
                config,
                userPricing,
                partnerPricing,

                hrEnabled,
                hrFilePattern,
            }

            try {
                const postData = await post<typeof jsonData, { data: { brand: Brand } }>('/v1/bo/brands', jsonData)
                const {
                    data: { brand },
                } = postData
                self.assignBrand({ brand })

                toast('success', 'web_brand_profile_saved')
            } catch (error) {
                console.error(error)
            }
        },

        updateBrand: async (
            id: string,
            name: string,
            url: string,
            logo: string,
            icon: string,
            color: string,
            viaColor: string,
            brandImage: string,
            brandLogo: string,
            configFile: string,
            config: Record<string, string | number | boolean | Record<string, string>>,
            userPricing: string,
            partnerPricing: string,

            hrEnabled: boolean,
            hrFilePattern: string
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                name,
                url,
                logo,
                icon,
                color,
                viaColor,
                brandImage,
                brandLogo,
                configFile,
                config,
                userPricing,
                partnerPricing,

                hrEnabled,
                hrFilePattern,
            }

            try {
                const postData = await put<typeof jsonData, { data: { brand: Brand } }>(`/v1/bo/brands/${id}`, jsonData)
                const {
                    data: { brand },
                } = postData
                self.assignBrand({ brand })

                toast('success', 'web_brand_profile_saved')
            } catch (error) {
                console.error(error)
            }
        },

        loadPrices: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            self.stripePrices.clear()

            try {
                const {
                    data: {
                        prices: { data },
                    },
                } = yield get<void, never>('/v1/web/prices')
                self.assignStripePrices({ stripePrices: data })
            } catch (error) {
                console.error(error)
            }
        }),

        loadProducts: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            self.stripeProducts.clear()

            try {
                const {
                    data: {
                        products: { data },
                    },
                } = yield get<void, never>('/v1/web/products')
                self.assignStripeProducts({ stripeProducts: data })
            } catch (error) {
                console.error(error)
            }
        }),

        loadPricings: flow(function* (limit: number, offset: number, q?: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            self.pricings.clear()

            const getData = {
                limit,
                offset,
                q: q === '' ? undefined : q,
            }

            try {
                const {
                    data: { pricings, count },
                } = yield get<typeof getData, { data: typeof self }>('/v1/bo/pricing', getData)
                self.assignPricings({ pricings, totalPricings: count })
            } catch (error) {
                console.error(error)
            }
        }),

        loadPricing: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = yield get<void, { data: User }>(`/v1/bo/pricing/${id}`)
                const {
                    data: { pricing },
                } = data

                self.assignPricing({ pricing })
            } catch (error) {
                console.error(error)
            }
        }),

        deletePricing: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                yield del<void>(`/v1/bo/pricing/${id}`)
            } catch (error) {
                console.error(error)
            }
        }),

        createPricing: flow(function* (
            name: string,
            publicName: string,
            description: string,
            longDescription: string,
            duration: { length: number; period: string },
            free: boolean,
            manual: boolean,
            treasy: boolean,
            storage: number,
            repeatable: boolean,
            connectors: number,
            shareFiles: number,
            transmission: number,
            isDefault: boolean,
            tribe: number,
            stripeProduct: string,
            stripePrice: string,
            storageMessage: string | null,
            storageCta: number | null,
            connectorsMessage: string | null,
            connectorsCta: number | null,
            shareFilesMessage: string | null,
            shareFilesCta: number | null,
            transmissionMessage: string | null,
            transmissionCta: number | null,
            upgrade: string | null,
            trialPeriodDays: number
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                name,
                longDescription,
                description,
                duration,
                storage,
                product: stripeProduct,
                price: stripePrice,
                tribe,
                free,
                manual,
                publicName,
                treasy,
                repeatable,
                connectors,
                shareFiles,
                transmission,
                isDefault,
                storageMessage,
                storageCta,
                connectorsMessage,
                connectorsCta,
                shareFilesMessage,
                shareFilesCta,
                transmissionMessage,
                transmissionCta,
                upgrade,
                trialPeriodDays,
            }

            try {
                const postData = yield post<typeof jsonData, void>('/v1/bo/pricing', jsonData)
                const {
                    data: { pricing },
                } = postData
                self.assignPricing({ pricing })

                toast('success', 'web_pricing_profile_saved')
            } catch (error) {
                console.error(error)
            }
        }),

        updatePricing: flow(function* (
            id: string,
            name: string,
            publicName: string,
            description: string,
            longDescription: string,
            duration: { length: number; period: string },
            free: boolean,
            manual: boolean,
            treasy: boolean,
            storage: number,
            repeatable: boolean,
            connectors: number,
            shareFiles: number,
            transmission: number,
            isDefault: boolean,
            tribe: number,
            stripeProduct: string,
            stripePrice: string,
            storageMessage: string | null,
            storageCta: number | null,
            connectorsMessage: string | null,
            connectorsCta: number | null,
            shareFilesMessage: string | null,
            shareFilesCta: number | null,
            transmissionMessage: string | null,
            transmissionCta: number | null,
            upgrade: string | null,
            trialPeriodDays: number
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                name,
                description,
                longDescription,
                duration,
                storage,
                product: stripeProduct,
                price: stripePrice,
                free,
                manual,
                publicName,
                treasy,
                tribe,
                repeatable,
                connectors,
                shareFiles,
                transmission,
                isDefault,
                storageMessage,
                storageCta,
                connectorsMessage,
                connectorsCta,
                shareFilesMessage,
                shareFilesCta,
                transmissionMessage,
                transmissionCta,
                upgrade,
                trialPeriodDays,
            }

            try {
                const postData = yield put<typeof jsonData, void>(`/v1/bo/pricing/${id}`, jsonData)
                const {
                    data: { pricing },
                } = postData
                self.assignPricing({ pricing })

                toast('success', 'web_pricing_profile_saved')
            } catch (error) {
                console.error(error)
            }
        }),

        inviteUser: flow(function* (
            id: string,
            emails: string,
            subject: string,
            confidential: boolean,
            tags: string[],
            type: string
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                emails,
                subject,
                confidential,
                tags,
                type,
            }

            try {
                yield post<typeof jsonData, void>(`/v1/web/partners/${id}/invite`, jsonData)

                toast('success', 'web_invitation_saved')
            } catch (error) {
                console.error(error)
            }
        }),

        loadInformationMessages: flow(function* (
            id: string = undefined,
            type: 'partner' | 'brand' | 'franchise' = 'partner'
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const getData = { id, type }
                const {
                    data: { informationMessages, count },
                } = yield get<typeof getData, { data: typeof self }>('/v1/bo/information-messages', getData)

                self.assignInformationMessages({ informationMessages, totalInformationMessages: count })
            } catch (err) {
                root.error.prepare(err)
            }
        }),
        loadNews: flow(function* (partnerId: string = undefined) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { news, count },
                } = yield get<void, { data: typeof self }>(`/v1/web/partners/${partnerId}/news`)

                self.assignNews({ news, totalNews: count })
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))

export interface Admin extends Instance<typeof _Admin> {}
