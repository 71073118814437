import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { BrandNews } from 'stores/brands/news'
import { News as Model } from 'stores/news/news'
import DownloadLink from '../spaceroom/download-link'

interface NewsProps {
    news: Model | BrandNews
    onRefresh: () => void
}

const News: FC<NewsProps> = ({ news, onRefresh }) => {
    const { t } = useTranslation()
    const [showAll, setShowAll] = useState<boolean>(false)

    const onDismiss = async () => {
        await news.dismiss()
        onRefresh()
    }

    return (
        <div
            className="rounded border-l-[10px] p-4 shadow-md print:shadow-none"
            style={{ borderLeftColor: news.color }}>
            <div className="mt-4 flex flex-col space-y-2 md:ml-2 md:mt-0">
                <h2 className="flex items-center justify-between text-lg font-bold">
                    <span>{news.title}</span>
                    {news.dismissible && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            onClick={onDismiss}
                            className="cursor-pointer fill-current text-regent-gray">
                            <path
                                d="M23.5,9.111,21.889,7.5,15.5,13.889,9.111,7.5,7.5,9.111,13.889,15.5,7.5,21.889,9.111,23.5,15.5,17.111,21.889,23.5,23.5,21.889,17.111,15.5Z"
                                transform="translate(-7.5 -7.5)"
                            />
                        </svg>
                    )}
                </h2>
                {showAll ? (
                    <ReactMarkdown className="prose w-full max-w-full">{news.bodyMessage}</ReactMarkdown>
                ) : (
                    <p>{news.teaserText}</p>
                )}
                <p>
                    <span
                        className="block w-full cursor-pointer text-center text-sm text-regent-gray"
                        onClick={() => setShowAll(s => !s)}>
                        {showAll ? t('web_news_show_less') : t('web_news_show_more')}
                    </span>
                </p>
                {news.upload && (
                    <DownloadLink href={news.upload} className="flex items-center space-x-2 underline">
                        <span>{t('web_news_download_file')}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10.479" height="13.971" className="fill-current">
                            <path d="M4.964 6.988a2.633 2.633 0 0 1-.055-1.28c.229.001.207 1.008.055 1.28Zm-.047 1.288a12.591 12.591 0 0 1-.775 1.711 10.048 10.048 0 0 1 1.716-.6 3.535 3.535 0 0 1-.941-1.111Zm-2.568 3.406c0 .022.36-.147.952-1.1a3.772 3.772 0 0 0-.952 1.1Zm4.418-7.316h3.711v8.95a.653.653 0 0 1-.655.655H.655A.653.653 0 0 1 0 13.316V.655A.653.653 0 0 1 .655 0h5.457v3.711a.657.657 0 0 0 .655.655Zm-.218 4.688a2.739 2.739 0 0 1-1.165-1.468 3.922 3.922 0 0 0 .169-1.752.683.683 0 0 0-1.3-.186 4.537 4.537 0 0 0 .221 2.1 25.624 25.624 0 0 1-1.113 2.341s0 0-.005 0c-.739.379-2.008 1.214-1.487 1.856a.848.848 0 0 0 .587.273c.488 0 .974-.491 1.667-1.686a15.554 15.554 0 0 1 2.15-.632 4.135 4.135 0 0 0 1.746.532.706.706 0 0 0 .538-1.184c-.379-.371-1.482-.265-2.008-.2Zm3.738-6.189L7.613.191A.654.654 0 0 0 7.149 0h-.163v3.493h3.493v-.167a.653.653 0 0 0-.191-.461ZM8.265 9.832c.112-.074-.068-.325-1.168-.246 1.013.431 1.168.246 1.168.246Z" />
                        </svg>
                    </DownloadLink>
                )}
                <p className=" text-sm font-bold ">{news.smartDate}</p>
            </div>
        </div>
    )
}

export default News
