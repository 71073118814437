import { Children, ReactNode } from 'react'

const Breadcrumb = ({ children }: { children: ReactNode }) => {
    return (
        <div className="mb-4 flex items-center space-x-2">
            {Children.map(children, (child, index) => {
                return (
                    <>
                        {child}
                        {Children.count(children) !== index + 1 && <span className="">&gt;</span>}
                    </>
                )
            })}
        </div>
    )
}

export default Breadcrumb
