import { toJS } from 'mobx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { useMst } from '../../../stores/store'
import Admin from '../admin'
import Form, { Schema } from './form'

const Edit = () => {
    const { admin } = useMst()
    const { t } = useTranslation()
    const { id } = useParams()
    const [values, setValues] = useState<Record<string, unknown>>({})
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(true)

    const onSubmit = async (data: Schema) => {
        const {
            name,
            url,
            companyNumber,
            healthPartner,
            logo,
            icon,
            pricing,
            otherPricing,
            managers,
            caseManagers,
            contactUrl,
        } = data
        setLoading(true)

        await admin.updatePartner(
            id,
            name,
            url,
            contactUrl,
            companyNumber,
            healthPartner,
            logo,
            icon,
            pricing.value,
            otherPricing ? otherPricing.map(p => p.value) : [],
            managers ? managers.map(u => u.value) : [],
            caseManagers ? caseManagers.map(u => u.value) : []
        )
        setLoading(false)
        navigate('/admin/partners')
    }

    const load = async () => {
        setLoading(true)
        await admin.loadPricings(1000, 0)
        await admin.loadPartner(id)

        const values = {
            ...toJS(admin.editingPartner),
            pricing: admin.editingPartner.pricing
                ? {
                      value: admin.editingPartner.pricing.uuid,
                      label: admin.editingPartner.pricing.name,
                  }
                : undefined,
            otherPricing: admin.editingPartner.otherPricing.map(pricing => ({
                value: pricing.uuid,
                label: pricing.name,
            })),
            managers: admin.editingPartner.managers.map(manager => ({
                value: manager.id,
                label: `${manager.fullname} (${manager.email})`,
            })),
            caseManagers: admin.editingPartner.caseManagers.map(manager => ({
                value: manager.id,
                label: `${manager.fullname} (${manager.email})`,
            })),
        }
        setValues(values)
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [id])

    return (
        <Admin title={t('web_menu_admin_partner')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} values={values} />
        </Admin>
    )
}

export default Edit
