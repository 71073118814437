import { Line } from './line'
import { Row } from './row'

export const Loader = () => {
    return (
        <Line>
            <Row className="col-span-full mb-1 h-8 border-b border-mercury p-1 transition-all duration-300 ease-linear">
                <div className="linear-background ml-14 h-6 w-1/3 rounded"></div>
            </Row>
        </Line>
    )
}
