import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BrandNews } from 'stores/brands/news'
import { useMst } from 'stores/store'
import ContentPage from '../content-page'
import News from './news'

interface AllNewsProps {}

const AllNews: FC<AllNewsProps> = () => {
    const { t } = useTranslation()
    const { newsStore, brand, user } = useMst()
    const [brandNews, setBrandNews] = useState<BrandNews[]>([])

    const load = async () => {
        if (user.currentBrand) {
            const { news } = await brand.dashboardListNews()
            setBrandNews(news)
        } else {
            await newsStore.load()
        }
    }

    useEffect(() => {
        load()
    }, [])

    return (
        <ContentPage title={t('web_news_title')}>
            <div className="flex flex-col space-y-4">
                {user.currentBrand ? (
                    <>
                        {brandNews.length === 0 && <p>{t('web_news_no_news')}</p>}
                        {brandNews.map(news => (
                            <News key={news.uuid} news={news} onRefresh={() => load()} />
                        ))}
                    </>
                ) : (
                    <>
                        {newsStore.news.length === 0 && <p>{t('web_news_no_news')}</p>}
                        {newsStore.news.map(news => (
                            <News key={news.uuid} news={news} onRefresh={() => load()} />
                        ))}
                    </>
                )}
            </div>
        </ContentPage>
    )
}

export default AllNews
