import { type Instance, types } from 'mobx-state-tree'
import { _User } from '../users'
import { _UserType } from '../userType/user-type'

const _TrustedUserCollaborationSpace = types.model('TrustedUserCollaborationSpace', {
    uuid: types.string,
    name: types.string,
})

const _TrustedUserCollaboration = types.model('TrustedUserCollaboration', {
    uuid: types.string,
    space: _TrustedUserCollaborationSpace,
})

export const _TrustedUser = types.model('TrustedUser', {
    uuid: '',
    accepted: false,
    trustedUser: types.optional(_User, {}),
    userType: types.optional(_UserType, {}),
    collaboration: types.maybeNull(_TrustedUserCollaboration),
})

export interface TrustedUser extends Instance<typeof _TrustedUser> {}
