interface IconUserFolderProps {
    className?: string
}

const IconUserFolder = ({ className }: IconUserFolderProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={className} fill="currentColor">
            <path d="M512 416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H181.5c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7H448c35.3 0 64 28.7 64 64V416zM320 256c0-35.3-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64zM144 405.3c0 5.9 4.8 10.7 10.7 10.7H357.3c5.9 0 10.7-4.8 10.7-10.7c0-29.5-23.9-53.3-53.3-53.3H197.3c-29.5 0-53.3 23.9-53.3 53.3z" />
        </svg>
    )
}

export default IconUserFolder
