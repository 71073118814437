import { Trans } from 'react-i18next'
import { toast as reactToast } from 'react-toastify'

type ToastType = 'success' | 'error' | 'info' | 'warning'

interface Props {
    title: string
    message?: string
    icon?: string
    type: ToastType
    titleParameters?: Record<string, string | number>
    messageParameters?: Record<string, string | number>
}
const ToastContent = ({ title, message, icon, type, titleParameters = {}, messageParameters = {} }: Props) => {
    return (
        <div className={`flex ${type}`}>
            {icon && <img src={icon} alt="" className="h-6" />}
            <div className="ml-4 flex flex-col">
                <h5 className="font-bold">
                    <Trans i18nKey={title} values={titleParameters} />
                </h5>
                {message && <Trans i18nKey={message} values={messageParameters} />}
            </div>
        </div>
    )
}

const toast = (
    type: ToastType,
    title: string,
    icon?: string,
    message?: string,
    titleParameters?: Record<string, string | number>,
    messageParameters?: Record<string, string | number>,
    autoClose: number | false | undefined = 2500,
    toastOptions: undefined | Record<string, unknown> = {}
): void => {
    reactToast[type](
        <ToastContent
            type={type}
            title={title}
            icon={icon}
            message={message}
            titleParameters={titleParameters}
            messageParameters={messageParameters}
        />,
        {
            position: 'bottom-right',
            autoClose: autoClose ?? 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            ...toastOptions,
        }
    )
}
export default toast
