import { clsx } from 'clsx'
import { Td } from 'components/shared/td'
import Tooltip from 'components/shared/tooltip'
import { Tr } from 'components/shared/tr'
import { DateTime } from 'luxon'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { SharedFile, SharedUser } from 'stores/files'
import { SpaceDirectory } from 'stores/files/directory'
import { SpaceFile } from 'stores/files/file'
import { Space } from 'stores/files/space'
import { Partner } from 'stores/partners'
import { useMst } from 'stores/store'
import { User } from 'stores/users'
import { padding } from '../../dataroom/spaceroom/props'
import { SharingPanelProps } from '../sharing-panel'
import SharingPanelLineOwner from './sharing-panel-line-owner'
import SharingPanelLinePartner from './sharing-panel-line-partner'
import SharingPanelLineUsers from './sharing-panel-line-users'

const shareStatus = (status: 'running' | 'stopped' | 'expired'): string => {
    switch (status) {
        case 'running':
            return 'text-sushi'
        case 'stopped':
            return 'text-regent-gray'
        case 'expired':
        default:
            return 'text-heather'
    }
}

export interface LineProps extends SharingPanelProps {
    level: number
    sharedFile: SharedFile
    users?: SharedUser[]
    owner?: User
    partner?: Partner
    className?: string

    setShowConfirmStop: (sharedFile: SharedFile) => void
    setShowRestart: (sharedFile: SharedFile) => void
    setShowConfirmDelete: (sharedFile: SharedFile) => void
    // setShowEditGroupName: (shareGroup: ShareGroup) => void
}

export interface SharedLinePartProps extends LineProps {
    fileOrDirectory: SpaceFile | SpaceDirectory | Space
    children: ReactNode
}

const SharedLinePart = (
    {
        fileOrDirectory,
        className,
        children,
        level,
        sharedFile,
        users,
        owner,
        partner,
        showUserSharedByMe,
        showUserSharedWithMe,
        setDownloading,
        setShowConfirmStop,
        setShowRestart,
        setShowConfirmDelete,
    }: SharedLinePartProps
) => {
    const { t } = useTranslation()
    const { files } = useMst()

    const onDownload = async () => {
        setDownloading(true)
        await files.downloadShare(sharedFile.uuid)
        setDownloading(false)
    }

    return (
        <Tr fileOrDirectory={fileOrDirectory} className={clsx(className)}>
            <Td
                className="flex items-center space-x-2"
                style={{ padding: `.25rem .25rem .25rem ${padding(level)}rem` }}>
                {children}
            </Td>
            <Td>
                {level === 0 && (
                    <>
                        {sharedFile.shareGroup &&
                        sharedFile.shareGroup.uuid !== '' &&
                        sharedFile.shareGroup.groupName !== '' ? (
                            <div className="flex items-center justify-center text-sm font-normal  ">
                                {sharedFile.shareGroup.groupName}
                            </div>
                        ) : (
                            <div
                                className={clsx(
                                    'flex items-center justify-center -space-x-2',
                                    sharedFile.canDownload ? '' : 'opacity-70'
                                )}>
                                {users?.length > 0 && <SharingPanelLineUsers users={users} />}
                                {partner && <SharingPanelLinePartner partner={partner} />}
                                {owner && <SharingPanelLineOwner user={owner} />}
                            </div>
                        )}
                    </>
                )}
            </Td>
            <Td className={`text-center ${sharedFile.canDownload ? '' : 'text-regent-gray opacity-70'}`}>
                {level === 0
                    ? sharedFile.dateTo
                        ? sharedFile.dateTo.toLocaleString(DateTime.DATE_SHORT)
                        : sharedFile.unlimited
                        ? t('web_share_trusted_people_unlimited_time')
                        : '-'
                    : '-'}
            </Td>
            <Td
                className={`text-center ${sharedFile.canDownload ? '' : 'text-regent-gray opacity-70'} ${shareStatus(
                    sharedFile.status
                )}`}>
                {level === 0 ? t(`web_share_status_${sharedFile.status}`) : ''}
            </Td>
            {showUserSharedWithMe && (
                <Td
                    className={
                        level === 0 && sharedFile.canDownload && sharedFile.downloaded
                            ? 'text-center'
                            : 'flex items-center justify-center'
                    }>
                    {level === 0 && sharedFile.canDownload ? (
                        sharedFile.downloaded ? (
                            t('web_share_file_downloaded')
                        ) : (
                            <Tooltip tooltip={t('web_share_file_download')}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 cursor-pointer"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    onClick={() => onDownload()}>
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                    />
                                </svg>
                            </Tooltip>
                        )
                    ) : (
                        ''
                    )}
                </Td>
            )}
            <Td className={`flex items-center justify-center ${sharedFile.canDownload ? '' : 'text-regent-gray'}`}>
                {showUserSharedByMe && level === 0 && (
                    <>
                        {sharedFile.status === 'running' ? (
                            <Tooltip tooltip={t('web_shares_stop_share')}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 cursor-pointer"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    onClick={() => setShowConfirmStop(sharedFile)}>
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </Tooltip>
                        ) : sharedFile.canBeRestarted ? (
                            <Tooltip tooltip={t('web_shares_restart_share')}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 cursor-pointer"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    onClick={() => setShowRestart(sharedFile)}>
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                        <Tooltip tooltip={t('web_shares_delete_share')}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                onClick={() => setShowConfirmDelete(sharedFile)}>
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                            </svg>
                        </Tooltip>
                    </>
                )}
            </Td>
        </Tr>
    )
}
export default SharedLinePart
