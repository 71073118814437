import { DateTime } from 'luxon'
import { Instance, types } from 'mobx-state-tree'

export const _Payment = types
    .model('Payment', {
        active: false,
        provider: '',
        currentPeriodEnd: types.optional(types.number, 0, [null, undefined]),
        cancelAt: types.optional(types.number, 0, [null, undefined]),
    })
    .views(self => ({
        get renewDate(): DateTime | undefined {
            if (self.currentPeriodEnd === 0) {
                return undefined
            }

            return DateTime.fromMillis(self.currentPeriodEnd)
        },
        get cancelAtDate(): DateTime | undefined {
            if (self.cancelAt === 0) {
                return undefined
            }

            return DateTime.fromMillis(self.cancelAt)
        },
    }))

export interface Payment extends Instance<typeof _Payment> {}
