import { loadStripe } from '@stripe/stripe-js'
import toast from 'core/utils/toast'
import { flow, getRoot, Instance, types } from 'mobx-state-tree'
import { del, get, post, put } from '../core/services/http-service'
import { _Payment } from './payments/payment'
import { _Pricing, Pricing } from './pricing'
import { RootInstance } from './store'

export const _PaymentManager = types
    .model('PaymentManager', {
        payment: types.optional(_Payment, {
            active: false,
        }),
        pricings: types.array(_Pricing),
    })
    .actions(self => ({
        loadStripePrice: flow(function* (id: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: {
                        prices: { data },
                    },
                } = yield get<void, never>('/v1/web/prices')
                const price = data.find(price => price.id === id)

                return price
            } catch (error) {
                console.error(error)
            }
        }),
    }))
    .actions(self => ({
        subscribe: flow(function* (pricing: Pricing, ctaContext?: boolean) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {
                sessionId: string
            }
            interface JsonReturn {
                data: JsonReturnContent
            }

            const data = {
                price: pricing.uuid,
                ctaContext,
            }

            try {
                const res = yield post<typeof data, JsonReturn>('/v1/web/subscription/checkout', data)
                const {
                    data: { sessionId, apiKey },
                } = res

                const stripe = yield loadStripe(apiKey)

                stripe.redirectToCheckout({ sessionId })
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        updateSubscription: flow(function* (pricing: Pricing) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const data = {
                price: pricing.uuid,
            }

            try {
                yield put<typeof data, never>('/v1/web/subscription/checkout', data)

                toast('success', 'web_subscription_changed')

                return true
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        cancelPayment: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                yield del<void>('/v1/web/subscription')
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        getStatus: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {
                sessionId: string
            }
            interface JsonReturn {
                data: JsonReturnContent
            }

            try {
                const data = yield get<void, JsonReturn>('/v1/web/subscription/status')

                const {
                    data: { active, provider, currentPeriodEnd, cancelAt, pricings },
                } = data
                self.payment.active = active
                self.payment.provider = provider
                self.payment.cancelAt = cancelAt
                self.payment.currentPeriodEnd = currentPeriodEnd
                if (!pricings) {
                    self.pricings.clear()
                } else {
                    const getDurationInDays = (duration: { length: number; period: 'days' | 'months' | 'years' }) => {
                        switch (duration.period) {
                            case 'days':
                                return duration.length
                            case 'months':
                                return duration.length * 30
                            case 'years':
                                return duration.length * 365
                            default:
                                return 0
                        }
                    }

                    const selfPricings = yield Promise.all(
                        pricings.map(async pricing => {
                            let _currency = '€'
                            let _unit_amount = 0
                            if (pricing.price) {
                                const stripePrice = await self.loadStripePrice(pricing.price)
                                if (stripePrice) {
                                    _currency = stripePrice.currency
                                    _unit_amount = stripePrice.unit_amount
                                }
                            }

                            return {
                                ...pricing,
                                _duration: JSON.stringify(pricing.duration),
                                durationInDays: getDurationInDays(pricing.duration),
                                _currency,
                                _unit_amount,
                            }
                        })
                    )
                    self.pricings = selfPricings.sort((a, b) => {
                        if (a.durationInDays === b.durationInDays) {
                            return a._unit_amount - b._unit_amount
                        }

                        return a.durationInDays - b.durationInDays
                    })
                }
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))

export interface PaymentManager extends Instance<typeof _PaymentManager> {}
