import { ReactComponent as IconGlass } from 'assets/icons/icon-glass.svg'
import { ReactComponent as IconPartner } from 'assets/icons/icon-partner.svg'
import { ReactComponent as IconPosition } from 'assets/icons/icon-position.svg'
import { ReactComponent as IconPlaceholder } from 'assets/icons/icon-user-placeholder.svg'
import { Option } from 'components/admin/forms'
import { Input, selectStyles } from 'components/shared/select-styles'
import countries from 'countries/fr.json'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Async from 'react-select/async'
import { useMst } from 'stores/store'
import { TrustedUser } from 'stores/trustedUser/trusted-user'
import { User } from 'stores/users'
import SmallLoader from '../../shared/small-loader'

interface AddTrustedPersonProps {
    onBack?: () => void
    onCancel?: () => void
    onAdd: (trustedUser: TrustedUser) => void
}

const FindPartner: FC<AddTrustedPersonProps> = ({ onBack, onAdd, onCancel }) => {
    const { t } = useTranslation()
    const { user, userTypeStore, findCity } = useMst()

    const [userType, setUserType] = useState<Option>()
    const [city, setCity] = useState<Option>()
    const [loading, setLoading] = useState<boolean>(false)
    const [showUsers, setShowUsers] = useState<boolean>(false)
    const [users, setUsers] = useState<User[]>([])
    const [selectedUser, setSelectedUser] = useState<User>()

    const search = async () => {
        setLoading(true)
        const users = await userTypeStore.findUsers(userType?.value, city?.value)
        setUsers(users)
        setShowUsers(true)
        setLoading(false)
    }

    const add = async () => {
        setLoading(true)
        const trustedUser = await user.linkTrustedPerson(selectedUser.uuid, userType.value)
        setLoading(false)
        onAdd(trustedUser)
    }

    return (
        <div className={`relative w-full rounded-lg bg-white ${onBack ? 'p-4' : ''}`}>
            {loading && (
                <div className="absolute inset-0 z-50 flex flex-col items-center justify-center rounded-lg bg-black/30">
                    <SmallLoader />
                </div>
            )}
            <div className="flex w-full items-center space-x-4">
                {onBack && (
                    <button className="flex items-center justify-center" onClick={onBack}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.752"
                            height="11.809"
                            viewBox="0 0 6.752 11.809">
                            <path
                                d="M12.094,13.282l4.465,4.469a.84.84,0,0,0,1.192,0,.851.851,0,0,0,0-1.2l-5.059-5.063a.842.842,0,0,0-1.164-.025L6.434,16.552a.844.844,0,1,0,1.192,1.2Z"
                                transform="translate(-11.246 17.996) rotate(-90)"
                                fill="#2f393e"
                            />
                        </svg>
                    </button>
                )}
                <div
                    className={
                        'flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-nepal text-white'
                    }>
                    <IconPartner className="fill-current" />
                </div>
                <h1 className="text-2xl">{t('web_share_search_partner')}</h1>
            </div>
            {showUsers ? (
                <div className="my-8 flex w-full flex-col space-y-4">
                    {users.map(user => (
                        <div
                            key={user.uuid}
                            onClick={() => setSelectedUser(user)}
                            className={`flex cursor-pointer items-center space-x-4 hover:bg-nepal/10 ${
                                selectedUser && user.uuid === selectedUser.uuid ? 'bg-nepal/10' : ''
                            } px-8 py-4`}>
                            <div
                                className={
                                    'relative flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-regent-gray text-white'
                                }>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <IconPlaceholder className="h-6 w-6 fill-current" />
                                </div>
                                <div
                                    className={
                                        'absolute inset-0 flex items-center justify-center bg-cover bg-center bg-no-repeat'
                                    }
                                    style={{ backgroundImage: `url(${user.picture})` }}></div>
                            </div>
                            <div className="flex flex-col justify-center">
                                <span className="text-lg">{user.fullname}</span>
                                <span className="text-sm font-bold">{user.userType.name}</span>
                                <div className="flex items-center space-x-4">
                                    <IconPosition className="h-4 fill-current text-regent-gray" />
                                    <span className="">{user.address}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="flex items-center justify-center space-x-4">
                        {onCancel && (
                            <button className="btn white" type="button" onClick={() => onCancel()}>
                                {t('web_cancel')}
                            </button>
                        )}
                        <button type="submit" className="btn" onClick={() => add()} disabled={!selectedUser}>
                            {t('web_share_add_trusted_partner_save')}
                        </button>
                    </div>
                </div>
            ) : (
                <div className="m-2">
                    <div className="flex w-full flex-col space-y-4">
                        <Async
                            styles={{
                                ...selectStyles,
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                input: (provided, state) => {
                                    return { ...provided, paddingLeft: '40px' }
                                },
                            }}
                            components={{ Input }}
                            className="w-full"
                            value={userType}
                            noOptionsMessage={() => t('web_share_trusted_people_search_no_options')}
                            isClearable={true}
                            defaultOptions
                            placeholder={
                                <div className="flex items-center space-x-4 text-regent-gray">
                                    <IconGlass className="h-4 fill-current" />
                                    <span>{t('web_share_trusted_people_search_type')}</span>
                                </div>
                            }
                            loadOptions={async (inputValue: string) => {
                                await userTypeStore.load(inputValue, true)

                                return [...userTypeStore.userTypes]
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(({ uuid, name }) => ({
                                        value: uuid,
                                        label: name,
                                    }))
                            }}
                            onChange={(option: Option) => setUserType(option)}
                        />
                        <Async
                            styles={{
                                ...selectStyles,
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                input: (provided, state) => {
                                    return { ...provided, paddingLeft: '40px' }
                                },
                            }}
                            components={{ Input }}
                            className="w-full"
                            value={city}
                            noOptionsMessage={() => t('web_share_trusted_people_search_no_options')}
                            isClearable={true}
                            // defaultOptions
                            placeholder={
                                <div className="ml-2 flex items-center space-x-5 text-regent-gray">
                                    <IconPosition className="h-4 fill-current" />
                                    <span>{t('web_share_trusted_people_search_city')}</span>
                                </div>
                            }
                            loadOptions={async (inputValue: string) => {
                                const cities = await findCity(inputValue)

                                return cities.map(({ uuid, name, zip, country }) => ({
                                    value: uuid,
                                    label: `${zip} - ${name} (${countries[country]})`,
                                }))
                            }}
                            onChange={(option: Option) => setCity(option)}
                        />

                        <div
                            className="text-regent-gray"
                            dangerouslySetInnerHTML={{ __html: t('web_share_add_trusted_desc_partner') }}></div>

                        <div className="flex items-center justify-center space-x-4">
                            {onCancel && (
                                <button className="btn white" type="button" onClick={() => onCancel()}>
                                    {t('web_cancel')}
                                </button>
                            )}
                            <button
                                type="submit"
                                className="btn"
                                onClick={() => search()}
                                disabled={!city || !userType}>
                                {t('web_share_add_trusted_partner_next')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FindPartner
