import { clsx } from 'clsx'
import { MouseEventHandler } from 'react'

interface SmallButtonProps {
    name: string
    size: number
    active?: boolean
    onClick?: MouseEventHandler
}

const SmallButton = ({ name, size, active = false, onClick }: SmallButtonProps) => {
    const textSize = size => {
        const sizes = {
            6: 'text-xs',
            8: 'text-sm',
            10: 'text-base',
            12: 'text-lg',
            14: 'text-xl',
            16: 'text-2xl',
        }

        return sizes[size] ?? 'text-md'
    }
    const width = `w-${size}` as 'w-6' | 'w-8' | 'w-10' | 'w-12' | 'w-14' | 'w-16'
    const height = `h-${size}` as 'h-6' | 'h-8' | 'h-10' | 'h-12' | 'h-14' | 'h-16'

    return (
        <div
            onClick={onClick}
            className={clsx(
                'm-2 inline-block cursor-pointer rounded-full bg-white hover:shadow-atomic-tangerine',
                width,
                height,
                active ? 'shadow-atomic-tangerine' : 'shadow-lg'
            )}>
            <div className="flex h-full w-full flex-wrap items-center justify-center">
                <span
                    className={`font-bold ${active ? 'text-atomic-tangerine' : 'text-regent-gray'} ${textSize(size)}`}>
                    {name}
                </span>
            </div>
        </div>
    )
}

export default SmallButton
