import Ocr from 'assets/dataroom/ocr'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useMst } from 'stores/store'
import { ReactComponent as IconConnector } from '../../assets/connectors.svg'
import { ReactComponent as IconMessage } from '../../assets/notifications/icon-message.svg'
import { ReactComponent as IconSharing } from '../../assets/notifications/icon-sharing.svg'
import { ReactComponent as IconSponsor } from '../../assets/notifications/icon-sponsor.svg'
import { ReactComponent as IconTransfer } from '../../assets/notifications/icon-transfer.svg'
import { ReactComponent as IconTreasy } from '../../assets/notifications/icon-treasy.svg'
import ContentPage from '../dataroom/content-page'
import Breadcrumb from '../shared/breadcrumb'
import Panel from '../shared/panel'
import NotificationSetting from './notification-setting'

const NotificationSettings = () => {
    const { t } = useTranslation()
    const { user } = useMst()

    const types = [
        { type: 'telecollecte', icon: <IconConnector className="h-6 w-6 fill-current" /> },
        { type: 'ocr', icon: <Ocr className="h-6 w-6 fill-current" /> },
        { type: 'sharing', icon: <IconSharing className="h-6 w-6 fill-current" /> },
        { type: 'transfer', icon: <IconTransfer className="h-6 w-6 fill-current" /> },
        { type: 'messages', icon: <IconMessage className="h-6 w-6 fill-current" /> },
        { type: 'treasy', icon: <IconTreasy className="h-6 w-6 fill-current" /> },
        { type: 'sponsoring', icon: <IconSponsor className="h-6 w-6 fill-current" /> },
    ]

    useEffect(() => {
        const load = async () => {
            await user.loadNotifications()
        }
        load()
    }, [])

    return (
        <ContentPage title={t('web_notifications_title')}>
            <Breadcrumb>
                <Link to="/me/notifications">{t('web_notifications_title')}</Link>
                <span>{t('web_notifications_configuration_title')}</span>
            </Breadcrumb>

            <div className="mb-4 flex w-full flex-col">
                <span>{t('web_notifications_configuration_subtitle')}</span>
            </div>

            <Panel size="full">
                {types.map(type => (
                    <NotificationSetting key={type.type} type={type.type} icon={type.icon} />
                ))}
            </Panel>
        </ContentPage>
    )
}

export default NotificationSettings
