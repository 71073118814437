import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as IconClipboard } from '../../assets/icons/icon-clipboard.svg'
import { ReactComponent as TwoFaLock } from '../../assets/login/register-lock.svg'
import { ReactComponent as TwoFaZen } from '../../assets/login/register-zen.svg'
import { ReactComponent as TwoFaSecure } from '../../assets/profile/secure-app.svg'
import { ReactComponent as TwoFaIcon } from '../../assets/profile/two-fa.svg'
import toast from '../../core/utils/toast'
import { useMst } from '../../stores/store'
import Panel from '../shared/panel'
import ProfilePage from './profile-page'

const TwoFa: FC = observer(() => {
    const { user } = useMst()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [shouldVerify, setShouldVerify] = useState<boolean>(false)
    const [twoFaVerify, setTwoFaVerify] = useState<string>('')
    const [showBack, setShowBack] = useState<boolean>(false)

    const verify = async () => {
        await user.verifyTwoFaCode(twoFaVerify, true)
    }
    const removeTwoFa = async () => {
        await user.removeTwoFa(twoFaVerify)
        setTwoFaVerify('')
        setShowBack(true)
    }

    useEffect(() => {
        if (!user.twoFa && user.twoFaQrCodeImage === '') {
            user.initTwoFa()
        }
    }, [user.twoFaQrCodeImage])

    return (
        <ProfilePage url="/me/security/twofa">
            <Panel
                className="mt-4"
                innerClassName={`flex flex-col items-center p-8  bg-white ${
                    user.twoFaKeys.length > 0 ? 'bg-yellow-orange text-white' : ''
                }`}>
                <div className="flex w-full flex-col space-y-2 md:flex-row md:space-x-8 md:space-y-0">
                    {user.twoFaKeys.length === 0 && !shouldVerify && <TwoFaIcon className="w-full flex-none md:w-64" />}
                    {user.twoFaKeys.length === 0 && shouldVerify && (
                        <TwoFaSecure className="w-full flex-none md:w-80" />
                    )}
                    {user.twoFaKeys.length > 0 && <TwoFaZen className="w-64 flex-none" />}
                    <div className="flex w-full flex-col space-y-4">
                        <div className="cms-page">
                            <h3 className="text-2xl font-bold">
                                {user.twoFaKeys.length > 0
                                    ? t('web_me_security_two_factor_success')
                                    : t('web_me_security_two_factor')}
                            </h3>
                            {user.twoFaKeys.length === 0 && !shouldVerify && (
                                <div>
                                    <Trans i18nKey="web_me_security_two_factor_long_desc">
                                        web_me_security_two_factor_long_desc
                                    </Trans>
                                </div>
                            )}
                        </div>
                        {!user.twoFa && (
                            <>
                                {user.twoFaKeys.length > 0 && (
                                    <div>
                                        <Trans i18nKey="web_me_security_two_factor_success_title">
                                            web_me_security_two_factor_success_title
                                        </Trans>
                                    </div>
                                )}
                                <div>
                                    {user.twoFaKeys.length === 0 && (
                                        <>
                                            {!shouldVerify && !user.twoFa && user.twoFaQrCodeImage !== '' && (
                                                <div className="mt-4 flex flex-col space-y-4">
                                                    <span className="text-lg">
                                                        {t('web_me_security_two_factor_use_app')}
                                                    </span>
                                                    <img src={user.twoFaQrCodeImage} className="w-32" />
                                                    <span>{t('web_me_security_two_factor_use_code')}</span>
                                                    <span className="font-bold tracking-widest text-christine">
                                                        {user.twoFaCode}
                                                    </span>
                                                    <div>
                                                        <button className="btn" onClick={() => setShouldVerify(true)}>
                                                            {t('web_me_security_two_factor_validate')}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {shouldVerify && (
                                                <div className="mt-4 flex flex-col space-y-4">
                                                    <span className="text-lg">
                                                        {t('web_me_security_two_factor_please_verify')}
                                                    </span>
                                                    <span className="font-bold uppercase">
                                                        {t('web_me_security_two_factor_verify_code')}
                                                    </span>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="w-full md:w-1/2"
                                                            value={twoFaVerify}
                                                            onChange={e => setTwoFaVerify(e.currentTarget.value)}
                                                            placeholder={t(
                                                                'web_me_security_two_factor_verification_code'
                                                            )}
                                                        />
                                                    </div>
                                                    <div>
                                                        <button
                                                            className="btn w-full md:w-1/2"
                                                            onClick={() => verify()}>
                                                            {t('web_me_security_two_factor_verify')}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}

                        {user.twoFa && (
                            <>
                                {user.twoFaKeys.length > 0 && (
                                    <>
                                        <div className="flex w-full justify-center">
                                            {/* <img src={lock} alt="" className="my-8" /> */}
                                            <TwoFaLock className="pulse-button my-4" />
                                        </div>
                                        <div className="my-4 font-bold">
                                            <Trans i18nKey="web_me_security_two_factor_success_save_keys">
                                                web_me_security_two_factor_success_save_keys
                                            </Trans>
                                        </div>
                                        <div className="relative">
                                            <pre className="rounded bg-geyser p-4 text-sm text-thunder shadow-inner">
                                                {user.twoFaKeys.join('\n')}
                                            </pre>
                                            <IconClipboard
                                                className="absolute bottom-2 right-2 w-4 cursor-pointer fill-current text-regent-gray"
                                                onClick={async () => {
                                                    await navigator.clipboard.writeText(user.twoFaKeys.join('\n'))
                                                    toast('success', 'web_share_file_link_copied')
                                                }}
                                            />
                                        </div>
                                        <div className="my-4 flex w-full justify-center">
                                            <button
                                                className="btn white"
                                                onClick={() => {
                                                    user.clearTwoFaInit()
                                                    navigate('/me/security')
                                                }}>
                                                {t('web_me_security_two_factor_success_back')}
                                            </button>
                                        </div>
                                    </>
                                )}
                                {user.twoFaKeys.length === 0 && (
                                    <>
                                        <h4 className="my-4 font-bold">{t('web_me_security_two_factor_delete_2fa')}</h4>
                                        <div className="my-4">
                                            <Trans i18nKey="web_me_security_two_factor_delete_2fa_desc">
                                                web_me_security_two_factor_delete_2fa_desc
                                            </Trans>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                value={twoFaVerify}
                                                onChange={e => setTwoFaVerify(e.currentTarget.value)}
                                                placeholder={t('web_me_security_two_factor_verification_code')}
                                            />
                                        </div>
                                        <div>
                                            <button className="btn" onClick={() => removeTwoFa()}>
                                                {t('web_me_security_two_factor_delete_2fa_validate')}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {showBack && (
                            <div>
                                <button
                                    className="btn white"
                                    onClick={() => {
                                        navigate('/me/security')
                                    }}>
                                    {t('web_me_security_two_factor_success_back')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Panel>
        </ProfilePage>
    )
})

export default TwoFa
