import SmallLoader from 'components/shared/small-loader'
import { get, post } from 'core/services/http-service'
import toast from 'core/utils/toast'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import {
    type ApiDownloadResponse,
    type ApiTransferInfosResponse,
    buttonClassName,
    type CodeFormData,
    type EmailFormData,
} from '.'
import BackgroundBlockchain from '../../assets/file-transfer/backgrounds/blockchain.jpg'
import BackgroundCollab from '../../assets/file-transfer/backgrounds/collaboration.jpg'
import BackgroundStamp from '../../assets/file-transfer/backgrounds/stamp.jpg'
import UploadCta from '../../assets/file-transfer/backgrounds/upload-cta.jpg'
import { ReactComponent as CtaArrowBottom } from '../../assets/file-transfer/cta-arrow-bottom.svg'
import Logo from '../../assets/file-transfer/logo'
import UploadIcon from '../../assets/file-transfer/upload-icon'
import { CodeForm } from './download-page/code-form'
import { EmailForm } from './download-page/email-form'
import { Header } from './header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-solid-svg-icons'
import { Main } from './wrappers/main'
import { Left } from './wrappers/left'
import { Right } from './wrappers/right'

const DownloadPage = () => {
    const { t, i18n } = useTranslation()
    const params = useParams()

    const [ctaBackground, setCtaBackground] = useState<string>(BackgroundCollab)
    const [transferModalDisplayed, displayTransferModal] = useState<boolean>(false)
    const [loadingTransfer, setLoadingTransfer] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [transferInfos, setTransferInfos] = useState<ApiTransferInfosResponse>()
    const [downloadState, setDownloadState] = useState<'none' | 'in_progress' | 'valid'>('none')
    const [recipientCuid, setRecipientCuid] = useState<string>()
    const [accessCodeSent, setAccessCodeSent] = useState<boolean>(false)
    const [emailFormData, setEmailFormData] = useState<EmailFormData>()

    const [emailFormSubmitting, setEmailFormSubmitting] = useState<boolean>(false)
    const [codeFormSubmitting, setCodeFormSubmitting] = useState<boolean>(false)

    useEffect(() => {
        getTransferInfos(params.transferCuid, params.recipientCuid ?? undefined)
        setRecipientCuid(params.recipientCuid)
    }, [params])

    const initDownload = async (cuid: string, recipientCuid?: string, accessCode?: string) => {
        try {
            setDownloadState('in_progress')

            const { url, name } = await post<{ recipientCuid?: string; accessCode?: string }, ApiDownloadResponse>(
                `/v1/web/file-transfer/${cuid}/download?locale=${i18n.language}`,
                { recipientCuid, accessCode }
            )

            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', name)
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)

            setDownloadState('valid')
        } catch (error) {
            displayTransferModal(false)
            toast('error', t('web_file_transfer_download_error'))
        }
    }

    const initTransfer = async () => {
        if (!transferInfos) {
            return
        }

        displayTransferModal(true)

        if (transferInfos.shareType === 'email' || (transferInfos.shareType === 'link' && !transferInfos.isSecure)) {
            await initDownload(transferInfos.cuid, recipientCuid)

            return
        }
    }

    const getTransferInfos = async (cuid: string, recipientCuid?: string) => {
        try {
            const res = await get<never, { found: boolean }>(
                `/v1/web/file-transfer/${cuid}/check?locale=${i18n.language}`
            )

            if (!res.found) {
                setLoadingTransfer(false)
                setErrorMessage(t('web_file_transfer_errors_transfer_not_found'))

                return
            }

            const data = await get<never, ApiTransferInfosResponse>(
                `/v1/web/file-transfer/${cuid}/infos?locale=${i18n.language}`
            )
            if (data.expired) {
                setLoadingTransfer(false)
                setErrorMessage(t('web_file_transfer_errors_transfer_expired'))

                return
            }

            setTransferInfos(data)
        } catch (error) {
            setErrorMessage(error?.message ?? t('web_file_transfer_errors_generic'))
        }

        setLoadingTransfer(false)
    }

    const sendEmailCheck = async (transferInfos: ApiTransferInfosResponse, data: EmailFormData) => {
        await post<EmailFormData>(
            `/v1/web/file-transfer/${transferInfos.cuid}/check-email?locale=${i18n.language}`,
            data
        )
    }

    const onEmailFormSubmit = async (data: EmailFormData) => {
        setEmailFormSubmitting(true)

        try {
            await sendEmailCheck(transferInfos, data)
            setAccessCodeSent(true)
            setEmailFormData(data)
        } catch (error) {
            toast('error', t('web_file_transfer_download_email_error'))
        }

        setEmailFormSubmitting(false)
    }

    const onCodeFormSubmit = async (data: CodeFormData) => {
        setCodeFormSubmitting(true)

        try {
            const result = await post<
                CodeFormData & { email: string },
                { valid: true; cuid: string } | { valid: false }
            >(`/v1/web/file-transfer/${transferInfos.cuid}/check-code?locale=${i18n.language}`, {
                ...data,
                email: emailFormData.email,
            })

            if (!result.valid) {
                setCodeFormSubmitting(false)
                toast(
                    'error',
                    t('web_file_transfer_download_invalid_code'),
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    { position: 'top-center' }
                )

                return
            }

            await initDownload(transferInfos.cuid, result.cuid, data.accessCode)
        } catch (error) {
            toast('error', t('web_file_transfer_download_code_error'))
        }

        setCodeFormSubmitting(false)
    }

    return (
        <div
            className="font-nunito min-h-screen w-full relative overflow-hidden bg-cover bg-no-repeat bg-center grid grid-cols-1"
            style={{
                backgroundImage: `url(${ctaBackground})`,
            }}
        >
            {transferModalDisplayed && <div className="absolute inset-0 z-0 bg-[#2C2A2C]/80" />}

            <Header transferModalDisplayed={transferModalDisplayed} />

            {loadingTransfer ? (
                <SmallLoader />
            ) : (
                <div className="flex items-center justify-center relative">
                    {!transferModalDisplayed ? (
                        <div className="flex flex-col gap-4 items-center z-50">
                            <button type="button" disabled={transferInfos === undefined} onClick={() => initTransfer()}>
                                <UploadIcon className="w-[160px] xl:w-[270px]" />
                            </button>
                            <div className="bg-white rounded-lg p-5 px-10 text-center">
                                {!transferInfos ? (
                                    <>
                                        <h4 className="font-bold text-xl">{t('web_file_transfer_errors_title')}</h4>
                                        <p>{t(errorMessage ?? 'web_file_transfer_errors_transfer_generic')}</p>
                                    </>
                                ) : (
                                    <div className="cursor-pointer" onClick={() => initTransfer()}>
                                        <h4 className="font-bold text-xl">
                                            {t('web_file_transfer_click_to_download')}
                                        </h4>
                                        <p>
                                            {t('web_file_transfer_available_during', {
                                                days: transferInfos.daysAvailable,
                                            })}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <Main>
                            <Left>
                                <div className="h-full flex flex-col gap-8 items-center justify-center">
                                    <UploadIcon className="w-[170px] shrink-0" />
                                    {['in_progress', 'valid'].includes(downloadState) && (
                                        <div className="text-center">
                                            {downloadState === 'in_progress' && (
                                                <>
                                                    <h4 className="font-bold text-xl mb-2 leading-6">
                                                        {t('web_file_transfer_download_preparing')}
                                                    </h4>
                                                    <p className="leading-5 font-nunito text-sm italic">
                                                        {t('web_file_transfer_download_preparing_message')}
                                                    </p>
                                                </>
                                            )}
                                            {downloadState === 'valid' && (
                                                <>
                                                    <h4 className="font-bold text-xl mb-2 leading-6">
                                                        {t('web_file_transfer_download_valid')}
                                                    </h4>
                                                    <p className="leading-5 font-nunito text-sm italic">
                                                        {t('web_file_transfer_download_valid_help')}
                                                    </p>
                                                    <FontAwesomeIcon icon={faDownload} className="text-xl" />
                                                    <p className="leading-5 font-nunito text-sm italic mt-4">
                                                        {t('web_file_transfer_download_valid_thanks')}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    {downloadState === 'none' &&
                                        transferInfos.shareType === 'link' &&
                                        transferInfos.isSecure && (
                                            <>
                                                {accessCodeSent ? (
                                                    <>
                                                        <div className="text-center">
                                                            <h4 className="font-bold text-xl mb-2 leading-6">
                                                                {t('web_file_transfer_download_code_form')}
                                                            </h4>
                                                            <p className="leading-5 font-nunito text-sm italic">
                                                                {t('web_file_transfer_download_code_form_message')}
                                                            </p>
                                                        </div>
                                                        <CodeForm
                                                            onSubmit={onCodeFormSubmit}
                                                            onCancel={() => {
                                                                setAccessCodeSent(false)
                                                            }}
                                                            submitting={codeFormSubmitting}
                                                            onResendCode={async () => {
                                                                await sendEmailCheck(transferInfos, emailFormData)
                                                                toast(
                                                                    'success',
                                                                    t('web_file_transfer_download_code_resent'),
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    { position: 'top-center' }
                                                                )
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="text-center">
                                                            <h4 className="font-bold text-xl mb-2 leading-6">
                                                                {t('web_file_transfer_download_email_form')}
                                                            </h4>
                                                            <p className="leading-5 font-nunito text-sm italic">
                                                                {t('web_file_transfer_download_email_form_message')}
                                                            </p>
                                                        </div>
                                                        <EmailForm
                                                            defaultValues={emailFormData}
                                                            onSubmit={onEmailFormSubmit}
                                                            submitting={emailFormSubmitting}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}
                                </div>
                            </Left>
                            <Right>
                                <div className="p-4 flex flex-col gap-4 lg:gap-8">
                                    <div
                                        className="relative w-full bg-cover p-5 lg:h-[425px] lg:p-10"
                                        style={{
                                            backgroundImage: `url(${UploadCta})`,
                                        }}
                                    >
                                        <Logo className="w-[160px] lg:w-[320px]" />
                                        <div
                                            className="lg:w-1/2 leading-6 bg-white/75 p-2 mt-1 rounded-md"
                                            dangerouslySetInnerHTML={{
                                                __html: t('web_file_transfer_upload_cta_text'),
                                            }}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2 items-center relative lg:flex-row lg:justify-between">
                                        <p
                                            dangerouslySetInnerHTML={{ __html: t('web_file_transfer_upload_cta') }}
                                            className="text-center"
                                        />
                                        <div className="absolute hidden xl:block xl:top-[-225px] xl:right-[125px]">
                                            <CtaArrowBottom />
                                        </div>
                                        <a href="/" target="_blank" className={buttonClassName} rel="noreferrer">
                                            {t('web_file_transfer_cta_discover_short')}
                                        </a>
                                    </div>
                                </div>
                            </Right>
                        </Main>
                    )}
                </div>
            )}
        </div>
    )
}
export default DownloadPage
