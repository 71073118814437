import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getFromQuery } from 'core/use-query'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import validator from 'validator'
import hacker from '../../assets/login/register-hacker.svg'
import lock from '../../assets/login/register-lock.svg'
import woman from '../../assets/login/register-woman.svg'
import zen from '../../assets/login/register-zen.svg'
import { useMst } from '../../stores/store'
import { passwordRegex } from '../../stores/users'
import SecurePassword from '../shared/secure-password'
import LoginApple from './buttons/login-apple'
import LoginGoogle from './buttons/login-google'
import LoginLinkedIn from './buttons/login-linkedin'
import TwoPart from './two-part'
import { AppContext } from 'context'

const Register = observer(() => {
    const rootStore = useMst()
    const { user, error } = rootStore
    const redirect = getFromQuery('redirect')

    const { t, i18n } = useTranslation()
    const maxPages = 3
    const [page, setPage] = useState<number>(1)
    const [cguChecked, setCguChecked] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [firstname, setFirstname] = useState<string>('')
    const [lastname, setLastname] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')

    const {
        loginRegister: { readOnlyEmail, email: registerEmail },
    } = useContext(AppContext)

    useEffect(() => {
        if (registerEmail) {
            setEmail(registerEmail)
        }
    }, [registerEmail])

    const toggleCguChecked = () => setCguChecked(!cguChecked)
    const location = useLocation()

    useEffect(() => {
        rootStore.error.clean()
        rootStore.reset()
    }, [location])

    const canAddPage2 = [!validator.isEmpty(firstname), !validator.isEmpty(lastname), validator.isEmail(email)].reduce(
        (previous, current) => previous && current,
        true
    )

    const canRegister =
        !isLoading &&
        [validator.matches(password, passwordRegex), password === confirmPassword, cguChecked].reduce(
            (previous, current) => previous && current,
            true
        )

    const reset = () => {
        error.clean()
        setFirstname('')
        setLastname('')
        setEmail('')
        setPage(1)
    }

    const src = (page: number): string => {
        switch (page) {
            case 3:
                return zen
            case 2:
                return hacker
            case 1:
            default:
                return woman
        }
    }

    const register = async (cguChecked: boolean) => {
        setIsLoading(true)

        await user.register(email, firstname, lastname, password, i18n.language, cguChecked)

        setIsLoading(false)
        if (error.hasError) {
            return
        }

        setPage(3)
    }

    return (
        <TwoPart
            image={src(page)}
            part="register"
            subTitle={<Trans i18nKey={`web_register_page_${page}_desc`}>{`web_register_page_${page}_desc`}</Trans>}
            page={page}
            maxPages={maxPages}
            bottomChildren={
                <>
                    {page === 1 && (
                        <>
                            <span className="mx-1">{t('web_login_page_connect_sso')}</span>
                            <LoginGoogle />
                            <LoginApple />
                            <LoginLinkedIn />
                        </>
                    )}
                </>
            }
        >
            {page === 1 && (
                <>
                    <div className="mt-8 flex w-full grow flex-col items-center justify-center">
                        <h3 className="text-2xl font-bold md:my-8 md:text-3xl">{t('web_register_page_1_title')}</h3>
                        <div className="flex w-3/4 flex-col">
                            <label className="mt-4 uppercase">
                                {t('web_register_page_1_email_address')} <span className="text-christine">*</span>
                            </label>
                            <input
                                type="email"
                                placeholder={t('web_register_page_1_email_address_placeholder')}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                readOnly={readOnlyEmail}
                            />
                            <label className="mt-4 uppercase">
                                {t('web_register_page_1_lastname')} <span className="text-christine">*</span>
                            </label>
                            <input
                                type="text"
                                placeholder={t('web_register_page_1_lastname_placeholder')}
                                value={lastname}
                                onChange={e => setLastname(e.target.value)}
                            />
                            <label className="mt-4 uppercase">
                                {t('web_register_page_1_firstname')} <span className="text-christine">*</span>
                            </label>
                            <input
                                type="text"
                                placeholder={t('web_register_page_1_firstname_placeholder')}
                                value={firstname}
                                onChange={e => setFirstname(e.target.value)}
                            />

                            {error.hasError && <p className="my-4 text-center text-christine">{t(error.message)}</p>}

                            <p className="my-4 mb-5 text-center md:mb-14">
                                <button
                                    type="button"
                                    className="btn px-8"
                                    disabled={!canAddPage2}
                                    onClick={() => setPage(2)}
                                >
                                    {t('web_register_page_1_continue')}
                                </button>
                            </p>
                            <p className="mb-3 text-center text-lg font-bold md:mb-6">
                                {t('web_register_page_1_already_have_treasy')}
                            </p>
                            <p className="text-center md:mb-11">
                                <Link className="btn px-9" to="/login">
                                    {t('web_register_page_1_login_me')}
                                </Link>
                            </p>
                        </div>
                    </div>
                </>
            )}
            {page === 2 && (
                <>
                    <div className="mt-8 flex w-full grow flex-col items-center justify-center">
                        <h3 className="my-8 text-2xl font-bold md:text-3xl">{t('web_register_page_2_title')}</h3>
                        <div className="flex w-3/4 flex-col">
                            <label className="uppercase">
                                {t('web_register_page_2_password')} <span className="text-christine">*</span>
                            </label>
                            <SecurePassword value={password} onChange={value => setPassword(value)} />
                            <p className="mb-8 text-sm text-regent-gray">{t('web_register_page_2_password_help')}</p>

                            <label className="uppercase">
                                {t('web_register_page_2_confirm_password')} <span className="text-christine">*</span>
                            </label>
                            <SecurePassword value={confirmPassword} onChange={value => setConfirmPassword(value)} />

                            <label className="mb-8 mt-4 flex items-center" htmlFor="cgu">
                                <input
                                    type="checkbox"
                                    className="border-atomic-tangerine text-atomic-tangerine"
                                    checked={cguChecked}
                                    onChange={() => toggleCguChecked()}
                                />
                                <span className="ml-2">
                                    <Trans i18nKey="web_register_page_2_i_have_read_cgu">
                                        connaissance
                                        <a
                                            href="https://treasy.fr/conditions-generales-dutilisation/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            CGU
                                        </a>
                                        et je les accepte.
                                    </Trans>
                                </span>
                            </label>

                            {error.hasError && <p className="my-4 text-center text-christine">{t(error.message)}</p>}

                            <p className="my-4 text-center">
                                <button
                                    type="button"
                                    className="btn px-8"
                                    disabled={!canRegister}
                                    onClick={() => register(cguChecked)}
                                >
                                    {isLoading && (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="mr-2 h-4 w-4 animate-spin"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                            />
                                        </svg>
                                    )}
                                    {isLoading ? t('web_register_page_2_loading') : t('web_register_page_2_continue')}
                                </button>
                            </p>
                            <p className="text-center">
                                <span className="cursor-pointer text-christine" onClick={() => reset()}>
                                    {t('web_register_page_2_back')}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="flex grow p-8">
                        <div className="self-end">
                            <div className="flex items-center">
                                <div className="m-2 mr-4 flex h-8 w-8 shrink-0 items-center justify-center rounded-50 bg-atomic-tangerine text-white">
                                    <FontAwesomeIcon icon={faLockKeyhole} />
                                </div>
                                <div className="flex">
                                    <Trans i18nKey="web_register_page_2_branding">web_register_page_2_branding</Trans>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {page === 3 && (
                <>
                    <div className="mt-8 flex w-full grow flex-col items-center justify-center text-center">
                        <h3 className="my-8 text-2xl font-bold md:text-3xl">{t('web_register_page_3_title')}</h3>
                        <p className="my-8">
                            <Trans i18nKey="web_register_page_3_email_desc">web_register_page_3_email_desc</Trans>
                        </p>
                        <img src={lock} alt="" className="my-8" />
                        <p className="my-4 text-center">
                            <Link to={redirect ? redirect : '/prices'} className="btn px-8">
                                {t('web_register_page_3_enter')}
                            </Link>
                        </p>
                        <p className="my-8 text-sm text-regent-gray">
                            <Trans i18nKey="web_register_page_3_no_email">web_register_page_3_no_email</Trans>
                        </p>
                    </div>
                </>
            )}
        </TwoPart>
    )
})

export default Register
