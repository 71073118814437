import { faEdit, faEnvelope, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from 'assets/backoffice/loader'
import Admin from 'components/admin/admin'
import Pagination from 'components/admin/pagination'
import { getTypes } from 'components/admin/users/form'
import Modal from 'components/shared/modal'
import ToggleButton from 'components/shared/toggle-button'
import Tooltip from 'components/shared/tooltip'
import useDebounce from 'core/debounce'
import { observer } from 'mobx-react-lite'
import { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMst } from 'stores/store'
import { User } from 'stores/users'

const UserLine = ({
    user,
    types,
    partnerId,
    onDelete,
    onRefresh,
}: {
    user: User
    types: { value: string; label: string }[]
    partnerId: string
    onDelete: (uuid: string) => void
    onRefresh: () => void
}) => {
    const [enabled, setEnabled] = useState<boolean>(true)
    const [isLoading, setLoading] = useState<boolean>(false)
    const { user: userStore } = useMst()
    const { t } = useTranslation()

    const toggleUser = useCallback(async () => {
        setLoading(true)
        await user.partnerToggle(partnerId)
        onRefresh()
        setLoading(false)
    }, [user.id])

    useEffect(() => {
        setEnabled(user.active)
    }, [user.enabled])

    return (
        <tr>
            <td className="text-left">{user.fullname}</td>
            <td className="text-left">{user.email}</td>
            <td className="text-left">{types.find(t => t.value === user.type)?.label}</td>
            <td>
                <div className="flex w-full items-center justify-center space-x-1">
                    {user.tags &&
                        user.tags.map(({ uuid, name }) => (
                            <span key={uuid} className="rounded-sm bg-heather px-1 py-0.5">
                                {name}
                            </span>
                        ))}
                </div>
            </td>
            <td className="text-left">{user.pricing?.name ?? ''}</td>
            <td>
                <div className="flex w-full items-center justify-center">
                    {isLoading ? (
                        <Loader loading={isLoading} className="text-christine" />
                    ) : (
                        <ToggleButton value={enabled} onChange={() => toggleUser()} />
                    )}
                </div>
            </td>
            <td className="flex items-center space-x-1 text-christine">
                <Link to={`/partner/${partnerId}/users/${user.id}`}>
                    <FontAwesomeIcon icon={faEdit} />
                </Link>
                <Tooltip tooltip={t('web_admin_user_send_recovery_tooltip')}>
                    <button
                        type="button"
                        onClick={async () => {
                            if (confirm(t('web_admin_user_send_recovery'))) {
                                try {
                                    await userStore.sendRecoveryEmail(user.id)
                                    toast.success(t('web_admin_user_recovery_sent'))
                                } catch (error) {
                                    toast.error(error.message ?? error)
                                }
                            }
                        }}>
                        <FontAwesomeIcon icon={faEnvelope} />
                    </button>
                </Tooltip>
                <FontAwesomeIcon icon={faTrash} className="cursor-pointer" onClick={() => onDelete(user.id)} />
            </td>
        </tr>
    )
}

const limit = 50

const Listing = observer(() => {
    const { partners } = useMst()
    const { t } = useTranslation()
    const { id } = useParams()

    const [users, setUsers] = useState<User[]>([])
    const [isLoading, setLoading] = useState<boolean>(true)
    const [offset, setOffset] = useState<number>(0)
    const [query, setQuery] = useState<string>('')
    const [page, setPage] = useState<number>(0)
    const [pageCount, setPageCount] = useState<number>(0)
    const [userToBeDeleted, setUserToBeDeleted] = useState<string>()

    const types = getTypes(t)

    const load = useCallback(async () => {
        setLoading(true)
        await partners.loadUsers(limit, offset, query, id)

        setPageCount(Math.ceil(partners.totalUsers / limit))
        setUsers(partners.users)
        setLoading(false)
    }, [offset, query, id])

    const changePage = async page => {
        setPage(page)
        setOffset(Math.ceil(page * limit))
    }

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            load()
        }
    }

    const debouncedQuery = useDebounce<string>(query, 500)
    useEffect(() => {
        load()
    }, [page, debouncedQuery])

    useEffect(() => {
        setPage(0)
    }, [])

    const confirmDelete = useCallback(async () => {
        setLoading(true)
        await partners.removeUser(id, userToBeDeleted)
        setUserToBeDeleted(undefined)
        setLoading(false)
        await load()
    }, [userToBeDeleted])

    return (
        <Admin
            title={t('web_admin_users')}
            isLoading={isLoading}
            header={
                <div className="flex items-center space-x-4">
                    <div className="flex items-center rounded border bg-white px-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2 h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>
                        <input
                            type="text"
                            className="m-0 grow border-none bg-transparent p-0 text-sm focus:border-none focus:outline-none"
                            placeholder={t('web_dashboard_search')}
                            onChange={e => setQuery(e.currentTarget.value)}
                            onKeyDown={e => onKeyDown(e)}
                        />
                    </div>
                </div>
            }>
            <table className="admin w-full table-auto text-center">
                <thead>
                    <tr>
                        <th>{t('web_menu_admin_users_name')}</th>
                        <th>{t('web_menu_admin_users_email')}</th>
                        <th>{t('web_admin_user_person_type')}</th>
                        <th>{t('web_admin_user_tags')}</th>
                        <th>{t('web_admin_user_pricing')}</th>
                        <th>{t('web_menu_admin_users_status')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <UserLine
                            key={user.id}
                            user={user}
                            partnerId={id}
                            onDelete={uuid => setUserToBeDeleted(uuid)}
                            onRefresh={() => load()}
                            types={types}
                        />
                    ))}
                </tbody>
            </table>
            <div className="mt-8 flex w-full justify-center">
                {pageCount > 1 && <Pagination page={page} totalPages={pageCount} onChangePage={changePage} />}
            </div>
            <Modal
                isOpen={userToBeDeleted !== undefined}
                size="1/4"
                onCancel={() => setUserToBeDeleted(undefined)}
                onRequestClose={() => setUserToBeDeleted(undefined)}
                cancelLabel={t('web_partner_remove_user_cancel')}
                okLabel={t('web_partner_remove_user_confirm')}
                onConfirm={() => confirmDelete()}>
                <p>{t('web_partner_remove_user')}</p>
            </Modal>
        </Admin>
    )
})

export default Listing
