import { flow, getRoot, Instance, types } from 'mobx-state-tree'
import { post } from '../core/services/http-service'
import toast from '../core/utils/toast'
import { RootInstance } from './store'

export const _Todo = types.model('Todo', {}).actions(self => {
    return {
        submit: flow(function* (title: string, note: number, message: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonOkReturn {
                ok: boolean
            }
            interface JsonReturn {
                data: JsonOkReturn
            }

            const postData = {
                page: title,
                note,
                message,
            }

            try {
                const data = yield post<typeof postData, JsonReturn>('/v1/web/todos', postData)

                const {
                    data: { ok },
                } = data
                toast(ok ? 'success' : 'error', ok ? 'web_post_todo_success' : 'web_post_todo_error')
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }
})

export interface Todo extends Instance<typeof _Todo> {}
