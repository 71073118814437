import { zodResolver } from '@hookform/resolvers/zod'
import { clsx } from 'clsx'
import 'components/admin/zod'
import { getFileSize } from 'components/dataroom/upload/file-size'
import { Input, selectStyles } from 'components/shared/select-styles'
import ToggleButton from 'components/shared/toggle-button'
import type { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useMst } from 'stores/store'
import { z } from 'zod'
import MdEditor from '../../shared/md-editor'
import { type FormProps, optionValueSchema } from '../forms'
import Label from '../label'

const schema = z.object({
    name: z.string().min(1),
    publicName: z.string().min(1),

    transmission: z.coerce.number().min(-1),
    transmissionCta: z.coerce.number(),
    transmissionMessage: z.string(),

    shareFiles: z.coerce.number().min(-1),
    shareFilesCta: z.coerce.number(),
    shareFilesMessage: z.string(),

    connectors: z.coerce.number().min(-1),
    connectorsCta: z.coerce.number(),
    connectorsMessage: z.string(),

    storage: z.coerce.number().min(-1),
    storageCta: z.coerce.number(),
    storageMessage: z.string(),

    durationCount: z.coerce.number().min(0),
    durationPeriod: optionValueSchema,
    trialPeriodDays: z.coerce.number().min(0).default(0),

    description: z.string().min(1),
    longDescription: z.string(),

    tribe: z.coerce.number(),

    repeatable: z.boolean().nullable().default(false),
    treasy: z.boolean().nullable().default(false),
    free: z.boolean().nullable().default(false),
    manual: z.boolean().nullable().default(false),
    isDefault: z.boolean().nullable().default(false),

    stripeProduct: optionValueSchema.nullish(),
    stripePrice: optionValueSchema.nullish(),
    upgrade: optionValueSchema.nullish(),
})
type Schema = z.infer<typeof schema>

export const getDurationPeriods = (t: TFunction): { value: string; label: string }[] => {
    return [
        { value: 'days', label: t('web_menu_admin_pricing_period_days') },
        { value: 'months', label: t('web_menu_admin_pricing_period_months') },
        { value: 'years', label: t('web_menu_admin_pricing_period_years') },
    ]
}

const Form = ({ onSubmit, values, currentId }: FormProps & { currentId?: string | undefined }) => {
    const { t } = useTranslation()
    const { admin } = useMst()

    const [isTribe, setIsTribe] = useState<boolean>(false)

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })
    const watchStorage = watch('storage', 0)
    const watchStorageCta = watch('storageCta', 0)

    useEffect(() => {
        if (!values) {
            return
        }

        for (const key of Object.keys(values) as (keyof Schema)[]) {
            setValue(key, values[key])
        }
        setIsTribe((values.tribe as number) > 0)
    }, [values])

    const durationPeriods = getDurationPeriods(t)

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full md:w-3/4">
            <Label
                name="name"
                error={errors.name && `${t('web_admin_pricing_name')} ${errors.name?.message}`}
                label={'web_admin_pricing_name'}
                required={true}
            >
                <input {...register('name')} type="text" className="w-full" />
            </Label>

            <Label
                name="publicName"
                error={errors.publicName && `${t('web_admin_pricing_name_public')} ${errors.publicName?.message}`}
                label={'web_admin_pricing_name_public'}
                required={true}
            >
                <input {...register('publicName')} type="text" className="w-full" />
            </Label>

            <Label
                name="description"
                error={errors.description && `${t('web_admin_pricing_description')} ${errors.description?.message}`}
                label={'web_admin_pricing_description'}
                required={true}
            >
                <Controller
                    control={control}
                    name="description"
                    render={({ field: { value, onChange } }) => (
                        <MdEditor className=" w-2/3" onChange={bodyMessage => onChange(bodyMessage)} value={value} />
                    )}
                />
            </Label>
            <Label
                name="longDescription"
                error={
                    errors.longDescription &&
                    `${t('web_admin_pricing_long_description')} ${errors.longDescription?.message}`
                }
                label={'web_admin_pricing_long_description'}
            >
                <Controller
                    control={control}
                    name="longDescription"
                    render={({ field: { value, onChange } }) => (
                        <MdEditor className=" w-2/3" onChange={bodyMessage => onChange(bodyMessage)} value={value} />
                    )}
                />
            </Label>

            <Label
                name="storage"
                help="web_admin_pricing_storage_help"
                helpParams={{ storage: getFileSize(t, watchStorage) }}
                error={errors.storage && `${t('web_admin_pricing_storage')} ${errors.storage?.message}`}
                label={'web_admin_pricing_storage'}
                required={true}
            >
                <input {...register('storage')} type="number" className="w-full" />
            </Label>
            <Label
                name="storageCta"
                help="web_admin_pricing_storage_cta_help"
                helpParams={{ storage: getFileSize(t, watchStorageCta) }}
                error={errors.storageCta && `${t('web_admin_pricing_storage_cta')} ${errors.storageCta?.message}`}
                label={'web_admin_pricing_storage_cta'}
            >
                <input {...register('storageCta')} type="number" className="w-full" />
            </Label>
            <Label
                name="storageMessage"
                help="web_admin_pricing_cta_message_help"
                error={
                    errors.storageMessage &&
                    `${t('web_admin_pricing_storage_message')} ${errors.storageMessage?.message}`
                }
                label={'web_admin_pricing_storage_message'}
            >
                <input {...register('storageMessage')} type="text" className="w-full" />
            </Label>

            <Label
                name="connectors"
                help="web_admin_pricing_help_use_negative_one"
                error={errors.connectors && `${t('web_admin_pricing_connectors')} ${errors.connectors?.message}`}
                label={'web_admin_pricing_connectors'}
                required={true}
            >
                <input {...register('connectors')} type="number" className="w-full" />
            </Label>
            <Label
                name="connectorsCta"
                error={
                    errors.connectorsCta && `${t('web_admin_pricing_connectors_cta')} ${errors.connectorsCta?.message}`
                }
                label={'web_admin_pricing_connectors_cta'}
            >
                <input {...register('connectorsCta')} type="number" className="w-full" />
            </Label>
            <Label
                name="connectorsMessage"
                help="web_admin_pricing_cta_message_help"
                error={
                    errors.connectorsMessage &&
                    `${t('web_admin_pricing_connectors_message')} ${errors.connectorsMessage?.message}`
                }
                label={'web_admin_pricing_connectors_message'}
            >
                <input {...register('connectorsMessage')} type="text" className="w-full" />
            </Label>

            <Label
                name="shareFiles"
                help="web_admin_pricing_help_use_negative_one"
                error={errors.shareFiles && `${t('web_admin_pricing_share_files')} ${errors.shareFiles?.message}`}
                label={'web_admin_pricing_share_files'}
                required={true}
            >
                <input {...register('shareFiles')} type="number" className="w-full" />
            </Label>
            <Label
                name="shareFilesCta"
                error={
                    errors.shareFilesCta && `${t('web_admin_pricing_share_files_cta')} ${errors.shareFilesCta?.message}`
                }
                label={'web_admin_pricing_share_files_cta'}
            >
                <input {...register('shareFilesCta')} type="number" className="w-full" />
            </Label>
            <Label
                name="shareFilesMessage"
                help="web_admin_pricing_cta_message_help"
                error={
                    errors.shareFilesMessage &&
                    `${t('web_admin_pricing_share_files_message')} ${errors.shareFilesMessage?.message}`
                }
                label={'web_admin_pricing_share_files_message'}
            >
                <input {...register('shareFilesMessage')} type="text" className="w-full" />
            </Label>

            <Label
                name="transmission"
                help="web_admin_pricing_help_use_negative_one"
                error={errors.transmission && `${t('web_admin_pricing_transmission')} ${errors.transmission?.message}`}
                label={'web_admin_pricing_transmission'}
                required={true}
            >
                <input {...register('transmission')} type="number" className="w-full" />
            </Label>
            <Label
                name="transmissionCta"
                help="web_admin_pricing_transmission_cta_help"
                error={
                    errors.transmissionCta &&
                    `${t('web_admin_pricing_transmission_cta')} ${errors.transmissionCta?.message}`
                }
                label={'web_admin_pricing_transmission_cta'}
            >
                <input {...register('transmissionCta')} type="number" className="w-full" />
            </Label>
            <Label
                name="transmissionMessage"
                help="web_admin_pricing_cta_message_help"
                error={
                    errors.transmissionMessage &&
                    `${t('web_admin_pricing_transmission_message')} ${errors.transmissionMessage?.message}`
                }
                label={'web_admin_pricing_transmission_message'}
            >
                <input {...register('transmissionMessage')} type="text" className="w-full" />
            </Label>

            <Label
                name="duration"
                error={[
                    errors.durationCount && `${t('web_admin_pricing_duration')} ${errors.durationCount?.message}`,
                    errors.durationPeriod && `${t('web_admin_pricing_duration')} ${errors.durationPeriod?.message}`,
                ].join(' ')}
                label={'web_admin_pricing_duration'}
                required={true}
            >
                <input {...register('durationCount')} type="number" className="mr-2 w-24" />
                <Controller
                    name="durationPeriod"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-full"
                                value={value}
                                options={durationPeriods}
                                onChange={durationPeriod => onChange(durationPeriod)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="trialPeriodDays"
                help="web_admin_pricing_trial_period_days_help"
                error={
                    errors.trialPeriodDays &&
                    `${t('web_admin_pricing_trial_period_days')} ${errors.trialPeriodDays?.message}`
                }
                label={'web_admin_pricing_trial_period_days'}
                required={true}
            >
                <input {...register('trialPeriodDays')} type="number" className="w-full" min={0} />
            </Label>

            <Label
                name="repeatable"
                error={errors.repeatable && `${t('web_admin_pricing_repeatable')} ${errors.repeatable?.message}`}
                label={'web_admin_pricing_repeatable'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="repeatable"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>

            <Label
                name="manual"
                error={errors.manual && `${t('web_admin_pricing_manual')} ${errors.manual?.message}`}
                label={'web_admin_pricing_manual'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="manual"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>
            <Label
                name="free"
                error={errors.free && `${t('web_admin_pricing_free')} ${errors.free?.message}`}
                label={'web_admin_pricing_free'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="free"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>
            <Label
                name="treasy"
                error={errors.treasy && `${t('web_admin_pricing_treasy')} ${errors.treasy?.message}`}
                label={'web_admin_pricing_treasy'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="treasy"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>
            <Label
                name="isDefault"
                error={errors.isDefault && `${t('web_admin_pricing_is_default')} ${errors.isDefault?.message}`}
                label={'web_admin_pricing_is_default'}
                help={'web_admin_pricing_is_default_help'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <Controller
                    control={control}
                    name="isDefault"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>
            <Label
                name="isTribe"
                label={'web_admin_pricing_is_tribe'}
                className="my-5 flex w-full flex-wrap items-center"
            >
                <ToggleButton value={isTribe} onChange={setIsTribe} />
            </Label>

            <Label
                className={clsx('my-5 flex w-full flex-wrap items-center', {
                    hidden: !isTribe,
                })}
                name="tribe"
                error={errors.tribe && `${t('web_admin_pricing_tribe')} ${errors.tribe?.message}`}
                label={'web_admin_pricing_tribe'}
                required={true}
            >
                <input {...register('tribe')} type="number" className="w-full" />
            </Label>

            <Label
                name="stripePrice"
                error={errors.stripePrice && `${t('web_admin_pricing_stripe_price')} ${errors.stripePrice?.message}`}
                label={'web_admin_pricing_stripe_price'}
            >
                <Controller
                    name="stripePrice"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                value={value}
                                isClearable={true}
                                className="w-full"
                                options={admin.selectablePrices}
                                onChange={price => onChange(price)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="stripeProduct"
                error={
                    errors.stripeProduct && `${t('web_admin_pricing_stripe_product')} ${errors.stripeProduct?.message}`
                }
                label={'web_admin_pricing_stripe_product'}
            >
                <Controller
                    name="stripeProduct"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                value={value}
                                isClearable={true}
                                className="w-full"
                                options={admin.selectableProducts}
                                onChange={product => onChange(product)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="upgrade"
                help="web_admin_pricing_uprgade_help"
                error={errors.upgrade && `${t('web_admin_pricing_uprgade')} ${errors.upgrade?.message}`}
                label={'web_admin_pricing_uprgade'}
            >
                <Controller
                    name="upgrade"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                value={value}
                                isClearable={true}
                                className="w-full"
                                options={admin.pricings
                                    .filter(p => p.uuid !== currentId)
                                    .map(p => ({ value: p.uuid, label: p.name }))}
                                onChange={pricing => onChange(pricing)}
                            />
                        )
                    }}
                />
            </Label>

            <input type="submit" className="btn float-right cursor-pointer" value={`${t('web_admin_pricing_save')}`} />
        </form>
    )
}

export default Form
