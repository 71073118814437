import { clsx } from 'clsx'
import { FC } from 'react'

interface Props {
    max: number
    current: number
    onChangePage?: (page: number) => void
}

const WizardProgress: FC<Props> = ({ current, max, onChangePage = () => {} }) => {
    const range = (start: number, end: number) => Array.from({ length: end - start }, (v, k) => k + start)

    return (
        <div className="z-10 my-8 flex flex-row items-center justify-center">
            {range(0, max).map(val => {
                const classes = clsx({
                    'w-2 bg-outer-space': current !== val,
                    'w-6 bg-sea-buckthorn': current === val,
                })

                return (
                    <div
                        key={val}
                        className={`mx-1 h-2 rounded ${classes} cursor-pointer transition duration-300 ease-in-out hover:bg-christine`}
                        onClick={() => onChangePage(val)}></div>
                )
            })}
        </div>
    )
}

export default WizardProgress
