import { TFunction } from 'i18next'

const sufixes = ['g', 'kg', 't']

export const getEcoGain = (t: TFunction, grams: number): string => {
    if (grams < 0) {
        return t('web_eco_g', { value: 0 })
    }
    if (grams >= 0 && grams < 1) {
        return t('web_eco_g', { value: grams })
    }

    const i = Math.floor(Math.log(grams) / Math.log(1000))
    if (Math.abs(i) === Infinity) {
        return t('web_eco_g', { value: 0 })
    }
    const value = Math.round((grams / Math.pow(1000, i)) * 100) / 100

    return t(`web_eco_${sufixes[i]}`, { value })
}

const destinations = [
    {
        from: 'Paris',
        to: [
            { km: 462, to: 'Lyon' },
            { km: 931, to: 'Nice' },
            { km: 380, to: 'Nantes' },
            { km: 488, to: 'Strasbourg' },
            { km: 746, to: 'Montpellier' },
            { km: 219, to: 'Lille' },
            { km: 348, to: 'Rennes' },
            { km: 143, to: 'Reims' },
            { km: 522, to: 'Saint-Etienne' },
            { km: 293, to: 'Angers' },
            { km: 575, to: 'Grenoble' },
            { km: 710, to: 'Nimes' },
            { km: 759, to: 'Aix-en-Provence' },
            { km: 589, to: 'Brest' },
            { km: 1540, to: 'Stockholm' },
            { km: 1890, to: 'Casablanca' },
            { km: 2000, to: 'Helsinki' },
            { km: 2680, to: 'Moscou' },
            { km: 3200, to: 'le Caire' },
            { km: 4000, to: 'Bagdad' },
            { km: 5500, to: 'Montreal' },
            { km: 5830, to: 'New York' },
            { km: 6100, to: 'Washington' },
            { km: 7000, to: 'Mumbai' },
            { km: 9000, to: 'Atlanta' },
        ],
    },
    {
        from: 'Lyon',
        to: [
            { km: 462, to: 'Paris' },
            { km: 472, to: 'Nice' },
            { km: 681, to: 'Nantes' },
            { km: 494, to: 'Strasbourg' },
            { km: 302, to: 'Montpellier' },
            { km: 689, to: 'Lille' },
            { km: 718, to: 'Rennes' },
            { km: 487, to: 'Reims' },
            { km: 62, to: 'Saint-Etienne' },
            { km: 594, to: 'Angers' },
            { km: 107, to: 'Grenoble' },
            { km: 251, to: 'Nimes' },
            { km: 299, to: 'Aix-en-Provence' },
            { km: 970, to: 'Brest' },
        ],
    },
    {
        from: 'Nice',
        to: [
            { km: 930, to: 'Paris' },
            { km: 471, to: 'Lyon' },
            { km: 1143, to: 'Nantes' },
            { km: 790, to: 'Strasbourg' },
            { km: 326, to: 'Montpellier' },
            { km: 1157, to: 'Lille' },
            { km: 1186, to: 'Rennes' },
            { km: 955, to: 'Reims' },
            { km: 490, to: 'Saint-Etienne' },
            { km: 1062, to: 'Angers' },
            { km: 465, to: 'Grenoble' },
            { km: 279, to: 'Nimes' },
            { km: 176, to: 'Aix-en-Provence' },
            { km: 1440, to: 'Brest' },
        ],
    },
    {
        from: 'Nantes',
        to: [
            { km: 380, to: 'Paris' },
            { km: 682, to: 'Lyon' },
            { km: 1145, to: 'Nice' },
            { km: 860, to: 'Strasbourg' },
            { km: 824, to: 'Montpellier' },
            { km: 597, to: 'Lille' },
            { km: 107, to: 'Rennes' },
            { km: 515, to: 'Reims' },
            { km: 662, to: 'Saint-Etienne' },
            { km: 88, to: 'Angers' },
            { km: 786, to: 'Grenoble' },
            { km: 874, to: 'Nimes' },
            { km: 972, to: 'Aix-en-Provence' },
            { km: 296, to: 'Brest' },
        ],
    },
    {
        from: 'Strasbourg',
        to: [
            { km: 487, to: 'Paris' },
            { km: 491, to: 'Lyon' },
            { km: 788, to: 'Nice' },
            { km: 860, to: 'Nantes' },
            { km: 791, to: 'Montpellier' },
            { km: 549, to: 'Lille' },
            { km: 827, to: 'Rennes' },
            { km: 347, to: 'Reims' },
            { km: 550, to: 'Saint-Etienne' },
            { km: 773, to: 'Angers' },
            { km: 533, to: 'Grenoble' },
            { km: 739, to: 'Nimes' },
            { km: 787, to: 'Aix-en-Provence' },
            { km: 1069, to: 'Brest' },
        ],
    },
    {
        from: 'Montpellier',
        to: [
            { km: 746, to: 'Paris' },
            { km: 303, to: 'Lyon' },
            { km: 327, to: 'Nice' },
            { km: 823, to: 'Nantes' },
            { km: 791, to: 'Strasbourg' },
            { km: 963, to: 'Lille' },
            { km: 895, to: 'Rennes' },
            { km: 787, to: 'Reims' },
            { km: 322, to: 'Saint-Etienne' },
            { km: 771, to: 'Angers' },
            { km: 297, to: 'Grenoble' },
            { km: 56, to: 'Nimes' },
            { km: 154, to: 'Aix-en-Provence' },
            { km: 1120, to: 'Brest' },
        ],
    },
    {
        from: 'Lille',
        to: [
            { km: 217, to: 'Paris' },
            { km: 690, to: 'Lyon' },
            { km: 1159, to: 'Nice' },
            { km: 598, to: 'Nantes' },
            { km: 522, to: 'Strasbourg' },
            { km: 963, to: 'Montpellier' },
            { km: 572, to: 'Rennes' },
            { km: 199, to: 'Reims' },
            { km: 749, to: 'Saint-Etienne' },
            { km: 511, to: 'Angers' },
            { km: 803, to: 'Grenoble' },
            { km: 938, to: 'Nimes' },
            { km: 986, to: 'Aix-en-Provence' },
            { km: 759, to: 'Brest' },
        ],
    },
    {
        from: 'Rennes',
        to: [
            { km: 347, to: 'Paris' },
            { km: 718, to: 'Lyon' },
            { km: 1186, to: 'Nice' },
            { km: 106, to: 'Nantes' },
            { km: 827, to: 'Strasbourg' },
            { km: 894, to: 'Montpellier' },
            { km: 572, to: 'Lille' },
            { km: 483, to: 'Reims' },
            { km: 699, to: 'Saint-Etienne' },
            { km: 126, to: 'Angers' },
            { km: 823, to: 'Grenoble' },
            { km: 948, to: 'Nimes' },
            { km: 1013, to: 'Aix-en-Provence' },
            { km: 241, to: 'Brest' },
        ],
    },
    {
        from: 'Reims',
        to: [
            { km: 143, to: 'Paris' },
            { km: 487, to: 'Lyon' },
            { km: 955, to: 'Nice' },
            { km: 516, to: 'Nantes' },
            { km: 347, to: 'Strasbourg' },
            { km: 786, to: 'Montpellier' },
            { km: 198, to: 'Lille' },
            { km: 483, to: 'Rennes' },
            { km: 546, to: 'Saint-Etienne' },
            { km: 429, to: 'Angers' },
            { km: 600, to: 'Grenoble' },
            { km: 735, to: 'Nimes' },
            { km: 783, to: 'Aix-en-Provence' },
            { km: 725, to: 'Brest' },
        ],
    },
    {
        from: 'Saint-Etienne',
        to: [
            { km: 523, to: 'Paris' },
            { km: 64, to: 'Lyon' },
            { km: 491, to: 'Nice' },
            { km: 661, to: 'Nantes' },
            { km: 552, to: 'Strasbourg' },
            { km: 322, to: 'Montpellier' },
            { km: 750, to: 'Lille' },
            { km: 698, to: 'Rennes' },
            { km: 548, to: 'Reims' },
            { km: 574, to: 'Angers' },
            { km: 155, to: 'Grenoble' },
            { km: 271, to: 'Nimes' },
            { km: 319, to: 'Aix-en-Provence' },
            { km: 950, to: 'Brest' },
        ],
    },
    {
        from: 'Angers',
        to: [
            { km: 293, to: 'Paris' },
            { km: 595, to: 'Lyon' },
            { km: 1062, to: 'Nice' },
            { km: 88, to: 'Nantes' },
            { km: 773, to: 'Strasbourg' },
            { km: 770, to: 'Montpellier' },
            { km: 511, to: 'Lille' },
            { km: 128, to: 'Rennes' },
            { km: 429, to: 'Reims' },
            { km: 576, to: 'Saint-Etienne' },
            { km: 700, to: 'Grenoble' },
            { km: 824, to: 'Nimes' },
            { km: 890, to: 'Aix-en-Provence' },
            { km: 377, to: 'Brest' },
        ],
    },
    {
        from: 'Grenoble',
        to: [
            { km: 574, to: 'Paris' },
            { km: 106, to: 'Lyon' },
            { km: 466, to: 'Nice' },
            { km: 786, to: 'Nantes' },
            { km: 534, to: 'Strasbourg' },
            { km: 296, to: 'Montpellier' },
            { km: 801, to: 'Lille' },
            { km: 823, to: 'Rennes' },
            { km: 599, to: 'Reims' },
            { km: 155, to: 'Saint-Etienne' },
            { km: 699, to: 'Angers' },
            { km: 245, to: 'Nimes' },
            { km: 293, to: 'Aix-en-Provence' },
            { km: 1075, to: 'Brest' },
        ],
    },
    {
        from: 'Nimes',
        to: [
            { km: 711, to: 'Paris' },
            { km: 252, to: 'Lyon' },
            { km: 281, to: 'Nice' },
            { km: 872, to: 'Nantes' },
            { km: 740, to: 'Strasbourg' },
            { km: 55, to: 'Montpellier' },
            { km: 938, to: 'Lille' },
            { km: 949, to: 'Rennes' },
            { km: 736, to: 'Reims' },
            { km: 271, to: 'Saint-Etienne' },
            { km: 825, to: 'Angers' },
            { km: 246, to: 'Grenoble' },
            { km: 108, to: 'Aix-en-Provence' },
            { km: 1169, to: 'Brest' },
        ],
    },
    {
        from: 'Aix-en-Provence',
        to: [
            { km: 757, to: 'Paris' },
            { km: 298, to: 'Lyon' },
            { km: 178, to: 'Nice' },
            { km: 970, to: 'Nantes' },
            { km: 786, to: 'Strasbourg' },
            { km: 153, to: 'Montpellier' },
            { km: 984, to: 'Lille' },
            { km: 1013, to: 'Rennes' },
            { km: 781, to: 'Reims' },
            { km: 317, to: 'Saint-Etienne' },
            { km: 889, to: 'Angers' },
            { km: 291, to: 'Grenoble' },
            { km: 106, to: 'Nimes' },
            { km: 1267, to: 'Brest' },
        ],
    },
    {
        from: 'Brest',
        to: [
            { km: 590, to: 'Paris' },
            { km: 971, to: 'Lyon' },
            { km: 1441, to: 'Nice' },
            { km: 298, to: 'Nantes' },
            { km: 1070, to: 'Strasbourg' },
            { km: 1121, to: 'Montpellier' },
            { km: 760, to: 'Lille' },
            { km: 241, to: 'Rennes' },
            { km: 725, to: 'Reims' },
            { km: 952, to: 'Saint-Etienne' },
            { km: 378, to: 'Angers' },
            { km: 1076, to: 'Grenoble' },
            { km: 1171, to: 'Nimes' },
            { km: 1269, to: 'Aix-en-Provence' },
        ],
    },
]

const getDestination = (grams: number) => {
    const co2GrToKm = 0.152

    const [destination] = destinations
        .reduce<{ from: string; to: string; km: number }[]>((p, c) => {
            const [to] = c.to.filter(({ km }) => km / co2GrToKm <= grams).sort((a, b) => b.km - a.km)

            if (to) {
                p.push({ from: c.from, ...to })
            }

            return p
        }, [])
        .sort((a, b) => b.km - a.km)

    return destination
}

export const getEcoGainAsKm = (t: TFunction, grams: number): string => {
    const destination = getDestination(grams)
    if (!destination) {
        return ''
    }

    const { from, to, km } = destination

    const roundTrip = Math.round(grams / km) / 2
    if (roundTrip >= 1) {
        return t('web_dashboard_co2_round_trip_fly', { from, to, count: roundTrip })
    }

    return t('web_dashboard_co2_one_fly', { from, to })
}
