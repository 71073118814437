import { ReactComponent as Google } from 'assets/sso/google.svg'
import { useEffect } from 'react'
import { useMst } from 'stores/store'
import SsoButton from './sso-button'

interface CredentialResponse {
    credential: string
}

declare global {
    interface Window {
        renderButton?: () => void
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        google: any

        handleCredentialResponse?: (data: CredentialResponse) => void
    }
}

interface Props {
    token?: string
    onRegistrationComplete?: () => void
}

const LoginGoogle = ({ onRegistrationComplete, token }: Props) => {
    const { user } = useMst()

    useEffect(() => {
        const handleCredentialResponse = async (response: CredentialResponse) => {
            await user.googleConnect(response.credential, token)
            await user.linkAccountIfNeeded()
            onRegistrationComplete?.()
        }

        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID,
                callback: handleCredentialResponse,
            })
        }
    }, [])

    return (
        <SsoButton onClick={() => window.google.accounts.id.prompt()}>
            <Google className="h-4 w-4" />
        </SsoButton>
    )
}

export default LoginGoogle
