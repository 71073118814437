import localeFr from 'air-datepicker/locale/fr'
import { ReactComponent as IconAddTrusted } from 'assets/icons/icon-add-trusted.svg'
import { ReactComponent as IconGlass } from 'assets/icons/icon-glass.svg'
import { ReactComponent as IconPartner } from 'assets/icons/icon-partner.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/icon-share-people.svg'
import { ReactComponent as IconPlaceholder } from 'assets/icons/icon-user-placeholder.svg'
import DatePicker from 'components/admin/date-picker'
import { Input, selectStyles } from 'components/shared/select-styles'
// import ToggleButton from 'components/shared/toggle-button'
import Tooltip from 'components/shared/tooltip'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { components, OptionProps } from 'react-select'
import Async from 'react-select/async'
import { useMst } from 'stores/store'
import SmallLoader from '../../shared/small-loader'
import { SharingOption } from './share-modal-content'

interface TrustedPersonLineProps {
    canChangeDate: boolean
    option: SharingOption
    onChange: (option: SharingOption, unlimited: boolean, fromDate?: DateTime, toDate?: DateTime) => void
    onRemove: (option: SharingOption) => void
}

const TrustedPersonLine = ({ option, canChangeDate, onChange, onRemove }: TrustedPersonLineProps) => {
    const { t } = useTranslation()
    const [unlimited, setUnlimited] = useState<boolean>(option.unlimited)
    const [fromDate, setFromDate] = useState<DateTime | undefined>(option.fromDate)
    const [toDate, setToDate] = useState<DateTime | undefined>(option.toDate)

    const currentLocale = localeFr

    useEffect(() => {
        onChange(option, unlimited, fromDate, toDate)
    }, [unlimited, fromDate, toDate])

    return (
        <div key={option.value} className="mb-2 grid w-full grid-cols-3 gap-4">
            <div className="col-span-3 flex grow items-center space-x-4 md:col-span-1">
                <div className="relative h-8 w-8 rounded-full bg-regent-gray text-white">
                    <div
                        className={
                            'relative flex h-8 w-8  items-center justify-center overflow-hidden rounded-full bg-regent-gray text-white'
                        }
                    >
                        <div className="absolute inset-0 flex items-center justify-center">
                            <IconPlaceholder className="h-3 w-3 fill-current" />
                        </div>
                        <div
                            className={
                                'absolute inset-0 flex items-center justify-center bg-cover bg-center bg-no-repeat'
                            }
                            style={{ backgroundImage: `url(${option.picture})` }}
                        ></div>
                    </div>
                    {option.userType && option.userType.uuid !== '' ? (
                        <Tooltip tooltip={option.userType.name}>
                            <div
                                className={
                                    'absolute -bottom-1 -right-1 flex h-4 w-4 cursor-help items-center justify-center rounded-full bg-nepal text-sm text-white'
                                }
                            >
                                <IconPartner className="h-3 w-3 fill-current" />
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            tooltip={
                                option.accepted
                                    ? t('web_trusted_person_accepted')
                                    : t('web_trusted_person_not_yet_accepted')
                            }
                        >
                            <div
                                className={`${
                                    option.accepted ? 'bg-sushi' : 'bg-christine'
                                } absolute -bottom-1 -right-1 flex h-4 w-4 cursor-help items-center justify-center rounded-full text-sm`}
                            >
                                {option.accepted ? '✓' : '✗'}
                            </div>
                        </Tooltip>
                    )}
                </div>
                <div className="flex flex-col justify-center">
                    <span className="">{option.label}</span>
                    {option.subLabel && <span className="text-sm">{option.subLabel}</span>}
                </div>
            </div>
            <div className="col-span-2 flex space-x-4 md:col-span-1">
                {canChangeDate && (
                    <>
                        <div className="w-1/2">
                            <DatePicker
                                className={`${!canChangeDate ? 'cursor-not-allowed' : 'cursor-pointer'} max-w-full`}
                                disabled={!canChangeDate}
                                value={fromDate?.toJSDate() ?? new Date()}
                                onChange={date => setFromDate(DateTime.fromJSDate(date as Date))}
                                locale={currentLocale}
                            />
                        </div>
                        <div className="w-1/2">
                            <DatePicker
                                className={`${
                                    !canChangeDate || unlimited ? 'cursor-not-allowed' : 'cursor-pointer'
                                } max-w-full`}
                                disabled={!canChangeDate || unlimited}
                                value={toDate?.toJSDate()}
                                onChange={date => setToDate(DateTime.fromJSDate(date as Date))}
                                locale={currentLocale}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="flex items-center justify-end space-x-8">
                {canChangeDate && (
                    <label className="flex items-center space-x-4">
                        <span className={!canChangeDate ? 'text-regent-gray' : ''}>
                            {t('web_share_trusted_people_unlimited_time')}
                        </span>
                        <input
                            disabled={!canChangeDate}
                            type="checkbox"
                            checked={unlimited}
                            onChange={() => {
                                setUnlimited(current => !current)
                                setToDate(!unlimited ? undefined : (fromDate ?? DateTime.local()).plus({ months: 1 }))
                            }}
                        />
                    </label>
                )}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.52"
                    height="11.519"
                    viewBox="0 0 11.52 11.519"
                    className="cursor-pointer stroke-current text-regent-gray"
                    onClick={() => onRemove(option)}
                >
                    <path
                        fill="none"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="m10.106 1.415-8.691 8.69M10.105 10.104l-8.691-8.69"
                    />
                </svg>
            </div>
        </div>
    )
}

const UserOption = (props: OptionProps<SharingOption>) => {
    const {
        isDisabled,
        data: { label, __isTrusted__, __isPartner__, picture, subLabel },
    } = props

    if (isDisabled) {
        return null
    }

    return (
        <components.Option {...props}>
            <div className="flex cursor-pointer items-center space-x-4">
                <div
                    className={`flex h-8 w-8 items-center justify-center overflow-hidden rounded-full text-white ${
                        __isTrusted__ ? 'bg-atomic-tangerine' : __isPartner__ ? 'bg-nepal' : 'bg-regent-gray'
                    }`}
                >
                    {__isPartner__ ? (
                        <IconPartner className="fill-current" />
                    ) : __isTrusted__ ? (
                        <IconAddTrusted className="fill-current" />
                    ) : (
                        <div
                            className={
                                'relative flex h-8 w-8  items-center justify-center overflow-hidden rounded-full bg-regent-gray text-white'
                            }
                        >
                            <div className="absolute inset-0 flex items-center justify-center">
                                <IconPlaceholder className="h-3 w-3 fill-current" />
                            </div>
                            <div
                                className={
                                    'absolute inset-0 flex items-center justify-center bg-cover bg-center bg-no-repeat'
                                }
                                style={{ backgroundImage: `url(${picture})` }}
                            ></div>
                        </div>
                    )}
                </div>
                <div className="flex flex-col justify-center">
                    <span className={`${__isTrusted__ || __isPartner__ ? 'underline' : ''}`}>{label}</span>
                    {subLabel && <span className="text-sm">{subLabel}</span>}
                </div>
            </div>
        </components.Option>
    )
}

interface SharingProps {
    loading: boolean
    onAddPartner: () => void
    onAddTrusted: () => void
    selectedPeople: SharingOption[]
    onSave: () => void
    onChangePeople: (option: SharingOption, unlimited: boolean, fromDate?: DateTime, toDate?: DateTime) => void
    addSelectedPeople: (option: SharingOption) => void
    removeSelectedPeople: (option: SharingOption) => void
    isGroup: boolean
    setIsGroup: (isGroup: boolean) => void
    groupName: string
    setGroupName: (setGroupName: string) => void
}

const Sharing = ({
    loading,
    onAddPartner,
    onAddTrusted,
    selectedPeople,
    onSave,
    onChangePeople,
    addSelectedPeople,
    removeSelectedPeople,
    isGroup,
}: SharingProps) => {
    const { t } = useTranslation()
    const { user } = useMst()

    // const [editedGroupName, setEditedGroupName] = useState<boolean>(false)

    const add = (option: SharingOption) => {
        if (!option) {
            return
        }
        const { __isTrusted__, __isPartner__ } = option
        if (__isPartner__) {
            onAddPartner()

            return
        }
        if (__isTrusted__) {
            onAddTrusted()

            return
        }
        addSelectedPeople(option)
    }

    return (
        <div className="relative rounded-lg bg-white p-8">
            {loading && (
                <div className="absolute inset-0 z-50 flex flex-col items-center justify-center rounded-lg bg-black/30">
                    <SmallLoader />
                </div>
            )}
            <div className="mb-2 flex w-full items-center space-x-2">
                <div className="rounded-full bg-regent-gray p-4">
                    <SearchIcon className="h-5 w-5 fill-current text-white" />
                </div>
                <h3 className="text-lg">{t('web_share_trusted_people_title')}</h3>
            </div>
            <div className="flex flex-col space-y-4">
                <Async
                    styles={{
                        ...selectStyles,
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        input: (provided, state) => {
                            return { ...provided, paddingLeft: '40px' }
                        },
                    }}
                    className="w-full"
                    value={[]}
                    noOptionsMessage={() => t('web_share_trusted_people_search_no_options')}
                    isClearable={true}
                    defaultOptions
                    components={{ Input, Option: UserOption }}
                    placeholder={
                        <div className="flex items-center space-x-4">
                            <IconGlass className="h-4 fill-current" />
                            <span>{t('web_share_trusted_people_search')}</span>
                        </div>
                    }
                    loadOptions={async (inputValue: string) => {
                        const { trustedUsers, trustedUsersPerFranchise, trustedUsersPerBrand } =
                            await user.findTrustedUser(inputValue)
                        const users = [...trustedUsers]

                        for (const franchise of trustedUsersPerFranchise) {
                            users.push(...Object.values(franchise))
                        }
                        for (const brand of trustedUsersPerBrand) {
                            users.push(...Object.values(brand))
                        }

                        const options = users
                            .flat()
                            .filter(({ uuid }) => !selectedPeople.find(({ value }) => value === uuid))
                            .map(({ accepted, uuid, trustedUser, userType }) => {
                                return {
                                    value: uuid,
                                    label: trustedUser.fullname,
                                    accepted,
                                    picture: trustedUser.picture,
                                    subLabel: userType ? userType.name : t('web_share_trusted_people'),
                                    userType,
                                }
                            })

                        return [
                            ...options,
                            {
                                value: `trusted_${inputValue}`,
                                label: t('web_share_add_trusted_person'),
                                __isNew__: true,
                                __isTrusted__: true,
                            },
                            {
                                value: `partner_${inputValue}`,
                                label: t('web_share_search_partner'),
                                __isNew__: true,
                                __isPartner__: true,
                            },
                        ]
                    }}
                    onChange={add}
                />
                <div className="flex w-full flex-col">
                    {selectedPeople.map((option, index) => (
                        <TrustedPersonLine
                            key={option.value}
                            option={option}
                            onChange={(person, unlimited, fromDate, toDate) =>
                                onChangePeople(person, unlimited, fromDate, toDate)
                            }
                            onRemove={person => removeSelectedPeople(person)}
                            canChangeDate={isGroup ? index === 0 : true}
                        />
                    ))}
                    <div className="mt-8 flex items-end justify-between">
                        <div className="w-2/3">
                            {/* {selectedPeople.length > 1 && (
                                <>
                                    <div className="flex items-center space-x-2">
                                        <ToggleButton value={isGroup} onChange={setIsGroup} />
                                        <span className="text-sm">{t('web_share_add_group')}</span>
                                    </div>
                                    {isGroup && (
                                        <div className="flex w-full items-center space-x-1 mt-2 mb-2">
                                            <span className="flex w-1/2 text-base items-center mt-2 mb-2">
                                                {t('web_share_group_name')}
                                            </span>
                                            <input
                                                className="w-full "
                                                type="text"
                                                value={groupName}
                                                onChange={e => setGroupName(e.target.value)}
                                            />
                                        </div>
                                    )}
                                </>
                            )} */}
                        </div>
                        <button
                            type="submit"
                            className="btn"
                            onClick={() => onSave()}
                            disabled={selectedPeople.length === 0}
                        >
                            {t('web_share_add_trusted_partner_save')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sharing
