import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { SharedGroup } from 'stores/files'
import { useMst } from 'stores/store'
import SharingPanelGroupUsers from './components/sharing-panel-group-users'
import { SharingPanelProps } from './sharing-panel'

interface SharingPanelGroupProps extends SharingPanelProps {}

const SharingPanelGroup: FC<SharingPanelGroupProps> = observer(
    ({ showUserSharedByMe = false, showUserSharedWithMe = false }) => {
        const { files } = useMst()
        const { t } = useTranslation()
        const [sharedFiles, setSharedFiles] = useState<SharedGroup[]>([])
        const navigate = useNavigate()

        const load = async () => {
            if (showUserSharedByMe) {
                await files.getFilesSharedByMe(true)
                setSharedFiles(files.sharedGroupByMe)
            } else if (showUserSharedWithMe) {
                await files.getFilesSharedWithMe(true)
                setSharedFiles(files.sharedGroupWithMe)
            }
        }

        useEffect(() => {
            load()
        }, [])

        return (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {sharedFiles.length === 0 ? (
                    <span>{t(showUserSharedByMe ? 'web_share_no_share_by_me' : 'web_share_no_share_with_me')}</span>
                ) : (
                    sharedFiles.map(file => (
                        <div
                            key={file.uuid}
                            className="flex w-full cursor-pointer space-x-2 rounded bg-white px-2 py-6 shadow-lg"
                            onClick={() => navigate(`/share/${showUserSharedByMe ? 'me' : 'with'}/${file.uuid}`)}>
                            <div className="flex w-1/3 items-center justify-center -space-x-2">
                                <SharingPanelGroupUsers users={file.users} />
                            </div>
                            <div className="flex w-2/3 flex-col space-y-2">
                                <span className="truncate pr-4 font-nunito text-2xl font-bold">
                                    {file.users.map(user => user.user.trustedUser.fullname).join(', ')}
                                </span>
                                <span>{t('web_share_count_shared_files', { count: file.countShared })}</span>
                            </div>
                        </div>
                    ))
                )}
            </div>
        )
    }
)

export default SharingPanelGroup
