import { CSSProperties, ReactNode } from 'react'

interface TdProps {
    children?: ReactNode
    className?: string
    style?: CSSProperties
}

export const Td = ({ children, className, style }: TdProps) => {
    return (
        <td className={className} style={style}>
            {children}
        </td>
    )
}
