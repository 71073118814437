import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowLeft } from '../../assets/arrow/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../assets/arrow/arrow-right.svg'

interface MenuItemProps {
    to?: string
    onClick?: () => void
    external?: boolean
    email?: boolean
    icon?: ReactNode
    children: ReactNode
    className?: string
}

export const MenuItem = ({
    to = '#',
    onClick,
    icon,
    children,
    className = '',
    external = false,
    email = false,
}: MenuItemProps) => {
    if (onClick) {
        return (
            <button
                onClick={onClick}
                className={`inline-flex cursor-pointer items-center px-4 py-2 text-regent-gray ${className}`}>
                {icon}
                {children}
            </button>
        )
    }

    if (email) {
        return (
            <a
                href={`mailto:${to}`}
                rel="noopener nofollow"
                className={`inline-flex items-center px-4 py-2 text-regent-gray ${className}`}>
                {icon}
                {children}
            </a>
        )
    }

    if (external) {
        return (
            <a
                href={to}
                rel="noopener nofollow noreferrer"
                target="_blank"
                className={`inline-flex items-center px-4 py-2 text-regent-gray ${className}`}>
                {icon}
                {children}
            </a>
        )
    }

    return (
        <Link to={to} className={`inline-flex items-center px-4 py-2 text-regent-gray ${className}`}>
            {icon}
            {children}
        </Link>
    )
}

export const MenuDivider = () => <div className="border-b border-geyser"></div>

interface SubMenuProps {
    icon?: ReactNode
    children: ReactNode
    onClick: () => void
}
export const SubMenu = ({ icon, children, onClick }: SubMenuProps) => {
    return (
        <span className="flex cursor-pointer items-center px-4 py-2 text-regent-gray" onClick={onClick}>
            {icon}
            {children}
            <ArrowRight className="ml-4 h-2 w-2 fill-current" />
        </span>
    )
}

interface SubMenuOpenProps {
    icon?: ReactNode
    children: ReactNode
    onClick: () => void
}
export const SubMenuOpen = ({ children, onClick }: SubMenuOpenProps) => {
    return (
        <span className="flex cursor-pointer items-center px-4 py-2 text-black" onClick={onClick}>
            <ArrowLeft className="mr-2 h-2 w-2 fill-current" />
            {children}
        </span>
    )
}
