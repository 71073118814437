import { getExtension } from 'components/dataroom/upload/mime-types'
import type { FileWithPath } from 'components/dataroom/upload/upload'
import { v4 as uuidv4 } from 'uuid'

export class TreasyFile {
    uuid: string
    hash: string
    file: File
    filename: string
    fullPath: string
    documentTypeUuid?: string

    constructor(fileWithPath: FileWithPath) {
        const { file, fullPath } = fileWithPath
        this.file = file
        this.hash = ''
        this.uuid = uuidv4()
        this.fullPath = fullPath
        this.documentTypeUuid = fileWithPath.documentTypeUuid

        const extension = getExtension(file.name)
        this.filename = `${this.uuid}.${extension}`
    }
}
