import { z } from 'zod'

export interface FormProps<T = Record<string, unknown>> {
    onSubmit: (data: T) => Promise<void>
    values?: T
}

export const optionValueSchema = z.object({
    value: z.string(),
    label: z.string(),
})

export interface Option {
    value: string
    label: string
}
