import { values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ReactComponent as EmptyTrash } from '../../assets/empty-trash.svg'
import { ReactComponent as Restore } from '../../assets/icons/icon-restore.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/icon-trash.svg'
import { ReactComponent as MoveTo } from '../../assets/move-to.svg'
import toast from '../../core/utils/toast'
import { SpaceDirectory } from '../../stores/files/directory'
import { SpaceFile } from '../../stores/files/file'
import { Space } from '../../stores/files/space'
import { useMst } from '../../stores/store'
import Breadcrumb from '../shared/breadcrumb'
import FloatingBar from '../shared/floating-bar'
import FloatingBarButton from '../shared/floating-bar-button'
import Modal from '../shared/modal'
import Panel from '../shared/panel'
import SmallLoader from '../shared/small-loader'
import Tooltip from '../shared/tooltip'
import ContentPage from './content-page'
import MoveFile from './move-file'
import Grid from './spaceroom/grid'
import TrashFile from './upload/trash-file'

const Trash = observer(() => {
    const { t } = useTranslation()
    const { files } = useMst()

    const [loading, setLoading] = useState<boolean>(true)
    const [shouldReset, setShouldReset] = useState<boolean>(true)

    const [selectedAll, setSelectedAll] = useState<boolean>(false)
    const [selectedFiles, setSelectedFiles] = useState<SpaceFile[]>([])

    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
    const [showEmptyTrash, setShowEmptyTrash] = useState<boolean>(false)
    const [showMoveTo, setShowMoveTo] = useState<boolean>(false)

    const reset = async () => {
        await files.loadTrashedFiles()
    }

    useEffect(() => {
        if (shouldReset) {
            setLoading(true)
            reset()
            setShouldReset(false)
            setLoading(false)
        }
    }, [shouldReset])

    const columns = [
        { label: t('web_dataroom_add_file_name'), className: 'col-span-8 text-left' },
        { label: t('web_dataroom_add_file_orig_space'), className: 'col-span-6' },
        { label: t('web_dataroom_add_file_size'), className: 'col-span-3' },
        { label: t('web_dataroom_last_modified_date'), className: 'col-span-4' },
    ]

    const changeSelection = (file: SpaceFile, selected: boolean) => {
        const selection = [...selectedFiles]
        if (selected) {
            selection.push(file)
        } else {
            const index = selection.findIndex(s => s.uuid === file.uuid)
            if (index === -1) {
                return
            }
            selection.splice(index, 1)
        }
        setSelectedFiles(selection)
    }

    const toggleAll = (selectedAll: boolean) => {
        setSelectedAll(selectedAll)
        if (selectedAll) {
            setSelectedFiles([...values<SpaceFile>(files.trash)])
        } else {
            setSelectedFiles([])
        }
    }

    const restore = async () => {
        await files.restore(selectedFiles)
        setSelectedFiles([])
    }

    const clean = async () => {
        setLoading(true)
        await files.emptyTrash()
        setSelectedFiles([])
        setLoading(false)
    }

    const confirmDelete = async () => {
        setLoading(true)
        await files.confirmDelete(selectedFiles)
        setSelectedFiles([])
        setLoading(false)
    }

    const setChooseDirectory = async (space: Space | undefined, directory: SpaceDirectory | undefined) => {
        if (space && directory) {
            await files.moveFilesFromTrash(selectedFiles, space, directory)

            setShowMoveTo(false)
            setSelectedFiles([])
        }
        toast('warning', 'setchoosedirectory CHECK ME')
    }

    return (
        <ContentPage title={t('web_trash_title')}>
            <Breadcrumb>
                <Link to="/dataroom">{t('web_breadcrumb_dataroom')}</Link>
                <Tooltip tooltip={t('web_tooltips_dataroom_trash')}>
                    <span>{t('web_trash_title')}</span>
                </Tooltip>
            </Breadcrumb>

            <Modal isOpen={loading} onRequestClose={() => {}}>
                <SmallLoader className="mt-6" />
            </Modal>

            <Panel>
                {values<SpaceFile>(files.trash).length > 0 && (
                    <Grid columns={columns} onSelect={selectedAll => toggleAll(selectedAll)}>
                        {values<SpaceFile>(files.trash).map(file => (
                            <TrashFile
                                key={file.uuid}
                                file={file}
                                selected={selectedAll}
                                onSelect={s => changeSelection(s, true)}
                                onDeselect={s => changeSelection(s, false)}
                            />
                        ))}
                    </Grid>
                )}
                {values<SpaceFile>(files.trash).length === 0 && (
                    <div className="col-span-full">{t('web_trash_is_empty')}</div>
                )}
            </Panel>

            <FloatingBar visible={values<SpaceFile>(files.trash).length > 0}>
                <div className="flex h-full flex-row">
                    <div className="flex space-x-4 px-4 py-2">
                        {selectedFiles.length > 0 && (
                            <FloatingBarButton
                                icon={<Restore className="h-4 fill-current" />}
                                label="web_trash_restore_file"
                                onClick={() => restore()}
                            />
                        )}
                        {selectedFiles.length > 0 && (
                            <div className="relative">
                                <FloatingBarButton
                                    icon={<MoveTo className="h-4 fill-current" />}
                                    label="web_trash_move_to"
                                    onClick={() => setShowMoveTo(true)}
                                />
                                <MoveFile
                                    isVisible={showMoveTo}
                                    showDirectories={true}
                                    onClose={() => setShowMoveTo(false)}
                                    onChoose={(space, directory) => setChooseDirectory(space, directory)}
                                />
                            </div>
                        )}
                        <FloatingBarButton
                            icon={<EmptyTrash className="h-4 fill-current" />}
                            label="web_empty_trash"
                            onClick={() => setShowEmptyTrash(true)}
                        />
                        {selectedFiles.length > 0 && (
                            <FloatingBarButton
                                icon={<TrashIcon className="h-4 fill-current" />}
                                label="web_trash_delete"
                                onClick={() => setShowConfirmDelete(true)}
                            />
                        )}
                    </div>
                </div>
            </FloatingBar>

            <Modal
                isOpen={showConfirmDelete}
                title={t('web_confirm_delete_files', { count: selectedFiles.length })}
                onCancel={() => setShowConfirmDelete(false)}
                onRequestClose={() => setShowConfirmDelete(false)}
                onConfirm={() => {
                    setShowConfirmDelete(false)
                    confirmDelete()
                }}></Modal>
            <Modal
                isOpen={showEmptyTrash}
                title={t('web_confirm_empty_trash', { count: values<SpaceFile>(files.trash).length })}
                onCancel={() => setShowEmptyTrash(false)}
                onRequestClose={() => setShowEmptyTrash(false)}
                onConfirm={() => {
                    setShowEmptyTrash(false)
                    clean()
                }}></Modal>
        </ContentPage>
    )
})

export default Trash
