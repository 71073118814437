import clsx from 'clsx'
import Modal from 'components/shared/modal'
import Config from 'core/config'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import type { Partner } from 'stores/partners'
import { useMst } from '../../stores/store'
import { getFileSize } from '../dataroom/upload/file-size'
import Panel from '../shared/panel'
import SmallLoader from '../shared/small-loader'
import ProfilePage from './profile-page'

interface CancelSubProps {
    onCancelPayment: () => void
}

const CancelSub = ({ onCancelPayment }: CancelSubProps) => {
    const { paymentManager } = useMst()
    const { t } = useTranslation()

    return (
        <>
            {paymentManager.payment.cancelAtDate ? (
                <>
                    <p className="mb-2">
                        {t('web_me_profile_subscription_premium_cancel', {
                            date: paymentManager.payment.cancelAtDate.toLocaleString(DateTime.DATE_SHORT),
                        })}
                    </p>
                    <Link className="btn w-full px-1" to="/me/prices">
                        {t('web_me_profile_subscription_renew')}
                    </Link>
                </>
            ) : (
                <>
                    {!paymentManager.payment.cancelAtDate && paymentManager.payment.renewDate && (
                        <p className="mb-2">
                            {t('web_me_profile_subscription_premium_renew', {
                                date: paymentManager.payment.renewDate.toLocaleString(DateTime.DATE_SHORT),
                            })}
                        </p>
                    )}

                    {paymentManager.payment.provider === 'stripe' ? (
                        <div className="flex flex-col gap-2">
                            <Link className="btn w-full px-1" to="/me/prices">
                                {t('web_me_profile_subscription_change_pricing')}
                            </Link>
                            <button className="btn premium w-full px-1" onClick={() => onCancelPayment()} type="button">
                                {t('web_me_profile_subscription_cancel_premium')}
                            </button>
                        </div>
                    ) : (
                        <span>
                            {t('web_me_profile_subscription_cancel_platform', {
                                provider: t(`web_me_profile_subscription_${paymentManager.payment.provider}`),
                            })}
                        </span>
                    )}
                </>
            )}
        </>
    )
}

interface PricingProps {
    partner: Partner

    onCancelPayment: () => void
    onLeaveTribe: () => void
}

const Pricing = ({ partner, onCancelPayment, onLeaveTribe }: PricingProps) => {
    const { user, paymentManager } = useMst()
    const { t } = useTranslation()

    return (
        <div className={clsx('w-full rounded border bg-white shadow lg:max-w-md')}>
            <div className="border-b px-4 py-6 flex flex-col justify-between">
                <div>
                    <h2 className="mb-6 text-xl font-bold">{user.pricing.publicName}</h2>
                    <ReactMarkdown
                        className="prose w-full max-w-full text-regent-gray mb-8"
                        components={{
                            a: ({ node, href, children, ...props }) => {
                                if (href.startsWith('http') && !href.startsWith(Config.app.WEBURL)) {
                                    return (
                                        <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
                                            {children}
                                        </a>
                                    )
                                }

                                return (
                                    <a href={href} {...props}>
                                        {children}
                                    </a>
                                )
                            },
                        }}
                    >
                        {user.pricing.description}
                    </ReactMarkdown>
                </div>
                {user.pricing.manual ? null : user.pricing.free ? (
                    <Link className="btn premium w-full px-1" to="/me/prices">
                        {t('web_me_profile_subscription_change_pricing')}
                    </Link>
                ) : user.pricing.isTribe && user.tribe.uuid !== '' ? (
                    <>
                        {user.tribe.master ? (
                            <CancelSub onCancelPayment={onCancelPayment} />
                        ) : (
                            <button className="btn premium w-full" onClick={() => onLeaveTribe()} type="button">
                                {t('web_me_profile_subscription_leave_tribe')}
                            </button>
                        )}
                    </>
                ) : (
                    <>
                        {paymentManager.payment.active && <CancelSub onCancelPayment={onCancelPayment} />}
                        <Link className="btn premium w-full" to="/me/prices">
                            {t('web_me_profile_subscription_start_premium')}
                        </Link>
                    </>
                )}
            </div>
        </div>
    )
}

const Subscription = observer(() => {
    const { user, paymentManager } = useMst()
    const { t } = useTranslation()

    const [newMember, setNewMember] = useState<string>()
    const [isLoadingPayment, setLoadingPayment] = useState<boolean>(false)

    const [showConfirmCancelAlert, setShowConfirmCancelAlert] = useState<boolean>(false)
    const [partner] = useState<Partner>(user.partner)

    const load = useCallback(async () => {
        setLoadingPayment(true)
        await user.refreshUser()
        await paymentManager.getStatus()
        setLoadingPayment(false)
        await user.loadPartner()
    }, [paymentManager])

    useEffect(() => {
        load()
    }, [paymentManager])

    const confirmCancel = async () => {
        setShowConfirmCancelAlert(false)
        setLoadingPayment(true)

        await paymentManager.cancelPayment()

        load()
    }

    const cancelPayment = async () => {
        setShowConfirmCancelAlert(true)
    }

    const inviteNewMember = async () => {
        await user.inviteTribeMember(newMember, false)

        setNewMember('')
    }

    const leaveTribe = async () => {
        await user.leaveTribe()
        await load()
    }

    const removeMember = async (email?: string, uuid?: string) => await user.removeTribeMember(email, uuid)
    const reinviteMember = async member => await user.inviteTribeMember(member.email, true)

    return (
        <ProfilePage url="/me/subscriptions">
            <Panel className="mt-4" innerClassName="flex justify-center bg-white">
                <div className="flex w-full flex-col gap-8 px-2 lg:px-8">
                    <div className="flex w-full flex-col">
                        <h2 className="text-lg">{t('web_me_profile_subscription')}</h2>
                        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
                            {isLoadingPayment ? (
                                <SmallLoader />
                            ) : (
                                <Pricing partner={partner} onCancelPayment={cancelPayment} onLeaveTribe={leaveTribe} />
                            )}
                        </div>
                    </div>

                    {user.pricing.isTribe && user.tribe.uuid && (
                        <div className="flex w-full flex-col">
                            <h2>{t('web_pricing_tribe_settings')}</h2>
                            <div className="w-full pr-2 pt-2 text-regent-gray">
                                {t('web_pricing_tribe_description')}
                            </div>

                            <div className="mt-4 grid w-full grid-cols-3 gap-2 px-8">
                                {user.tribe.members.length && (
                                    <>
                                        <h4 className="font-bold">{t('web_pricing_tribe_members')}</h4>
                                        {user.tribe.master ? (
                                            <h4 className="col-span-2 font-bold">
                                                {t('web_pricing_tribe_member_usage')}
                                            </h4>
                                        ) : (
                                            <span className="col-span-2">&nbsp;</span>
                                        )}

                                        {user.tribe.members.map(member => (
                                            <Fragment key={member.id}>
                                                <span>
                                                    {member.fullname}{' '}
                                                    {member.id === user.uuid && t('web_pricing_tribe_you')}
                                                </span>
                                                {user.tribe.master ? (
                                                    <>
                                                        <span>{getFileSize(t, member.storage)}</span>
                                                        {member.master ? (
                                                            <span>&nbsp;</span>
                                                        ) : (
                                                            <button
                                                                type="button"
                                                                className="btn white w-fit"
                                                                onClick={() => removeMember(undefined, member.id)}
                                                            >
                                                                {t('web_pricing_tribe_remove_member')}
                                                            </button>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="col-span-2">&nbsp;</span>
                                                    </>
                                                )}
                                            </Fragment>
                                        ))}
                                    </>
                                )}

                                {user.tribe.master && user.tribe.invitations && (
                                    <>
                                        <h4 className="col-span-3 font-bold">{t('web_pricing_tribe_invitations')}</h4>
                                        {user.tribe.invitations
                                            .filter(invitation => invitation.status === 'pending')
                                            .map(member => (
                                                <Fragment key={member.email}>
                                                    <span>{member.email}</span>
                                                    <button
                                                        type="button"
                                                        className="btn white w-fit"
                                                        onClick={() => reinviteMember(member)}
                                                    >
                                                        {t('web_pricing_tribe_reinvite_member')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn white w-fit"
                                                        onClick={() => removeMember(member.email, undefined)}
                                                    >
                                                        {t('web_pricing_tribe_remove_member')}
                                                    </button>
                                                </Fragment>
                                            ))}

                                        {user.tribe.master && (
                                            <>
                                                {user.tribe.members.length < user.tribe.maxMembers ? (
                                                    <>
                                                        <span className="col-span-3 mt-4">
                                                            {t('web_pricing_tribe_email')}
                                                        </span>
                                                        <input
                                                            type="email"
                                                            className="col-span-2 w-full md:w-1/2"
                                                            value={newMember}
                                                            onChange={e => setNewMember(e.target.value)}
                                                        />
                                                        <button
                                                            className="btn w-fit"
                                                            onClick={() => inviteNewMember()}
                                                            type="button"
                                                        >
                                                            {t('web_pricing_tribe_add_member')}
                                                        </button>
                                                    </>
                                                ) : (
                                                    <span className="col-span-3 mt-4 text-sm text-regent-gray">
                                                        {t('web_pricing_tribe_full')}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Panel>

            <Modal
                isOpen={showConfirmCancelAlert}
                bottomAlignment="center"
                onRequestClose={() => setShowConfirmCancelAlert(false)}
                cancelLabel={t('web_confirm_cancel_cancel')}
                okLabel={t('web_confirm_cancel_ok')}
                onConfirm={() => confirmCancel()}
                onCancel={() => setShowConfirmCancelAlert(false)}
            >
                <h2 className="mb-2 mt-4 text-xl font-bold">{t('web_confirm_cancel_title')}</h2>
                <p className="my-6 text-center text-sm text-pale-sky">{t('web_confirm_cancel_desc')}</p>
            </Modal>
        </ProfilePage>
    )
})

export default Subscription
