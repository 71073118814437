import type { Option } from 'components/admin/forms'
import toast from 'core/utils/toast'
import { DateTime } from 'luxon'
import { useState } from 'react'
import type { DetachedSpaceOrDirectoryOrFile } from 'stores/files'
import { useMst } from 'stores/store'
import type { TrustedUser } from 'stores/trustedUser/trusted-user'
import type { UserType } from 'stores/userType/user-type'
import AddTrustedPerson from './add-trusted-person'
import FindPartner from './find-partner'
import ShareLink from './link'
import Sharing from './sharing'

export type SharingOption = Option & {
    __isTrusted__?: boolean
    __isPartner__?: boolean
    picture?: string
    subLabel?: string
    accepted?: boolean
    unlimited?: boolean
    fromDate?: DateTime
    toDate?: DateTime
    userType?: UserType
}

interface ShareModalContentProps {
    selectedFiles: DetachedSpaceOrDirectoryOrFile[]
    onClose: () => void
}

const ShareModalContent = ({ selectedFiles, onClose }: ShareModalContentProps) => {
    const { files } = useMst()
    const [showAddPartner, setShowAddPartner] = useState<boolean>(false)
    const [showAddTrusted, setShowAddTrusted] = useState<boolean>(false)
    const [selectedPeople, setSelectedPeople] = useState<SharingOption[]>([])
    const [isGroup, setIsGroup] = useState<boolean>(false)
    const [groupName, setGroupName] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const save = async () => {
        setLoading(true)
        const result = await files.share(
            selectedFiles,
            selectedPeople.map(option => ({
                uuid: option.value,
                unlimited: option.unlimited,
                fromDate: option.fromDate,
                toDate: option.toDate,
            })),
            isGroup,
            groupName
        )
        setLoading(false)
        if (result) {
            toast('success', 'web_file_shared_with_people')
            onClose()
        }
    }

    return (
        <div className="flex w-full flex-col space-y-2">
            {showAddPartner ? (
                <FindPartner
                    onBack={() => setShowAddPartner(false)}
                    onAdd={(trustedUser: TrustedUser) => {
                        setShowAddPartner(false)
                        setSelectedPeople(current => [
                            ...current,
                            {
                                label: trustedUser.trustedUser.fullname,
                                accepted: trustedUser.accepted,
                                picture: trustedUser.trustedUser.picture,
                                value: trustedUser.uuid,
                                unlimited: true,
                                fromDate: DateTime.local(),
                            },
                        ])
                    }}
                />
            ) : showAddTrusted ? (
                <AddTrustedPerson
                    onBack={() => setShowAddTrusted(false)}
                    onAdd={(trustedUser: TrustedUser) => {
                        setShowAddTrusted(false)
                        setSelectedPeople(current => [
                            ...current,
                            {
                                label: trustedUser.trustedUser.fullname,
                                accepted: trustedUser.accepted,
                                picture: trustedUser.trustedUser.picture,
                                value: trustedUser.uuid,
                                unlimited: true,
                                fromDate: DateTime.local(),
                            },
                        ])
                    }}
                />
            ) : (
                <>
                    <Sharing
                        selectedPeople={selectedPeople}
                        onChangePeople={(
                            option: SharingOption,
                            unlimited: boolean,
                            fromDate?: DateTime,
                            toDate?: DateTime
                        ) =>
                            setSelectedPeople(current =>
                                current.map(o =>
                                    o.value === option.value ? { ...option, unlimited, fromDate, toDate } : o
                                )
                            )
                        }
                        addSelectedPeople={(option: SharingOption) =>
                            setSelectedPeople(current => [
                                ...current,
                                { ...option, unlimited: true, fromDate: DateTime.local() },
                            ])
                        }
                        removeSelectedPeople={(option: SharingOption) =>
                            setSelectedPeople(current => current.filter(o => o.value !== option.value))
                        }
                        onAddPartner={() => setShowAddPartner(true)}
                        onAddTrusted={() => setShowAddTrusted(true)}
                        onSave={() => save()}
                        loading={loading}
                        isGroup={isGroup}
                        setIsGroup={setIsGroup}
                        groupName={groupName}
                        setGroupName={setGroupName}
                    />
                    <ShareLink selectedFiles={selectedFiles} />
                </>
            )}
        </div>
    )
}

export default ShareModalContent
