import { ReactComponent as IconPartner } from 'assets/icons/icon-partner.svg'
import { ReactComponent as IconPlaceholder } from 'assets/icons/icon-user-placeholder.svg'
import { clsx } from 'clsx'
import Tooltip from 'components/shared/tooltip'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import type { Partner } from 'stores/partners'
import type { TrustedUser } from 'stores/trustedUser/trusted-user'
import type { UserType } from 'stores/userType/user-type'
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash.svg'

interface UserCardProps {
    user: TrustedUser
    partner?: Partner
    userType?: UserType
    className?: string
    onClick: () => void
    onDelete?: () => void
}

const UserCard: FC<UserCardProps> = ({ user, onClick, onDelete, className }) => {
    const { t } = useTranslation()

    return (
        <div className={clsx('w-full flex-none p-2 md:w-1/2 lg:w-1/3 xl:w-1/4', className)} onClick={onClick}>
            <div className="flex flex-col px-6 py-4 shadow-md">
                {onDelete && (
                    <div className="relative self-end">
                        <IconTrash
                            className="cursor-pointer fill-current text-regent-gray"
                            title={t('web_link_trash')}
                            onClick={() => onDelete()}
                        />
                    </div>
                )}
                <div className="mt-4 flex flex-row items-center">
                    <div className="relative h-12 w-12 rounded-full bg-regent-gray">
                        {user.trustedUser.picture !== '' ? (
                            <div
                                className="h-12 w-12 rounded-full bg-contain bg-center bg-no-repeat"
                                style={{ background: `url(${user.trustedUser.picture})` }}
                            ></div>
                        ) : (
                            <div className="absolute inset-0 flex h-12 w-12 items-center justify-center overflow-hidden rounded-full text-white">
                                <IconPlaceholder className="h-8 w-8 fill-current" />
                            </div>
                        )}
                        {user.userType?.uuid !== '' ? (
                            <div
                                className={
                                    'absolute -bottom-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-nepal text-sm text-white'
                                }
                            >
                                <IconPartner className="h-3 w-3 fill-current" />
                            </div>
                        ) : (
                            <Tooltip
                                tooltip={
                                    user.accepted
                                        ? t('web_trusted_person_accepted')
                                        : t('web_trusted_person_not_yet_accepted')
                                }
                            >
                                <div
                                    className={`${
                                        user.accepted ? 'bg-sushi' : 'bg-christine'
                                    } absolute -bottom-1 -right-1 flex h-4 w-4 cursor-help items-center justify-center rounded-full text-sm`}
                                >
                                    {user.accepted ? '✓' : '✗'}
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    <div
                        className="-mt-1 ml-4 flex flex-col text-thunder"
                        style={{
                            width: 'calc(100% - 2.5rem - 0.25rem - 2rem)',
                        }}
                    >
                        {/* <span className="text-sm">{userType?.name ?? partner?.name ?? t('web_share_trusted_people_user_type')}</span> */}
                        <p className="font-bold">{user.trustedUser.fullname}</p>
                        <p className="text-xs font-semibold">{user.trustedUser.email}</p>
                        {user.collaboration && (
                            <p className="text-xs">
                                {t('web_collaboration_space_invitation', { space: user.collaboration.space.name })}
                            </p>
                        )}
                    </div>
                </div>
                {/* <div className="flex flex-col items-end justify-between mt-4">
                <span className="text-sm font-bold">{user.checked ? t('api_trusted_user_active') : t('api_trusted_user_inactive')}</span>
                <div className={`w-full h-2 rounded-full flex overflow-hidden ${user.checked ? 'bg-sushi' : 'bg-regent-gray'}`}></div>
            </div> */}
            </div>
        </div>
    )
}

export default UserCard
