import { Instance, types } from 'mobx-state-tree'

export const _Meta = types.model('Meta', {
    percent: 0,
    current: 0,
    max: 0,
    sorted: 0,
    toSort: 0,
    compressed: 0,
    shared: 0,
    sharedCount: 0,
    preSorted: 0,
    quarantine: 0,
    co2gain: 0,
})
export interface Meta extends Instance<typeof _Meta> {}
