import { decode } from 'html-entities'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useMst } from 'stores/store'
import { ReactComponent as IconError } from '../../assets/notifications/error.svg'
import { ReactComponent as IconInfo } from '../../assets/notifications/info.svg'
import { ReactComponent as IconSuccess } from '../../assets/notifications/success.svg'
import { ReactComponent as IconWarn } from '../../assets/notifications/warn.svg'
import { Notification as Model } from '../../stores/notification/notification'

interface Props {
    notification: Model
}

const Notification = ({ notification }: Props) => {
    const bgColor = {
        error: 'bg-bittersweet',
        info: 'bg-sky-blue',
        warn: 'bg-atomic-tangerine',
        success: 'bg-sushi',
    }[notification.type]
    const { notificationCenter } = useMst()

    const navigate = useNavigate()

    const onNotificationDelete = async (id: string) => {
        await notificationCenter.notificationDelete(id)
        await notificationCenter.load()
        navigate('/me/notifications')
    }

    return (
        <div className="flex flex-col items-start space-y-2 border-l-8 border-christine bg-white p-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            <div className={`${bgColor} flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-white`}>
                {notification.type == 'error' && <IconError className="h-6 w-6 fill-current" />}
                {notification.type == 'warn' && <IconWarn className="h-6 w-6 fill-current" />}
                {notification.type == 'info' && <IconInfo className="h-6 w-6 fill-current" />}
                {notification.type == 'success' && <IconSuccess className="h-6 w-6 fill-current" />}
            </div>
            <div className="flex shrink-0 flex-col lg:w-64">
                <strong dangerouslySetInnerHTML={{ __html: notification.title }} />
                <span dangerouslySetInnerHTML={{ __html: notification.body }} />
            </div>
            <div
                className="flex-auto text-regent-gray"
                dangerouslySetInnerHTML={{ __html: decode(notification.content) }}
            />
            <div className="shrink-0 justify-self-end text-regent-gray">{notification.smartDate}</div>
            <Link to={'/me/notifications'}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    onClick={() => onNotificationDelete(notification.id)}>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                </svg>
            </Link>
        </div>
    )
}

export default Notification
