import { observer } from 'mobx-react-lite'
import Panel from '../shared/panel'
import ProfilePage from './profile-page'
import PricingList from '../pricing/pricing-list'
import { useTranslation } from 'react-i18next'

const Pricing = observer(() => {
    const { t } = useTranslation()

    return (
        <ProfilePage url="/me/prices">
            <Panel className="mt-4" innerClassName="flex flex-col items-center justify-center bg-white space-y-6">
                <h2 className="text-lg font-bold">{t('web_me_choose_pricing')}</h2>
                <PricingList />
            </Panel>
        </ProfilePage>
    )
})

export default Pricing
