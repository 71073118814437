import { faDownload, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as AnchorIcon } from 'assets/icons/icon-anchor.svg'
import { ReactComponent as IconClipboard } from 'assets/icons/icon-clipboard.svg'
import ToggleButton from 'components/shared/toggle-button'
import toast from 'core/utils/toast'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { DetachedSpaceOrDirectoryOrFile } from 'stores/files'
import { useMst } from 'stores/store'

interface ShareLinkProps {
    selectedFiles: DetachedSpaceOrDirectoryOrFile[]
}

const ShareLink = ({ selectedFiles }: ShareLinkProps) => {
    const { t } = useTranslation()
    const { files } = useMst()

    const [loading, setLoading] = useState<boolean>(false)
    const [shareLink, setShareLink] = useState<string>()

    const [archiveName, setArchiveName] = useState<string>()
    const [keepStructure, setKeepStructure] = useState<boolean>(true)

    const getSharableLink = async () => {
        setLoading(true)
        const url = await files.getSharableLink(selectedFiles, archiveName, keepStructure)
        setShareLink(url)
        setLoading(false)
    }

    return (
        <div className="rounded-lg bg-white p-8">
            <div className="mb-2 flex w-full items-center space-x-2">
                <div className="rounded-full bg-regent-gray p-4">
                    <AnchorIcon className="h-5 w-5 stroke-current text-white" />
                </div>
                <h3 className="text-lg">{t('web_share_file_title')}</h3>
            </div>
            <p className="mb-4">{t('web_share_file_desc')}</p>

            {shareLink ? (
                <div className="flex w-full space-x-4">
                    <code
                        className="grow rounded bg-geyser px-4 py-2 text-sm shadow-inner"
                        onClick={() => {
                            if (!shareLink) {
                                getSharableLink()
                            }
                        }}
                    >
                        {shareLink}
                    </code>
                    <IconClipboard
                        className="w-4 cursor-pointer fill-current text-regent-gray"
                        onClick={async () => {
                            await navigator.clipboard.writeText(shareLink)
                            toast('success', 'web_share_file_link_copied')
                        }}
                    />
                </div>
            ) : (
                <div className="flex flex-col items-center gap-4">
                    <div className="flex justify-center items-center gap-4 w-full">
                        <p>{t('web_share_archive_name')}</p>
                        <input
                            className="w-full md:w-1/2"
                            type="text"
                            placeholder={t('web_share_archive_name_placeholder')}
                            onChange={e => setArchiveName(e.currentTarget.value.trim())}
                        />
                    </div>
                    <div className="flex justify-center items-center gap-4 w-full">
                        <p>{t('web_share_archive_keep_structure')}</p>
                        <ToggleButton value={keepStructure} onChange={setKeepStructure} />
                    </div>
                    <div className="flex w-full justify-center space-x-4">
                        <button
                            type="button"
                            className="btn flex items-center justify-center gap-2"
                            onClick={() => getSharableLink()}
                            disabled={loading || !archiveName || archiveName.trim().length === 0}
                        >
                            <FontAwesomeIcon
                                icon={loading ? faSpinner : faDownload}
                                spin={loading}
                                fixedWidth
                                className="fill-current"
                            />
                            <span>{t('web_api_click_to_share')}</span>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ShareLink
