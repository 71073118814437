import toast from 'core/utils/toast'
import { flow } from 'mobx'
import { getRoot, type Instance, types } from 'mobx-state-tree'
import { del, get, put } from '../core/services/http-service'
import type { RootInstance } from './store'
import { _TrustedUser } from './trustedUser/trusted-user'
import { _User } from './users'

export const _TrustedUserStore = types
    .model('TrustedUserStore', {
        franchises: types.array(types.string),
        brands: types.array(types.string),
        trustedUsers: types.array(_TrustedUser),
        trustedUsersPerFranchise: types.array(types.array(_TrustedUser)),
        trustedUsersPerBrand: types.array(types.array(_TrustedUser)),
        otherUsers: types.array(_User),
        pendingInvitations: types.array(_TrustedUser),
    })
    .volatile<{ loaded: boolean }>(() => ({
        loaded: false,
    }))
    .actions(self => ({
        replace: ({ trustedUsers, trustedUsersPerFranchise, trustedUsersPerBrand, otherUsers, pendingInvitations }) => {
            self.franchises.clear()
            self.brands.clear()
            self.trustedUsersPerFranchise.clear()
            self.trustedUsersPerBrand.clear()
            self.trustedUsers = trustedUsers.sort((a, b) => b.createdAt - a.createdAt)

            self.otherUsers = otherUsers
            self.pendingInvitations = pendingInvitations

            trustedUsersPerFranchise.map(franchise => {
                const franchiseEntries = Object.entries(franchise)[0]
                const franchiseName = franchiseEntries[0]
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const franchiseTrustedUsers = franchiseEntries[1] as any

                self.franchises.push(franchiseName)
                self.trustedUsersPerFranchise.push(franchiseTrustedUsers)
            })

            trustedUsersPerBrand.map(brand => {
                const brandEntries = Object.entries(brand)[0]
                const brandName = brandEntries[0]
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const brandTrustedUsers = brandEntries[1] as any

                self.brands.push(brandName)
                self.trustedUsersPerBrand.push(brandTrustedUsers)
            })
        },
    }))
    .actions(self => ({
        getInvitationInfos: flow(function* (userId: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {}
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const data = yield get<void, JsonReturn>(`/v1/web/trusted-users/${userId}`)

                const {
                    data: { validSessionUser, ...rest },
                } = data

                return { validSessionUser, ...rest }
            } catch (err) {
                root.error.prepare(err)
            }
        }),
        confirm: flow(function* (userId: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {}
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const {
                    data: { success },
                } = yield put<void, JsonReturn>(`/v1/web/trusted-users/${userId}`)

                return success
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        load: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {}
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const data = yield get<{ getPendingInvitations: boolean }, JsonReturn>('/v1/web/trusted-users', {
                    getPendingInvitations: true,
                })

                const {
                    data: {
                        trustedUsers,
                        trustedUsersPerFranchise,
                        trustedUsersPerBrand,
                        otherUsers,
                        pendingInvitations,
                    },
                } = data
                self.replace({
                    trustedUsers,
                    trustedUsersPerFranchise,
                    trustedUsersPerBrand,
                    otherUsers,
                    pendingInvitations,
                })
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        remove: flow(function* (userToDelete) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                yield del<void>(`/v1/web/trusted-users/${userToDelete.uuid}`)

                toast('success', 'api_user_trusted_deleted')
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        // createTrustedUser: flow(function* (userTypeId, trustedUserId) {
        //     const root = getRoot(self) as RootInstance
        //     root.error.clean()

        //     try {
        //         const postData = { userTypeId, trustedUserId }
        //         const data = yield post<typeof postData, TrustedUser>('/v1/web/trusted-users', postData)

        //         self.append(data.data)

        //         toast('success', 'api_user_trusted_created')

        //         return data.data

        //     } catch (err) {
        //         root.error.prepare(err)
        //     }
        // }),
    }))

export interface TrustedUserStore extends Instance<typeof _TrustedUserStore> {}
