import { get, post } from 'core/services/http-service'
import toast from 'core/utils/toast'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { Brand } from 'stores/brands'
import Admin from '../../admin/admin'
import Form, { type FormSchema } from './form'

const Create = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const { id } = useParams()

    const [brandId, setBrandId] = useState<string>()

    useEffect(() => {
        const loadBrandByFranchiseId = async (id: string) => {
            const {
                data: { brand: franchiseBrand },
            } = await get<void, { data: { brand: Brand } }>(`/v1/web/franchises/${id}/brand`)

            setBrandId(franchiseBrand.uuid)
        }

        loadBrandByFranchiseId(id)
    }, [id])

    const onSubmit = async (data: FormSchema) => {
        const postData = {
            ...data,
            role: data.role.value,
            type: data.type.value,
            tags: data.tags?.map(t => t.value) ?? [],
        }
        setLoading(true)

        try {
            await post<typeof postData, never>(`/v1/web/franchises/${id}/users`, postData)
            navigate(`/franchises/${id}/users`)
        } catch (error) {
            setLoading(false)
            toast('error', error.message)
        }
    }

    return (
        <Admin title={t('web_menu_admin_user')} isLoading={isLoading}>
            <Form showMessage onSubmit={onSubmit} brandId={brandId} />
        </Admin>
    )
}

export default Create
