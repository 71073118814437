import { flow } from 'mobx'
import { getRoot, Instance, types } from 'mobx-state-tree'
import { get, put } from '../core/services/http-service'
import { RootInstance } from './store'

export const _ShareGroup = types
    .model('ShareGroup', {
        uuid: '',
        groupName: types.optional(types.string, '', [null, undefined]),
        shareUuid: '',
    })
    .volatile<{ loaded: boolean }>(() => ({
        loaded: false,
    }))
    .actions(self => ({
        setLoaded: loaded => {
            self.loaded = loaded
        },
    }))

    .actions(self => ({
        load: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {}
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const data = yield get<void, JsonReturn>('/v1/web/shared-groups')

                const {
                    data: { sharedGroups },
                } = data

                return sharedGroups
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        editGroupName: flow(function* (id: string, groupName: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const putData = {
                    groupName: groupName,
                }
                yield put<typeof putData, void>(`/v1/web/shareGroup/${id}`, putData)
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))

export interface ShareGroup extends Instance<typeof _ShareGroup> {}
