import { flow, getRoot, Instance, types } from 'mobx-state-tree'
import { put } from '../../core/services/http-service'
import { RootInstance } from '../store'

export const _Connector = types
    .model('Connector', {
        uuid: '',
        name: '',
        color: '',
        slug: '',
        hidden: true,
        liked: false,
    })
    .views(self => ({
        get isDirectory(): boolean {
            return false
        },
        get isFile(): boolean {
            return false
        },
        get isSpace(): boolean {
            return false
        },
        get isConnector(): boolean {
            return true
        },
        get displayName(): string {
            return self.name
        },
        get url(): string {
            return `/dataroom/telecollecte?search=${self.uuid}`
        },
    }))
    .actions(self => ({
        toggleLike: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonReturnContent {}
            interface JsonReturn {
                data: JsonReturnContent
            }

            try {
                const data = yield put<void, JsonReturn>(`/v1/web/connectors/${self.uuid}/like`)
                const {
                    data: { liked },
                } = data
                self.liked = liked
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))
export interface Connector extends Instance<typeof _Connector> {}
