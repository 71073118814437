import { flow, values } from 'mobx'
import { getRoot, Instance, types } from 'mobx-state-tree'
import { get, post } from '../core/services/http-service'
import { _SpaceDirectory, SpaceDirectory } from './files/directory'
import { _SpaceFile, SpaceFile } from './files/file'
import { _Space, Space } from './files/space'
import { _SearchHistory } from './search/history'
import { RootInstance } from './store'
import { _Connector, Connector } from './telecollecte/connector'

export type SearchResult = Space | SpaceFile | SpaceDirectory | Connector

export const _SearchFile = _SpaceFile
    .props({
        url: types.string,
    })
    .named('SearchFile')
export const _SearchDirectory = _SpaceDirectory.props({ url: types.string }).named('SearchDirectory')
export const _SearchSpace = _Space.props({ url: types.string }).named('SearchSpace')

export interface SearchFile extends Instance<typeof _SearchFile> {}
export interface SearchDirectory extends Instance<typeof _SearchDirectory> {}
export interface SearchSpace extends Instance<typeof _SearchSpace> {}

export const _Search = types
    .model('Search', {
        files: types.map(_SearchFile),
        directories: types.map(_SearchDirectory),
        spaces: types.map(_SearchSpace),
        history: types.array(_SearchHistory),
        connectors: types.array(_Connector),
    })
    .views(self => ({
        get results(): SearchResult[] {
            return [
                ...values<SearchFile>(self.files),
                ...values<SearchDirectory>(self.directories),
                ...values<SearchSpace>(self.spaces),
                ...self.connectors.slice(),
            ].sort((a: SearchResult, b: SearchResult) => a.displayName.localeCompare(b.displayName))
        },
    }))
    .actions(self => ({
        replace: ({ files, directories, spaces, history, connectors }) => {
            self.files = files
            self.directories = directories
            self.spaces = spaces
            self.history = history
            self.connectors = connectors
        },
    }))
    .actions(self => ({
        search: flow(function* (term: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()
            interface JsonReturnContent {}
            interface JsonReturn {
                data: JsonReturnContent
            }
            const getData = {
                q: term,
            }

            try {
                const data = yield get<typeof getData, JsonReturn>('/v1/web/search', getData)
                const { data: search } = data
                self.replace(search)
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        save: flow(function* (term: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const postData = {
                term,
            }

            try {
                yield post<typeof postData, void>('/v1/web/search', postData)
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))

export interface Search extends Instance<typeof _Search> {}
