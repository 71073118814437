import { SharedUser } from 'stores/files'
import SharingPanelGroupUser from './sharing-panel-group-user'

const SharingPanelGroupUsers = ({ users }: { users: SharedUser[] }) => {
    switch (users.length) {
        case 0:
            return null
        case 1:
            return (
                <SharingPanelGroupUser user={users[0]} zIndex={1} size="big" userType={users[0].user.userType?.name} />
            )
        case 2:
            return (
                <>
                    {users.map((user, index) => (
                        <SharingPanelGroupUser
                            key={user.uuid}
                            user={user}
                            zIndex={users.length - index}
                            size="small"
                            accepted={user.user.accepted}
                            userType={user.user.userType?.name}
                        />
                    ))}
                </>
            )
        default:
            return (
                <>
                    {users.slice(0, 2).map((user, index) => (
                        <SharingPanelGroupUser
                            key={user.uuid}
                            user={user}
                            zIndex={users.length - index}
                            size="small"
                            accepted={user.user.accepted}
                            userType={user.user.userType?.name}
                        />
                    ))}
                    <div
                        className={
                            'relative flex h-11 w-11 items-center justify-center rounded-full border border-white bg-athens-gray font-nunito text-xs font-bold text-thunder'
                        }
                        style={{ zIndex: 1 }}>
                        +{users.length - 2}
                    </div>
                </>
            )
    }
}
export default SharingPanelGroupUsers
