import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    page: number
    totalPages: number
    onChangePage: (page: number) => void
}

const Pagination: React.FC<Props> = ({ page, totalPages, onChangePage }) => {
    const { t } = useTranslation()

    if (totalPages === 0) {
        return null
    }

    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 items-center justify-between space-x-6">
                <button onClick={() => onChangePage(page - 1)} disabled={page - 1 < 0} className="btn">
                    <span className="sr-only">{t('web_paginator_previous')}</span>
                    <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true">
                        <path
                            fillRule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>

                <span className="text-sm">{t('web_paginator_page', { page: page + 1, total: totalPages + 1 })}</span>

                <button onClick={() => onChangePage(page + 1)} disabled={page + 1 > totalPages} className="btn">
                    <span className="sr-only">{t('web_paginator_next')}</span>
                    <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true">
                        <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default Pagination
