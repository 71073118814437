import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useMst } from '../../../stores/store'
import Admin from '../admin'
import Form from './form'

const Create = () => {
    const { admin } = useMst()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(false)

    const onSubmit = async data => {
        const {
            name,
            description,
            longDescription,
            stripeProduct,
            stripePrice,
            durationPeriod,
            durationCount,
            free,
            manual,
            publicName,
            tribe,
            treasy,
            repeatable,
            storage,
            storageMessage,
            storageCta,
            connectors,
            connectorsMessage,
            connectorsCta,
            shareFiles,
            shareFilesMessage,
            shareFilesCta,
            transmission,
            transmissionMessage,
            transmissionCta,
            isDefault,
            upgrade,
            trialPeriodDays,
        } = data
        setLoading(true)

        await admin.createPricing(
            name,
            publicName,
            description,
            longDescription,
            {
                length: durationCount,
                period: durationPeriod?.value ?? null,
            },
            free,
            manual,
            treasy,
            storage,
            repeatable,
            connectors,
            shareFiles,
            transmission,
            isDefault,
            tribe,
            stripeProduct?.value ?? null,
            stripePrice?.value ?? null,
            storageMessage,
            storageCta,
            connectorsMessage,
            connectorsCta,
            shareFilesMessage,
            shareFilesCta,
            transmissionMessage,
            transmissionCta,
            upgrade?.value ?? null,
            trialPeriodDays
        )

        setLoading(false)
        navigate('/admin/pricings')
    }

    const load = async () => {
        setLoading(true)
        await admin.loadProducts()
        await admin.loadPrices()
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    return (
        <Admin title={t('web_menu_admin_pricing')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} />
        </Admin>
    )
}

export default Create
