export const fileColors = {
    jpg: '#F15F12',
    jpeg: '#F15F12',
    png: '#F15F12',
    heic: '#F15F12',
    webp: '#F15F12',
    gif: '#F15F12',

    psd: '#31a8ff',
    ai: '#ff9a00',
    xd: '#ff61f6',

    mp4: '#99bf27',
    mp3: '#99bf27',
    mov: '#99bf27',
    wav: '#99bf27',
    mkv: '#99bf27',
    ogg: '#99bf27',

    ppt: '#c63d19',
    pptx: '#c63d19',
    odp: '#c63d19',

    txt: '#B5C0CC',
    pdf: '#cb0606',

    rtf: '#2256a0',
    odt: '#2256a0',
    doc: '#2256a0',
    docx: '#2256a0',

    ods: '#0a7e43',
    csv: '#0a7e43',
    xlsx: '#0a7e43',
    xls: '#0a7e43',

    gz: '#fccd3a',
    zip: '#fccd3a',
    rar: '#fccd3a',
    tar: '#fccd3a',
    gzip: '#fccd3a',
    tgz: '#fccd3a',
    '7z': '#fccd3a',
}
