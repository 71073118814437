import { faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'components/shared/modal'
import Tooltip from 'components/shared/tooltip'
import useDebounce from 'core/debounce'
import { observer } from 'mobx-react-lite'
import { type KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import type { Pricing } from 'stores/pricing'
import { useMst } from 'stores/store'
import Admin from '../admin'
import Pagination from '../pagination'

const PricingLine = ({ pricing, onDelete }: { pricing: Pricing; onDelete: (id: string) => void }) => {
    const { t } = useTranslation()

    return (
        <tr>
            <td className="text-left">{pricing.name}</td>
            <td>
                {pricing.duration.length} {t(`web_menu_admin_pricing_period_${pricing.duration.period}`)}
            </td>
            <td>{pricing.trialPeriodDays}</td>
            <td>{pricing.free ? '✓' : '✗'}</td>
            <td>{pricing.manual ? '✓' : '✗'}</td>
            <td>{pricing.treasy ? '✓' : '✗'}</td>
            <td>{pricing.isDefault ? '✓' : '✗'}</td>
            <td className="flex items-center justify-center space-x-1 text-christine">
                <Link to={`/admin/pricings/${pricing.uuid}`}>
                    <FontAwesomeIcon icon={faEdit} />
                </Link>
                {pricing.usedBy > 0 ? (
                    <Tooltip tooltip={t('web_admin_pricings_cannot_delete', { count: pricing.usedBy })}>
                        <span>
                            <FontAwesomeIcon icon={faTrash} className="text-regent-gray" />
                        </span>
                    </Tooltip>
                ) : (
                    <button onClick={() => onDelete(pricing.uuid)} type="button">
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                )}
            </td>
        </tr>
    )
}

const limit = 50

const Listing = observer(() => {
    const { admin } = useMst()
    const { t } = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [offset, setOffset] = useState<number>(0)
    const [query, setQuery] = useState<string>('')
    const [page, setPage] = useState<number>(0)
    const [pageCount, setPageCount] = useState<number>(0)
    const [toDelete, setToDelete] = useState<string>()
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

    const load = useCallback(async () => {
        setLoading(true)
        await admin.loadPricings(limit, offset, query)

        setPageCount(Math.ceil(admin.totalPricings / limit))

        setLoading(false)
    }, [offset, query])

    const changePage = async page => {
        setPage(page)
        setOffset(Math.ceil(page * limit))
    }

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            load()
        }
    }

    const askConfirmDelete = (id: string) => {
        setToDelete(id)
        setShowConfirmDelete(true)
    }

    const confirmDelete = useCallback(async () => {
        await admin.deletePricing(toDelete)
        setShowConfirmDelete(undefined)
        await load()
    }, [admin, toDelete])

    const debouncedQuery = useDebounce<string>(query, 500)
    useEffect(() => {
        load()
    }, [page, debouncedQuery])

    useEffect(() => {
        setPage(0)
    }, [])

    return (
        <Admin
            title={t('web_admin_pricings')}
            isLoading={isLoading}
            header={
                <div className="flex items-center space-x-4">
                    <Link to="/admin/pricings/create" className="btn">
                        {t('web_admin_pricings_new')}
                    </Link>
                    <div className="flex items-center rounded border bg-white px-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2 h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>
                        <input
                            type="text"
                            className="m-0 grow border-none bg-transparent p-0 text-sm focus:border-none focus:outline-none"
                            placeholder={t('web_dashboard_search')}
                            onChange={e => setQuery(e.currentTarget.value)}
                            onKeyDown={e => onKeyDown(e)}
                        />
                    </div>
                </div>
            }
        >
            <table className="admin w-full table-auto text-center">
                <thead>
                    <tr>
                        <th>{t('web_menu_admin_pricings_name')}</th>
                        <th>{t('web_admin_pricing_duration')}</th>
                        <th>{t('web_admin_pricing_trial_period_days')}</th>
                        <th>{t('web_admin_pricing_free')}</th>
                        <th>{t('web_admin_pricing_manual')}</th>
                        <th>{t('web_admin_pricing_treasy')}</th>
                        <th>{t('web_admin_pricing_default')}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {admin.pricings.map(pricing => (
                        <PricingLine key={pricing.uuid} pricing={pricing} onDelete={id => askConfirmDelete(id)} />
                    ))}
                </tbody>
            </table>
            <div className="mt-8 flex w-full justify-center">
                {pageCount > 1 && <Pagination page={page} totalPages={pageCount} onChangePage={changePage} />}
            </div>
            <Modal
                isOpen={showConfirmDelete}
                size="1/4"
                onCancel={() => setShowConfirmDelete(false)}
                onRequestClose={() => setShowConfirmDelete(false)}
                cancelLabel={t('web_dataroom_trash_number_files_cancel')}
                okLabel={t('web_dataroom_trash_number_files_confirm')}
                onConfirm={() => confirmDelete()}
            >
                <p>{t('web_admin_pricings_delete_confirm')}</p>
            </Modal>
        </Admin>
    )
})

export default Listing
