import { ReactComponent as IconPartner } from 'assets/icons/icon-partner.svg'
import { ReactComponent as IconPlaceholder } from 'assets/icons/icon-user-placeholder.svg'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import Tooltip from '../../shared/tooltip'

interface SharingPanelGroupIconProps {
    image: string
    size: 'big' | 'small'
    zIndex?: number
    userType?: string
    accepted?: boolean
}

const SharingPanelGroupIcon = ({ image, zIndex = undefined, size, userType, accepted }: SharingPanelGroupIconProps) => {
    const { t } = useTranslation()

    return (
        <div
            className={clsx(
                'rounded-full border border-white text-white',
                size === 'small' ? 'h-11 w-11' : 'h-16 w-16',
                'relative flex items-center justify-center bg-regent-gray'
            )}
            style={{ zIndex }}>
            <div className="absolute inset-0 flex items-center justify-center overflow-hidden rounded-full">
                <IconPlaceholder className="h-3 w-3 fill-current" />
                <div
                    className={'absolute inset-0 flex items-center justify-center bg-cover bg-center bg-no-repeat'}
                    style={{ backgroundImage: `url(${image})` }}></div>
            </div>
            {userType && userType !== '' ? (
                <Tooltip tooltip={userType}>
                    <div className="absolute -bottom-1 -right-1 flex h-4 w-4 cursor-help items-center justify-center rounded-full bg-nepal text-sm text-white">
                        <IconPartner className="h-3 w-3 fill-current" />
                    </div>
                </Tooltip>
            ) : (
                <Tooltip
                    tooltip={accepted ? t('web_trusted_person_accepted') : t('web_trusted_person_not_yet_accepted')}>
                    <div
                        className={clsx(
                            accepted ? 'bg-sushi' : 'bg-christine',
                            'absolute -bottom-1 -right-1 flex h-4 w-4 cursor-help items-center justify-center rounded-full text-sm'
                        )}>
                        {accepted ? '✓' : '✗'}
                    </div>
                </Tooltip>
            )}
        </div>
    )
}
export default SharingPanelGroupIcon
