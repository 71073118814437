import Admin from 'components/admin/admin'
import toast from 'core/utils/toast'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useMst } from '../../../stores/store'
import Form, { Schema } from './form'

const Create = () => {
    const { brand, files } = useMst()
    const { id } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(false)

    const onSubmit = async (data: Schema) => {
        const { title, teaserText, bodyMessage, color, pdf, dismissible, startDate, endDate } = data

        setLoading(true)
        await brand.createNews(id, {
            title,
            teaserText,
            bodyMessage,
            color,
            pdf: pdf as FileList,
            dismissible,
            startDate,
            endDate,
        })
        toast('success', t('web_brand_news_created'))
        setLoading(false)
        navigate(`/brands/${id}/news`)
    }

    const load = async () => {
        setLoading(true)
        await files.getConfig()
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    return (
        <Admin title={t('web_partner_news')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} />
        </Admin>
    )
}

export default Create
