import { clsx } from 'clsx'
import { DragEventHandler, ReactNode } from 'react'

export type PanelSize =
    | '0'
    | '0.5'
    | '1'
    | '1.5'
    | '2'
    | '2.5'
    | '3'
    | '3.5'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | '14'
    | '16'
    | '20'
    | '24'
    | '28'
    | '32'
    | '36'
    | '40'
    | '44'
    | '48'
    | '52'
    | '56'
    | '60'
    | '64'
    | '72'
    | '80'
    | '96'
    | 'auto'
    | '1/2'
    | '1/3'
    | '2/3'
    | '1/4'
    | '2/4'
    | '3/4'
    | '1/5'
    | '2/5'
    | '3/5'
    | '4/5'
    | '1/6'
    | '2/6'
    | '3/6'
    | '4/6'
    | '5/6'
    | '1/12'
    | '2/12'
    | '3/12'
    | '4/12'
    | '5/12'
    | '6/12'
    | '7/12'
    | '8/12'
    | '9/12'
    | '10/12'
    | '11/12'
    | 'full'
    | 'screen'
    | 'min'
    | 'max'

interface Props {
    children?: ReactNode
    className?: string
    innerClassName?: string
    innerPadding?: string
    size?: PanelSize
    noSize?: boolean
    onDrop?: DragEventHandler
    onDragOver?: DragEventHandler
    dropType?: 'space' | 'directory'
    dropUuid?: string
}

const Panel = (
    {
        children,
        size = 'full',
        className = '',
        innerClassName = 'bg-white',
        noSize = false,
        onDrop,
        onDragOver,
        innerPadding = 'p-4',
        dropType,
        dropUuid,
    }: Props
) => {
    // useless typecast. it's only to force purgecss to keep the size
    const classSize = `md:w-${size}` as
        | 'md:w-0'
        | 'md:w-0.5'
        | 'md:w-1'
        | 'md:w-1.5'
        | 'md:w-2'
        | 'md:w-2.5'
        | 'md:w-3'
        | 'md:w-3.5'
        | 'md:w-4'
        | 'md:w-5'
        | 'md:w-6'
        | 'md:w-7'
        | 'md:w-8'
        | 'md:w-9'
        | 'md:w-10'
        | 'md:w-11'
        | 'md:w-12'
        | 'md:w-14'
        | 'md:w-16'
        | 'md:w-20'
        | 'md:w-24'
        | 'md:w-28'
        | 'md:w-32'
        | 'md:w-36'
        | 'md:w-40'
        | 'md:w-44'
        | 'md:w-48'
        | 'md:w-52'
        | 'md:w-56'
        | 'md:w-60'
        | 'md:w-64'
        | 'md:w-72'
        | 'md:w-80'
        | 'md:w-96'
        | 'md:w-auto'
        | 'md:w-1/2'
        | 'md:w-1/3'
        | 'md:w-2/3'
        | 'md:w-1/4'
        | 'md:w-2/4'
        | 'md:w-3/4'
        | 'md:w-1/5'
        | 'md:w-2/5'
        | 'md:w-3/5'
        | 'md:w-4/5'
        | 'md:w-1/6'
        | 'md:w-2/6'
        | 'md:w-3/6'
        | 'md:w-4/6'
        | 'md:w-5/6'
        | 'md:w-1/12'
        | 'md:w-2/12'
        | 'md:w-3/12'
        | 'md:w-4/12'
        | 'md:w-5/12'
        | 'md:w-6/12'
        | 'md:w-7/12'
        | 'md:w-8/12'
        | 'md:w-9/12'
        | 'md:w-10/12'
        | 'md:w-11/12'
        | 'md:w-full'
        | 'md:w-screen'
        | 'md:w-min'
        | 'md:w-max'

    return (
        <div
            className={clsx(className, 'transition-all duration-500 ease-in-out', {
                [classSize]: !noSize,
                'w-full': !noSize,
            })}
            onDrop={onDrop}
            onDragOver={onDragOver}
            data-drop-type={dropType}
            data-drop-uuid={dropUuid}>
            <div
                className={clsx(
                    'rounded shadow-md transition-all duration-500 ease-in-out print:shadow-none',
                    innerPadding,
                    innerClassName
                )}>
                {children}
            </div>
        </div>
    )
}

export default Panel
