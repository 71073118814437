import { Input, selectStyles } from 'components/shared/select-styles'
import ToggleButton from 'components/shared/toggle-button'
import { CropAs } from 'core/images/canvas-utils'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { useMst } from 'stores/store'
import { FormProps, optionValueSchema } from '../forms'
import Label from '../label'
import PictureUpload from '../picture-upload'
import 'components/admin/zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const schema = z.object({
    name: z.string().min(1),
    companyNumber: z.string().min(1),
    url: z.string().url(),
    contactUrl: z.string().min(1),
    pricing: optionValueSchema,
    otherPricing: z.array(optionValueSchema).nullable(),
    managers: z.array(optionValueSchema).min(1),
    caseManagers: z.array(optionValueSchema).nullable(),
    healthPartner: z.boolean().nullish().default(false),
    logo: z.string(),
    icon: z.string(),
})
export type Schema = z.infer<typeof schema>

const Form = ({ onSubmit, values }: FormProps<Schema>) => {
    const { t } = useTranslation()
    const { admin } = useMst()

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })

    useEffect(() => {
        if (!values) {
            return
        }

        for (const key of Object.keys(values) as (keyof Schema)[]) {
            setValue(key, values[key])
        }
    }, [values])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Label
                name="name"
                error={errors['name'] && `${t('web_admin_partner_name')} ${errors['name']?.message}`}
                label={'web_admin_partner_name'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <input {...register('name')} type="text" className="w-1/3" autoComplete="off" />
            </Label>
            <Label
                name="companyNumber"
                error={
                    errors['companyNumber'] &&
                    `${t('web_admin_partner_company_number')} ${errors['companyNumber']?.message}`
                }
                label={'web_admin_partner_company_number'}
                className="my-5 flex w-full flex-wrap items-center">
                <input {...register('companyNumber')} type="text" className="w-1/3" autoComplete="off" />
            </Label>
            <Label
                name="url"
                error={errors['url'] && `${t('web_admin_partner_url')} ${errors['url']?.message}`}
                label={'web_admin_partner_url'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <input {...register('url')} type="text" className="w-1/3" defaultValue="https://" autoComplete="off" />
            </Label>
            <Label
                name="contactUrl"
                error={errors['contactUrl'] && `${t('web_admin_partner_contact_url')} ${errors['url']?.message}`}
                label={'web_admin_partner_contact_url'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <input
                    {...register('contactUrl')}
                    type="text"
                    className="w-1/3"
                    defaultValue="https://"
                    autoComplete="off"
                />
            </Label>
            <Label
                name="healthPartner"
                error={
                    errors['healthPartner'] &&
                    `${t('web_admin_partner_health_partner')} ${errors['healthPartner']?.message}`
                }
                label={'web_admin_partner_health_partner'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <Controller
                    control={control}
                    name="healthPartner"
                    render={({ field }) => (
                        <ToggleButton value={field.value} onChange={enabled => field.onChange(enabled)} />
                    )}
                />
            </Label>
            <Label
                name="logo"
                error={errors['logo'] && `${t('web_admin_partner_logo')} ${errors['logo']?.message}`}
                label={'web_admin_partner_logo'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <Controller
                    control={control}
                    name="logo"
                    render={({ field }) => <PictureUpload {...field} value={field.value} as={CropAs.URL} />}
                />
            </Label>
            <Label
                name="icon"
                error={errors['icon'] && `${t('web_admin_partner_icon')} ${errors['icon']?.message}`}
                label={'web_admin_partner_icon'}
                className="my-5 flex w-full flex-wrap items-center"
                required={true}>
                <Controller
                    control={control}
                    name="icon"
                    render={({ field }) => (
                        <PictureUpload {...field} value={field.value} as={CropAs.URL} aspectRatio={1} />
                    )}
                />
            </Label>

            <Label
                name="pricing"
                error={errors['pricing'] && `${t('web_admin_partner_default_pricing')} ${errors['pricing']?.message}`}
                label={'web_admin_partner_default_pricing'}
                className="my-5 flex w-full flex-wrap items-center leading-tight"
                required={true}>
                <Controller
                    name="pricing"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                isClearable={true}
                                placeholder={t('web_admin_placeholder_select')}
                                options={[...admin.pricings].map(pricing => ({
                                    value: pricing.uuid,
                                    label: pricing.name,
                                }))}
                                onChange={pricing => onChange(pricing)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="otherPricing"
                error={
                    errors['otherPricing'] &&
                    `${t('web_admin_partner_other_pricing')} ${errors['otherPricing']?.message}`
                }
                label={'web_admin_partner_other_pricing'}
                className="my-5 flex w-full flex-wrap items-center leading-tight">
                <Controller
                    name="otherPricing"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Select
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                isClearable={true}
                                isMulti
                                placeholder={t('web_admin_placeholder_select')}
                                options={[...admin.pricings].map(pricing => ({
                                    value: pricing.uuid,
                                    label: pricing.name,
                                }))}
                                onChange={pricing => onChange(pricing)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="managers"
                error={errors['managers'] && `${t('web_admin_partner_managers')} ${errors['managers']?.message}`}
                label={'web_admin_partner_managers'}
                className="my-5 flex w-full flex-wrap items-center leading-tight"
                required={true}>
                <Controller
                    name="managers"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <AsyncSelect
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                isClearable={true}
                                isMulti
                                defaultOptions
                                placeholder={t('web_admin_placeholder_select')}
                                loadOptions={async (inputValue: string) => {
                                    await admin.loadUsers(50, 0, inputValue)

                                    return admin.users.map(u => ({
                                        value: u.id,
                                        label: `${u.fullname} (${u.email})`,
                                    }))
                                }}
                                onChange={user => onChange(user)}
                            />
                        )
                    }}
                />
            </Label>

            <Label
                name="caseManagers"
                error={
                    errors['caseManagers'] &&
                    `${t('web_admin_partner_case_manager')} ${errors['caseManagers']?.message}`
                }
                label={'web_admin_partner_case_manager'}
                className="my-5 flex w-full flex-wrap items-center leading-tight">
                <Controller
                    name="caseManagers"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <AsyncSelect
                                styles={selectStyles}
                                components={{ Input }}
                                className="w-1/3"
                                value={value}
                                isClearable={true}
                                isMulti
                                defaultOptions
                                placeholder={t('web_admin_placeholder_select')}
                                loadOptions={async (inputValue: string) => {
                                    await admin.loadUsers(50, 0, inputValue)

                                    return admin.users.map(u => ({
                                        value: u.id,
                                        label: `${u.fullname} (${u.email})`,
                                    }))
                                }}
                                onChange={user => onChange(user)}
                            />
                        )
                    }}
                />
            </Label>

            <input type="submit" className="btn float-right cursor-pointer" value={`${t('web_admin_partner_save')}`} />
        </form>
    )
}

export default Form
