import Admin from 'components/admin/admin'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useMst } from '../../../stores/store'
import Form, { Schema } from './form'

const Create = () => {
    const { informationMessageStore, files } = useMst()
    const { id } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(false)

    const onSubmit = async (data: Schema) => {
        const { title, bodyMessage, color, dismissible, startDate, endDate, users, tags } = data
        setLoading(true)
        await informationMessageStore.informationMessageCreate(
            title,
            bodyMessage,
            dismissible,
            startDate,
            endDate,
            color,
            id,
            null,
            id,
            users ? users.map(u => u.value) : [],
            tags ? tags.map(u => u.value) : []
        )

        setLoading(false)
        navigate(`/partner/${id}/messages`)
    }

    const load = async () => {
        setLoading(true)
        await files.getConfig()
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    return (
        <Admin title={t('web_admin_information_messages')} isLoading={isLoading}>
            <Form onSubmit={onSubmit} partnerId={id} />
        </Admin>
    )
}

export default Create
