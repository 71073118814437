const UploadIcon = ({ className }: { className?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 312 312" className={className}>
            <defs>
                <filter id="a" width="290" height="290" x="11" y="14" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur result="blur" stdDeviation="3" />
                    <feFlood floodColor="#fda86a" floodOpacity=".4" />
                    <feComposite in2="blur" operator="in" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g>
                <g>
                    <g fill="none" stroke="rgba(253,168,106,0.5)" strokeWidth="20" transform="translate(20 20)">
                        <circle cx="136" cy="136" r="136" stroke="none" />
                        <circle cx="136" cy="136" r="146" />
                    </g>
                </g>
                <g filter="url(#a)" transform="translate(.003)">
                    <circle cx="136" cy="136" r="136" fill="#fda86a" transform="translate(20 20)" />
                </g>
                <g>
                    <path
                        fill="rgba(229,229,229,0.19)"
                        d="M198.275 176.028a10.814 10.814 0 0 0-2.821-11.5c-3.989-5.018-11.017-12.439-16.471-19.327s-.075-13.578 5.186-6.274l3.35 4.578c.692.918 4.2 5.01 5.278 6.087 2.459 2.448 9.7 1.886 9.7 1.886 1.619-4.955 3.118-44.531 3.118-55.215l-8.446-2.778c-3.6-1.567-8.922-.219-8.922-.219s-27.747 4.491-29.054 4.859c-5.541 1.561-4.742 7.718-2.21 10.207l5.231 5.146c3.682 4.3.252 10.75-5.362 5.5l-16.9-22.177-9.292-4.081c-5.5-3.192-8.389 1.142-13.425 8.9a11.241 11.241 0 0 0-.6 1.185 90.878 90.878 0 0 0-13.364 34.513c-5.241 30.936 4.923 59.649 23.607 72.243 4.605 3.1 19.019 9.311 24.556 10.249 0 0 34.4-16.308 46.046-41.814.021-.032.069-.07.086-.1.079-.156.1-.292.17-.446.172-.385.365-.749.533-1.138.022-.075-.01-.197.006-.284Z"
                    />
                    <path
                        fill="#fff"
                        d="M207.564 180.109a10.813 10.813 0 0 0-2.821-11.5c-3.989-5.018-11.017-12.439-16.47-19.327s1.371-12.912 6.632-5.608l4.74 5.856c1.807 3.059 5.3 4.043 6.9-8.1 1.488-11.278 3.327-26.963 3.556-35.609.3-11.361-8.156-10.107-8.156-10.107s-23.236 3.359-34.008 5.771c-4.99 1.117-6.6 5.6-2.348 9.41l5.056 5.312c6.553 6.553 1.113 12.1-4.5 6.852l-26.2-26.258c-5.5-3.192-8.389 1.142-13.424 8.9a11.248 11.248 0 0 0-.6 1.185 90.88 90.88 0 0 0-13.364 34.513c-6.533 38.561 10.87 73.667 38.871 78.411 21.864 3.7 43.7-12.226 55.338-37.732.021-.032.07-.07.086-.1.08-.156.1-.293.17-.447.172-.384.365-.749.533-1.138.027-.073-.003-.197.009-.284Z"
                    />
                </g>
            </g>
        </svg>
    )
}

export default UploadIcon
