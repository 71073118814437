interface Props {
    title?: string
    className?: string
}

const Users = ({ title, className = 'h-6 w-6' }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-label={title}
            className={className}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
            />
        </svg>
    )
}

export default Users
