import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { buttonClassName, type EmailFormData, emailFormSchema, inputClassName } from '..'

export const EmailForm = ({
    defaultValues,
    onSubmit,
    submitting,
}: {
    defaultValues?: EmailFormData
    onSubmit: (data: EmailFormData) => void
    submitting: boolean
}) => {
    const { t } = useTranslation()

    const methods = useForm<EmailFormData>({
        resolver: zodResolver(emailFormSchema),
    })
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = methods

    useEffect(() => {
        if (defaultValues) {
            setValue('email', defaultValues.email)
        }
    }, [defaultValues])

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8 items-center w-full">
                <div className="w-full">
                    <input
                        autoComplete="off"
                        type="email"
                        required
                        className={inputClassName}
                        placeholder={t('web_file_transfer_download_your_email')}
                        {...register('email')}
                    />
                    {errors.email?.message && (
                        <p className="text-christine text-sm leading-4 pt-1">{errors.email?.message as string}</p>
                    )}
                </div>
                <button type="submit" className={buttonClassName} disabled={submitting}>
                    {t('web_file_transfer_download_email_form_submit')}
                </button>
            </form>
        </FormProvider>
    )
}
