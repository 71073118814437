import { clsx } from 'clsx'
import { getFileSizeSuffix } from 'components/dataroom/upload/file-size'
import { Input, selectStyles } from 'components/shared/select-styles'
import { FC, forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

interface StorageChooserProps {
    onChange: (value: number) => void
    value: number
    className?: string
    disabled?: boolean
}

const StorageChooser: FC<StorageChooserProps> = forwardRef<HTMLDivElement, StorageChooserProps>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ onChange, value, className, disabled }, ref) => {
        const { t } = useTranslation()

        const sizes = [
            { value: 'bytes', label: t('web_admin_file_size_bytes'), multiplier: 1 },
            { value: 'kb', label: t('web_admin_file_size_kb'), multiplier: 1024 },
            { value: 'mb', label: t('web_admin_file_size_mb'), multiplier: 1024 * 1024 },
            { value: 'gb', label: t('web_admin_file_size_gb'), multiplier: 1024 * 1024 * 1024 },
            { value: 'tb', label: t('web_admin_file_size_tb'), multiplier: 1024 * 1024 * 1024 * 1024 },
            { value: 'pb', label: t('web_admin_file_size_pb'), multiplier: 1024 * 1024 * 1024 * 1024 * 1024 },
            { value: 'eb', label: t('web_admin_file_size_eb'), multiplier: 1024 * 1024 * 1024 * 1024 * 1024 * 1024 },
            {
                value: 'zb',
                label: t('web_admin_file_size_zb'),
                multiplier: 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
            },
            {
                value: 'yb',
                label: t('web_admin_file_size_yb'),
                multiplier: 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
            },
        ] as const

        const [size, setSize] = useState<{ value: string; label: string; multiplier: number }>(sizes[0])
        const [number, setNumber] = useState<number>(0)

        useEffect(() => {
            if (typeof value !== 'undefined' && number !== value) {
                const suffix = getFileSizeSuffix(value)
                const size = sizes.find(s => s.value === suffix)

                const multiplier = size.multiplier
                setSize(size)
                setNumber(Math.round(value / multiplier))
            }
        }, [value])

        return (
            <div className={clsx(className ?? 'w-1/3', 'flex gap-2')}>
                <input
                    value={number}
                    onChange={e => {
                        const value = parseInt(e.target.value)
                        if (isNaN(value)) {
                            return
                        }
                        setNumber(value)
                        const multiplier = size.multiplier
                        onChange(value * multiplier)
                    }}
                    type="number"
                    className="w-full"
                    disabled={disabled}
                />

                <Select
                    styles={selectStyles}
                    components={{ Input }}
                    className="w-1/3"
                    value={size}
                    options={sizes}
                    onChange={size => {
                        setSize(size)
                        const multiplier = size.multiplier
                        setNumber(Math.round(value / multiplier))
                    }}
                />
            </div>
        )
    }
)
StorageChooser.displayName = 'StorageChooser'

export default StorageChooser
