import { getExtension } from 'components/dataroom/upload/mime-types'
import Modal from 'components/shared/modal'
import { KeyboardEvent, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconCancel } from '../../assets/notifications/error.svg'
import { ReactComponent as IconConfirm } from '../../assets/notifications/success.svg'

interface Props {
    isEditing: boolean
    isFile?: boolean
    label: string
    onSave: (label: string) => void
    onCancel: () => void
    children?: ReactNode
}

const Editable = ({ isFile = false, isEditing, label, onSave, onCancel, children }: Props) => {
    const [value, setValue] = useState<string>('')
    const [confirmExtension, setConfirmExtension] = useState<boolean>(false)
    const { t } = useTranslation()

    const save = (value: string) => {
        let newLabel = value.trim()

        if (!isFile) {
            onSave(newLabel)

            return
        }

        const extension = getExtension(label)
        const newExtension = getExtension(value)
        if (!value.includes('.') || newExtension === '') {
            newLabel = `${newLabel}.${extension}`
            setValue(newLabel)
        } else if (extension !== newExtension) {
            setConfirmExtension(true)

            return
        }

        onSave(newLabel)
    }

    const onKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
        if (!isEditing) {
            return
        }

        if (e.key === 'Enter') {
            save(value)
        } else if (e.key === 'Escape') {
            onCancel()
        }
    }

    useEffect(() => {
        setValue(label)
    }, [label])

    return (
        <>
            {!isEditing ? (
                children
            ) : (
                <>
                    <input
                        type="text"
                        className="mr-2 w-full border-0 p-0"
                        value={value}
                        onChange={e => setValue(e.currentTarget.value)}
                        onKeyDown={e => onKeyDown(e)}
                    />
                    <div className="flex items-center space-x-1">
                        <button
                            className="btn white h-6 px-2 py-1 text-sushi"
                            onClick={() => save(value)}
                            type="button"
                        >
                            <IconConfirm className="fill-current" />
                        </button>
                        <button className="btn white h-6 px-2 py-1 text-bittersweet" onClick={onCancel} type="button">
                            <IconCancel className="fill-current" />
                        </button>
                    </div>
                    <Modal
                        isOpen={confirmExtension}
                        onCancel={() => setConfirmExtension(false)}
                        cancelLabel={t('web_rename_extension_cancel')}
                        onRequestClose={() => setConfirmExtension(false)}
                        okLabel={t('web_rename_extension_ok')}
                        onConfirm={() => {
                            setConfirmExtension(false)
                            onSave(value)
                        }}
                    >
                        <h4 className="my-4 text-center text-xl">{t('web_rename_extension_title')}</h4>
                        <span className="text-sm text-regent-gray">
                            {t('web_rename_extension', { old: getExtension(label), new: getExtension(value) })}
                        </span>
                    </Modal>
                </>
            )}
        </>
    )
}

export default Editable
