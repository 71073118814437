import { ReactComponent as UploadZen } from 'assets/login/register-zen.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ContentPage from '../dataroom/content-page'
import Modal from '../shared/modal'
import SharingPanel from './sharing-panel'
import WeTreasy from 'components/dataroom/we-treasy'

const Share = () => {
    const { t } = useTranslation()
    const [downloading, setDownloading] = useState<boolean>(false)

    return (
        <ContentPage title={t('web_link_share')} containerClassnames="flex-col space-y-6">
            <SharingPanel showUserSharedByMe setDownloading={setDownloading} />
            <SharingPanel showUserSharedWithMe setDownloading={setDownloading} />

            <WeTreasy />

            <Modal isOpen={downloading} size="1/2" onRequestClose={() => {}} overflowHidden={false}>
                <UploadZen className="-mt-36" />
                <h3 className="mt-12 font-bold">{t('web_share_downloading')}</h3>
            </Modal>
        </ContentPage>
    )
}

export default Share
