import { fileColors } from './file-colors'

interface FileIconProp {
    extension?: string
    className?: string
}
const FileIcon = ({ extension, className = '' }: FileIconProp) => {
    let x: number | undefined = undefined
    let fileColor = extension && fileColors[extension]
    if (!fileColor) {
        fileColor = '#B5C0CC'
    }
    if (extension) {
        x = extension.length === 3 ? 9.412 : 7.412
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="22.794"
            // height="26.119"
            viewBox="0 0 22.794 26.119"
            className={className}>
            <path
                d="M4.884 0a1.635 1.635 0 00-1.628 1.632v22.855a1.635 1.635 0 001.628 1.632h16.281a1.635 1.635 0 001.628-1.632V6.53L16.281 0z"
                fill="#f2f2f2"
            />
            <path
                d="M19.537 21.221a.818.818 0 01-.814.816H.814A.818.818 0 010 21.221v-8.162a.818.818 0 01.814-.816h17.909a.818.818 0 01.814.816z"
                fill={fileColor}
            />
            {extension && x && (
                <text
                    transform={`translate(${x} 19.427)`}
                    fill="#fff"
                    fontSize="6"
                    fontFamily="Quicksand-Bold, Quicksand"
                    fontWeight="700">
                    <tspan x="-5.64" y="0">
                        {extension.toUpperCase()}
                    </tspan>
                </text>
            )}
            <path d="M17.91 6.53h4.884L16.282 0v4.9a1.635 1.635 0 001.628 1.63z" fill="#d0d8e0" />
            <path d="M22.792 11.43l-4.884-4.9h4.884z" fill="#e1e7ed" />
        </svg>
    )
}
export default FileIcon
