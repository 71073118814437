import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import ReactMde, { Command, getDefaultToolbarCommands, MarkdownUtil, TextApi, TextState } from 'react-mde'

interface Props {
    className?: string
    value?: string
    disabled?: boolean
    onChange: (image: string) => void
}

const setHeader = (initialState: TextState, api: TextApi, prefix: '#' | '##' | '###' | '####') => {
    // Adjust the selection to encompass the whole word if the caret is inside one
    const newSelectionRange = MarkdownUtil.selectWord({
        text: initialState.text,
        selection: initialState.selection,
    })

    const state1 = api.setSelectionRange(newSelectionRange)
    const state2 = api.replaceSelection(`${prefix} ${state1.selectedText}`)
    api.setSelectionRange({
        start: state2.selection.end,
        end: state2.selection.end,
    })
}

const h1: Command = {
    icon: () => (
        <span role="img" aria-label="Add Header One">
            H1
        </span>
    ),
    execute: ({ initialState, textApi }) => setHeader(initialState, textApi, '#'),
}
const h2: Command = {
    icon: () => (
        <span role="img" aria-label="Add Header Two">
            H2
        </span>
    ),
    execute: ({ initialState, textApi }) => setHeader(initialState, textApi, '##'),
}
const h3: Command = {
    icon: () => (
        <span role="img" aria-label="Add Header Three">
            H3
        </span>
    ),
    execute: ({ initialState, textApi }) => setHeader(initialState, textApi, '###'),
}
const h4: Command = {
    icon: () => (
        <span role="img" aria-label="Add Header Four">
            H4
        </span>
    ),
    execute: ({ initialState, textApi }) => setHeader(initialState, textApi, '####'),
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MdEditor: FC<Props> = ({ className = '', value, onChange, disabled = false }) => {
    const { t } = useTranslation()
    const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write')

    const tabs = getDefaultToolbarCommands()
    tabs[0].shift()
    tabs[0] = ['h1', 'h2', 'h3', 'h4', ...tabs[0]]

    return (
        <div className={`${className} w-full`}>
            <ReactMde
                value={value}
                onChange={onChange}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                minEditorHeight={500}
                l18n={{
                    write: t('web_mde_write'),
                    preview: t('web_mde_preview'),
                    uploadingImage: t('web_mde_uploading_image'),
                    pasteDropSelect: t('web_mde_paste_drop_select'),
                }}
                generateMarkdownPreview={markdown => Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)}
                commands={{
                    h1,
                    h2,
                    h3,
                    h4,
                }}
                toolbarCommands={tabs}
                childProps={{
                    writeButton: {
                        tabIndex: -1,
                    },
                }}
            />
        </div>
    )
}

export default MdEditor
