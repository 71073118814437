import { zodResolver } from '@hookform/resolvers/zod'
import { ReactComponent as IconAddTrusted } from 'assets/icons/icon-add-trusted.svg'
import 'components/admin/zod'
import toast from 'core/utils/toast'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMst } from 'stores/store'
import { TrustedUser } from 'stores/trustedUser/trusted-user'
import { z } from 'zod'

const schema = z.object({
    email: z.string().email(),
    firstname: z.string().min(1),
    lastname: z.string().min(1),
})
type Schema = z.infer<typeof schema>

interface AddTrustedPersonProps {
    onBack?: () => void
    onCancel?: () => void
    onAdd: (trustedUser: TrustedUser) => void
}

const AddTrustedPerson = ({ onBack, onAdd, onCancel }: AddTrustedPersonProps) => {
    const { t } = useTranslation()
    const { user } = useMst()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Schema>({
        resolver: zodResolver(schema),
    })
    const onSubmit = async (data: Schema) => {
        const { email, firstname, lastname /*, mobile, phone, address, zip, city, country*/ } = data

        const trustedUser = await user.addTrustedPerson(email, firstname, lastname, null, null, null, null, null, 'FR')
        if (trustedUser) {
            onAdd(trustedUser)
        } else {
            toast('error', t('api_add_trusted_person'), undefined, t('api_add_trusted_person_error'))
        }
    }

    return (
        <div className={`w-full rounded-lg bg-white ${onBack ? 'p-4' : ''}`}>
            <div className="flex w-full items-center space-x-4">
                {onBack && (
                    <button className="flex items-center justify-center" onClick={onBack}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.752"
                            height="11.809"
                            viewBox="0 0 6.752 11.809">
                            <path
                                d="M12.094,13.282l4.465,4.469a.84.84,0,0,0,1.192,0,.851.851,0,0,0,0-1.2l-5.059-5.063a.842.842,0,0,0-1.164-.025L6.434,16.552a.844.844,0,1,0,1.192,1.2Z"
                                transform="translate(-11.246 17.996) rotate(-90)"
                                fill="#2f393e"
                            />
                        </svg>
                    </button>
                )}
                <div
                    className={
                        'flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-atomic-tangerine text-white'
                    }>
                    <IconAddTrusted className="fill-current" />
                </div>
                <h1 className="text-2xl">{t('web_share_add_trusted_person')}</h1>
            </div>
            <form className="my-3 flex w-full flex-col items-center space-y-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid w-full grid-cols-2 gap-4">
                    <div className="col-span-2 flex flex-col ">
                        <input
                            {...register('email')}
                            type="text"
                            className="w-full"
                            autoComplete="off"
                            placeholder={t('web_share_add_trusted_email')}
                        />
                        {errors.email?.message && (
                            <span className="text-christine">
                                <>
                                    {t('web_share_add_trusted_email')} {errors.email?.message}
                                </>
                            </span>
                        )}
                    </div>
                    <div className="flex flex-col">
                        <input
                            {...register('firstname')}
                            type="text"
                            className="w-full"
                            autoComplete="off"
                            placeholder={t('web_share_add_trusted_firstname')}
                        />
                        {errors.firstname?.message && (
                            <span className="text-christine">
                                <>
                                    {t('web_share_add_trusted_firstname')} {errors.firstname?.message}
                                </>
                            </span>
                        )}
                    </div>
                    <div className="flex flex-col">
                        <input
                            {...register('lastname')}
                            type="text"
                            className="w-full"
                            autoComplete="off"
                            placeholder={t('web_share_add_trusted_lastname')}
                        />
                        {errors.lastname?.message && (
                            <span className="text-christine">
                                <>
                                    {t('web_share_add_trusted_lastname')} {errors.lastname?.message}
                                </>
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex items-center justify-center space-x-4">
                    {onCancel && (
                        <button type="button" className="btn white" onClick={() => onCancel()}>
                            {t('web_cancel')}
                        </button>
                    )}
                    <button type="submit" className="btn">
                        {t('web_share_add_trusted_save')}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AddTrustedPerson
